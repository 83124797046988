import { ICatalougeItemInterface, IDeliveryChallanItemOrder, INotification } from "./types";
import * as Yup from "yup";
import moment from "moment-timezone";

export const regexNumeric = /^\d+$/;
export const regexNumberDecimal = /^\d*\.?\d*$/;

export const paginate = (page: number, dataList: any[], rowsPerPage:number) => {
    const currentPage = page - 1;
    const startIndex = currentPage*rowsPerPage;
    const currentDataList= dataList.slice(startIndex, rowsPerPage*page);
    let endIndex = rowsPerPage*(currentPage + 1);
    if(endIndex > dataList.length) {
      endIndex = dataList.length;
    }
    return {
      startIndex,
      endIndex,
      currentDataList, 
      currentPage: page,
    }
}

export const roundNumber = (num:number)=>{
    return Math.round(num*100)/100
}

export const convertUtcToIst = (utcDateStr: string) => {
  if(utcDateStr){
    const utcMoment = moment.utc(utcDateStr);
  
  // Convert to IST
  const istMoment = utcMoment.tz('Asia/Kolkata');
  
  // Format the IST date
  const formattedISTDate = istMoment.format('DD/MM/YYYY - hh:mm A');
  
  return formattedISTDate;
  }
  return "";
};

export const handlePaginationWithFilter = (e: any ,page: number, dataList : any, rowsPerPage:number, totalRecords:number) => {
  const currentPage = page - 1;
  const startIndex = currentPage*rowsPerPage;
  let endIndex = rowsPerPage*(currentPage + 1);
  
  if(endIndex > totalRecords) {
    endIndex = startIndex + dataList.length ; //6
  }

 return {
    startIndex,
    endIndex,
    currentDataList:dataList, 
    currentPage: page,
  }
}

export const getVendorForm = (values:any, image: any,data:any={}) => {    
  let formData = new FormData();
  Object.keys(values).forEach((key:any) => {
        if(key === "photo"){
          if(image?.file){
            formData.append(`data[${key}]`,image.file);
          }
        }else if(key === "latitude" ){         
          values[key]===0&&data? formData.append(`data[${key}]`,data.attributes.latitude):formData.append(`data[${key}]`,values[key])
        }
        else  if(key === "longitude" ){         
          values[key]===0&&data? formData.append(`data[${key}]`,data.attributes.longitude):formData.append(`data[${key}]`,values[key])
        }
        else{
           formData.append(`data[${key}]`,values[key]);
        }      
    
    });
  return formData;
}

export const calculateTotal = (dataArray : {[keyVal:string] : any}[], key:string) =>{
 return dataArray.reduce((acc,curr)=> acc + Number(curr[key]) ,0)
   
}

const createDarkstoreInventoryData = (sNo: number, itemDetail: string, itemCode: string, quantity: number, stockLevel: string) =>  {
  return { sNo,id: sNo, itemDetail, itemCode, quantity, stockLevel };
}

const createSalesForceData = (sNo: number, itemDetail: string, itemCode: string, quantity: number, stockLevel: string) =>  {
return { sNo, itemDetail, itemCode, quantity, stockLevel };
}

export const createPurchaseOrdersData = (id : string, poNo: string, supplierName: {attributes : {name : string}} | null,creatorEmail:string, creationDate: string, expectedInboundDate: string, totalValue: string, status: string) =>  {
return { id, poNo, supplierName,creatorEmail, creationDate : formatDate(creationDate), expectedInboundDate : formatDate(expectedInboundDate), totalValue: `₹${totalValue}`,status };
}

export const createDeliveryChallanData = (sNo: number, orderID: string|number,warehouseName:string, estimatedDate: string, status: string, orderNumber:string|number) =>  {
  return {id:orderID, sNo, orderID,warehouseName, estimatedDate:formatStringDate(estimatedDate), status,orderNumber };
}

const createInwardsInventoryData = (sNo: number, orderID: string,warehouseName:string, estimatedDate: string, status: string) =>  {
  return { sNo, id:sNo, orderID,warehouseName, estimatedDate, status };
}

const createDarkstoreOrdersData = (sNo: number, orderID: string,buyerName:string, edt: string, adt: string, deliveryAgent: string, status: string) =>  {
  return { sNo, orderID,buyerName, edt, adt, deliveryAgent,status };
}

export const createMassUploadRecordData = (date:string,fileName:string, totalCount:number, uploaded:number, status:boolean )=>{
  

  return {date:dateTimeFormat(date),file:fileName, product:`${uploaded}/${totalCount}`,status:status ? "Successful" : "Failed"}
}

export const formatDate = (date:string | Date) => {
  if(date){
    const updatedDate = new Date(date)
    const day = String(updatedDate.getDate()).padStart(2, '0');
    const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
    const year = updatedDate.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  else {
  return "--"
  }
 
}

export const checkSubsringPresent =(mainStr:string, subStr:string)=>{
  const regex = new RegExp(subStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
  return regex.test(mainStr)
}

export const dateTimeFormat = (date:string)=>{
  const utcTime = new Date(date);
  const istTime = new Date(utcTime.getTime() );
  
  const formatTwoDigits = (num:number) => (num < 10 ? `0${num}` : num);
  
  const istYear = istTime.getFullYear();
  const istMonth = formatTwoDigits(istTime.getMonth() + 1);
  const istDay = formatTwoDigits(istTime.getDate());
  const istHour = formatTwoDigits(istTime.getHours());
  const istMinute = formatTwoDigits(istTime.getMinutes());
  const istSecond = formatTwoDigits(istTime.getSeconds());
  
  return`${istDay}/${istMonth}/${istYear}, ${istHour}:${istMinute}:${istSecond}`;
}

const formatStringDate = (date:String):String=>{
  return date.split("-").reverse().join("/")
}

export const foramtedDateForNotification =(date:string)=>{
  const utcTime = new Date(date);
  const istTime = new Date(utcTime.getTime() );

  const currentDateTime = new Date()
  const formatTwoDigits = (num:number) => (num < 10 ? `0${num}` : num);
  const currentDay = currentDateTime.getDate();
  const currentMonth = currentDateTime.getMonth();
  const currentYear = currentDateTime.getFullYear();

  const istYear = istTime.getFullYear();
  const istMonth = formatTwoDigits(istTime.getMonth() + 1);
  const istDay = formatTwoDigits(istTime.getDate());
  const istHour = istTime.getHours() < 12 ? formatTwoDigits(istTime.getHours()) : formatTwoDigits(istTime.getHours()-12)  ;
  const istMinute = formatTwoDigits(istTime.getMinutes());
  const period =  istTime.getHours() < 12 ? "AM" : "PM"  
  
  if(currentYear === istTime.getFullYear() && currentMonth === istTime.getMonth() && currentDay === istTime.getDate()){
  	return`${istHour}:${istMinute} ${period}`
  }
  else{
  	return`${istDay}/${istMonth}/${istYear.toString().slice(-2)}, ${istHour}:${istMinute} ${period}`;
  }
}

export const createDataForNotification =(data:INotification[])=>{
    const formatedNotification = data.map((notification:INotification)=>{
      return {
        id:notification.attributes.id,
        notification:notification.attributes.contents,
        date:notification.attributes.created_at
      }
    })
    return formatedNotification
}

export const formatStatus = (status:string) => {
  switch(status){
    case "pending":
       return "Pending"
    case "cancelled":
      return "Cancelled"
    case "inbounded":
      return "Inbounded"
    case "pending_supplier":
      return "Pending Supplier"
      default: return ""
  }
}

export const getDarkstoreInventoryStaticData = () => {
  const dataList = [
    createDarkstoreInventoryData(1, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(2, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(3, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(4, "Toor dal, 500g", "SKU 345-091", 4, "Low Stock"),
    createDarkstoreInventoryData(5, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(6, "Toor dal, 500g", "SKU 345-091", 4, "Low Stock"),
    createDarkstoreInventoryData(7, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(8, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(9, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(10, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(11, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(12, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(13, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(14, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(15, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(16, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(17, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(14, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(15, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(16, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(17, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(18, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(19, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(20, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(21, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(22, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(23, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(24, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(25, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(26, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(27, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(28, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(29, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(30, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createDarkstoreInventoryData(31, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(32, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createDarkstoreInventoryData(33, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    ];
    return dataList;
}

export const getSalesForceStaticData = () => {
  const dataList = [
    createSalesForceData(1, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(2, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(3, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(4, "Toor dal, 500g", "SKU 345-091", 4, "Low Stock"),
    createSalesForceData(5, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(6, "Toor dal, 500g", "SKU 345-091", 4, "Low Stock"),
    createSalesForceData(7, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(8, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(9, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(10, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(11, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(12, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(13, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(14, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(15, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(16, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(17, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(14, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(15, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(16, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(17, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(18, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(19, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(20, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(21, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(22, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(23, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(24, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(25, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(26, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(27, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(28, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(29, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(30, "Toor dal, 500g", "SKU 345-091", 4, "Out of Stock"),
    createSalesForceData(31, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(32, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    createSalesForceData(33, "Toor dal, 500g", "SKU 345-091", 4, "Full Stock"),
    ];
  return dataList;
}

export const getInwardsInventoryStaticData = () => {
  const dataList = [
    createInwardsInventoryData(1, "#354-091","Warehouse 1", "15 August 2023", "Pending"),
    createInwardsInventoryData(2, "#354-091","Warehouse 2", "15 August 2023", "Pending"),
    createInwardsInventoryData(3, "#354-091","Warehouse 1", "15 August 2023", "Ready"),
    createInwardsInventoryData(4, "#354-091","Warehouse 3", "15 August 2023", "Delivering"),
    createInwardsInventoryData(5, "#354-091","Warehouse 1", "15 August 2023","Completed"),
    createInwardsInventoryData(6, "#354-091","Warehouse 1", "15 August 2023", "Pending"),
    createInwardsInventoryData(7, "#354-091","Warehouse 1", "15 August 2023", "Pending"),
    createInwardsInventoryData(8, "#354-091","Warehouse 1", "15 August 2023", "Ready"),
    createInwardsInventoryData(9, "#354-091","Warehouse 1", "15 August 2023", "Delivering"),
    createInwardsInventoryData(10, "#354-091","Warehouse 1", "15 August 2023","Completed"),
    createInwardsInventoryData(11, "#354-091","Warehouse 1", "15 August 2023", "Pending"),
    createInwardsInventoryData(12, "#354-091","Warehouse 1", "15 August 2023", "Pending"),
    createInwardsInventoryData(13, "#354-091","Warehouse 1", "15 August 2023", "Ready"),
    createInwardsInventoryData(14, "#354-091","Warehouse 1", "15 August 2023", "Delivering"),
    createInwardsInventoryData(15, "#354-091","Warehouse 1", "15 August 2023","Completed"),
    createInwardsInventoryData(16, "#354-091","Warehouse 1", "15 August 2023", "Pending"),
    createInwardsInventoryData(17, "#354-091","Warehouse 1", "15 August 2023", "Pending"),
    ];
  return dataList;
}

export const getDeliveryChallanStaticData = () => {
  const dataList = [
    createDeliveryChallanData(1, "#354-091","Warehouse 1", "15 August 2023", "Pending",1),
    createDeliveryChallanData(2, "#354-091","Warehouse 2", "15 August 2023", "Pending",1),
    createDeliveryChallanData(3, "#354-091","Warehouse 1", "15 August 2023", "Ready",1),
    createDeliveryChallanData(4, "#354-091","Warehouse 3", "15 August 2023", "Delivering",1),
    createDeliveryChallanData(5, "#354-091","Warehouse 1", "15 August 2023","Completed",1),
    createDeliveryChallanData(6, "#354-091","Warehouse 1", "15 August 2023", "Pending",1),
    createDeliveryChallanData(7, "#354-091","Warehouse 1", "15 August 2023", "Pending",1),
    createDeliveryChallanData(8, "#354-091","Warehouse 1", "15 August 2023", "Ready",1),
    createDeliveryChallanData(9, "#354-091","Warehouse 1", "15 August 2023", "Delivering",1),
    createDeliveryChallanData(10, "#354-091","Warehouse 1", "15 August 2023","Completed",1),
    createDeliveryChallanData(11, "#354-091","Warehouse 1", "15 August 2023", "Pending",1),
    createDeliveryChallanData(12, "#354-091","Warehouse 1", "15 August 2023", "Pending",1),
    createDeliveryChallanData(13, "#354-091","Warehouse 1", "15 August 2023", "Ready",1),
    createDeliveryChallanData(14, "#354-091","Warehouse 1", "15 August 2023", "Delivering",1),
    createDeliveryChallanData(15, "#354-091","Warehouse 1", "15 August 2023","Completed",1),
    createDeliveryChallanData(16, "#354-091","Warehouse 1", "15 August 2023", "Pending",1),
    createDeliveryChallanData(17, "#354-091","Warehouse 1", "15 August 2023", "Pending",1),
    ];
  return dataList;
}

export const getDarkstoreOrdersStaticData = () => {
  const dataList = [
    createDarkstoreOrdersData(1, "#354-091","Shree ram", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    createDarkstoreOrdersData(2, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    createDarkstoreOrdersData(3, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "Manoj Kumar","Ready"),
    createDarkstoreOrdersData(4, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "abdul","Delivering"),
    createDarkstoreOrdersData(5, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "15 /03 /2023 - 10.40AM", "Karthik","Delivered"),
    createDarkstoreOrdersData(6, "#354-091","Shree ram", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    createDarkstoreOrdersData(7, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    createDarkstoreOrdersData(8, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "Manoj Kumar","Ready"),
    createDarkstoreOrdersData(9, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "abdul","Delivering"),
    createDarkstoreOrdersData(10, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "15 /03 /2023 - 10.40AM", "Karthik","Delivered"),
    createDarkstoreOrdersData(11, "#354-091","Shree ram", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    createDarkstoreOrdersData(12, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    createDarkstoreOrdersData(13, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "Manoj Kumar","Ready"),
    createDarkstoreOrdersData(14, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "abdul","Delivering"),
    createDarkstoreOrdersData(15, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "15 /03 /2023 - 10.40AM", "Karthik","Delivered"),
    createDarkstoreOrdersData(16, "#354-091","Shree ram", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    createDarkstoreOrdersData(17, "#354-091","Manoj", "15 /03 /2023 - 10.20AM", "-", "-","Pending"),
    ];
    return dataList;
}


export const getDiliveryOrderSelectedItem = (data:IDeliveryChallanItemOrder):ICatalougeItemInterface[]=>{
  const selectedData = data.attributes.catalogues.map(item=>{
    return {
      catalogue_id :item.catalogue_id,
      catalogue_name: item.catalogue_name,
      price: item.catalogue_price,
      hsn_code:item.hsn_code,
      item_code:item.item_code,
      quantity:item.quantity,
      displayLocation:item.display_location,
      weight_unit:item.weight_unit,
      weight:item.weight

    } as ICatalougeItemInterface
  })

  return selectedData
}

export const stockStatusLabel = (status: string) =>{
  switch(status){
    case 'full_stock':
      return "Full Stock";
    case "out_of_stock":
      return "Out of Stock";
    case "low_stock":
      return "Low Stock";
      default: return status
  }
}

export const NoPermissionLabel = "Sorry, you don't have the permission";

export const VendorManagementForm1Schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Email is required"),
  legal_name: Yup.string()
    .nullable()
    .required("Legal name is required"),
  address: Yup.string()
  .nullable()
  .required("Address is required"),
  contact: Yup.string()
    .nullable()
    .required("Contact is required")
    .matches(/^\d+$/, 'Contact must be a valid number')
    .min(10, 'Contact must be at least 10 digits')
    .max(15, 'Contact can be at most 15 digits'),
  
  store_size: Yup.number()
    .typeError('Please enter a valid store size')
    .required('Store size is required')
    .min(1, "Store size can't be zero"),
  pan: Yup.string()
  .required('PAN number is required')
  .matches(
    /^[A-Z]{5}\d{4}[A-Z]{1}$/,
    'PAN number must be in the format: 5 uppercase letters followed by 4 digits and 1 uppercase letter'
  ),
  limit: Yup.number()
    .typeError('Please enter a valid limit')
    .required('Limit is required')
    .min(1, "Limit can't be zero")
    .max(20, 'limit must be a number between 1 and 20'),
  ifsc: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
      .required('IFSC code is required')
    ,
  authorized_person_name: Yup.string()
    .nullable()
    .required("Authorized person name is required"),
  authorized_person_contact: Yup.string()
    .nullable()
    .required("Authorized person contact is required")
    .matches(/^\d+$/, 'Contact number must be a valid number')
    .min(10, 'Contact number must be at least 10 digits')
    .max(15, 'Contact number can be at most 15 digits'),
  bank_account_number: Yup.number()
    .nullable()
    .required("Bank account number is required"),
  bank_branch: Yup.string()
  .nullable()
  .required("Bank branch is required"),
  authorized_person_designation: Yup.string()
  .nullable()
  .required("Authorized person designation is required"),
});

export const checkIsFinite=(expression:any)=>{
  if( isFinite(expression)){
    return expression
  }
  else{
    return 0
  }
}