import React from "react";
// Customizable Area Start
import { withStyles, Table, Menu, TableHead, TableBody, TableCell, TableRow, Typography, Link, MenuItem, FormLabel } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import DarkstoreOrderDetailsController, { Props, rows, itemRows } from "./DarkstoreOrderDetailsController.web";
import DarkStoreDashboardLayout from "../../../components/src/DarkStoreDashboardLayout.web";
import {convertUtcToIst} from "../../../components/src/CommonFunctions";
import CustomPagination from "../../../components/src/Pagination.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import Loader from "../../../components/src/Loader.web";
const inventoryImg = require("../../../components/src/assets/images/product.jpeg");

const styles: any = {
    container: {
        margin: "24px",
        padding: "24px",
        background: "#fff",
        borderRadius: "10px",
        "& table":{
            "& td":{
                fontFamily:"gilroy-medium"
            },
            "& th":{
                fontFamily:"gilroy-bold"
            },
        }
    },
    orderDetailsHeader: {
        display: "flex",
        justifyContent:"space-between",
        "& h6": {
            fontSize: "16px",
            fontFamily: "gilroy-bold",
            marginBottom:"24px"
        }
    },
    statusDropdown:{
        color: "#6D6D6D",
        textDecoration:"underline",
    },
    table:{

        "& tbody tr":{
            borderColor:"#F0F0F0",
            "& td.Assigned, td.Pending, td.Ready, td.Delivered, td.On_the_way, td.Cancelled ":{
                padding:"0px",
            },
            "& td.Assigned span, td.Pending span, td.Packed span, td.Delivered span, td.On_the_way span,td.Cancelled span":{
                padding:"6px 16px",
                borderRadius:"6px",
                minWidth:"110px",
                display:"inline-flex",
                justifyContent:"center",
            },
            "& td.Assigned span":{
                background:"#fffae2",
                color:"#CBC61C"
            },
            "& td.Cancelled span": {
                background: "red",
                color: "white"
            },
            "& td.Delivered span":{
                background:"#e4fcd8",
                color:"#239A3C"
            },
            "& td.Pending span":{
                background:"#fed4d5",
                color:"#C62323"
            },
            "& td.On_the_way span":{
                background: "#D4E6FE",
                color: "#2368C6"
            },
            "& td.Packed span":{
                background:"#FEE5D4",
                color: "#C67723"
            },
        }
    },
    orderDetails:{
        padding:"24px 0",
        "& table":{
            maxWidth:"560px",
        },  
        "& td":{
            fontFamily:"gilroy-medium",
            padding:"12px 12px 12px 0 !important"
        },
        "& tr, td":{
            border:"none !important"
        }
    },
    key:{
        fontFamily:"gilroy-bold !important",
        paddingLeft:"0px !important",
        whiteSpace:"nowrap"
    },
    value:{
        color:"#83889e"
    },
    totalkey:{
        fontFamily:"gilroy-extra-bold !important"
    },
    totalValue:{
        fontFamily:"gilroy-extra-bold !important",
        color:"#83889e"
    },
    itemsTable:{
        "& h6": {
            fontSize: "14px",
            fontFamily: "gilroy-bold",
            marginBottom:"24px"
        },
        "& th": {
            fontFamily: "gilroy-bold",
            maxWidth:"350px",
            minWidth:"50px"
        }, 
    },
    items:{
        padding:"24px 0",
        width:"260px",
        marginLeft:"auto",
        marginRight:"0px",
        "& table":{
            maxWidth:"560px",
        },
       
        "& td":{
            fontFamily:"gilroy-medium",
            padding:"12px !important"
        },
        "& tr, td":{
            border:"none !important"
        },
        "& tr:last-child":{
            "& td":{
                borderTop:"2px solid #DFDFDF !important"
            }
        }
    },
    itemImg:{
        display:"inline-flex",
        justifyContent:"flex-start",
        alignItems:"center",
        width:"100%",
        "& img":{
            marginRight:"8px",
            height:"50px",
            paddingRight:"8px",
            width:"80px",
        }
    },
    image:{
        height:"50px",
        width:"80px",
        display:"flex",
        alignItems:"center"
    },
    photos:{
        "& h6":{
            marginTop:"24px",
            marginBottom:"24px",
            fontSize: "14px",
            fontFamily: "gilroy-bold",
        },
        "& h5":{
            marginTop:"24px",
            marginBottom:"24px",
            fontSize: "16px",
            fontFamily: "gilroy-bold",
        }
    },
    photoContainer:{
        display:"inline-flex"
    },
    itemPhoto:{
        padding:"12px",
        border:"1px solid #EFEFEF",
        borderRadius:"8px",
        "& img":{
            height:"73px"
        }
    }
}

class DarkstoreOrderDetails extends DarkstoreOrderDetailsController {
    conditionShort =(conditon:any,trueStatement:any,falseStatement:any) =>{
        return  conditon?trueStatement:falseStatement
     }

     loadingPage = () => {
       return this.state.isLoading ? <Loader loading={this.state.isLoading} /> :<NoAccessPage description="Something went wrong"/> 
     }

     isLoading = () => {
        return this.state.orderDetails !== null  && this.state.orderDetails.hasOwnProperty("attributes") && !this.state.isLoading
     }
    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { orderDetails, metaData, endIndex, startIndex, currentPage,total_pages,isLoading } = this.state; 

       
        
        return (
            <DarkStoreDashboardLayout {...restProps} headerTitle="Orders" actionHandler={undefined} headerAction="" subTitle="" hasAccess={true}>
            { (orderDetails !== null && this.isLoading()) ? 
            <div className={`${classes.container} order-details`}>
                 <div className={classes.orderDetailsHeader}>
                     <Typography variant="h6">Order details</Typography>
                     <Link className={classes.statusDropdown} id="status-dropdown" onClick={this.handleSelectDropdown}>Update status</Link>
                     <Menu
                     onClose={this.handleClose} 
                     PaperProps={{
                         style:{
                             marginTop:"24px"
                         }
                     }} anchorEl={this.state.anchorEl} open={this.state.isOpen}>
                         <MenuItem className="statusButton" onClick={this.changeStatusReady}>Ready</MenuItem>
                     </Menu>
                 </div>
                 <Table className={classes.table}>
                     <TableHead>
                         <TableRow>
                             {rows.map((row:any) => <TableCell key={row.key} align="center">{row.label}</TableCell>)}
                         </TableRow>
                     </TableHead>
                     <TableBody>
                         <TableRow>
                             <TableCell align="center">{orderDetails.attributes.order_number}</TableCell>
                             <TableCell align="center">{orderDetails.attributes.account.first_name}</TableCell>
                             <TableCell align="center">{convertUtcToIst(orderDetails.attributes.arrival_time)}</TableCell>
                             <TableCell align="center">{convertUtcToIst(orderDetails.attributes.delivered_at)}</TableCell>
                             <TableCell align="center">{convertUtcToIst(orderDetails.attributes.placed_at)}</TableCell>
                             <TableCell align="center" className={this.getOrderStatus(orderDetails.attributes.status)}>
                                 <span>{this.getOrderStatus(orderDetails.attributes.status)}</span>
                             </TableCell>
                         </TableRow>
                     </TableBody>
                 </Table>
                 <div className={classes.orderDetails}>
                     <Table>
                         <TableBody>
                             <TableRow>
                                 <TableCell className={classes.key}>Customer Name</TableCell>
                                 <TableCell className={classes.value}>{orderDetails.attributes.account.first_name}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell className={classes.key}>Customer Phone Number</TableCell>
                                 <TableCell className={classes.value}>{orderDetails.attributes.account.full_phone_number}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell className={classes.key}>Customer Address</TableCell>
                               
                                 <TableCell  className={classes.value} >
                                 <Typography style={{color:"white",
                                     backgroundColor:"#652d92 ",
                                     borderRadius:"5px",
                                     width:"12%",
                                     border:"1px solid #652d92;",
                                     padding:"1px",
                                     marginBottom:"5px",
                                     display:"flex",
                                     alignItems:"center",
                                     justifyContent:"center",
                                     boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                     fontWeight:"bold",
                                     fontSize:"12px"
                                     
                                 }}
                                 
                                 >
                                     {orderDetails.attributes.delivery_address?.address_type}</Typography>
                                     <Typography data-test-id='delivery-address' className={classes.value}>

                                     {`${orderDetails.attributes.delivery_address?.block_no ? orderDetails.attributes.delivery_address.block_no + ", " : ""}${
                                         orderDetails.attributes.delivery_address?.flat_no ? orderDetails.attributes.delivery_address.flat_no + ", " : ""
                                        }${orderDetails.attributes.delivery_address?.address}`}
                                        </Typography>
                                </TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell className={classes.key}>Darkstore Address</TableCell>
                                 <TableCell className={classes.value}>{orderDetails.attributes.dark_store.address}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell className={classes.key}>Delivery Agent Name</TableCell>
                                 <TableCell className={classes.value}>{orderDetails.attributes?.delivery_agent?.first_name}</TableCell>
                             </TableRow>
                         </TableBody>
                     </Table>
                 </div>
                 <div className={classes.itemsTable}>
                     <Typography variant="h6">Items</Typography>
                     <Table>
                         <TableHead>
                             <TableRow>
                                 {itemRows.map((row:any) => <TableCell key={row.key} align="left">{row.label}</TableCell>)}
                             </TableRow>
                         </TableHead>
                         <TableBody>
                             {orderDetails.attributes.order_items.length > 0 && orderDetails.attributes.order_items.map((item: any, index: number) => (
                                 <TableRow key={item.id}>
                                 <TableCell align="left">{startIndex + index}</TableCell>
                                 <TableCell align="left" className={classes.itemImg}>
                                      <div className={classes.image}>
                                         {item.attributes.catalogue.attributes.images ? <img src={item.attributes.catalogue.attributes.images[0].url } /> : <img src={inventoryImg} />}
                                         </div>
                                         <div>
                                         <FormLabel>
                                         <span className="detail">{item.attributes.catalogue.attributes.name}</span></FormLabel>  
                                         
                                         </div>
                                    

                                 </TableCell>
                                 <TableCell align="left">{item.attributes.quantity}</TableCell>
                                 <TableCell align="left">{item.attributes.total_price}</TableCell>
                             </TableRow>
                             ))}
                         </TableBody>
                     </Table>
                 </div>
                 <div style={{marginTop:"20px"}}>
                 { this.conditionShort(orderDetails.attributes?.order_items.length,<CustomPagination
                     dataLength={!!metaData && metaData.total_count}
                     rowsCount={total_pages}
                     endIndex={endIndex}
                     handlePrevNext={this.handleDarkstoreOrdersDetailsPrevNext}
                     handlePagination={this.handleDarkstoreOrdersDetailsPagination}
                     startIndex={startIndex - 1}
                     currentPage={currentPage}
                 /> ,"")}
                 </div>
                 <div className={classes.items}>
                     <Table>
                         <TableBody>
                             <TableRow>
                                 <TableCell align="right" className={classes.totalValue}>Subtotal</TableCell>
                                 <TableCell align="right" className={classes.totalkey}>₹ {orderDetails.attributes.sub_total}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell align="right" className={classes.value}>Delivery Charges</TableCell>
                                 <TableCell align="right" className={classes.key}>₹ {orderDetails.attributes.delivery_charges}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell align="right" className={classes.value}>Handling Fee</TableCell>
                                 <TableCell align="right" className={classes.key}>₹ {orderDetails.attributes.handling_fee}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell align="right" className={classes.value}>Surcharge</TableCell>
                                 <TableCell align="right" className={classes.key}>₹ {orderDetails.attributes.surge_charge}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell align="right" className={classes.value}>Small Order Fee</TableCell>
                                 <TableCell align="right" className={classes.key}>₹ {orderDetails.attributes.small_order_fee}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell align="right" className={classes.value}>Total</TableCell>
                                 <TableCell align="right" className={classes.key}>₹ {orderDetails.attributes.total}</TableCell>
                             </TableRow>
                         </TableBody>
                     </Table>
                 </div>
                                     {!!orderDetails.attributes ? 
                     <div className={classes.photos}>
                     <Typography variant="h5">Photos</Typography>
                     <Typography variant="h6">Pickup photos</Typography>
                     {!!orderDetails.attributes.pickup_photo && <div className={classes.photoContainer}>
                         <div className={classes.itemPhoto}>
                             <img src={orderDetails.attributes.pickup_photo} />
                         </div>
                     </div>}
                     <Typography variant="h6">Delivered photos</Typography>
                     {!!orderDetails.attributes.delivery_photo && <div className={classes.photoContainer}>
                         <div className={classes.itemPhoto}>
                         <img src={orderDetails.attributes.delivery_photo} />
                         </div>
                     </div>}
                 </div>:<></>}
                 
             </div> : this.loadingPage()}
         </DarkStoreDashboardLayout>
        
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { DarkstoreOrderDetails };
export default withStyles(styles)(withRouter(DarkstoreOrderDetails));
// Customizable Area End