import { Box, TextField, Grid, Menu, MenuItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import OutboundOrderItemTable from "./OutboundOrderItemTable.web";
import DatePicker from "react-datepicker" ;
import "react-datepicker/dist/react-datepicker.css";
import { IDeliveryChallanItemOrder } from "./types";
import { getDiliveryOrderSelectedItem } from "./CommonFunctions";
interface Props extends RouteComponentProps, WithStyles<typeof styles> {
    anchorEl: null | HTMLElement;
    handleClose: () => void;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    itemList:any[];
    orderDetails:IDeliveryChallanItemOrder ;
    globalStore: boolean;
    handleUpdateStatus : (orderId: string| number,status:string)=>void
}

const styles: any = {
    darkStoreName: {
        color: "#000",
        fontFamily: "gilroy-bold",
        fontSize: "16px",
    },
    fontStyle:{
        color: "#808080",
        fontFamily: "gilroy-medium",
        fontSize: "16px",
        letterSpacing: "0.797px",
    },
    underline: {
        textDecoration: "underline",
        cursor: "pointer"
    },
    addressSection: {
        width: "500px",
    },
    datePickerConatiner:{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    inputLabel:{
        color: "#000",
        fontFamily: "gilroy-medium",
        fontSize: "16px",
    },
    image:{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width:"169px"
    },
    activeStatus:{
        backgroundColor:"#8356a7", 
        color:"white !important"
    },
    ewayBillMarginLeft: {
        display:"inline",
        marginLeft:"2rem",
        letterSpacing:0
    },
    menuItem:{
        color: "#595959",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "11px",
        fontWeight: 600,
    }
}

class OutboundOrderDetail extends React.Component<Props>{
    constructor(props: any) {
        super(props)
    }

    render() {
        const { classes, anchorEl, handleClick, handleClose ,orderDetails,globalStore,handleUpdateStatus} = this.props
        return (
            <div className="main_container">
                <Grid container spacing={3}>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                        <Typography className={classes.darkStoreName} variant="body1">{orderDetails?.attributes.store?.legal_name || "--"}</Typography>  
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} style={{textAlign:"end"}}>
                        <span aria-controls="simple-menu" onClick={handleClick} className={classes.underline}>
                            Update Status
                        </span>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem disabled className={`${classes.menuItem} ${orderDetails.attributes.status === "pending"? classes.activeStatus:""}`}>Pending</MenuItem>
                            <MenuItem onClick={()=>handleUpdateStatus(orderDetails.attributes.id,"delivering")} disabled ={orderDetails.attributes.status === "completed" || orderDetails.attributes.status === "delivering" ? true: false}  className={`${classes.menuItem} ${orderDetails.attributes.status === "delivering"? classes.activeStatus:""}`}>Delivering</MenuItem>
                            <MenuItem onClick={()=>handleUpdateStatus(orderDetails.attributes.id, "completed")} disabled ={globalStore || orderDetails.attributes.status === "completed" ? true : false}  className={`${classes.menuItem} ${orderDetails.attributes.status === "completed"? classes.activeStatus:""}`}>Completed</MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                        <Typography className={`${classes.addressSection} ${classes.fontStyle} `} variant="body2">{orderDetails.attributes.shipment_address}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={`${classes.fontStyle} `} variant="body2">{orderDetails.attributes.creator_email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={`${classes.inputLabel}`} style={{display:"inline"}} variant="body2">E Way Bill No</Typography>  <Typography className={`${classes.ewayBillMarginLeft} ${classes.fontStyle}`} variant="body2">{orderDetails.attributes.eway_bill_no}</Typography>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                        <Typography className={`${classes.inputLabel}`} variant="body2">Items</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <OutboundOrderItemTable editable={false} itemData={getDiliveryOrderSelectedItem(orderDetails)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className={classes.datePickerConatiner}>
                            <Typography className={classes.inputLabel} variant="inherit">Expected Delivery Date</Typography>
                                <DatePicker 
                                    selected={new Date(orderDetails.attributes.delivery_date)}
                                    onChange={()=>{}}
                                    dateFormat="dd/MM/yyyy"
                                    disabled
                                    customInput={
                                        <TextField
                                        className="width100"
                                        variant="outlined"
                                        value={new Date(orderDetails.attributes.delivery_date)}
                                        disabled
                                        />
                                    }
                                />       
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={`${classes.inputLabel}`} variant="body2">QR Code details</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <img src={orderDetails.attributes.qr?.url} alt="QR Code details" className={classes.image}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export { OutboundOrderDetail }
export default withStyles(styles)(withRouter(OutboundOrderDetail));