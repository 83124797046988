Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "vendormanagement2";
exports.labelBodyText = "vendormanagement2 Body";

exports.createDarkstoreEndPoint = "/account_block/dark_stores";
exports.createDarkstoreContentType = "";
exports.createDarkstoreMethodType = "POST";


exports.btnExampleTitle = "CLICK ME";
// Customizable Area End