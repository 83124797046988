import React from "react";
// Customizable Area Start
import { makeStyles ,Dialog, IconButton,DialogContent, DialogActions, Button} from "@material-ui/core";
import { CloseIcon, AlertIcon } from "./assets.web";

const useStyles : any = makeStyles({
    paper:{
        marginTop:"100px",
        maxHeight:"950px",
    },
    header:{
        display:"inline-flex",
        paddingTop:"18px",
        marginBottom:"6px",
        "& button":{
            marginLeft:"auto",
            marginRight:"8px"
        }
    },
    content:{
        padding:"0px 24px 0px",
        display:"flex",
        flexFlow:"column",
        alignItems:"center",
        "& svg":{
            height:"63px",
            width:"63px"
        },
        "& h6":{
            fontFamily:"gilroy-bold",
            fontSize:"1rem",
            margin:"20px auto",
            width:"234px",
            textAlign:"center",
            "& span":{
                color:"#ff6373"
            }
        }
    },
    actions:{
        justifyContent:"start",
        "& button":{
            width:"161px",
            height: "55px",
            textTransform: 'initial',
            fontSize: "16px",
            fontWeight:"bold",
            fontFamily:"gilroy-bold",
            marginBottom:"24px",
            margin:"auto 16px"
        },
        "& .cancel":{
            border:"1px solid #DBDBDB",
            background:"#F3F3F3"
        },
        "& .action":{
            color:"#fff",
            background: "#ff6373",
        }
    }
});

interface IAlertPopupProps {
    description: React.ReactElement<any>,
    isOpen: boolean,
    action: string,
    paperClass?: object,
    hideButtons?: boolean,
    cancelHandler?: any,
    actionHandler?: any,
    handleClose: any
}

const AlertPopup = (props:IAlertPopupProps) => {
    const { description, isOpen, action, paperClass, hideButtons } = props;
    const classes = useStyles();
    return (
        <Dialog open={isOpen} classes={{paper:`${classes.paper} ${paperClass}`}}>
            <div className={classes.header}>
                <IconButton onClick={props.handleClose}><CloseIcon/></IconButton>
                </div>
            <DialogContent className={classes.content}>
                <AlertIcon/>
                {description}
            </DialogContent>
            <DialogActions className={classes.actions}>
               {!hideButtons &&  <Button className="cancel" onClick={props.cancelHandler} variant="outlined">Cancel</Button>}
                {!hideButtons && <Button className="action" data-test-id="actionBtn" onClick={props.actionHandler} variant="contained">{action}</Button>}
            </DialogActions>
        </Dialog>
    )
}

export default AlertPopup;

// Customizable Area End