import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import {  handlePaginationWithFilter, createDeliveryChallanData } from "../../../components/src/CommonFunctions";
import { InwardsInventoryItem } from "./types";
// Customizable Area End

export const configJSON = require("./config");
export const rows = configJSON.inwardsInventoryRows;
interface S {
  // Customizable Area Start
  currentPage:number;
  startIndex:number;
  dataList:any;
  endIndex:number;
  rowsCount:number;
  search:string;
  totalCount:number;
  filterYear:string;
  listOfYears:string[];
  filterStatus:string;
  hasAccess: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export type Props = RouteComponentProps &  {
  id: string;
  navigation: any;
  // Customizable Area Start
  userProfile: any;
  classes:any;
  userType:any;
  setUserProfile:any;
  setError:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  history:any
  // Customizable Area End
}
export default class InwardsInventoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInwardsInventoryListApiCallId:string="";
  getLinkedDarkstoreInfoApiCallId: string="";
  searchDebounceTimer: NodeJS.Timeout | null = null
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      currentPage:1, 
      dataList:[],
      startIndex:0,
      rowsCount:0,
      endIndex:0,
      totalCount:0,
      search:"",
      filterStatus:" ",
      filterYear:String(new Date().getFullYear()),
      listOfYears:[],
      hasAccess: true

      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let inwardsInventoryListPageApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      const inwardsInventoryListPageResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(inwardsInventoryListPageResponseJson){
        if(inwardsInventoryListPageResponseJson.status === 500){
          this.props.setError("Internal Server Error");
          return;
        }
        else if(inwardsInventoryListPageResponseJson.errors){
          console.log("####errors",inwardsInventoryListPageResponseJson.errors);
        }
        else{
         this.handleSuccessCallbacks(inwardsInventoryListPageApiCallId, inwardsInventoryListPageResponseJson);
        } 
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  filterByStatus =(event:React.ChangeEvent<{ value: unknown }>)=>{
    const status = event.target.value as string
    this.setState({currentPage:1, filterStatus:status,search:""},this.getInwardsInventoryList)
  }

  filterByYear =(event:React.ChangeEvent<{ value: unknown }>)=>{
    const year = event.target.value as string
    this.setState({currentPage:1, filterYear:year,search:""},this.getInwardsInventoryList)
  }

  handleSuccessCallbacks = (apiRequestCallId:string, responseJson:any)=>{
    if(this.getInwardsInventoryListApiCallId === apiRequestCallId){
    
      this.handleDataList(responseJson)
    }

    if(this.getLinkedDarkstoreInfoApiCallId === apiRequestCallId){
      if(responseJson.data.data != null){
        this.getInwardsInventoryList()
      }else{
        this.setState({hasAccess: false})
      }
    }
  }

  handleDataList =(responseJson:any)=>{
    if(responseJson.message){
     this.setState({dataList:[],startIndex:0,endIndex:0,currentPage:0,totalCount:0, rowsCount:0})
    }else{
     const { startIndex, endIndex, currentPage} = handlePaginationWithFilter({},responseJson.meta.page,responseJson.data,configJSON.rowsPerPage,responseJson.meta.total_count);
     
     const formatedData = responseJson.data.map((item:InwardsInventoryItem,index:number)=>{
      
       return createDeliveryChallanData(index+startIndex+1, item.attributes.id, item.attributes.store.legal_name,item.attributes.delivery_date,item.attributes.status,item.attributes.order_number)
     });
       this.setState({dataList:formatedData,startIndex,endIndex,currentPage,totalCount:responseJson.meta.total_count, rowsCount: Math.ceil(responseJson.meta.total_count/configJSON.rowsPerPage)})
    }
   }

   handleInwardsInventoryPagination = (e: any ,page: number) => {
    this.setState({currentPage: page},this.getInwardsInventoryList)
  }

  
  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(() => {
     
     this.setState({currentPage:1},this.getInwardsInventoryList)
    }, 300);
  }

  handleSearchItem = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
   
      this.setState({search:event.target.value},this.handleSearchDebounce)
  }

  inwardsInventoryListApiCall = (data: any) => {
    const { contentType, method, endPoint, body, type} = data;
    let token = window.localStorage.getItem("token");
    let header:Record<string,string|undefined|null> = {
      token,
    };
    if(contentType){
      header = {
        ...header,
        "Content-Type":contentType
      }
    }
    const inwardsInventoryApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    inwardsInventoryApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    inwardsInventoryApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    inwardsInventoryApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    inwardsInventoryApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type === "form-data"? body : JSON.stringify(body) 
    );
    runEngine.sendMessage(inwardsInventoryApiMessage.id, inwardsInventoryApiMessage)
    return inwardsInventoryApiMessage.messageId;
  }

  async componentDidMount(){
    const { history } = this.props;
    if (history.location.state && history.location.state.inwardsCurrentPage) {
      this.setState({currentPage:history.location.state.inwardsCurrentPage ,filterYear:history.location.state.year,filterStatus:history.location.state.status},()=>{
        this.getListOfYears();
        this.getLinkedDarkstore();      
      })
     }else{
      this.getListOfYears();
      this.getLinkedDarkstore();
     }
   
  }

  getLinkedDarkstore() {
    this.getLinkedDarkstoreInfoApiCallId = this.inwardsInventoryListApiCall({
      contentType:configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getLinkedDarkstoreEndPoint}`,
    })
  }

  getListOfYears(){
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startYear = 2022;
    const yearsArray = Array.from({ length: currentYear - startYear + 1 }, (_, index) => String(startYear + index));
    this.setState({ listOfYears:yearsArray})
  }
    
  handleInwardsInventoryPrevNext = (type: any) => {
    const { currentPage, rowsCount } = this.state;
    if(type === "previous" && currentPage > 1){
      this.setState({currentPage: currentPage-1},this.getInwardsInventoryList)
    }else if(type === "next" && currentPage < rowsCount + 1){
      this.setState({currentPage: currentPage+1},this.getInwardsInventoryList)
    }
  }

  navigateToDetails = (e: React.MouseEvent<HTMLButtonElement>, orderId: string) => {
    this.props.history.replace({
      ...this.props.history.location,
      state: {
        ...this.props.history.location.state,
        inwardsCurrentPage: this.state.currentPage,
        status:this.state.filterStatus,
        year:this.state.filterYear      
        
      }
    });
    this.props.history.push(`/inwards-inventory/${orderId}`);
  }

  getInwardsInventoryList = () => {
    this.getInwardsInventoryListApiCallId = this.inwardsInventoryListApiCall({
      contentType:configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getDeliveryChallanListEndPoint}?page=${this.state.currentPage}&per_page=${configJSON.rowsPerPage}&search_query=${this.state.search}&status=${this.state.filterStatus}&year=${this.state.filterYear}`,
    })
  }

  // Customizable Area End
}
