import React from "react";
// Customizable Area Start
import { TextField, IconButton, withStyles, Typography, Select, MenuItem } from "@material-ui/core";
import {withRouter} from "react-router-dom";

import InwardsInventoryController,{  rows } from "./InwardsInventoryController.web";
import CustomPagination from "../../../components/src/Pagination.web";
import DarkStoreDashboardLayout from "../../../components/src/DarkStoreDashboardLayout.web";
import { SearchIcon } from "../../../components/src/assets.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TableCommonComponent from "../../../components/src/TableCommonCompoent.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import { NoPermissionLabel } from "../../../components/src/CommonFunctions";

const styles : any = {
table:{
    "& tbody tr":{
        "& td.Delivering, td.Pending, td.Ready, td.Delivered, td.Completed ":{
            padding:"0px",
        },
        "& td.Delivering span, td.Pending span, td.Ready span, td.Delivered span,  td.Completed span":{
            padding:"6px 16px",
            borderRadius:"6px",
            minWidth:"110px",
            display:"inline-flex",
            justifyContent:"center"
        },
        "& td.Delivering span":{
            background:"#fffae2",
            color:"#cfcd6a"
        },
        "& td.Pending span":{
            background:"#fed4d5",
            color:"#c96058"
        },
        "& td.Ready span":{
            background:"#ffe5d4",
            color: "#b08055"
        },
        "& td.Delivered span":{
            background:"#e4fcd8",
            color:"#438147"
        },
        "& td.Completed span":{
            background:"#e4fcd8",
            color:"#438147"
        }
    },
    },
    select:{
        marginLeft:"24px",
    },
    actionBar:{
        display:"inline-flex",
        width:"100%",
        "& h6":{
            width:"100%",
        }
    },
    filterBar:{
        width:"90%",
        display:"flex",
        justifyContent:"end",
        marginBottom:"24px",
        "& .MuiOutlinedInput-root":{
            height:"40px",
        },
        "& .MuiOutlinedInput-input":{
            padding:"10.5px 30px"
        }
    }
}
class InwardsInventory extends InwardsInventoryController {

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { hasAccess, dataList, currentPage, rowsCount, startIndex, endIndex } = this.state;

        return (
            <DarkStoreDashboardLayout {...restProps} headerTitle="Inwards Inventory" actionHandler={undefined} headerAction="New Order" subTitle="" hasAccess={true}>
                {hasAccess ? <div className="main_container">
                    <TextField 
                        className="text_field"
                        placeholder="Search by Order ID, Warehouse Name"
                        variant="outlined"
                        onChange={this.handleSearchItem}
                        InputProps={{
                            endAdornment: <IconButton><SearchIcon/></IconButton>
                        }}
                     />
                    <div className="details_container">
                        <div className={classes.actionBar}>
                            <Typography variant="h6" className="title">List of orders</Typography>
                            <div className={classes.filterBar}>
                                <Select 
                                    IconComponent={KeyboardArrowDownIcon}
                                    onChange={this.filterByStatus}
                                    variant="outlined" 
                                    id="filterByStatus"
                                    value={this.state.filterStatus} 
                                    className={classes.select}>
                                        <MenuItem value=" ">All Data</MenuItem>
                                        <MenuItem value="pending">Pending</MenuItem>
                                        <MenuItem value="delivering">Delivering</MenuItem>
                                        <MenuItem value="completed">Completed</MenuItem>
                                </Select>
                                <Select 
                                    onChange={this.filterByYear}
                                    IconComponent={KeyboardArrowDownIcon}
                                    variant="outlined"
                                     value={this.state.filterYear} 
                                     id="filterByYear"
                                     className={classes.select}>
                                        {this.state.listOfYears.map(year=>(
                                            <MenuItem key={year} value={year}>{year}</MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                        {<TableCommonComponent 
                            handleNavigate={this.navigateToDetails} 
                            rows={rows} 
                            data={dataList} cssClasses={classes.table} /> }
                    </div>
                   <CustomPagination
                        dataLength={this.state.totalCount}
                        currentPage={currentPage}
                        rowsCount={rowsCount}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        handlePagination={this.handleInwardsInventoryPagination}
                        handlePrevNext={this.handleInwardsInventoryPrevNext}
                    />
                </div>: <NoAccessPage className="no-access" description={NoPermissionLabel} />}
            </DarkStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { InwardsInventory };
export default withStyles(styles)(withRouter(InwardsInventory));
// Customizable Area End
