import React from "react";

export const InventoryActive = () => {
    return (
        <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy" transform="translate(-24.000000, -361.000000)" fill="#662D91" fill-rule="nonzero">
            <g id="inventory_2_FILL1_wght200_GRAD0_opsz24-(1)" transform="translate(24.000000, 361.000000)">
                <path d="M1,15.384625 L1,4.85385 C0.755116667,4.77308333 0.527233333,4.59744167 0.31635,4.326925 C0.10545,4.05640833 0,3.74230833 0,3.384625 L0,1.615375 C0,1.155125 0.154166667,0.770833333 0.4625,0.4625 C0.770833333,0.154166667 1.155125,0 1.615375,0 L16.384625,0 C16.844875,0 17.2291667,0.154166667 17.5375,0.4625 C17.8458333,0.770833333 18,1.155125 18,1.615375 L18,3.384625 C18,3.74230833 17.89455,4.05640833 17.68365,4.326925 C17.4727667,4.59744167 17.2448833,4.77308333 17,4.85385 L17,15.384625 C17,15.844875 16.8458333,16.2291667 16.5375,16.5375 C16.2291667,16.8458333 15.844875,17 15.384625,17 L2.615375,17 C2.155125,17 1.77083333,16.8458333 1.4625,16.5375 C1.15416667,16.2291667 1,15.844875 1,15.384625 Z M16.384625,4 C16.5641083,4 16.7115417,3.94230833 16.826925,3.826925 C16.9423083,3.71154167 17,3.56410833 17,3.384625 L17,1.615375 C17,1.43589167 16.9423083,1.28845833 16.826925,1.173075 C16.7115417,1.05769167 16.5641083,1 16.384625,1 L1.615375,1 C1.43589167,1 1.28845833,1.05769167 1.173075,1.173075 C1.05769167,1.28845833 1,1.43589167 1,1.615375 L1,3.384625 C1,3.56410833 1.05769167,3.71154167 1.173075,3.826925 C1.28845833,3.94230833 1.43589167,4 1.615375,4 L16.384625,4 Z M6.384625,9.384625 L11.615375,9.384625 L11.615375,8.384625 L6.384625,8.384625 L6.384625,9.384625 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>)
}