Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "salesteamdashboard12";
exports.labelBodyText = "salesteamdashboard12 Body";
exports.createOutboundOrder = "/delivery_challan/create-challan"
exports.salesForceEndPoints = "bx_block_catalogue/dark_store_catalogues"
exports.getApiMethod = "GET"
exports.patchApiMethod = "PATCH"
exports.apiContentType = "application/json"
exports.updateStockQuantityEndPoint = "bx_block_catalogue/catalogues"
exports.rowsPerPage = 15;
exports.listDarkstoreOrdersEndPoint = "account_block/dark_stores?per_page=5000";


exports.btnExampleTitle = "CLICK ME";
exports.productsPerPage = 5;
exports.salesForceDashboradRows = [
  {key:"select", label:"Select All"},
  {key:"sNo", label:"S No"},
  {key:"itemDetail", label:"Item Detials"},
  {key:"itemCode", label:"Item Code"},
  {key:"quantity", label:"Quantity"},
  {key:"stockLevel", label:"Stock Level"}
]

// Customizable Area End