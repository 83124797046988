import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import {  handlePaginationWithFilter, createDeliveryChallanData } from "../../../components/src/CommonFunctions";
import { IDeliveryChallanItem } from "./types";
// Customizable Area End

export const configJSON = require("./config");
export const rows = configJSON.deliveryChallanRows
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  currentPage:number;
  dataList:any;
  rowsCount:number;
  startIndex:number;
  endIndex:number;
  totalCount:number;
  search:string;
  filterStatus:string;
  filterYear:string;
  listOfYears:string[]
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  userProfile:any;
  setUserProfile:any;
  setError:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  history:any
  // Customizable Area End
}

export default class DeliveryChallanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDeliveryChallanListApiCallId:string="";
  searchDebounceTimer: NodeJS.Timeout | null = null
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      currentPage:1,
      dataList:[],
      rowsCount:0,
      startIndex:0,
      endIndex:0,
      totalCount:0,
      search:"",
      filterStatus:" ",
      filterYear:String(new Date().getFullYear()),
      listOfYears:[]
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let deliveryChallanLandingPageApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const deliveryChallanLandingPageResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(deliveryChallanLandingPageResponseJson){
        if(deliveryChallanLandingPageResponseJson.status === 500){
          this.props.setError("Internal Server Error");
          return;
        }
        else if(deliveryChallanLandingPageResponseJson.errors){
          console.log("####errors",deliveryChallanLandingPageResponseJson.errors);
        }
        else{
         this.handleSuccessCallbacks(deliveryChallanLandingPageApiCallId, deliveryChallanLandingPageResponseJson);
        } 
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
    const { history } = this.props; 
    if (history.location.state && history.location.state.deliveryChallanCurrentPage) {
     this.setState({currentPage:history.location.state.deliveryChallanCurrentPage ,filterYear: history.location.state?.filterYear},()=>{
        this.getDeliveryChallanList()
        this.getListOfYears()
     })
    } else{
      this.getDeliveryChallanList()
      this.getListOfYears()
    }   
  }

  getListOfYears(){
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startYear = 2022;
    const yearsArray = Array.from({ length: currentYear - startYear + 1 }, (_, index) => String(startYear + index));
    this.setState({listOfYears:yearsArray})
  }

  handleSuccessCallbacks = (apiRequestCallId:string, responseJson:any)=>{
    if(this.getDeliveryChallanListApiCallId === apiRequestCallId){
      this.handleDataList(responseJson)
    }
  }

  handleDataList =(responseJson:any)=>{
   if(responseJson.message){
    this.setState({dataList:[],startIndex:0,endIndex:0,currentPage:0,totalCount:0, rowsCount:0})
   }else{
    const { startIndex, endIndex, currentPage} = handlePaginationWithFilter({},responseJson.meta.page,responseJson.data,configJSON.deliveryChallanRowPerPage,responseJson.meta.total_count);
    const formatedData = responseJson.data.map((item:IDeliveryChallanItem,index:number)=>{
      return createDeliveryChallanData(index+startIndex+1, item.attributes.id, item.attributes?.store?.legal_name,item.attributes.delivery_date,item.attributes.status,item.attributes.order_number)
    })
      this.setState({dataList:formatedData,startIndex,endIndex,currentPage,totalCount:responseJson.meta.total_count, rowsCount: Math.ceil(responseJson.meta.total_count/configJSON.deliveryChallanRowPerPage)})
   }
  }

  handleNavigateToAddNewChallan = () => {
    this.props.history.push(configJSON.routeForAddNewChallan);
  }

  handleNavigateToOrderDetails =(e:any,id:number)=>{
    this.props.history.replace({
      ...this.props.history.location,
      state: {
        ...this.props.history.location.state,
        deliveryChallanCurrentPage: this.state.currentPage,
        filterYear:this.state.filterYear
      }
    });
    this.props.history.push(`${configJSON.orderStatusRoute}/${id}`)
  }

  handleDeliveryChallanPagination = (e: any ,page: number) => {
    this.setState({currentPage: page},this.getDeliveryChallanList)
  }

  filterByStatus =(event:React.ChangeEvent<{ value: unknown }>)=>{
    const status = event.target.value as string
    this.setState({currentPage:1, filterStatus:status,search:""},this.getDeliveryChallanList)
  }

  filterByYear =(event:React.ChangeEvent<{ value: unknown }>)=>{
    const year = event.target.value as string
    this.setState({currentPage:1, filterYear:year,search:""},this.getDeliveryChallanList)
  }

  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(() => {
     
     this.setState({currentPage:1},this.getDeliveryChallanList)
    }, 300);
  }

  handleSearchItem = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
   
      this.setState({search:event.target.value},this.handleSearchDebounce)
  }


    
  handleDeliveryChallanPrevNext = (type: string) => {
    const { currentPage, rowsCount } = this.state;
    if(type === "previous" && currentPage > 1){
      this.setState({currentPage: currentPage-1},this.getDeliveryChallanList)
    }else if(type === "next" && currentPage < rowsCount + 1){
      this.setState({currentPage: currentPage+1},this.getDeliveryChallanList)
    }
  }

  getDeliveryChallanList =()=>{
    this.getDeliveryChallanListApiCallId = this.deliveryChallanListApiCall({
      contentType:configJSON.contentTypeApplicationJson,
      method: configJSON.getAPIMethod,
      endPoint: `${configJSON.getDeliveryChallanListEndPoint}?page=${this.state.currentPage}&per_page=${configJSON.deliveryChallanRowPerPage}&search_query=${this.state.search}&status=${this.state.filterStatus}&year=${this.state.filterYear}`,
    })
  }

  deliveryChallanListApiCall = (data: any) => {
    const { contentType, method, endPoint, body, type} = data;
    let token = window.localStorage.getItem("token");
    let header:Record<string,string|undefined|null> = {
      token,
    };
    if(contentType){
      header = {
        ...header,
        "Content-Type":contentType
      }
    }
    const deliveryChallanApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type === "form-data"? body : JSON.stringify(body) 
    );
    runEngine.sendMessage(deliveryChallanApiMessage.id, deliveryChallanApiMessage)
    return deliveryChallanApiMessage.messageId;
  }

  // Customizable Area End
}
