import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { IGenratedProductFileDetails, IMassUploadRecord, Nullable } from "./types";
import { createMassUploadRecordData, handlePaginationWithFilter } from "../../../components/src/CommonFunctions";

export const configJSON = require("./config");


interface SS {
    id: any;
}

interface S {
    // Customizable Area Start
    selectedFiles: Nullable<File>
    selectedTab: number
    openFileNotSupportedPopUp: boolean
    loadingOnFileUpload: boolean
    currentPage:number;
    dataList:any;
    rowsCount:number;
    startIndex:number;
    endIndex:number;
    totalCount:number;
    totalItemCodes:string|number
    processedItemCodes:string|number
    processedIds:number[]
    itemCodeFile:Nullable<File>
    uploadLoading:boolean
    downloadableFileDetails:Nullable<IGenratedProductFileDetails>
    // Customizable Area End
}

export type Props = RouteComponentProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    userType: any;
    userProfile: any;
    setUserProfile: any;
    setError: any;
    setSuccess:any;
    newNotification:boolean
    setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
    setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
    lengthOfNotification:number
    // Customizable Area End
}


export default class InventoryMassUpdateController extends BlockComponent<
    Props,
    S,
    SS
>{

  getMassUpdateRecordsApiCallId:string=""
  generateProductsListApiCallId:string=""
  uploadItemCodesFile:string =""
  uploadCatalogueApiCallId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      selectedTab:0,
      selectedFiles:null,
      loadingOnFileUpload:false,
      openFileNotSupportedPopUp:false,
      dataList:[],
      currentPage:1,
      rowsCount:0,
      endIndex:0,
      totalCount:0,
      startIndex:0,
      totalItemCodes:"",
      processedItemCodes:'',
      processedIds:[],
      itemCodeFile:null,
      uploadLoading:false,
      downloadableFileDetails:null
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let inventoryMassUpdateApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const inventoryMassUpdateResponseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (inventoryMassUpdateResponseJson) {
        if (inventoryMassUpdateResponseJson.status === 500) {
          this.props.setError("Internal Server Error");
          this.removeFile()
          return;
        }
        else if (inventoryMassUpdateResponseJson.errors) {
          this.props.setError(inventoryMassUpdateResponseJson.errors)
          this.removeFile()
          console.log("####errors", inventoryMassUpdateResponseJson.errors);
        }
        else if (!inventoryMassUpdateResponseJson?.data && !inventoryMassUpdateResponseJson?.total_csv_item_codes) {
          this.props.setError(inventoryMassUpdateResponseJson.message)
          this.removeFile()
        }
        else {
          this.handleSuccessCallback(inventoryMassUpdateApiCallId,inventoryMassUpdateResponseJson)
        }
      }
    }
  }

  handleSuccessCallback=(apiRequestCallId:string, response:any)=>{
    switch(apiRequestCallId){
      case this.uploadItemCodesFile:{
        this.setState({totalItemCodes:response.total_csv_item_codes, processedItemCodes:response.matching_item_codes_count,processedIds:response.matching_item_ids, uploadLoading:false})
        break
      }
      case this.getMassUpdateRecordsApiCallId:{
        this.handleMassUpdateRecordData(response)
        break
      }
      case this.generateProductsListApiCallId:{
          const data=response.data.data.attributes
          this.setState({downloadableFileDetails:{name:data.filename,created_at:data.created_at,url:data.exported_file.url}})
        break
      }
      case this.uploadCatalogueApiCallId:{
        if(response.data.data.attributes.error_file){
          this.handleDownloadErrorFile("error.csv", response.data.data.attributes.error_file.url)
        }else{
          this.props.setSuccess(response.message)
        }
        this.state.currentPage === 0 ? this.setState({currentPage:1},this.getMassUpdateRecord) : this.getMassUpdateRecord()
        this.removeFile()
        break
      }
      default:{
        break
      }
    }
  } 

  handleDownloadErrorFile = (fileName:string,url:string) =>{
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  removeFile = ()=>{
    this.setState({selectedFiles:null,uploadLoading:false})
  }

  handleNavigateToAddNewInventoryFromMassUpdate = () => {
    this.props.history.push(configJSON.pathForNewInventory);
  }


  handleMassUpdateRecordData = (response:any)=>{
    if(response.message){
      this.setState({startIndex:0,dataList:[],endIndex:0,currentPage:0,totalCount:0, rowsCount:0})
     }else{
      const { startIndex, currentPage,endIndex} = handlePaginationWithFilter({},response.meta.page,response.data,configJSON.rowsPerPage,response.meta.total_count);
      const formatedMassUpdatedData = response.data.map((item:IMassUploadRecord,index:number)=>{
        return createMassUploadRecordData(item.attributes.created_at,item.attributes.file_name,item.attributes.total_count, item.attributes.uploaded_count,item.attributes.status)
      })
      this.setState({dataList:formatedMassUpdatedData,startIndex,endIndex,currentPage,totalCount:response.meta.total_count, rowsCount: Math.ceil(response.meta.total_count/configJSON.rowsPerPage)})
     }
  }  


  handleChangeMassUpdateTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({selectedTab:newValue},()=>{
      if(this.state.selectedTab === 1){
        this.setState({currentPage:1},this.getMassUpdateRecord)}
    });
  };

  handleClosePopUp = () => {
    this.setState({openFileNotSupportedPopUp:false})
  }

  handleDrop = (e:React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files
    const fileFormats = "text/csv"
    if(fileFormats === files[0].type){
      const formdata = new FormData();
      this.setState({selectedFiles:files[0]})
      formdata.append("file",files[0])
      this.uploadCatalogueApiCallId = this.inventoryMassUpdateApiCall({
        type:"form-data",
        endPoint:configJSON.uploadProductCsvFileApi,method:configJSON.postAPIMethod,
        body:formdata,
      })
    }else{
      this.setState({openFileNotSupportedPopUp:true})
    }
  };

  handleFileChange = (e:any) => {
    const files = e.target.files as File[];
    const fileFormats = "text/csv"
    if(fileFormats === files[0].type){
      const formdata = new FormData();
      this.setState({selectedFiles:files[0]})
      formdata.append("file",files[0])
      this.uploadCatalogueApiCallId = this.inventoryMassUpdateApiCall({
        body:formdata,
        type:"form-data",
        endPoint:configJSON.uploadProductCsvFileApi,
        method:configJSON.postAPIMethod
      })
    }else{
      this.setState({openFileNotSupportedPopUp:true})
    }
  };

  handleDropSelectedItemCodesFile = (e:React.DragEvent<HTMLDivElement>)=>{
    e.preventDefault();
    const files = e.dataTransfer.files
    const fileFormats = "text/csv"
    if(fileFormats === files[0].type){
      const formdata = new FormData();
      formdata.append("csv_file",files[0])
      this.setState({itemCodeFile:files[0],uploadLoading:true})
      this.uploadItemCodesFile = this.inventoryMassUpdateApiCall({
        type:"form-data",
        body:formdata,
        endPoint:configJSON.uploadItemsCodesApi,
        method:configJSON.putAPiMethod
      })
    }else{
      this.setState({openFileNotSupportedPopUp:true})
    }
  }

  handleSelectSelectedItemCodesFile = (e:any)=>{
    const files = e.target.files as File[];
    const fileFormats = "text/csv"
    if(fileFormats === files[0].type){
      const formdata = new FormData();
      formdata.append("csv_file",files[0])
       this.setState({itemCodeFile:files[0],uploadLoading:true})
      this.uploadItemCodesFile = this.inventoryMassUpdateApiCall({
        type:"form-data",
        body:formdata,
        endPoint:configJSON.uploadItemsCodesApi,
        method:configJSON.putAPiMethod
      })
    }else{
      this.setState({openFileNotSupportedPopUp:true})
    }
  }

  handleResetForm =()=>{
    this.setState({itemCodeFile:null,totalItemCodes:"",processedItemCodes:"",processedIds:[],downloadableFileDetails:null})
  }

  handleRecordPrevNext = (type: string) => {
    const { currentPage, rowsCount } = this.state;
    if(type === "previous" && currentPage > 1){
      this.setState({currentPage: currentPage-1},this.getMassUpdateRecord)
    }else if(type === "next" && currentPage < rowsCount + 1){
      this.setState({currentPage: currentPage+1},this.getMassUpdateRecord)
    }
  }

  handleRecordPagination = (e: any ,page: number) => {
    this.setState({currentPage: page},this.getMassUpdateRecord)
  }


  getMassUpdateRecord = () =>{
    this.getMassUpdateRecordsApiCallId = this.inventoryMassUpdateApiCall({
     method: configJSON.getAPIMethod,
     endPoint: `${configJSON.getMassUploadRecordsApi}?page=${this.state.currentPage}&per_page=${configJSON.rowsPerPage}`
     })
 }

  generateProductFile = ()=>{
    if(this.state.processedIds.length){        
      let queryParams = this.state.processedIds.join("&ids[]=")
      this.generateProductsListApiCallId = this.inventoryMassUpdateApiCall({
        endPoint:`${configJSON.exportProductsApi}?ids[]=${queryParams}`,
        method:configJSON.getApiMethod
      })

    }else{
      this.props.setError("File is not selected or item code not presents ")
    }
  }

  inventoryMassUpdateApiCall = (data: any) => {
      const { contentType, method, endPoint, body, type} = data;
      let token = window.localStorage.getItem("token");
      let header:Record<string,string|undefined|null> = {
        token,
      };
      if(contentType){
        header = {
          ...header,
          "Content-Type":contentType
        }
      }
      const inventoryMassUpdateApiCallApiMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      inventoryMassUpdateApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      inventoryMassUpdateApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      inventoryMassUpdateApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      inventoryMassUpdateApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "form-data"? body : JSON.stringify(body) 
      );
      runEngine.sendMessage(inventoryMassUpdateApiCallApiMessage.id, inventoryMassUpdateApiCallApiMessage)
      return inventoryMassUpdateApiCallApiMessage.messageId;
    }
}