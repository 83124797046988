import React, { PureComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { withState } from './AppContext.web';

export type Props = RouteProps & { userType:any };

interface S {
  isLoggedInUser: any;
  loading: any;
}

class PublicRoute extends PureComponent<Props, S> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoggedInUser: false,
      loading: true
    };
  }

  componentDidMount = async () => {
    let isLoggedInUser = await localStorage.getItem('token');
    if (isLoggedInUser) {
      this.setState({ isLoggedInUser: isLoggedInUser, loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    
    const { location, userType } = this.props;
    const token = localStorage.getItem("token");
  
    return !this.state.loading ? (
      !token  ? (
        <Route {...this.props} />
      ) : (
        (!!location && !!location.pathname && ["/","/login","/singup","/forget-password", "/create-new-password"].includes(location.pathname))
        ? (userType === "globalStoreUser" ? <Redirect to={{ pathname: "/dark_store_management" }}  /> : <Redirect to={{ pathname: "/orders" }}  />) 
        : <Route {...this.props} />
      )
    ) : null
  }
}

export default withState(PublicRoute);