
import React from "react";
// Customizable Area Start
import { TextField, Typography, IconButton, withStyles, Table, TableBody, TableCell, TableHead, TableRow, Popover, Button, FormLabel } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import GlobalStoreInventoryController, { Props } from "./GlobalStoreInventoryController";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CustomPagination from "../../../components/src/Pagination.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import AlertPopup from "../../../components/src/AlertPopup.web";
import CommonStatuscard from "../../../components/src/CommonStatuscard.web"; 
import styles from "./styles";
const inventoryImg = require("../../../components/src/assets/images/product.jpeg");
const componentStyles: any = {
    ...styles,
    image:{
        height:"70px",
        marginRight:"12px",
        "& img":{
            width:"80px",
        }
    },
    table: {
        "& th": {
            fontFamily: "gilroy-bold"
        },

        "& td": {
            fontFamily: "gilroy-medium"
        },
        "& thead tr": {
            background: "#f7f7f7",
            color: "#848192",

        }
    },
    productDetails:{
        display:"inline-flex",
        alignItems:"center",
        width:"100%",
        "& img":{
            height:"50px",
            marginRight:"8px"
        }
    },
    detailsContainer: {
        padding: "24px 0",
        "& h6": {
            fontFamily: "gilroy-bold",
            fontSize: "16px",

        },
        "& .title": {
            marginBottom: "24px"
        }
    },
    productStatusList: {
        display: "inline-flex",
        "& .statusCard": {
            width: "330px",
            height: "80px",
            padding: "12px",
            marginRight: "24px",
            display: "inline-flex",
            borderRadius: "6px"
        },
        "& .out-of-stock": {
            background: "#f7e0e0",
        },
        "& .low-stock": {
            background: "#f7f0e0",
        },
        "& .all": {
            background: "#e0f7e4",
        },
    },
    statusIcon: {
        width: "60px",
        height: "54px",
        display: "flex",
        background: "#fff",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px",
        "& img": {
            width: "40px",
            height: "25px"
        }
    },
    statusDetail: {
        display: "flex",
        flexFlow: "column",
        margin: "0 14px",
        "& h6": {
            fontFamily: "gilroy-bold",

        },
        "& h5": {
            fontFamily: "gilroy-extra-bold",
            fontSize: "18px"
        }
    },
    editPopper:{
        width:"140px",
        display:"flex",
        height:"100px",
        flexFlow:"column",
        "& button":{
            height:"50px",
            '& span':{
                justifyContent:"space-evenly"
            },
            padding:"8px 10px !important"
        },
        "& p":{
            width:"50px",
            textAlign:"left"
        }
    },
    paper:{
        boxShadow:"0 0 8px 0 rgba(0, 0, 0, 0.05) !important",
        background:"#fff",
        borderRadius:"8px !important"
    },
    paperFordelete:{
        width: '391px',
        height: '330px',
        borderRadius: '21px'
    },
    pagination:{
        margin:"0 24px",
        paddingBottom:"18px"
    }
}

class GlobalStoreInventory extends GlobalStoreInventoryController {
    constructor(props: Props) {
        super(props);
    }


    renderGlobalStoreInventory = (hasAccess: boolean) => {
        const { classes, userProfile } = this.props;
        const { metaDetails, totalRecords, currentPage, rowsCount, currentDataList, startIndex, endIndex, open, anchorEl, deleteDialogBox } = this.state;

        if(!!userProfile && !hasAccess){
            return <NoAccessPage description="Sorry, you don't have the permission" />;
        }else {
            return (
                <div className={classes.container}>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        onChange={this.handleChange}
                        placeholder="Search by Item Name, Item Code"
                        variant="outlined"
                        value={this.state.filterValue}
                        // InputProps={{
                        //     endAdornment: <SearchIcon />
                        // }}
                    />
                    <div className="details_container">
                    <CommonStatuscard handleStockStatus={this.handleStockFilter} metaData={metaDetails} stockStatus={this.state.stockStatus} />
                    </div>
                    <div className={classes.detailsContainer}>
                        <Typography variant="h6" className="title">All Products</Typography>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" component="th" scope="row">
                                        S No
                                    </TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Item Name</TableCell>
                                    <TableCell align="left">Item Code</TableCell>
                                    <TableCell align="center">Categories</TableCell>
                                    <TableCell align="center">Display Location</TableCell>
                                    <TableCell align="center">Sub Categories</TableCell>
                                    <TableCell align="center">Selling Price</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentDataList.length > 0 
                                ?currentDataList.map((row: any) => (
                                    <TableRow key={row.sNo}>
                                        <TableCell align="center" component="th" scope="row">
                                            {row.sNo + startIndex}
                                        </TableCell>
                                        <TableCell align="center">{row.quality} Pcs</TableCell>
                                        <TableCell align="center" className={classes.productDetails}>
                                            <div className={classes.image}>
                                                {<img src={row?.images && row?.images[0]?.url || inventoryImg} />}
                                            </div>
                                            <FormLabel>{`${row.itemName}, ${row.attributes.weight}${row.attributes.weight_unit?.charAt(0)}`}</FormLabel>  
                                            </TableCell>
                                        <TableCell align="left">{row.itemCode}</TableCell>
                                        <TableCell align="center">{row.categories}</TableCell>
                                        <TableCell align="center">{row.displayLocation}</TableCell>
                                        <TableCell align="center">{row.subCategories}</TableCell>
                                        <TableCell align="center">₹ {row.sellingPrice}</TableCell>
                                        <TableCell align="center">
                                            <IconButton id="editDeleteOptions" onClick={(e) => {
                                                this.handleUpdateDropdown(e,row)
                                            }}>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </TableCell>
                                        <Popover
                                            onClose={this.handleClose}
                                            open={open}
                                            anchorEl={anchorEl}
                                            PaperProps={{ className: classes.paper }}
                                            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                        >
                                            <div className={classes.editPopper} >
                                                <Button id="editButton" onClick={() => this.handleEdit(this.state.EditData)} >
                                                    {/* <EditIcon /> */}
                                                    <Typography variant="body1">Edit</Typography>
                                                </Button>
                                                <Button>
                                                    {/* <DeleteIcon /> */}
                                                    <Typography variant="body1" id="deleteButton" onClick={() => { this.deleteDialogBoxFunc(this.state.EditData) }} color="error">Delete</Typography>
                                                </Button>
                                            </div>
                                        </Popover>
                                    </TableRow>
                                ))
                                : <div className="noRecords">No Records found</div>}
                            </TableBody>
                        </Table>
                    </div>
                    <div className={classes.pagination}>
                       {currentDataList.length > 0 ? <CustomPagination
                            dataLength={totalRecords}
                            currentPage={currentPage}
                            rowsCount={rowsCount}
                            startIndex={startIndex}
                            endIndex={endIndex}
                            handlePagination={this.handlePagination}
                            handlePrevNext={this.handlePrevNext}
                        /> : <></>}
                    </div>
                    <AlertPopup 
                        isOpen={deleteDialogBox}
                        handleClose={this.deleteDialogBoxFunc}
                        cancelHandler={this.deleteDialogBoxFunc}
                        actionHandler={this.deleteRecord}
                        action="Delete" 
                        description={<Typography variant="h6">Are You Sure that you want to <span>Delete</span> this product from Inventory ?</Typography>}/>
                </div>
            )
        }
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
       
        const { userProfile } = restProps;
        const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile.attributes.groups.data[0].attributes.settings : {};
        const hasAccess = groups?.inventory;
        return (
            <GlobalStoreDashboardLayout {...restProps} headerAction="New Item" actionHandler={this.handleNavigateToAddNewInventory} subTitle="" headerTitle="Inventory Management" hasAccess={hasAccess} isInventory ={hasAccess}>
                {this.renderGlobalStoreInventory(hasAccess)}
            </GlobalStoreDashboardLayout>
        )
    }
}

// Customizable Area Start
export { GlobalStoreInventory };
export default withStyles(componentStyles)(withRouter(GlobalStoreInventory));
// Customizable Area End