import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");
export const rows = configJSON.orderDetailsRows;
export const itemRows = configJSON.orderItemsRows;
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  isOpen:boolean;
  anchorEl:any;
  orderDetails:{ attributes: any, id: string } | null;
  metaData :any;
  total_pages:number;
  currentPage:number;
  startIndex:number;
  endIndex:number;
  isLoading:boolean;
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  match:{ params: { orderId:any } };
  setError:any;
  userProfile:any;
  setUserProfile:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}
export default class DarkstoreOrderDetailsControler extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDarkstoreOrderDetailsApiCallId : any;
  putDarkstoreOrderApiCallId: any;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      isOpen:false,
      anchorEl:null,
      orderDetails:null,
      metaData:null,
      total_pages: 0,
      currentPage:1,
      startIndex:0,
      endIndex:0,
      isLoading:true,
      // Customizable Area End
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if(errorReponse){
          this.setState({isLoading:false})
          this.props.setError(errorReponse);
        }
  
        if(responseJson){
          if(responseJson.status === 500){
            this.setState({isLoading:false})
            this.props.setError("Internal server error");
          }else if(responseJson.errors){
            this.setState({orderDetails: null,isLoading:false})
          }else{
            this.handleSuccessCallbacks(apiRequestCallId, responseJson);
          }
        }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessCallbacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getDarkstoreOrderDetailsApiCallId) {
      this.setState({ orderDetails: responseJson.data.data });
      this.setState({ metaData: responseJson.meta.pagination_data });

      const meta = responseJson.meta;
      const data = responseJson.data.data.attributes.order_items;

      const page = meta.pagination_data.page;
      const rowsPerPage = configJSON.rowsPerPageOrderDetails;
      let pages = Math.ceil(meta.total_count / configJSON.rowsPerPageOrderDetails);
      this.setPagination(pages);
      let updatedEndIndex = rowsPerPage * page;
      let endIndex = rowsPerPage * (page - 1) + data.length;

      if (updatedEndIndex > endIndex) {
        updatedEndIndex = endIndex;
      }
      this.setState({
        currentPage: page,
        startIndex: rowsPerPage * page - rowsPerPage + 1,
        endIndex: updatedEndIndex,
        total_pages: meta.pagination_data.total_pages
      });
      this.setState({isLoading:false})
    } else if (apiRequestCallId === this.putDarkstoreOrderApiCallId) {
      this.setState({isLoading:false})
      this.props.history.push("/orders");
    }
  }

  setPagination = (total_pages:number) => {
    this.setState({total_pages})
  }

  darkstoreOrderDetailsApiCall = async(data: any) => {
    const { contentType, method, endPoint, body} = data;
    let token = await localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };
    const darkStoreOrderDetailsApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    darkStoreOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    darkStoreOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    darkStoreOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    darkStoreOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) 
    );
    runEngine.sendMessage(darkStoreOrderDetailsApiMessage.id, darkStoreOrderDetailsApiMessage);
    return darkStoreOrderDetailsApiMessage.messageId;
  }

  handleSelectDropdown = (e:any) => {
    if(!!this.state.orderDetails){
      if(this.getOrderStatus(this.state.orderDetails.attributes.status) === "Pending"){
        this.setState({isOpen: true, anchorEl: e.currentTarget});
      }
    }
  }

  handleClose = () => {
    this.setState({isOpen:false, anchorEl:null})
  }

  changeStatusReady = async() => {
    const { orderDetails } = this.state;
    if(!!orderDetails){
      const body = {
        order_id : orderDetails.id,
        status: "confirmed"
      }
      this.putDarkstoreOrderApiCallId = await this.darkstoreOrderDetailsApiCall({
        contentType: configJSON.putDarkstoreOrderContentType,
        method: configJSON.putDarkstoreOrderMethodType,
        endPoint: `${configJSON.putDarkstoreOrderEndPoint}`,
        body
      });
    }
  }

  async componentDidMount(){
    this.setState({isLoading:true})
    await this.darkstoreOrderDetails(1);
  }

  getOrderStatus = (value : string) => {
        let status = "";
    if(value === "placed"){
      status = "Pending";
    }else if(value === "confirmed"){
      if(!!this.state.orderDetails && this.state.orderDetails.attributes.deliver_by){
        status = "Assigned";
      }else{
        status = "Packed";
      }
    }else if(value === "in_transit"){
      status = "On the way";
    }else if(value === "delivered"){
      status = "Delivered";
    }else if(value === "cancelled"){
      status = "Cancelled";
    }
    return status;
  }

  darkstoreOrderDetails = async(page:number) => {
    const orderId = this.props.match.params.orderId;
    this.getDarkstoreOrderDetailsApiCallId = await this.darkstoreOrderDetailsApiCall({
      contentType: configJSON.getDarkstoreOrderDetailsContentType,
      method: configJSON.getDarkstoreOrderDetailsMethodType,
      endPoint: `${configJSON.getDarkstoreOrderDetailsEndPoint}/${orderId}?page=${page}&per_page=${configJSON.rowsPerPageOrderDetails}`,
    });
  }

  handleDarkstoreOrdersDetailsPagination = (e: any ,page: number) => {
    this.darkstoreOrderDetails(page);
  }

  handleDarkstoreOrdersDetailsPrevNext = (type: any) => {
    const { currentPage, total_pages } = this.state;
    if(type === "previous" && currentPage > 1){
      this.handleDarkstoreOrdersDetailsPagination({}, currentPage - 1);
    }else if(type === "next" && currentPage < total_pages + 1){
      this.handleDarkstoreOrdersDetailsPagination({}, currentPage + 1);
    }
  }

  // Customizable Area End
}