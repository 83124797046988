import React from "react";
// Customizable Area Start
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {   DashboardTableHeaders, IGlobaldashboardTableData } from "../../blocks/dashboard/src/types";
// import { EditIcon, KebabIcon } from "./assets.web";

const useStyles: any = makeStyles({
  tableHeadings: {
    color: "#8181A5",
    fontFamily: "gilroy-medium",
    fontSize: "13px",
  },
  tableDataStye: {
    color: " #1C1D21",
    fontFamily: "gilroy-medium",
    fontSize: "14px",
    border: "1px solid #F2F2F2",
    textAlign: "center",
  },
});
interface IProps {
  currentPage: number,
  perPageCount: number
  tableRowsData: IGlobaldashboardTableData[],
  classes?: any;
  tableHeadings:DashboardTableHeaders[];
  cssClasses?: any;
  visibleLabels: string[];
}

const GlobalStoreTable = (props: IProps) => {
  const classes = useStyles();
  const { cssClasses, tableHeadings, visibleLabels,tableRowsData } = props;

  return (
    <div
      style={{
        overflowX: "scroll",
        maxWidth: "100vw",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Table
        style={{ flex: "1",
        width:"200%"
        }}
        className={` ${cssClasses ? cssClasses : {}}`}
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{ border: "1px solid #F2F2F2" }}
              className={`${classes.tableHeadingWidth} ${classes.tableHeadings}`}
              align="center"
            >
              {"S No"}
            </TableCell>
            {tableHeadings.map((row:  DashboardTableHeaders, index: number) => {
              return (
                row.isChecked && (
                  <TableCell
                    style={{ border: "1px solid #F2F2F2" }}
                    className={`${classes.tableHeadingWidth} ${classes.tableHeadings}`}
                    key={row.label}
                    align="center"
                  >
                    {row.label}
                  </TableCell>
                )
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRowsData.length > 0 ?
          tableRowsData.map((ele: any, index: number) => {
            return (
              <TableRow key={ele.catalogue_id}>
                <TableCell
                  className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                >
                  {index + 1 + (props.currentPage - 1) * props.perPageCount}
                </TableCell>
                {visibleLabels.includes("Item Code") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.item_code}
                  </TableCell>
                )}
                {visibleLabels.includes("Item Name") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.name}
                  </TableCell>
                )}

                {visibleLabels.includes("Average Inventory") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.average_inventory}
                  </TableCell>
                )}

                {visibleLabels.includes("Inventory Turnover Ratio") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {Number.isNaN(ele.inventory_turnover_ratio) ?0:Number(ele.inventory_turnover_ratio)}
                  </TableCell>
                )}
                {visibleLabels.includes("Days On Hands") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {Number.isNaN(ele.days_on_hands) ?0:Number(ele.days_on_hands)}
                  </TableCell>
                )}
                {visibleLabels.includes("Stocks to Sales Ratio") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {Number.isNaN(ele.stocks_to_sales_ratio) ?0:Number(ele.stocks_to_sales_ratio)}
                  </TableCell>
                )}
                {visibleLabels.includes("Gross Margin By Product") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {Number.isNaN(ele.gross_margin_by_product) ? 0: Number(ele.gross_margin_by_product)}
                  </TableCell>
                )}
                {/* {visibleLabels.includes("Time To Recieve") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.time_to_receive}
                  </TableCell>
                )} */}
                {visibleLabels.includes("Unit Purchase Cost") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.unit_purchase_price}
                  </TableCell>
                )}
                {visibleLabels.includes("Opening Inventory") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.opening_count}
                  </TableCell>
                )}
                {visibleLabels.includes("Opening Inventory Value") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.opening_inventory_value}
                  </TableCell>
                )}
                {visibleLabels.includes("Inwards") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {Number(ele.inward_quantity)}
                  </TableCell>
                )}
                {visibleLabels.includes("Inwards Value") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.inwards_value}
                  </TableCell>
                )}

                {visibleLabels.includes("Outwards") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {Number(ele.outward_quantity)}
                  </TableCell>
                )}

                {visibleLabels.includes("Outwards Value") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.outwards_value}
                  </TableCell>
                )}
                {visibleLabels.includes("Closing Inventory") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.closing_count}
                  </TableCell>
                )}
                {visibleLabels.includes("Closing Inventory Value") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.closing_inventory_value}
                  </TableCell>
                )}
                {visibleLabels.includes("Costs Of Goods Sold") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.costs_of_goods_sold}
                  </TableCell>
                )} 
                {visibleLabels.includes("Sales") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.sales}
                  </TableCell>
                )}
                 {visibleLabels.includes("Selling Price") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.selling_price}
                  </TableCell>
                )}
                    {visibleLabels.includes("Gross Margin") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.gross_margin}
                  </TableCell>
                )}
                     {visibleLabels.includes("Margin Per Unit") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${classes.tableDataStye}`}
                  >
                    {ele.margin_per_unit}
                  </TableCell>
                )}
              </TableRow>
            );
          }) : <Typography className="no_records_found">No Records found</Typography>}
        </TableBody>
      </Table>
    </div>
  );
};

export default GlobalStoreTable;
// Customizable Area End
