import React from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";
import {
  Typography,
  withStyles,

} from "@material-ui/core";

import GlobalStoreDashboardController, {
  Props,
} from "./GlobalStoreDashboardController.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import CustomPagination from "../../../components/src/Pagination.web";
import GlobalStoreTable from "../../../components/src/GlobalStoreTable.web";
import { NoPermissionLabel } from "../../../components/src/CommonFunctions";
import DashboardHeaderFilter from "../../../components/src/DashboardHeaderFilters";
import AlertPopup from "../../../components/src/AlertPopup.web";

export const configJSON = require("./config");

const styles: any = {
  table: {
    maxWidth: "1172px",
  },
  exportBtn: {
    fontFamily: "gilroy-medium",
    width: "124px",
    height: "40px",
    backgroundColor: "#662D91",
    color: "white",
    "&:hover": {
      backgroundColor: "#662D91",
    },
  },
  dataSelection: {
    width: "159px",
    height: "40px",
    backgroundColor: "#f9f9f9",

    "& .MuiButton-label": {
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
    fontFamily: 'gilroy-medium',
}
  },
  date_picker: {
    borderRadius: "14px",
    fontFamily:"gilroy-medium !imortant",
    backgroundColor: "#E7E7E7",
    color: "red",
    "& .react-datepicker__day--in-range":{
      display:" block",
      border:"10px solid red",
      width: "250px",
      marginRight: "13px",
      marginLeft: "3px",
      borderRadius: "1.3rem",
      backgroundColor: "red",
      color: "black",
    },
  },

  inputCheckBox: {
    accentColor: "#662D91",
    width: "16px",
    height: "16px",
  },
  columnSaveBtn: {
    width: "206px",
    height: "40px",
    backgroundColor: "#662D91",
    color: "white",
    margin: "auto",
    marginTop: "20px",
    position: "sticky",
    "&:hover": {
      backgroundColor: "#662D91",
    },
  },
  
};

class GlobalStoreDashboard extends GlobalStoreDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes, groups, ...restProps } = this.props;
    const {
      currentPage,
      endIndex,
      startIndex,
      rowsCount,
      tableHeadings,
      totalRecords,
      globalstoreDashboardTableHeadings,
      openMenu,
      isAllSelected,
      visibleLabels,
      dateRange,
      searchQuery,
      filteredDataList
    } = this.state;
  
    return (
      <GlobalStoreDashboardLayout
        {...restProps}
        headerTitle="Dashboard"
        headerAction=""
        actionHandler={undefined}
        subTitle=""
        hasAccess={groups?.dashboard}
      >
        {groups && !groups.dashboard ? (
          <NoAccessPage className="no-access" description={NoPermissionLabel} />
        ) : (
          <div className="main_container">
            <DashboardHeaderFilter
            searchQuery={searchQuery}
            handlesearchItem={this.handleSearchItem}
           configJSON = {configJSON}
            classes={classes}
            handleSelectAllColumns={this.handleSelectAllColumns}
              handleExportData={this.handleExportData}
              handleOpenColumnMenu={this.handleOpenColumnMenu}
              openMenu={openMenu}
              handleCloseColumnMenu={this.handleCloseColumnMenu}
              isAllSelected={isAllSelected}
              tableHeadings={tableHeadings}
              handleSelectColumnFilter={this.handleSelectColumnFilter}
              handleDataColumnsSave={this.handleDataColumnsSave}
              dateRange={dateRange}
              handleDateChange={this.handleDateChanger}
              visibleLabels={visibleLabels}
            />
            <GlobalStoreTable
            currentPage={currentPage}
            perPageCount={configJSON.rowsPerPage}
              tableRowsData={filteredDataList}
              visibleLabels={visibleLabels}
              tableHeadings={globalstoreDashboardTableHeadings}
            />

            <div className={classes.pagination}>
              <CustomPagination
                dataLength={totalRecords}
                handlePrevNext={this.handleGlobalStoreDashboardPrevNext}
                currentPage={currentPage}
                startIndex={startIndex}
                rowsCount={rowsCount}
                handlePagination={this.handleGlobalStoreDashboardPagination}
                endIndex={endIndex}
              />
            </div>
            {this.state.isModal&& <AlertPopup description={<Typography variant="h6">The Date interval exceeds 1 month. Please select a valid range (within 1 month)</Typography>} 
                handleClose={this.handleClose}
                action="OK" 
                isOpen={this.state.isModal} 
                cancelHandler={this.handleClose}
                actionHandler={this.handleClose}         
            />}
          </div>
        )}
      </GlobalStoreDashboardLayout>
    );
    // Customizable Area Start
  }
}

// Customizable Area Start
export { GlobalStoreDashboard };
export default withRouter(withStyles(styles)(GlobalStoreDashboard));
// Customizable Area End
