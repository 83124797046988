import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { StockStatus } from "./types";
// Customizable Area End

export const configJSON = require("./config");
interface SS {
  id: any;
}

interface S {
  // Customizable Area Start
  currentPage:number;
  currentDataList:any;
  dataList:any;
  rowsCount:number;
  startIndex:number;
  endIndex:number;
  open:boolean;
  anchorEl:any;
  deleteDialogBox: boolean;
  selectedRow: any;
  totalRecords: number;
  EditData:any //6
  metaDetails:any //7
  stockStatus:StockStatus;
  filterValue:any;
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  userProfile:any;
  setUserProfile:any;
  setError:any;
  headerAction:any;
  actionHandler:any;
  subTitle:string;
  headerTitle:string;
  hasAccess:boolean;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  history:any
  // Customizable Area End
}
export default class GlobalStoreInventoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getItemsApiCallId = "";
  deleterecordIdaPI:any;
  searchItemsApiCallId: any;
  debounceTimeout: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      currentPage:1,
      currentDataList:[],
      dataList:[],
      rowsCount:0,
      startIndex:0,
      endIndex:0,
      open:false,
      anchorEl:null,
      deleteDialogBox: false,
      selectedRow: null,
      totalRecords: 0,
      EditData:null , //6
      metaDetails:null,
      stockStatus:"",
      filterValue:"",
      // Customizable Area End
    }
    this.debounceTimeout = null;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Received", message);

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson){
        if(responseJson.errors){
          console.log("@@@errors", responseJson.errors);
        }else if(responseJson.status === 500){
          console.log("@@@errors", responseJson);
        }else{
          this.handleSuccessCallbacks(apiRequestCallId, responseJson);
        }
      }

      if(errorReponse){
        this.handleErrorCallbacks(apiRequestCallId, errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleStockFilter = (status: StockStatus) => {
    
    this.setState({stockStatus:status, currentPage:1});
    const token = localStorage.getItem("token");
    this.getInventoryItems(token, status,1);
  }

  handleSuccessCallbacks = (apiReqCallId:any, responseJson: any) => {
    switch(apiReqCallId){
      case this.getItemsApiCallId :{
          if(responseJson.data && responseJson.meta){
            const rows = responseJson.data.map((item: any, index: number) => this.createData(index+1, item) );
            this.setState({
              dataList: rows,
              rowsCount:Math.ceil(responseJson.meta.total_count/configJSON.rowsPerPage),
              totalRecords: responseJson.meta.total_count,
              metaDetails:responseJson.meta
            },
            () => this.handlePagination({},this.state.currentPage)
            );
          }
          break;
      }

      case this.deleterecordIdaPI :{
          if(responseJson.success){
            const token = localStorage.getItem("token");
            this.getInventoryItems(token,this.state.stockStatus, this.state.currentPage)
            this.setState(prevState => ({
              deleteDialogBox: !prevState.deleteDialogBox
            }));
          }
          if (responseJson.data) {
            this.setState({
              selectedRow : null
            })
            this.setState(prevState => ({
              deleteDialogBox: !prevState.deleteDialogBox
            }));
          }

        break;
      }

      case this.searchItemsApiCallId :{
          if(responseJson?.message === "No Product found."){
            this.setState({dataList: [], currentDataList: [], rowsCount: 0, totalRecords: 0, metaDetails: null});
          }else if (responseJson.data && responseJson.meta) {
            const rows = responseJson.data.map((item: any, index: number) => this.createData(index+1, item) );
            this.setState({
              dataList: rows ,
              rowsCount:Math.ceil(responseJson.meta.total_count/configJSON.rowsPerPage),
              totalRecords: responseJson.meta.total_count,
              metaDetails:responseJson.meta
            },
            () => this.handlePagination({},this.state.currentPage)
            );
          }
        break;
      }

      default : {
        break;
      }
    }
  }

  handleErrorCallbacks = (apiReqCallId:any, errorResponse: any) => {
    switch(apiReqCallId){
      case this.getItemsApiCallId :{
        this.showAlert("Alert", "No Data");
        this.setState({ dataList: [] });
        this.parseApiCatchErrorResponse(errorResponse);
        break;
      }

      case this.deleterecordIdaPI : {
        this.parseApiCatchErrorResponse(errorResponse);
      
        this.setState({
          selectedRow : null
        });

        break;
      }

      case this.searchItemsApiCallId: {
        this.parseApiCatchErrorResponse(errorResponse);
        break;
      }

      default:{
        break;
      }
    }
  }

  async componentDidMount(){
    const token = localStorage.getItem("token");
    const { history } = this.props;
    if (history.location.state && history.location.state.inventoryCurrentPage) {
     this.setState({currentPage:history.location.state.inventoryCurrentPage,stockStatus:history.location.state.status},()=>this.getInventoryItems(token,this.state.stockStatus,this.state.currentPage))
    }
    else{
      this.getInventoryItems(token,this.state.stockStatus,1)
    }   
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentPage !== this.state.currentPage &&this.state.filterValue ) {  
      this.searchInventoryItems(this.state.filterValue,this.state.stockStatus);   
      
    }else if (prevState.currentPage !== this.state.currentPage&&this.state.filterValue=='') {     
      const token = localStorage.getItem("token");
      this.getInventoryItems(token, this.state.stockStatus, this.state.currentPage)
    }
  }

  createData = (sNo: number, item: any) =>  {
    return { sNo, id: item.id, quality: item.attributes?.stock_qty, itemName: item.attributes?.name, itemCode: item.attributes?.item_code, images: item.attributes?.images,
      categories: item.attributes?.category?.name, subCategories: item.attributes?.sub_category?.name, sellingPrice: item.attributes?.sale_price, 
      displayLocation:item.attributes?.display_location ,attributes: item.attributes };
  }

  handlePagination = (e: any ,page: number) => {
    const { dataList, totalRecords } = this.state;

  
    const currentPage = page - 1;
    const rowsPerPage = configJSON.rowsPerPage;
    const startIndex = currentPage*rowsPerPage;

    let endIndex = rowsPerPage*(currentPage + 1);
    
    if(endIndex > totalRecords) {
      endIndex = startIndex + dataList.length  //6
    }
  
    this.setState({
      startIndex,
      endIndex,
      currentDataList:dataList, 
      currentPage: page,
    });
  }
    
  handlePrevNext = (type: any) => {
    const { currentPage, dataList } = this.state;
    if(type === "previous" && currentPage > 0){
      this.handlePagination({}, currentPage - 1);
    }else if(type === "next" && dataList.length >= configJSON.rowsPerPage){
      this.handlePagination({}, currentPage + 1);
    }
  }

  handleNavigation = (path:any) => {
    this.props.history.push(`/${path}`);
  }

  handleNavigateToAddNewInventory = () => {
    this.props.history.push("/add-inventory");
  }

  handleUpdateDropdown = (e:any,data:any) => {
     //6 all block
    this.setState({open:true, anchorEl:e?.currentTarget});
    this.setState({EditData:data }) 
      
  }
 

  handleClose = () => {
    this.setState({open:false, anchorEl:null});
  }

  handleLogout = () => {
    localStorage.clear();
    this.props.history.push("/login")
  }

  // Function to fetch the group list from API
  getInventoryItems = (token: any, status: string, page: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.inventoryAPiEndPoint}?page=${page}&per_page=${configJSON.rowsPerPage}&status=${status}`
    );  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchInventoryItems = (value: string, status: string) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.generalContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchItemEndPoint}?data[search_params]=${value}&page=${this.state.currentPage}&per_page=${configJSON.rowsPerPage}&status=${status}`
    );  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.searchItemApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChange = (e:any) => {
    const { value } = e.target;
    
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  
    this.setState({filterValue:value,currentPage:1})
    this.debounceTimeout = setTimeout(() => {
      const {stockStatus} = this.state;
      if(value.length === 0) {
        this.setState({currentPage:1});
        const token = localStorage.getItem("token");
        this.getInventoryItems(token,stockStatus,1);
      }else{
        this.searchInventoryItems(value, stockStatus);
      }
    }, 600);
  }

  handleEdit = (data:any) => {
    this.props.history.replace({
      ...this.props.history.location,
      state: {
        ...this.props.history.location.state,
        inventoryCurrentPage: this.state.currentPage,
        status:this.state.stockStatus
      }
    });
    this.props.history.push({
      pathname: '/edit-inventory/'+data?.id,
      state: data
    });
    
  }

  deleteDialogBoxFunc = (data: any) => {
    this.setState({selectedRow:data })
    this.setState({open:false, anchorEl:null});

    this.setState(prevState => ({
      deleteDialogBox: !prevState.deleteDialogBox
    }));
  };
  
  deleteRecord= ()=> {
    
    const id: any = this.state.selectedRow?.id

    this.deleterecordIdaPI = this.apiCallHelper({
      contentType: configJSON.generalContentType,
      method: configJSON.deletApiMethod,
      endPoint: configJSON.inventoryAPiEndPoint + '/' + id,
      body: null,
    });

  }

  apiCallHelper = (data:any) => {
    const { method, endPoint, body } = data;

    const header = {
      // "Content-Type": contentType,
      token: localStorage.getItem('token'),
    };
    
    const loginReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    loginReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    loginReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    
    loginReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if(body){
      loginReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    
    runEngine.sendMessage(loginReqMessage.id, loginReqMessage);
    
    return loginReqMessage.messageId;
  }

  // Customizable Area End
}
