import React,{ createContext, useEffect, useState } from 'react';

const AppContext = createContext({});


const USER_TYPE  = {
    darkStoreUser : "darkStoreUser",
    globalStoreUser : "globalStoreUser"
}

const Store = (props : any) => {
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [userType, setUserType] = useState(USER_TYPE.globalStoreUser)
    const [userProfile, setUserProfile ] = useState();
    const [newNotification ,setNewNotification] = useState(false)
    const [lengthOfNotification,setLenghtOfNotification] = useState(0) 

    return (
        <AppContext.Provider value={{ lengthOfNotification,setLenghtOfNotification,newNotification,setNewNotification,error, setError, success, setSuccess, userType, setUserType, userProfile, setUserProfile}}>
            {props.children}
        </AppContext.Provider>
    );
};

const withState = (Child: any) => (props: any) => (
    <AppContext.Consumer>
        {(context) => <Child {...props} {...context} />}
    </AppContext.Consumer>
);

export { Store, withState };