import React from "react";
// Customizable Area Start
import { TextField, IconButton, withStyles, Typography, Select, MenuItem } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import PurchaseOrdersController,{ Props, rows } from "./PurchaseOrdersController.web";
import CustomPagination from "../../../components/src/Pagination.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import { SearchIcon } from "../../../components/src/assets.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TableCommonComponent from "../../../components/src/TableCommonCompoent.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";

const styles : any = {
table:{
    "& td":{
        fontFamily:"gilroy-medium"
    },
    "& th":{
        fontFamily:"gilroy-bold"
    },
    "& tbody tr":{
        "& td.Cancelled, td.Pending, td.Inbounded, td.pending_supplier  ":{
            padding:"0px",
        },
        "& td.Cancelled span, td.Pending span, td.Inbounded span, td.pending_supplier span":{
            padding:"6px 16px",
            borderRadius:"6px",
            minWidth:"110px",
            display:"inline-flex",
            justifyContent:"center",
            whiteSpace: "nowrap"
        },
        "& td.Cancelled span":{
            background:"#fed4d5",
            color:"#c96058"
        },
        "& td.Pending span":{
            background:"#ffe5d4",
            color: "#b08055"
        },
        "& td.Inbounded span":{
            background:"#e4fcd8",
            color:"#438147"
        },
        "& td.pending_supplier span":{
            background:"#8ED1FC",
            color:"#194D33"
        },
        "& td button span svg g":{
            stroke:"#652d92"
        }
    },
    "& thead tr":{
        background:"#f7f7f7",
        color:"#848192", 
    },
    
},
actionBar:{
    marginBottom:"12px",
    display:"inline-flex",
    width:"100%"
},
select:{
    marginLeft:"24px",
},
filterBar:{
    marginRight:"0px",
    marginLeft:"auto",
    "& .MuiOutlinedInput-root":{
        height:"40px",
    },
    "& .MuiOutlinedInput-input":{
        padding:"10.5px 30px"
    }
}
}

class PurchaseOrders extends PurchaseOrdersController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const {  currentPage, rowsCount, currentDataList, startIndex, endIndex, totalRecords } = this.state;
        const { userProfile } = restProps;
        const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile?.attributes?.groups?.data[0].attributes.settings : {};

        return (
            <GlobalStoreDashboardLayout {...restProps} headerTitle="Purchase Orders" actionHandler={this.handleNavigateToAddPurchaseOrder} headerAction="New Purchase Order" subTitle="" hasAccess={groups?.purchase_orders}>
                {!groups?.purchase_orders
                ?<NoAccessPage description="Sorry, you don't have the permission" />
                :<div className="main_container">
                <TextField 
                    className="text_field"
                    placeholder="Search by PO No, Supplier Name"
                    variant="outlined"
                    InputProps={{
                        endAdornment: <IconButton><SearchIcon/></IconButton>
                    }}
                    value={this.state.searchQuery}
                    onChange={this.handleSearchItem}
                 />
                <div className="details_container">
                    <div className={classes.actionBar}>
                        <Typography variant="h6" className="title">List of orders</Typography>
                        <div className={classes.filterBar}>
                            <Select 
                            value={this.state.fliterStatus}
                            onChange={this.filterByStatus}
                            IconComponent={KeyboardArrowDownIcon}
                            variant="outlined"  className={classes.select}>
                                <MenuItem value=" ">All Data</MenuItem>
                                <MenuItem value="pending_supplier">Pending Supplier</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="inbounded">Inbounded</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                                
                            </Select>
                            
                        </div>
                    </div>
                    <TableCommonComponent rows={rows} data={currentDataList} cssClasses={classes.table} handleAction={this.handleNavigateToEditPurchaseOrder} handleNavigate={this.handleViewPurchaseOrder}  />
                </div>
               {currentDataList.length ? <CustomPagination
                    dataLength={totalRecords}
                    
                    currentPage={currentPage}
                    rowsCount={rowsCount}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    handlePagination={this.handlePurchaseOrdersPagination}
                    handlePrevNext={this.handlePurchaseOrdersPrevNext}
                /> : <></>}
            </div>}
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { PurchaseOrders };
export default withStyles(styles)(withRouter(PurchaseOrders));
// Customizable Area End
