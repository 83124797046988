import React from "react";
// Customizable Area Start
import { Checkbox, TextField, IconButton, withStyles, Typography, Icon, Table, TableBody, TableHead, TableCell, TableRow } from "@material-ui/core";
import {withRouter} from "react-router-dom";
import DarkStoreOrdersController, { rows } from "./DarkStoreOrdersController.web";
import { SearchIcon, PendingIcon, DeliveryBikeIcon, ReadyToDeliverIcon, CourierIcon, DeliveredIcon, CloseRoundLineIcon } from "../../../components/src/assets.web";
import CustomPagination from "../../../components/src/Pagination.web";
import DarkStoreDashboardLayout from "../../../components/src/DarkStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import CashCollectPopup from "../../../components/src/CashCollectPopup.web";
import {convertUtcToIst} from "../../../components/src/CommonFunctions";

const styles: any = {
    statusCardList: {
        display: "inline-flex",
        flexWrap:"wrap"
    },
    statusIcon: {
        width: "60px",
        height: "54px",
        display: "flex",
        background: "#fff",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px",
        "& .MuiIcon-root": {
            width: "auto",
            height: "auto"
        }
    },
    table: {
        "& td": {
            fontFamily: "gilroy-medium"
        },
        "& th": {
            fontFamily: "gilroy-bold"
        },
        "& tbody tr": {
            "& td.Assigned, td.Pending, td.Ready, td.Delivered, td.On_the_way, td.Cancelled ": {
                padding: "0px",
            },
            "& td.Assigned span, td.Pending span, td.Packed span, td.Delivered span, td.On_the_way span, td.Cancelled span": {
                padding: "6px 16px",
                borderRadius: "6px",
                minWidth: "110px",
                display: "inline-flex",
                justifyContent: "center",

            },
            "& td.Assigned span": {
                background: "#fffae2",
                color: "#CBC61C"
            },
            "& td.Pending span": {
                background: "#fed4d5",
                color: "#C62323"
            },
            "& td.Cancelled span": {
                background: "red",
                color: "white"
            },
            "& td.Packed span": {
                background: "#FEE5D4",
                color: "#C67723"
            },
            "& td.Delivered span": {
                background: "#e4fcd8",
                color: "#239A3C"
            },
            "& td.On_the_way span": {
                background: "#D4E6FE",
                color: "#2368C6"
            }
        },
        "& thead tr": {
            background: "#f7f7f7",
            color: "#848192",
        },

    }
}

class DarkStoreOrders extends DarkStoreOrdersController {
    renderDarkstoreOrders = () => {
        const { noAccess, metaData, currentPage, total_pages, dataList, startIndex, endIndex, selectedRow,card_status } = this.state;
        const { classes } = this.props;
                if (noAccess) {
            return <NoAccessPage className="noAccess" description="Your account is currently not linked to any darstore. To Link it, please contact the Docile administrator." />;
        } else {
            return (
                <div className="main_container">
                    <TextField
                        onChange={this.handleSearch}
                        className="text_field"
                        placeholder="Search Order ID, Buyer Name, Delivery Agent"
                        variant="outlined"
                        InputProps={{
                            endAdornment: <IconButton><SearchIcon /></IconButton>
                        }}
                    />
                     <div className="details_container">
                         <Typography variant="h6" className="title">{dataList.length > 0 && dataList[0].attributes.dark_store.legal_name}</Typography>
                        <div className={classes.statusCardList}>
                            <div className="status_card" key={"Pending"} style={{border:this.conditionShort(card_status === "Pending","1px solid #00C120","none")}} data-test-id="PendingTab" onClick={() => this.handleOrderStatus("Pending")}>
                                <div className={classes.statusIcon}>
                                    <Icon><PendingIcon /></Icon>
                                </div>
                                <div className="status_detail">
                                    <Typography variant="h6">Pending</Typography>
                                    <Typography variant="h5">{metaData.pending}</Typography>
                                </div>
                            </div>
                            <div className="status_card" key={"Packed"} style={{border:this.conditionShort(card_status === "Packed","1px solid #00C120","none")}} data-test-id="PackedTab"  onClick={() => this.handleOrderStatus("Packed")}>
                                <div className={classes.statusIcon}>
                                    <Icon><ReadyToDeliverIcon /></Icon>
                                </div>
                                <div className="status_detail">
                                    <Typography variant="h6">Packed</Typography>
                                    <Typography variant="h5">{metaData.packed}</Typography>
                                </div>
                            </div>
                            <div className="status_card" key={"Assigned"} style={{border:this.conditionShort(card_status === "Assigned","1px solid #00C120","none")}} data-test-id="AssignedTab" onClick={() => this.handleOrderStatus("Assigned")}>
                                <div className={classes.statusIcon}>
                                    <Icon><CourierIcon /></Icon>
                                </div>
                                <div className="status_detail">
                                    <Typography variant="h6">Assigned</Typography>
                                    <Typography variant="h5">{metaData.assigned}</Typography>
                                </div>
                            </div>
                            <div className="status_card" key={"Delivering"} style={{border:this.conditionShort(card_status === "on_the_way","1px solid #00C120","none")}} data-test-id="DeliveringTab" onClick={() => this.handleOrderStatus("on_the_way")}>
                                <div className={classes.statusIcon}>
                                    <Icon><DeliveryBikeIcon /></Icon>
                                </div>
                                <div className="status_detail">
                                    <Typography variant="h6">On the way</Typography>
                                    <Typography variant="h5">{metaData.on_the_way}</Typography>
                                </div>
                            </div>
                            <div className="status_card" key={"Delivered"}  style={{border:this.conditionShort(card_status === "Delivered","1px solid #00C120","none")}} data-test-id="DeliveredTab" onClick={() => this.handleOrderStatus("Delivered")}>
                                <div className={classes.statusIcon}>
                                    <Icon><DeliveredIcon /></Icon>
                                </div>
                                <div className="status_detail">
                                    <Typography variant="h6">Delivered</Typography>
                                    <Typography variant="h5">{metaData.delivered}</Typography>
                                </div>
                            </div>
                            <div className="status_card" key={"Cancelled"} style={{ border: this.conditionShort(card_status === "Cancelled", "1px solid #00C120", "none") }} data-test-id="CancelledTab" onClick={() => this.handleOrderStatus("Cancelled")}>
                                <div className={classes.statusIcon}>
                                    <Icon><CloseRoundLineIcon /></Icon>
                                </div>
                                <div className="status_detail">
                                    <Typography variant="h6">Cancelled</Typography>
                                    <Typography variant="h5">{metaData.cancelled || 0}</Typography>
                                </div>
                            </div>
                        </div>
                    </div> 
                     <div className="details_container">
                        <Typography variant="h6" className="title">List of orders</Typography>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {rows.map((row: any) => <TableCell key={row.key} align="center">{row.label}</TableCell>)}
                                </TableRow>

                            </TableHead>
                            <TableBody>
                               {dataList.length > 0 
                               ? dataList.map((order: any, index: number) => {
                                const status = this.getStatus(order);
                                const cod = order.attributes.payment_type === "cash_on_delivery";
                                const cashCollected = order.attributes.cash_collected;
                                const isCashCollected = cod && cashCollected;
                                return (
                                    <TableRow key={order.id} className="orderRow" onClick={(e: React.MouseEvent<HTMLTableRowElement>) => this.navigateToOrderDetails(order.id,e)}>
                                        <TableCell align="center">{startIndex + index}</TableCell>
                                        <TableCell align="center">{order.attributes.order_number}</TableCell>
                                        <TableCell align="center">{order.attributes.total}</TableCell>
                                        <TableCell align="center">{order.attributes.account.first_name}</TableCell>
                                        <TableCell align="center">{convertUtcToIst(order.attributes.arrival_time)}</TableCell>
                                        <TableCell align="center">{convertUtcToIst(order.attributes.delivered_at)}</TableCell>
                                        <TableCell align="center">{!!order.attributes.delivery_agent && order.attributes.delivery_agent.first_name}</TableCell>
                                        <TableCell align="center">{cod ? "Yes": "No"}</TableCell>
                                         <TableCell align="center" className={status.replace(/ /g,"_")}>
                                        <span>{status}</span>
                                        </TableCell>
                                        <TableCell align="center"><Checkbox 
                                            className="cashcollect-checkbox"
                                            checked={isCashCollected}
                                            onClick={(e:any) => e.stopPropagation()}
                                            disabled={status !== "Delivered" || !cod || isCashCollected} 
                                            onChange={(e:any) => {
                                            this.handleCashCollect(order)}}
                                            color="primary" 
                                        /></TableCell>
                                     </TableRow>
                                 )
                            }) : <Typography className="no_records_found">No Records found</Typography>}
                            </TableBody>
                            {selectedRow && <CashCollectPopup 
                            isOpen={this.state.isOpen}
                            handleClose={this.handleClose}
                            cancelHandler={this.handleClose}
                            actionHandler={this.handleCashCollected}
                            action="Yes, cash collected" 
                            description={<Typography variant="h6">Please collect <span>₹ {selectedRow.attributes.total}</span> from <span>{selectedRow.attributes && selectedRow.attributes.delivery_address?.name}</span> for order <span>{selectedRow.attributes.order_number}</span>. Please note that you are about to mark this order as cash collected, once you confirm the cach collection, it cannot be changed.</Typography>}/>}
                        </Table>
                    </div> 
                   { this.conditionShort(dataList.length,<CustomPagination
                        dataLength={!!metaData && metaData.total_count}
                        rowsCount={total_pages}
                        endIndex={endIndex}
                        handlePrevNext={this.handleDarkstoreOrdersPrevNext}
                        handlePagination={this.handleDarkstoreOrdersPagination}
                        startIndex={startIndex - 1}
                        currentPage={currentPage}
                    /> ,"")}
                </div>
            )
        }
    }
 
    conditionShort =(conditon:any,trueStatement:any,falseStatement:any) =>{
       return  conditon?trueStatement:falseStatement
    }
    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        return (
            <DarkStoreDashboardLayout {...restProps} headerTitle="Orders" actionHandler={undefined} headerAction="" subTitle="" hasAccess={true}>
                {this.renderDarkstoreOrders()}
            </DarkStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { DarkStoreOrders };
export default withStyles(styles)(withRouter(DarkStoreOrders));
// Customizable Area End
