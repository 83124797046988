Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.searchPlaceholderText = "Search by Item Code, Item Name";
exports.datePlaceholderText = "Select Date Range"
exports.dateFormat = "dd/MM/yyyy"
exports.rowsPerPage = 10;
exports.globalStoreDashboardEndPoint="/bx_block_dashboard/dashboards/global_dashboard";
exports.getApiMethod="GET";
exports.dasrkstoreDashboardsEndPoint="/bx_block_dashboard/dashboards/dark_store_dashboard";
exports.getLinkedDarkstoreEndPoint = "/account_block/dark_stores/get_linked_store";
exports.globalDashboardRows = {
  "item_code": "Item Code",
  "name": "Item Name",
  "average_inventory": "Average Inventory",
  "inventory_turnover_ratio": "Inventory Turnover Ratio",
  "days_on_hands": "Days On Hands",
  "stocks_to_sales_ratio": "Stocks to Sales Ratio",
  "gross_margin_by_product": "Gross Margin By Product",
  // "time_to_recieve": "Time To Recieve",
  "unit_purchase_cost": "Unit Purchase Cost",
  "opening_count": "Opening Inventory",
  "opening_inventory_value": "Opening Inventory Value",
  "inward_quantity": "Inwards",
  "inwards_value": "Inwards Value",
  "outward_quantity": "Outwards",
  "outwards_value": "Outwards Value",
  "closing_count": "Closing Inventory",
  "closing_inventory_value": "Closing Inventory Value",
  "costs_of_goods_sold": "Costs Of Goods Sold",
  "sales": "Sales",
  "selling_price": "Selling Price",
  "gross_margin":"Gross Margin",
  "margin_per_unit":"Margin Per Unit"
}
exports.darkstoreDashboardRows={
  "name": "Item Name",
  "item_code": "Item Code",
  "no_of_units_sold": "No Of Units Sold",
  "no_of_units_received": "No of Units Received",
  "sell_through_rate": "Sell-Through Rate",
  "selling_price": "Selling Price",
  "discount_price": "Discounted Price",
  "product_sales": "Product Sales",
  "sales_value": "Sales Value",
  "revenue_per_product": "Revenue Per Product",
  "no_of_orders" :"No Of Orders",
  "order_cycle_time": "Order Cycle Time"
}
exports.GlobalStoreTableHeaders = [
  {
    label: "Item Code",

    mandatory: true,
  },
  {
    label: "Item Name",
    mandatory: true
  },
  {
       label: "Average Inventory"
  },
  {
        label: "Inventory Turnover Ratio"
  },
  {
    label: "Days On Hands",
  },
  {

    label: "Stocks to Sales Ratio",
  },
  {
    label: "Gross Margin By Product",
  },

  // {
  //   label: "Time To Recieve",
  // },

  {
    label: "Unit Purchase Cost",
  },
  {

    label: "Opening Inventory" 
  },
  {

    label: "Opening Inventory Value" 

  },
  {
    label: "Inwards",

  },
  {
    label: "Inwards Value",
  },

  {
     label: "Outwards"
  },

  {
      label: "Outwards Value" 
  },
  {
    label: "Closing Inventory",
  },
  {

    label: "Closing Inventory Value"
   },
  {

    label: "Costs Of Goods Sold",
  },
  {

    label: "Sales",
  },
  {

      label: "Selling Price"

  },
  {

    label: "Gross Margin"

},
{

  label: "Margin Per Unit"

},
];


exports.DarkStoreTableHeaders = [
  {
    label: "Item Name",
    
  },
  {
    label: "Item Code",
   
  },
 
  {
    label: "No Of Units Sold"
},
 {
   label: "No of Units Received",
    },
   {
     label: "Sell-Through Rate",
  },
  {
    label: "Selling Price"

  },
  {

    label: "Discounted Price",
    
      },
  {

    label: "Product Sales"
  },
  {
    label: "Sales Value"

 },
  {
    label: "Revenue Per Product"
  },
];

exports.noOfOrders = [
  {
    label: "No Of Orders"
  },
]
exports.orderCyle = [
  {
    label: "Order Cycle Time"
  },
]




// Customizable Area End
