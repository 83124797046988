import React from "react";

export const WarehouseActive = () => {
    return (
        <svg width="17.99995px" height="16.8307px" viewBox="0 0 17.99995 16.8307" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-2" transform="translate(-24.000000, -157.000000)" fill="#662D91" fill-rule="nonzero">
            <g id="warehouse_FILL1_wght300_GRAD0_opsz24-(1)" transform="translate(24.000000, 157.000000)">
                <path d="M5.21050187,16.8307 L1.71255764,16.8307 C1.24160508,16.8307 0.838444614,16.6536917 0.503076242,16.299675 C0.167692081,15.945675 4.20716032e-16,15.5201167 4.20716032e-16,15.023 L4.20716032e-16,4.892275 C4.20716032e-16,4.51505833 0.0974763015,4.17556667 0.292428905,3.8738 C0.487365718,3.57201667 0.748191996,3.35254167 1.07490774,3.215375 L8.36232509,0.140375 C8.56515664,0.0467916667 8.77770661,0 8.999975,0 C9.22224339,0 9.43479336,0.0467916667 9.63762491,0.140375 L16.9250423,3.215375 C17.251758,3.35254167 17.5125843,3.57201667 17.7075211,3.8738 C17.9024737,4.17556667 17.99995,4.51505833 17.99995,4.892275 L17.99995,15.023 C17.99995,15.5201167 17.8322579,15.945675 17.4968738,16.299675 C17.1615054,16.6536917 16.7583449,16.8307 16.2873924,16.8307 L12.7894481,16.8307 L12.7894481,8.8307 L5.21050187,8.8307 L5.21050187,16.8307 Z M6.42206748,16.8307 L6.42206748,15.061475 L8.09815145,15.061475 L8.09815145,16.8307 L6.42206748,16.8307 Z M8.16193302,13.8307 L8.16193302,12.061475 L9.83801698,12.061475 L9.83801698,13.8307 L8.16193302,13.8307 Z M9.90179855,16.8307 L9.90179855,15.061475 L11.5778825,15.061475 L11.5778825,16.8307 L9.90179855,16.8307 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>)
}