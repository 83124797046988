import React from "react";
// Customizable Area Start
import CommonDashboard from "./CommonDashboard.web";
import GlobalStoreDashboardController from "../../blocks/landingpage/src/GlobalStoreLandingPageController.web";
import {  Toolbar, ListItem, ListItemIcon, ListItemText, withStyles } from "@material-ui/core";
import DashboardHeader from "./DashboardHeader.web";
import { WarehouseIcon, WarehouseActiveIcon ,GridViewIcon, GridViewActiveIcon, ListIcon, ListActiveIcon, OutboundIcon, OutboundActiveIcon, InventoryIcon, InventoryActiveIcon, TeamDashboardIcon, TeamDashboardActiveIcon} from "./assets.web";
import QRScannerWeb from "./QRScanner.web";

const styles : any = {
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        paddingTop: "1rem",
        background:"#f6f5fa",
        minHeight:"900px"
      },
      toolbar:{
        minHeight:"36px"
      },
      selectedListItem:{
        borderTopRightRadius:"6px",
        borderBottomRightRadius:"6px",
        borderLeft:"4px solid #652d92 !important",
        background:"linear-gradient(0deg, #F7F7FC, #F7F7FC), #F5F5FA"
      },
      rootListItem:{
        borderLeft:"4px solid #fff",
      },
      dashboardContent:{
        background:"#f6f5fa"
      }
}

class GlobalStoreDashboardLayout extends GlobalStoreDashboardController{
    
    customListItem = (props:any) => {
        const { classes } = this.props;
        return (<ListItem {...props} onClick={() => this.handleNavigation(props.pathname)} classes={{selected:classes.selectedListItem, root:classes.rootListItem}}>
            {props.children}
      </ListItem>)
    }

    render() {

        const { classes, headerAction, headerTitle, actionHandler, subTitle, userProfile, hasAccess ,isInventory} = this.props;

        return (

            <div style={{display: "flex"}}>
                <CommonDashboard clearNotification={this.clearAllGlobalStoreNotification} name={userProfile?.attributes?.first_name} email={userProfile?.attributes?.email} logout={this.handleLogout} userType={this.props.userType} anchorEl ={this.state.anchorEl} handleOpenNotification = {this.handleOpenNotification} handleCloseNotification ={this.handleCloseNotification} handleOpenQrModal = {this.handleOpenQrModal} newNotificationAdded={this.props.newNotification} notificationList = {this.state.listOfNotification}
                isSidebarClosing={this.state.isClosing} handleCloseAction={this.handleCloseDrawer}
                >
                    <this.customListItem button key="Dark Store Management" pathname="dark_store_management" selected={window.location.pathname.includes("/dark_store_management") }>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/dark_store_management") ? <WarehouseActiveIcon/>  :<WarehouseIcon/>}</ListItemIcon>
                        <ListItemText primary="Dark Store Management" onClick={this.handleCloseDrawer}/>
                    </this.customListItem>
                    <this.customListItem button key="Purchase Orders" pathname="purchase-orders" selected={/\/(view-purchase-order|edit-purchase-order|create-purchase-order|purchase-orders)\b/.test(window.location.pathname)}>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{/\/(view-purchase-order|edit-purchase-order|create-purchase-order|purchase-orders)\b/.test(window.location.pathname) ? <ListActiveIcon/> :<ListIcon/>}</ListItemIcon>
                        <ListItemText primary="Purchase Orders" onClick={this.handleCloseDrawer}/>
                    </this.customListItem>
                    <this.customListItem button key="Delivery Challan" pathname="delivery_challan" selected={window.location.pathname.includes("/delivery_challan") }>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/delivery_challan") ? <OutboundActiveIcon/>  :<OutboundIcon/>}</ListItemIcon>
                        <ListItemText primary="Delivery Challan" onClick={this.handleCloseDrawer}/>
                    </this.customListItem>
                    <this.customListItem button key="Inventory"pathname="inventory" selected={/\/(edit-inventory|add-inventory|inventory)\b/.test(window.location.pathname)}>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{/\/(edit-inventory|add-inventory|inventory)\b/.test(window.location.pathname) ? <InventoryActiveIcon/> :<InventoryIcon/>}</ListItemIcon>
                        <ListItemText primary="Inventory" onClick={this.handleCloseDrawer}/>
                    </this.customListItem>
                    <this.customListItem button key="Dashboard" pathname="dashboard" selected={window.location.pathname.includes("/dashboard") }>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/dashboard") ? <GridViewActiveIcon/> :<GridViewIcon/>}</ListItemIcon>
                        <ListItemText primary="Dashboard" onClick={this.handleCloseDrawer}/>
                    </this.customListItem>
                    <this.customListItem button key="Sales Force Dashboard" pathname="sales-force-dashboard" selected={window.location.pathname.includes("/sales-force-dashboard") }>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/sales-force-dashboard")? <TeamDashboardActiveIcon/> :<TeamDashboardIcon/>}</ListItemIcon>
                        <ListItemText primary="Sales Force Dashboard" onClick={this.handleCloseDrawer}/>
                    </this.customListItem>
                </CommonDashboard>
                <main className={classes.content}>
                <Toolbar className={classes.toolbar} />
                <DashboardHeader hasAccess={hasAccess} subTitle={subTitle} actionHandler={actionHandler} headerAction={headerAction} headerTitle={headerTitle} isInventory = {isInventory} />
                <div className={classes.dashboardContent}>
                    {this.props.children}
                </div>
                </main>
                {this.state.openQrModal && this.state.videoDeviceAvailable && <QRScannerWeb open={this.state.openQrModal} handleClose={this.handleOpenQrModal}  handleScan={this.handleScan} invalidQR={this.state.invalidQR} setError={this.props.setError}/>}
            </div>
            )
    }
}

export default withStyles(styles)(GlobalStoreDashboardLayout);
// Customizable Area End