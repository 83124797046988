import React from "react";
import { Delete } from "./assets/icons/delete.web";
import { Dashboard } from "./assets/icons/dashboard.web";
import { Edit } from "./assets/icons/edit.web";
import { Link } from "./assets/icons/link.web";
import { Alert } from "./assets/icons/alert.web";
import { Close } from "./assets/icons/close.web";
import { GridView } from "./assets/icons/gridView.web";
import { GridViewActive } from "./assets/icons/gridViewActive.web";
import { Warehouse } from "./assets/icons/warehouse.web";
import { WarehouseActive } from "./assets/icons/warehouseActive.web";
import { List } from "./assets/icons/list.web";
import { ListActive } from "./assets/icons/listActive.web";
import { AllStock } from "./assets/icons/all_stock.web";
import { OutOfStock } from "./assets/icons/out_of_stock.web";
import { LowStock } from "./assets/icons/low_stock.web";
import { Outbound } from "./assets/icons/outbound.web";
import { OutboundActive } from "./assets/icons/outboundActive.web";
import { Inventory } from "./assets/icons/inventory.web";
import { InventoryActive } from "./assets/icons/inventoryActive.web";
import { TeamDashboard } from "./assets/icons/team_dashboard.web";
import { TeamDashboardActive } from "./assets/icons/team_dashboardActive.web";
import { ArrowRight } from "./assets/icons/arrow_right.web";
import { CheckMarkCircleLine } from "./assets/icons/checkmark_circle_line.web";
import { CloseRoundLine } from "./assets/icons/close_round_line.web";
import { DeliveryBike } from "./assets/icons/deliveryBike.web";
import { Group } from "./assets/icons/group.web";
import { Kebab } from "./assets/icons/kebab.web";
import { Location } from "./assets/icons/location.web";
import { Logout } from "./assets/icons/logout.web";
import { Notification } from "./assets/icons/notification.web";
import { Pending } from "./assets/icons/pending.web";
import { ReadyToDeliver } from "./assets/icons/ready_to_deliver.web";
import { Scan } from "./assets/icons/scan.web";
import { UnlinkSharp } from "./assets/icons/unlink_sharp.web";
import { Search } from "./assets/icons/search.web";
import { Courier } from "./assets/icons/courier.web";
import { Delivered } from "./assets/icons/delivered.web";
import { Checkmark } from "./assets/icons/checkmark.web";

export const ArrowRightIcon = (props:any) => <ArrowRight {...props} />;
export const DeleteIcon = (props:any) => <Delete {...props} />;
export const DashboardIcon = (props:any) => <Dashboard {...props} />;
export const EditIcon = (props: any) => <Edit {...props} />;
export const LinkIcon = (props: any) => <Link {...props} />;
export const AlertIcon = (props: any) => <Alert {...props} />;
export const CloseIcon = (props: any) => <Close {...props} />;
export const GridViewActiveIcon = (props: any) => <GridViewActive {...props} />;
export const GridViewIcon = (props: any) => <GridView {...props} />;
export const WarehouseActiveIcon = (props: any) => <WarehouseActive {...props} />;
export const WarehouseIcon = (props: any) => <Warehouse {...props} />;
export const ListActiveIcon = (props: any) => <ListActive {...props} />;
export const ListIcon = (props: any) => <List {...props} />;
export const CourierIcon = (props: any) => <Courier {...props} />;
export const AllStockIcon = (props: any) => <AllStock {...props} />;
export const OutOfStockIcon = (props: any) => <OutOfStock {...props} />;
export const OutboundIcon = (props: any) => <Outbound {...props} />;
export const OutboundActiveIcon = (props: any) => <OutboundActive {...props} />;
export const InventoryIcon = (props: any) => <Inventory {...props} />;
export const InventoryActiveIcon = (props: any) => <InventoryActive {...props} />;
export const TeamDashboardIcon = (props: any) => <TeamDashboard {...props} />;
export const TeamDashboardActiveIcon = (props: any) => <TeamDashboardActive {...props} />;
export const LowStockIcon = (props: any) => <LowStock {...props} />;
export const SearchIcon = (props: any) => <Search {...props} />
export const CheckMarkCircleLineIcon = (props: any) => <CheckMarkCircleLine {...props} />;
export const CloseRoundLineIcon = (props: any) => <CloseRoundLine {...props} />;
export const DeliveryBikeIcon = (props: any) => <DeliveryBike {...props} />;
export const GroupIcon = (props: any) => <Group {...props} />;
export const KebabIcon = (props: any) => <Kebab {...props} />;
export const LocationIcon = (props: any) => <Location {...props} />;
export const LogoutIcon = (props: any) => <Logout {...props} />;
export const NotificationIcon = (props: any) => <Notification {...props} />;
export const PendingIcon = (props: any) => <Pending {...props} />;
export const ReadyToDeliverIcon = (props: any) => <ReadyToDeliver {...props} />;
export const ScanIcon = (props: any) => <Scan {...props} />;
export const CheckmarkIcon = (props: any) => <Checkmark {...props} />;
export const DeliveredIcon = (props: any) => <Delivered {...props} />;
export const UnlinkSharpIcon = (props: any) => <UnlinkSharp {...props} />;
