import React from "react";

export const AllStock = () => {
    return (
        <svg width="57px" height="35.6537486px" viewBox="0 0 57 35.6537486" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Vendor-Managemtent-wrehouse1-Copy" transform="translate(-363.000000, -341.000000)">
                    <g id="noun-speedometer-1580196-copy-4" transform="translate(363.000000, 341.000000)">
                        <path d="M28.4996381,0 C12.7847265,0 0,12.8630034 0,28.6753461 L0,30 L3.59873525,30 L3.59873525,28.6753461 C3.59873525,14.8607863 14.7691458,3.62052643 28.4998794,3.62052643 C42.2303717,3.62052643 53.4022298,14.8601795 53.4022298,28.6753461 L53.4022298,30 L57,30 L57,28.6753461 C57,12.8642171 44.2152735,0 28.5003619,0 L28.4996381,0 Z M56.2962248,29.2919158 L54.1052812,29.2919158 L54.1052812,28.6751034 C54.1052812,14.4697258 42.6186906,2.91268499 28.4996381,2.91268499 C14.3805856,2.91268499 2.89520133,14.4696044 2.89520133,28.6751034 L2.89520133,29.2919158 L0.703533923,29.2919158 L0.703533923,28.6751034 C0.703533923,13.2547922 13.1734073,0.707355952 28.4998794,0.707355952 C43.8263514,0.707355952 56.2962248,13.2535785 56.2962248,28.6751034 L56.2962248,29.2919158 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
                        <path d="M0.833557388,25.9029062 L3.0315773,26.0000837 C2.99857327,26.3104154 2.97459943,26.5817549 2.95965576,26.8141022 C2.94463813,27.0475995 2.92896249,27.2901895 2.91966248,27.597411 C2.91211823,27.8466314 2.8983241,28.0894009 2.89569033,28.2753583 C2.89384976,28.4053116 2.89566921,28.5334053 2.89569033,28.6554753 C2.89570409,28.7350089 2.89570409,28.9471557 2.89569033,29.2919158 L0.703533923,29.2919158 C0.702744268,28.80584 0.702744268,28.4789868 0.703533923,28.311356 C0.704323578,28.1437252 0.704323578,27.9371563 0.703533923,27.6916494 C0.732975848,27.2605182 0.751650636,26.9505708 0.759558289,26.761807 C0.767465942,26.5730432 0.792132308,26.2867429 0.833557388,25.9029062 Z" id="Rectangle" fill="#D54747"></path>
                        <path d="M54.1248133,26.3181562 L56.3104198,26.4709887 C56.2964626,26.6697564 56.2862622,26.8156303 56.2798184,26.9086102 C56.2617276,27.1696496 56.2398684,27.7602489 56.2223592,28.2296432 C56.2136164,28.4640238 56.2041936,28.3581863 56.2011414,28.5330709 C56.1990085,28.6552862 56.205979,29.035591 56.2060034,29.1503924 C56.2060166,29.212274 56.2166401,29.2911619 56.2169046,29.3490855 C56.2169414,29.3571455 56.1565626,29.361448 56.035768,29.361993 L54.0204486,29.3677432 C54.010493,29.1011107 54.0059727,28.8889698 54.0068878,28.7313205 C54.0078029,28.5736713 54.0159622,28.3105023 54.0313658,27.9418135 C54.0424401,27.6383865 54.0525592,27.3979111 54.0617231,27.2203872 C54.0708871,27.0428633 54.0919171,26.7421197 54.1248133,26.3181562 Z" id="Rectangle-Copy-13" fill="#47D55E" transform="translate(55.158596, 27.842950) rotate(176.000000) translate(-55.158596, -27.842950) "></path>
                        <path d="M27.9001672,0.222631045 L30.1206546,0.339001594 C30.0932642,0.654052853 30.0661369,1.17167115 30.0392727,1.8918565 C30.0305299,2.12623707 30.0349888,2.53416758 30.0333518,2.70908802 C30.0325821,2.79132902 30.0215567,2.83203238 30.0207501,2.94954307 C30.0202123,3.02788353 30.0253557,3.19403408 30.0361803,3.44799471 L27.8019907,3.33090579 C27.7833793,3.03933972 27.7745311,2.81473208 27.7754462,2.65708287 C27.7763613,2.49943366 27.7898897,2.22308638 27.8160313,1.82804104 C27.8352345,1.46428734 27.849418,1.19364858 27.858582,1.01612474 C27.8677459,0.838600906 27.8816077,0.574103007 27.9001672,0.222631045 Z" id="Rectangle-Copy-12" fill="#DD7E37" transform="translate(28.948018, 1.835313) scale(1, -1) rotate(267.000000) translate(-28.948018, -1.835313) "></path>
                        <path d="M41.1829658,22.5987731 C39.8547212,21.913146 38.2142401,21.9159867 36.8892593,22.5902736 L21.8068902,17.5070909 L21.4876908,18.1270794 L34.376063,27.4666162 C34.5979891,28.9662706 35.5225503,30.2647783 36.8729887,30.9603297 C37.5373648,31.3031372 38.281982,31.4844593 39.0252816,31.4844593 C40.7951058,31.4844593 42.3985972,30.5079674 43.2107992,28.9360381 C43.7864,27.8192997 43.8935855,26.5444001 43.5106454,25.3473119 C43.1267504,24.1507797 42.3013481,23.1742274 41.183679,22.5987112 L41.1829658,22.5987731 Z M42.5820468,28.6121379 C41.8917053,29.9489651 40.5280305,30.7790575 39.0231782,30.7790575 C38.3828817,30.7790575 37.7670999,30.6288986 37.1938804,30.3333074 C36.0063176,29.7213454 35.2068323,28.5607029 35.0544009,27.2300407 L35.0369299,27.0756389 L23.7765881,18.9151791 L36.9538668,23.3570861 L37.0898583,23.2824793 C38.2457865,22.6450235 39.6808297,22.6204604 40.8589396,23.2262888 C41.8089947,23.7154837 42.5111462,24.5455881 42.8359545,25.5631676 C43.1612946,26.5793206 43.071105,27.6625144 42.5819102,28.6121585 L42.5820468,28.6121379 Z" id="Shape" fill="#000000" fillRule="nonzero" transform="translate(32.612022, 24.495775) rotate(154.000000) translate(-32.612022, -24.495775) "></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}