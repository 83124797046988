// App.js - WEB
import React, { Component } from "react";
import HomeScreen from '../../components/src/HomeScreen';
import WebRoutes from './WebRoutes.web';
import { withState } from "./AppContext.web";
import "./App.css";

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

class App extends Component {

  componentDidMount() {
    const userType = localStorage.getItem("userType");
    if(userType){
      this.props.setUserType(userType);
    }
  }
   
  render() {
    return (
      <>
         <WebRoutes {...this.props}/>
      </>
    );
  }
}

export default withState(App);
