import React from "react";
// Customizable Area Start
import { makeStyles ,Dialog, Typography, IconButton,DialogContent, DialogActions, Button} from "@material-ui/core";
import { CloseIcon } from "./assets.web";

const useStyles : any = makeStyles({
    paper:{
        marginTop:"100px",
        maxHeight:"580px",
        maxWidth:"900px"
    },
    header:{
        display:"inline-flex",
        paddingTop:"18px",
        marginBottom:"20px",
        "& button":{
            marginLeft:"auto",
            marginRight:"8px"
        },
        "& p":{
            marginLeft:"24px",
            marginTop:"8px"
        }
    },
    content:{
        padding:"8px 24px 0px",
        "& form":{
            marginBottom:"0px !important"
        }
    },
    actions:{
        justifyContent:"center",
        "& button, button:hover, button:active":{
            width:"335px",
            background: "#652d92",
            color:"#fff",
            height: "55px",
            textTransform: 'initial',
            fontSize: "16px",
            fontWeight:"bold",
            fontFamily:"gilroy-bold",
            marginBottom:"24px"
        }
    }
});

interface ICustomPopupProps {
    title: string,
    isOpen: boolean,
    action: string,
    paperClass?: object,
    actionHandler: any,
    handleClose: () => void,
    children: React.ReactNode
}

const CustomPopup = (props:ICustomPopupProps) => {
    const { title, isOpen, action, paperClass, actionHandler, handleClose } = props;
    const classes = useStyles();
    return (
        <Dialog onClose={handleClose} open={isOpen} classes={{paper:`${classes.paper} ${paperClass}`}}>
            <div className={classes.header}>
                <Typography>{title}</Typography>
                <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                </div>
            <DialogContent className={classes.content}>
                {props.children}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button variant="contained" onClick={actionHandler}>{action}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomPopup;

// Customizable Area End