//@ts-nocheck
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
withRouter  
} from 'react-router-dom';
import PrivateRoute from './PrivateRoutes.web';
import PublicRoute from './PublicRoutes.web';
import GlobalStoreLandingPage from "../../blocks/landingpage/src/GlobalStoreLandingPage.web";
import DarkStoreLandingPage from "../../blocks/landingpage/src/DarkStoreLandingPage.web";
import DarkStoreDashboard from "../../blocks/dashboard/src/DarkStoreDashboard.web";
import GlobalStoreDashboard from "../../blocks/dashboard/src/GlobalStoreDashboard.web";
import SignupPage from "../../blocks/email-account-registration/src/SignupPage.web";
import LoginPage from "../../blocks/email-account-login/src/LoginPage.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import CreateNewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Notification from "./Notification.web";
import GlobalStoreInventory from "../../blocks/inventorymanagement2/src/GlobalStoreInventory.web";
import DarkStoreOrders from "../../blocks/ordermanagement/src/DarkStoreOrders.web";
import PurchaseOrders from "../../blocks/ordermanagement/src/PurchaseOrders.web";
import PurchaseOrderAddForm from "../../blocks/ordermanagement/src/PurchaseOrderAddForm.web"
import DeliveryChallan from "../../blocks/ordermanagement/src/DeliveryChallan.web";
import DeliveryChallanAddEdit from "../../blocks/ordermanagement/src/DeliveryChallanAddEdit.web";
import DeliveryChallanOrderStatus from "../../blocks/ordermanagement/src/DeliveryChallanOrderStatus.web"
import InwardsInventory from "../../blocks/vendorordermanagement2/src/InwardsInventory.web";
import DarkStoreManagement from "../../blocks/warehousemanagement2/src/DarkStoreManagement.web";
import InventoryMassUpload from "../../blocks/inventorymanagement2/src/InventoryMassUpload.web";
import InventoryMassUpdate from "../../blocks/inventorymanagement2/src/InventoryMassUpdate.web";
import DarkStoreInventory from "../../blocks/inventorymanagement2/src/DarkstoreInventory/DarkStoreInventory.web";
import SalesForceDashboard from "../../blocks/salesteamdashboard12/src/SalesForceDashboard.web";
import VendorManagementForm from "../../blocks/vendormanagement2/src/VendorManagementForm.web";
import WarehouseDetails from "../../blocks/warehousemanagement2/src/WarehouseDetails.web";
import GlobalStoreInventoryAddEdit from   "../../blocks/inventorymanagement2/src/GlobalStoreInventoryAddEdit.web";
import PurchaseOrderDetailsForm from "../../blocks/ordermanagement/src/PurchaseOrderDetailsForm.web";

import DarkstoreOrderDetails from "../../blocks/ordermanagement/src/DarkstoreOrderDetails.web"
import InwardsInventoryDetails from "../../blocks/vendorordermanagement2/src/InwardsInventoryOrderDetails.web";
import InventoryDetails from "../../blocks/inventorymanagement2/src/DarkstoreInventory/InventoryDetails.web";

const configJSON = require("../../blocks/ordermanagement/src/config.js")

function WebRoutes(stateProps) { 
    const { userType } = stateProps;
    const groups =
    stateProps?.userProfile?.attributes?.groups?.data?.length > 0
        ? stateProps.userProfile.attributes.groups.data[0].attributes.settings
        : {};
        console.log("__MARKER_FOR_BACKEND_URL_REPLACEMENT")
    return (
        <>
        <Notification/>
          <Router>
            <Switch>
              <PublicRoute
                path="/signup"
                exact
                render={props => <SignupPage {...props} {...stateProps} />}
              />
              <PublicRoute
                path="/login"
                exact
                render={props => <LoginPage {...props} {...stateProps} />}
              />
              <PublicRoute
                path="/forget-password"
                exact
                render={props => <ForgotPassword {...props} {...stateProps} />}
              />
              <PublicRoute
                path="/create-new-password"
                exact
                render={props => <CreateNewPassword {...props} {...stateProps}/>}
              />
               <PrivateRoute
                path="/orders/:orderId"
                exact
                render={props => userType === "darkStoreUser" ? <DarkstoreOrderDetails {...props} {...stateProps} />:<GlobalStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/orders"
                exact
                render={props => userType === "darkStoreUser" ? <DarkStoreOrders {...props} {...stateProps} /> : <GlobalStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/sales-force-dashboard"
                render={props => userType === "globalStoreUser" ? <SalesForceDashboard {...props} {...stateProps} /> : <DarkStoreLandingPage {...props} {...stateProps}/>}
              />
               <PrivateRoute
                path="/inventory/mass-upload"
                exact
                render={props => userType === "globalStoreUser" ? <InventoryMassUpload {...props} {...stateProps}/> : <DarkStoreLandingPage {...props} {...stateProps}/>  }
              />
              <PrivateRoute
                path="/inventory/mass-update"
                exact
                render={props => userType === "globalStoreUser" ? <InventoryMassUpdate {...props} {...stateProps}/> : <DarkStoreLandingPage {...props} {...stateProps}/>  }
              />
              <PrivateRoute
                path="/inventory"
                exact
                render={props => userType === "globalStoreUser" ? <GlobalStoreInventory {...props} {...stateProps}/> : <DarkStoreInventory {...props} {...stateProps} />}
              />
               <PrivateRoute
                path="/inventory/:id"
                exact
                render={props => userType === "darkStoreUser" ? <InventoryDetails {...props} {...stateProps} />: <GlobalStoreInventory {...props} {...stateProps}/> }
              />
              
                <PrivateRoute
                path="/add-inventory"
                render={props => userType === "globalStoreUser" ? <GlobalStoreInventoryAddEdit {...props} {...stateProps}/> : <DarkStoreLandingPage {...props} {...stateProps}/> }
              />
               
              <PrivateRoute
                path="/edit-inventory/:id"
                render={props => userType === "globalStoreUser" ? <GlobalStoreInventoryAddEdit {...props} {...stateProps}/> : <DarkStoreLandingPage {...props} {...stateProps}/> }
              />
              <PrivateRoute
                path="/dashboard"
                render={props => userType === "globalStoreUser" ? <GlobalStoreDashboard {...props} {...stateProps} groups={groups} /> : <DarkStoreDashboard {...props} {...stateProps} groups={groups} />}
              />
              <PrivateRoute
                path="/purchase-orders"
                render={props => userType === "globalStoreUser" ? <PurchaseOrders {...props} {...stateProps}/>: <DarkStoreLandingPage {...props} {...stateProps}/>}
              />                         
               <PrivateRoute
                path={configJSON.createPurchaseOrderURL}
                render={props => userType === "globalStoreUser" ? <PurchaseOrderAddForm {...props} {...stateProps}/> : <DarkStoreLandingPage {...props} {...stateProps}/> }
              />
                <PrivateRoute
                path={`${configJSON.editPurchaseOrderURL}:id`}
                render={props => userType === "globalStoreUser" ? <PurchaseOrderAddForm {...props} {...stateProps}/> : <DarkStoreLandingPage {...props} {...stateProps}/> }
              />
               {/*PurchaseOrderDetailsForm */}
               <PublicRoute
                path={`${configJSON.viewPurchaseOrder}:id`}
                render={props => <PurchaseOrderDetailsForm {...props} {...stateProps} />}
              />
              <PrivateRoute
                path="/delivery_challan"
                exact
                render={props => userType === "globalStoreUser" ? <DeliveryChallan {...props} {...stateProps} /> : <DarkStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/delivery_challan/create-challan"
                exact
                render={props => userType === "globalStoreUser" ? <DeliveryChallanAddEdit {...props} {...stateProps} /> : <DarkStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/delivery_challan/order-status/:orderId"
                exact
                render={props => userType === "globalStoreUser" ? <DeliveryChallanOrderStatus {...props} {...stateProps} /> : <DarkStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                exact
                path="/inwards-inventory/:orderId"
                render={props => userType === "darkStoreUser" ? <InwardsInventoryDetails {...props} {...stateProps} />: <GlobalStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                exact
                path="/inwards-inventory"
                render={props => userType === "darkStoreUser" ? <InwardsInventory {...props} {...stateProps} />: <GlobalStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/dark_store_management/add-new-vendor"
                exact
                render={props => userType === "globalStoreUser" ? <VendorManagementForm {...props} {...stateProps} /> : <DarkStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/dark_store_management/:warehouseId"
                render={props => userType === "globalStoreUser" ? <WarehouseDetails {...props} {...stateProps} />: <DarkStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/dark_store_management"
                render={props => userType === "globalStoreUser" ? <DarkStoreManagement {...props} {...stateProps} />:<GlobalStoreLandingPage {...props} {...stateProps}/>}
              />
              <PrivateRoute
                path="/"
                render={props => userType === "globalStoreUser" ? <GlobalStoreLandingPage {...props} {...stateProps}/> : <DarkStoreLandingPage {...props} {...stateProps} />}
              />
            </Switch>
          </Router>
        </>
    )
}

//@ts-ignore
export default withRouter(WebRoutes);