import React from "react";

export const Location = () => {
    return (
        <svg width="12.5075212px" height="14.3301466px" viewBox="0 0 12.5075212 14.3301466" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-2" transform="translate(-802.494739, -772.499997)" stroke="#662D91">
            <g id="Location-Linear-16px" transform="translate(803.000000, 773.000000)">
                <path d="M5.74850395,7.62000144 C6.49161639,7.62000144 7.17828061,7.2235557 7.54983683,6.58000146 C7.92139305,5.93644721 7.92139305,5.14355568 7.54983683,4.50000143 C7.17828061,3.85644718 6.49161639,3.46000144 5.74850395,3.46000144 C4.59975167,3.46000144 3.66850395,4.39124916 3.66850395,5.54000144 C3.66850395,6.68875372 4.59975167,7.62000144 5.74850395,7.62000144 L5.74850395,7.62000144 Z" id="Path"></path>
                <path d="M0.161837287,4.32666811 C1.47517062,-1.44666522 10.028504,-1.43999856 11.3351706,4.33333478 C12.1018373,7.72000144 9.99517062,10.5866681 8.14850395,12.3600014 C6.80734849,13.6535036 4.68299275,13.6535036 3.34183729,12.3600014 C1.50183729,10.5866681 -0.604829379,7.71333478 0.161837287,4.32666811 L0.161837287,4.32666811 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
    )
}