import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { ISupplierListInterface, supplierFields } from '../../blocks/ordermanagement/src/types';
import { StringSchema } from 'yup';

interface IProps {
    value : {label:string, value:string};
    handleListItemSelect:any;
    handleNewSelect : (name:supplierFields,value:string)=> void ;
    supplierOptions : ISupplierListInterface[];
    error : string
}

const CreatableSelectComponent = (props:IProps) =>{
    const  {value, supplierOptions, handleNewSelect, handleListItemSelect, error } = props;
 return <>
  <CreatableSelect
 value={value}
 options={supplierOptions}
 onChange={(e:any)=> {
     if(e.__isNew__){
         handleNewSelect("name",e.value)
        }else {
            handleListItemSelect(e)
        }
        console.log("data",e)
    }}
    />
<div style={{color:"red" , visibility: ((error && !value.label) ? "visible" : "hidden" )}} >{error}</div>
    </>

}

export default CreatableSelectComponent

