const styles: any = {
    formControl: {
        marginBottom: "24px",
        "*": {
            fontFamily: "Plus Jakarta Sans !important"
        },
        "& label": {
            textAlign: "start",
            color: "black",
            fontWeight: "500",
            fontSize: "14px",
        },
        " & input": {
            fontFamily: "Plus Jakarta Sans",
            fontSize: "14px !important"
        },
        "& a, a:hover, a:active": {
            textDecoration: "none",
            marginRight: "0px",
            marginLeft: "auto",
            color: "rgba(0, 0, 0, 0.54)",
            "& h6": {
                paddingBottom: "0px !important",
                fontSize: "14px !important"
            }
        },
        "& p": {
            color: "red"
        }
    },
    container: {
        margin: "24px",
        background: "#fff",
        padding: "24px",
        borderRadius: "10px",
        paddingTop: "15px",
        paddingBottom: "0px"
    },
    textField: {
        "& .MuiOutlinedInput-root": {
            height: "40px",
        },
        '& fieldset': {
            border: "none",
        },
        background: "#F7F7F7",
        width: "410px",
        borderRadius: "4px"
    }
}

export default styles;