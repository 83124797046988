import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { errorMessageDisplay } from "../../../components/src/ErrorMessageDisplay.web"
// Customizable Area End

export const configJSON = require("./config");
interface SS {
  id: any;
}

interface S {
  // Customizable Area Start
  warehouse:any;
  dataList:any;
  open:any;
  anchorEl:any;
  linkNewAccountForm:any;
  isLinkPopup:any;
  email:field;
  remark:field;
  account:any;
  isNewAdded:boolean;
  searchAccount:string;
  filteredList:[];
  isLoading:boolean
  // Customizable Area End
}

// Customizable Area Start
type field = {
  value: string;
  error: string;
  valid?: boolean;
}
// Customizable Area End

export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  match:{ params: { warehouseId:any } };
  setError:any;
  setSuccess:any;
  userProfile:any;
  setUserProfile:any;
  hasAccess:boolean;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}
export default class WarehouseDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDarkstoreApiCallId: any;
  createNewLinkAccountId:any;
  unlinkAccountId:any;
  searchDebounceTimer: NodeJS.Timeout | null = null
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      // Customizable Area Start
      warehouse:null,
      filteredList:[],
      dataList:[],
      open:false,
      anchorEl:null,
      linkNewAccountForm:false,
      isLinkPopup:false,
      remark: {
        value:"",
        error:"",
      },
      email:{
        value:"",
        error:""
      },
      account:null,
      isNewAdded:false,
      searchAccount:"",
      isLoading:true
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson){
        if (responseJson.status === 500 || (!responseJson.message && apiRequestCallId === this.unlinkAccountId)) {
          this.setState({isLoading:false})
          this.props.setError("Internal Server Error");
          return;
        }
          else if(responseJson.errors){
            this.setState({isLoading:false})
            await this.handleFailureCallbacks(apiRequestCallId, responseJson);

          }
          else{
           await this.handleSuccessCallbacks(apiRequestCallId, responseJson);
          }
      }

      if(errorReponse){
        this.props.setError(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
    await this.fetchWarehouseDetails();
    this.setState({isLoading:true})
  }

  fetchWarehouseDetails = async() => {
    const warehouseId = this.props.match.params.warehouseId;
    this.getDarkstoreApiCallId = await this.warehouseManagementApiCall({
      contentType: configJSON.getDarkstoreContentType,
      method: configJSON.getDarkstoreMethodType,
      endPoint: `${configJSON.getDarkstoreEndPoint}/${warehouseId}`,
    });
  }

  handleSuccessCallbacks = async(apiRequestCallId: any,responseJson: any) => {
    switch(apiRequestCallId){

      case this.getDarkstoreApiCallId : {
        this.handleGetWarehouseSuccessCallback(responseJson);

        break;
      }

      case this.createNewLinkAccountId : {
        await this.handleCreateNewLinkAccSuccessCallback(responseJson);
        break;
      }

      case this.unlinkAccountId:{
        await this.handleCreateNewLinkAccSuccessCallback(responseJson);
        break;
      }

      default :{
        break;
      }
    }
  }

  handleFailureCallbacks = async(apiRequestCallId: any,responseJson: any) => {
    switch(apiRequestCallId){

      case this.getDarkstoreApiCallId : {
        this.props.setError(responseJson.message);
        break;
      }

      case this.createNewLinkAccountId : {
        const email = {...this.state.email}
        this.setState({email: {...email,error: errorMessageDisplay(responseJson)}});
        break;
      }

      case this.unlinkAccountId:{
        await this.handleCreateNewLinkAccSuccessCallback(responseJson);
        break;
      }

      default :{
        break;
      }
    }
  }


  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(() => {
     const filteredList = this.state.warehouse.attributes.accounts.data.filter(((account:any)=>account.attributes.email.toLowerCase().includes(this.state.searchAccount.trim().toLowerCase())))
     this.setState({filteredList})
    }, 300);
  }


  searchLinkAccount=(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
    event.preventDefault()
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
      this.setState({searchAccount:event.target.value},this.handleSearchDebounce)
  }

  handleGetWarehouseSuccessCallback = (res: any) => {
    this.setState({warehouse:res.data,filteredList:res.data.attributes.accounts.data,searchAccount:"",isLoading:false});
  }

  handleCreateNewLinkAccSuccessCallback = async(res: any) => {
    this.setState({isNewAdded:true, email:{value:"", error:""}, remark:{value:"", error:""}});
    this.handleClose();
    await this.fetchWarehouseDetails();
  }

  warehouseManagementApiCall = async(data: any) => {
    const { contentType, method, endPoint, body, type} = data;
    let token = window.localStorage.getItem("token");
    let header = {
      token,
    } as any;
    if(contentType){
      header ={
        ...header,
        "Content-Type": contentType,
      }
    }
    const warehouseManagementApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    warehouseManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    warehouseManagementApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    warehouseManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    warehouseManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type === "form-data" ? body: JSON.stringify(body)
    );
    runEngine.sendMessage(warehouseManagementApiMessage.id, warehouseManagementApiMessage);
    return warehouseManagementApiMessage.messageId;
  }

  handleLinkNewVendor = () => {
    this.setState({linkNewAccountForm:true})
  }

  handleLinkAccount = (e:React.MouseEvent<HTMLButtonElement>, account:any) => {
    this.setState({open: !this.state.open, anchorEl:e.currentTarget, account});
  }

  handleClose = () => {
    this.setState({open:false, linkNewAccountForm:false, isLinkPopup: false, account:null, email:{error:"", value:""}, remark:{error:"", value:""}});
  }

  handleUnlinkAccuount = () => {
    this.setState({isLinkPopup: true});
  }

  handleSuccessClose = () => {
    this.setState({isNewAdded:false});
  }

  unlinkHandler = async() => {
    const { account, warehouse } = this.state;
    this.unlinkAccountId = await this.warehouseManagementApiCall({
      contentType: configJSON.unLinkAccountContentType,
      method: configJSON.unLinkAccountMethodType,
      endPoint: `${configJSON.unLinkAccountEndPoint}/${warehouse.id}/unlink_account?account_id=${account.id}`,
    });
    this.handleClose();
  }

  emailFieldHandler = (e:any) => {
    this.setState({email: {
      ...this.state.email,
      value: e.target.value,
      error: ""
    }})
  }

  remarkFieldHandler = (e:any) => {
    this.setState({remark: {
      ...this.state.remark,
      value: e.target.value,
      error: ""
    }})
  }

  linkNewAccountSubmitHandler = async () => {
    let { email, remark, warehouse } = this.state;
    let validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
    if(email.value.trim() === "" || !(validEmailRegex.test(email.value.trim()))){
      email.error = configJSON.invalidEmailErrorText;
    }

    if(remark.value.trim() === ""){
      remark.error = configJSON.emptyRemarkErrorText;
    }
    this.setState({email:email, remark: remark});
    if(!!!email.error && !!!remark.error){
      let formData = new FormData();
      formData.append("email",email.value);
      this.createNewLinkAccountId = await this.warehouseManagementApiCall({
        contentType: configJSON.createNewLinkAccountContentType,
        method: configJSON.createNewLinkAccountMethodType,
        endPoint: `${configJSON.createNewLinkAccountEndPoint}/${warehouse.id}/link_account`,
        body:formData,
        type:"form-data"
      });
    }

  }
  // Customizable Area
}