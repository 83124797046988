import { createTheme } from "@material-ui/core";

export default  createTheme({
    overrides:{
        MuiTextField:{
            root:{
                fontFamily:"gilroy-medium"
            },
        },
        MuiFormLabel:{
            root:{
                fontFamily:"gilroy-medium"
            },
            asterisk:{
                color:"red"
            }
        },
        MuiInputBase:{
            root:{
                fontFamily:"gilroy-regular"
            }
        },
        MuiTypography:{
            body1:{
                fontFamily:"gilroy-medium"
            }
        },
        MuiOutlinedInput:{
            root:{
                borderColor:"#E7E7E7 !important",
                // height:"40px",
                fontFamily:"gilroy-medium !important"
            },
            notchedOutline:{
                borderColor:"#E7E7E7 !important"
            },
            input:{
                //padding:"10.5px 14px"
            }
        },
        MuiTable:{
            root:{
               // border:"1px solid rgba(224, 224, 224, 1)",
            //    maxWidth:"1038px",
               border:"none",
               "& tbody tr":{
                borderLeft:"1px solid rgba(224, 224, 224, 1)",
                borderRight:"1px solid rgba(224, 224, 224, 1)"
               }
            }
        },
        MuiTableHead:{
            root:{
                background:"#f7f7f7"
            }
        },
        MuiTableCell:{
            head:{
                color:"#83889e"
            }
        },
        MuiButton:{
            root:{
                textTransform:"initial",
                fontFamily:"gilroy-regular" 
            }
        },
        MuiListItemText:{
            root:{
                "& span":{
                    fontSize:"14px",
                    fontFamily:"gilroy-medium",
                    fontWeight:"bold"
                  }
            }
        },
        MuiListItemIcon:{
            root:{
                "& img":{
                    height:"24px"
                },
            }
        },
        MuiMenuItem:{
            root:{
                fontSize:"14px",
                fontFamily:"gilroy-medium",
                fontWeight:"bold",
                // justifyContent: "center"
            }
        },
        MuiCheckbox:{
            disabled:{
                color:"#CACACA !important"
            }
        }
    }
})