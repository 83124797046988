Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "vendorordermanagement2";
exports.labelBodyText = "vendorordermanagement2 Body";
exports.getDeliveryChallanListEndPoint = "/bx_block_order_management/delivery_challans";
exports.getDeliveryChallanOrderDetailsEndPoint = "/bx_block_order_management/delivery_challans";
exports.getLinkedDarkstoreEndPoint = "/account_block/dark_stores/get_linked_store";
exports.btnExampleTitle = "CLICK ME";
exports.putDeliveryChallanOrderDetails = "PUT";
exports.rowsPerPage = 5;

exports.inwardsInventoryRows = [
  {key:"sNo", label:"S No"},
  {key:"orderID", label:"Order ID"},
  {key:"warehouseName", label:"Warehouse Name"},
  {key:"estimatedDate", label:"Estimated Date"},
  {key:"status", label:"Status"}
];
// Customizable Area End