import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
import SignupPageController,{ Props, userType } from "./SignupPageController.web";
import { Grid, Button, Typography, TextField, Tab, Tabs, IconButton, FormControl, FormLabel, withStyles, Tooltip } from "@material-ui/core";
import { eyeOpenLogo, eyeCloseLogo, resetPasswordLogo } from "./assets";
import CommonAuthModal from "../../../components/src/CommonAuthModal";
// Customizable Area Start

// Customizable Area Start
const styles : any = {
    formControl:{
        marginBottom:"24px",
        " & input":{
            fontSize:"14px !important",
            fontFamily:"Plus Jakarta Sans"
        },
        "& label":{
            fontSize:"14px",
            fontWeight:"500",
            fontFamily:"Plus Jakarta Sans !important",
            textAlign:"start",
            color: "black"
        },
        "& a, a:hover, a:active":{
            textDecoration: "none",
            marginRight:"0px",
            marginLeft: "auto",
            color:"rgba(0, 0, 0, 0.54)",
            "& h6":{
                paddingBottom:"0px !important"
            }
        },
        "& p":{
            color:"red"
        },
        "& button.passwordEyeIcon:hover, button.passwordEyeIcon:active, button.passwordEyeIcon":{
            background:"#fff !important",
            "& img":{
                width:"28px"
            }
        }
    },
    
    tabSelected:{
        background:'#e8bdea !important'
    },
    tabs:{
        "& .MuiTabs-flexContainer":{
            width:"350px"
        },
        marginBottom:"24px",
    },
    tabRoot:{
        fontSize:"16px",
        background:"#f5f5f5",
        textTransform: "initial",
        borderRadius:"4px",
        fontFamily:"Plus Jakarta Sans",
    },
    submitBtnContainer:{
        "& button, button:hover, button:active":{
            fontWeight:"bold",
            textTransform: 'initial',
            height: "55px",
            background: "#652d92",
            fontSize: "16px",
            fontFamily:"Plus Jakarta Sans",
        }
    },
    resetPasswordScreen:{
        padding:"24px 18px", 
        display:"flex",
        flexFlow:"column",
        alignItems:"center",
        "& h6":{
            textAlign: "center",
            marginTop: "24px",
            fontSize: "16px !important",
        },
        "& img" :{
            width:"200px"
        },
        "& button, button:hover, button:active":{
            height: "55px",
            fontWeight:"bold",
            fontSize: "16px",
            background: "#652d92",
            fontFamily:"Plus Jakarta Sans",
            textTransform: 'initial',
        }
        
    },
    emailPostfix:{
        alignItems:"center",
        
        height:"90px",
        "& h6":{
            fontSize:"14px !important",
            marginLeft: "16px",
            padding:"0px !important",
            fontFamily:"Plus Jakarta Sans",
           
        },
        display:"flex"
        
    },
    passwordEyeIcon:{
        background:"#fff",
        "& img":{
            width:"28px"
        }
    },
    redirectLink:{
        "& span":{
            fontWeight: "bold"
        },
        "& a, a:hover":{
            textDecoration:"none !important",
            display:"flex",
            cursor:"pointer",
            width:"fit-content",
            color: "black",
            margin:"auto",
        },
        "& h6":{
            fontSize:"14px !important"
        }
    },
    validatorList:{
        padding:"0px 8px"
    },
    invalid:{
        color:"red"
    },
    arrow:{
        "&::before": {
            border:"1px solid #ccc",
            backgroundColor: "#fff",
          },
    },
    tooltip:{
        border:"1px solid #ccc",
        backgroundColor:"#fff",
        color:"black"
    },
    valid:{
        color:"green"
    },
   
}
// Customizable Area End


class SignupPage extends SignupPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderAccountCreated = (classes:any) => {
        return (
                <CommonAuthModal haveBackButton={false} title="Account Created" description="" className={classes.resetPasswordScreen}>
                    <img src={resetPasswordLogo}/>
                    <Typography variant="h6">Your account has been created successfully. Please verify your account by clicking on activation link sent on email and proceed for login. </Typography>
                    <Button data-test-id="nextBtn" variant="contained" color="primary" onClick={this.handleNavigateToLoginScreen}>Login</Button>
                </CommonAuthModal>
        )
    }
    // Customizable Area End

    // Customizable Area Start 
    renderGlobalStoreSignupFormEmailField = (classes:any) => {
        const { email } = this.state;
        return (
            <Grid container>
                <Grid item md={8}>
                    <TextField 
                        value={email.value}
                        data-test-id="txtInputEmail"
                        placeholder="Enter email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={this.emailFieldHandler}
                        helperText={email.error || "" }
                    />
                </Grid>
                <Grid item md={4} className={classes.emailPostfix}>
                    <Typography variant="h6">@docilekart.com</Typography>
                </Grid>
            </Grid>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    
    renderDarkStoreSignupFormEmailField = (classes:any) => {
        const { email } = this.state;
        return (
            <TextField
                value={email.value} 
                data-test-id="txtInputEmail"
                placeholder="Enter email"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.emailFieldHandler}
                helperText={email.error || "" }
        />
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderSignupForm = (classes: any) => {
        const { password, passwordVisibility, passwordValidations } = this.state;
        return (
            <CommonAuthModal 
            backHandler={this.backHandler}
            title="Create Account" 
            description="Let's create your account" haveBackButton={true}>
                <Tabs 
                    variant="fullWidth" 
                    onChange={this.handleChangeSignupType}
                    value={this.state.signupType} 
                    TabIndicatorProps={{style:{display:"none"}}} 
                    className={classes.tabs}
                >
                    <Tab classes={{selected: classes.tabSelected, root: classes.tabRoot}}  value={userType.globalStoreUser} label="Docile Hub" />
                    <Tab classes={{selected: classes.tabSelected, root: classes.tabRoot}} value={userType.darkStoreUser} label="Dark Stores" />
                </Tabs>
               <> 
               <form>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel>Email</FormLabel>
                            {this.state.signupType === userType.globalStoreUser ? 
                                this.renderGlobalStoreSignupFormEmailField(classes) :
                                this.renderDarkStoreSignupFormEmailField(classes)}
                        </FormControl>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel>Password</FormLabel>
                            <Tooltip 
                            classes={{
                                arrow: classes.arrow,
                                tooltip: classes.tooltip,
                            }}
                            placement="top-end" 
                            arrow
                            title={
                                <ul className={classes.validatorList}>
                                    {passwordValidations.map((validator:any, index:any) => {
                                        return <li key={index} className={validator.valid ? classes.valid : classes.invalid}>{validator.description}</li>
                                    })}
                                </ul>
                            } 
                            >
                            <TextField 
                                type={passwordVisibility ? "text": "password"}
                                value={password.value}
                                data-test-id="txtInputPassword"
                                placeholder="Enter password"
                                margin="normal"
                                variant="outlined"
                                onChange={this.passwordFieldHandler}
                                InputProps={{
                                    endAdornment: <IconButton data-test-id="eyeIconBtn" onClick={this.handlePasswordVisibility} className="passwordEyeIcon"><img src={passwordVisibility ? eyeOpenLogo: eyeCloseLogo} /></IconButton>
                                }}
                                helperText={password.error || "" }
                            />
                            </Tooltip>
                           
                        </FormControl>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.submitBtnContainer}>
                        <FormControl fullWidth>
                            <Button data-test-id="signupSubmitBtn" variant="contained" color="primary" onClick={this.signupHandler}>Signup</Button>
                        </FormControl>
                    </Grid>
                </Grid>
                    
                </form>
                <div className={classes.redirectLink}>
                    <Link to="/login"><Typography variant="h6">Have an account? <span>Sign In</span></Typography></Link>                              
                </div>
                </>
            </CommonAuthModal>
        ) 
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { step } = this.state;
        switch(step){
            case 0 :{
                return this.renderSignupForm(classes);
            }
            case 1 :{
                return this.renderAccountCreated(classes)
            }

            default:{
                return this.renderSignupForm(classes)
            }
        }
    }
}
// Customizable Area Start
export { SignupPage };
export default withStyles(styles)(withRouter(SignupPage));
// Customizable Area End