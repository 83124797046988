import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { handlePaginationWithFilter } from "../../../components/src/CommonFunctions";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Item, StockStatus } from "./types";
// Customizable Area End
export const configJSON = require("./config");

interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  darkStoreData: { value: string; label: string }[];
  stockStatus:StockStatus;
  openStatusMenu: null | HTMLElement;
  totalCount: number;
  isAllDataSelected: boolean;
  openoptions: boolean;
  searchQuery: string;
  currentPage: number;
  currentPageRecords: Item[];
  totalAvailableRecords: Item[];
  rowsCount: number;
  startIndex: number;
  endIndex: number;
  checkAPI: boolean;
  selectedItem: Record<string | number, Item>;
  selectedDarkStore: { label: string; value: string };
  totalRecords: number;
  stockData: {
    allProducts: number;
    lowStock: number;
    outOfStock: number;
  };
  editingRowId: null | string
  editStockQuantity : number
  // Customizable Area End
}
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  userType: any;
  userProfile: any;
  setError: any;
  setUserProfile: any;
  newNotification: boolean
  setNewNotification: React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification: React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification: number
  // Customizable Area End
};
export default class SalesForceDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSalesForceApiCallId: string = "";
  getAllSalesForceApiCallId: string = "";
  getDarkStoreApiCallId: string = "";
  searchDebounceTimer: NodeJS.Timeout | null = null
  updateDarkStoreStockQuantity:string=""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openStatusMenu: null,
      stockStatus:"",
      darkStoreData: [],
      isAllDataSelected: false,
      totalCount: 0,
      openoptions: false,
      searchQuery: "",
      selectedDarkStore: { label: "Select Dark Store", value: "" },
      currentPage: 1,
      currentPageRecords: [],
      totalAvailableRecords: [],
      rowsCount: 0,
      startIndex: 0,
      endIndex: 0,
      selectedItem: {},
      checkAPI: false,
      totalRecords: 0,
      stockData: {
        allProducts: 0,
        lowStock: 0,
        outOfStock: 0,
      },
      editingRowId:null,
      editStockQuantity: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSalesForceApiCallId) {
        this.setState(
          {
            currentPageRecords: responseJson?.data ? responseJson.data : [],
            rowsCount: Math.ceil(
              responseJson?.meta?.total_count / configJSON.rowsPerPage
            ),
            totalRecords: responseJson?.meta?.total_count,
            stockData: {
              allProducts: responseJson?.meta?.total_catalogues_count,
              lowStock: responseJson?.meta?.low_stock,
              outOfStock: responseJson?.meta?.out_of_stock,
            },
          },
          () => {
            this.isAllDataSelected();
            this.handleSalesForceDashboardPagination(
              {},
              this.state.currentPage
            );
          }
        );
      }

      if (apiRequestCallId === this.getAllSalesForceApiCallId) {
        this.setState(
          {
            totalAvailableRecords: responseJson?.data ? responseJson.data : [],
            rowsCount: Math.ceil(
              responseJson?.meta?.total_count / configJSON.rowsPerPage
            ),
            totalRecords: responseJson?.meta?.total_count,
            stockData: {
              allProducts: responseJson?.meta?.total_catalogues_count,
              lowStock: responseJson?.meta?.low_stock,
              outOfStock: responseJson?.meta?.out_of_stock,
            },
          },
          () => {
            this.isAllDataSelected();
            this.handleSalesForceDashboardPagination(
              {},
              this.state.currentPage
            );
            const currentPageDataIds = [...this.state.totalAvailableRecords];
            let selectedItems = { ...this.state.selectedItem };
              currentPageDataIds.forEach((element: Item) => {
                selectedItems = {
                  ...selectedItems,
                  [element.id]: element,
                };
              });
            this.setState({ selectedItem: { ...selectedItems } });
          }
        );
      }

      if (apiRequestCallId === this.getDarkStoreApiCallId) {
        this.setState({
          darkStoreData: responseJson?.data?.map((ele: any) => ({
            value: ele.id,
            label: ele.attributes.legal_name,
          })),
        });
      }

      if(apiRequestCallId === this.updateDarkStoreStockQuantity){
        this.getSalesForceData(this.state.selectedDarkStore.value)
        this.setState({editingRowId:null,editStockQuantity:0})
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getDarkStoreData();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.getSalesForceData(this.state.selectedDarkStore.value);
    }
  }

  handleSalesForceDashboardPagination = (e: any, page: number) => {
    const { currentPageRecords, totalRecords } = this.state;
    const { startIndex, endIndex, currentDataList, currentPage } =
      handlePaginationWithFilter(
        {},
        page,
        currentPageRecords,
        configJSON.rowsPerPage,
        totalRecords
      );
    this.setState({
      startIndex,
      endIndex,
      currentPageRecords: currentDataList,
      currentPage,
    });
  };

  handleSalesForceDashboardPrevNext = (type: any) => {
    const { currentPage, rowsCount} = this.state;    
    if (type === "previous" && currentPage > 0) {
      this.handleSalesForceDashboardPagination({}, currentPage - 1);
    } else if (type === "next" && currentPage <= rowsCount ) {
      this.handleSalesForceDashboardPagination({}, currentPage + 1);
    }
  };


  onEditStockQuqntity = (event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
    this.setState({editStockQuantity:Math.abs(Number(event.target.value))})
  }

  handleClickEdit = (id:string, quantity : number)=>{
    this.setState({editingRowId:id,editStockQuantity:quantity})
  }

  handleCloseEditingRow = ()=>{
    this.setState({editingRowId:null,editStockQuantity:0})
  }

  handleUpdate =() =>{
    let formdata = new FormData()
    formdata.append('stock_qty', String(this.state.editStockQuantity));
    const header = {
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateDarkStoreStockQuantity = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.updateStockQuantityEndPoint}/${this.state.editingRowId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchApiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }
  
  isAllDataSelected = () => {
    let selected = true;
    const currentData = this.state.currentPageRecords;

    for (let element of currentData) {
      if (!this.state.selectedItem[element.id]) {
        selected = false;
        break;
      }
    }
    this.setState({ isAllDataSelected: selected });
  };

  handleRedirectToCreateChallanOrder = () => {
    const selectedItemsId = Object.keys(this.state.selectedItem);
    const data = selectedItemsId.map((id) => this.state.selectedItem[id]);
    const finalData = this.prepDeliveryData(data);
    this.props.history.push({
      pathname: configJSON.createOutboundOrder,
      state: { data: finalData, from: "salesDashboard" },
    });
  };

  handleSelectDarkStore = (event: { label: string; value: string }) => {
    this.setState(
      {
        selectedDarkStore: event,
        selectedItem: {},
        isAllDataSelected: false,
        currentPage: 1,
        stockStatus:"",
        totalRecords : 0,
        searchQuery:""
      },
      () => {   
        this.getSalesForceData(event.value);
      }
    );
  };

  handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLInputElement>) => {
    this.setState({
      openStatusMenu: event.currentTarget,
    });
  };
  handleCloseMenu = () => {
    this.setState({
      openStatusMenu: null,
    });
  };

  handleSelectAllinCurrentPage = () => {
    const currentPageDataIds = [...this.state.currentPageRecords];
    let selectedItems = { ...this.state.selectedItem };
    currentPageDataIds.forEach((element: Item) => {
      selectedItems = {
        ...selectedItems,
        [element.id]: element,
      };
    });
    this.setState(
      {
        openStatusMenu: null,
        selectedItem: { ...selectedItems },
      },
      this.isAllDataSelected
    );
  };

  handleSelectAllinAllPage = () => {
    this.setState({ checkAPI: true });
    this.getAllSalesForceData(this.state.selectedDarkStore.value);
    const currentPageDataIds = [...this.state.currentPageRecords];
    let selectedItems = { ...this.state.selectedItem };
    currentPageDataIds.forEach((element: Item) => {
      selectedItems = {
        ...selectedItems,
        [element.id]: element,
      };
    });

    this.setState(
      {
        openStatusMenu: null,
        isAllDataSelected: !this.state.isAllDataSelected,
        selectedItem: { ...selectedItems },
      },
      this.isAllDataSelected
    );
  };

  handleUnSelectAllRecords = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!Object.keys(this.state.selectedItem).length) {
      this.handleOpenMenu(event)
    } else {
      this.setState({
        selectedItem: {},
      });
    }
  }

  handleSelectRecords = (data: Item) => {
    if (this.state.selectedItem[data?.id]) {
      const newData = this.state.selectedItem;
      delete newData[data.id];
      this.setState({ selectedItem: newData }, this.isAllDataSelected);
    } else {
      this.setState(
        { selectedItem: { ...this.state.selectedItem, [data.id]: data } },
        this.isAllDataSelected
      );
    }
  };

  getSalesForceData = (darkStoreID: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSalesForceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.salesForceEndPoints}?page=${this.state.currentPage}&per_page=${configJSON.rowsPerPage}&dark_store_id=${darkStoreID}&status=${this.state.stockStatus}&search_query=${this.state.searchQuery}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSalesForceData = (darkStoreID: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSalesForceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.salesForceEndPoints}?page=1&per_page=${this.state.totalRecords}&dark_store_id=${darkStoreID}&status=${this.state.stockStatus}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

handleStockStatus=(stockStatus:StockStatus)=>{
  this.setState({
    stockStatus:stockStatus,
    currentPage:1,
    selectedItem:{},
    totalRecords : 0,
    searchQuery:""
  },()=>{
    if(this.state.selectedDarkStore.value){
      this.getSalesForceData(this.state.selectedDarkStore.value)
    }
  })
}

  getDarkStoreData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDarkStoreApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listDarkstoreOrdersEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  prepDeliveryData = (deliverydata: Item[]) => {
    return deliverydata.map((ele: Item) => {
      return {
        catalogue_id: ele.attributes?.parent_id,
        item_code: ele.attributes?.item_code,
        catalogue_name: ele.attributes.name,
        displayLocation: ele.attributes?.display_location,
        hsn_code: ele.attributes?.hsn_code,
        price: ele.attributes?.price,
        quantity: ele.attributes?.stock_qty,
        weight: ele.attributes?.weight,
        weight_unit: ele.attributes?.weight_unit,
        darkStore : this.state.selectedDarkStore,
        max_quantity:ele.attributes.global_quantity,
      };
    });
  };

  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(() => {     
     this.setState({currentPage:1},()=>{
      if(this.state.selectedDarkStore.value){
        this.getSalesForceData(this.state.selectedDarkStore.value)
      }
     })
    }, 300);
  }

  handleSearchItem = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
   
      this.setState({searchQuery:event.target.value},this.handleSearchDebounce)
  }
 
  // Customizable Area End
}
