import React, { useEffect } from 'react'
import { Box, Grid, IconButton, Modal, Paper, ThemeProvider, Typography, createTheme, withStyles } from "@material-ui/core";
import QrReader from "react-qr-reader";
import CloseIcon from '@material-ui/icons/Close';

interface Props{
    open :boolean
    handleClose:()=>void
    classes:any
    handleScan:(data:string|null)=>void
    invalidQR:boolean
    setError:any
}

const theme = createTheme({
    palette: {
        secondary: {
            light: '#662D91',
            main: '#662D91',
        } 
    },
    typography: {
      fontFamily: "Plus Jakarta Sans",
      fontWeightLight: "normal",
      fontWeightRegular: "normal",
      h4:{
        fontSize:"20px"
      },
      h5:{
        fontSize:"18px",
        fontWeight:500,
        fontFamily: "gilroy-bold !important"
      },
      h6:{
        fontSize:"16px",
        fontWeight:500,
        fontFamily: "gilroy-bold !important"
      },
      subtitle1:{
        fontSize:"14px",
        fontWeight:500,
        fontFamily: "gilroy-medium"
      },
      subtitle2:{
        fontSize:"12px",
        fontWeight:500,
        fontFamily: "gilroy-regular"
      }
    },
  });
  

const styles: any = {
    qrContainer:{
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)",
        position:"absolute",
        padding:"20px",
        width:"500px"
    },
    qrScanner:{
        "& div":{
            border:"0 !important",
        },
    },
    gilroyBold:{
        fontWeight:500,
        fontFamily: "gilroy-bold !important"
     },
     gilroyMedium:{
        fontWeight:500,
        fontFamily: "gilroy-medium"
     },
     gilroyRegular:{
        fontWeight:500,
        fontFamily: "gilroy-regular"
     },


}

function QRSacnner(props:Props){
    const {handleClose,open,classes,handleScan,setError} = props

    const handleError =(err:string)=>{
       setError(err)
    }
    
    return(
        <ThemeProvider theme={theme}>
        <Modal open={open} onClose={handleClose}>
            <Grid container component={Paper} className={classes.qrContainer} spacing={2}>
                <Grid item xs={12}>
                    <Box style={{display:"flex", justifyContent:"space-between"}}>
                        <Typography variant='h5' style={{lineHeight:"2.5"}}>QR Scanner</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs ={12} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <QrReader delay={1000}
                    onError={handleError}
                    onScan={handleScan} 
                    className={classes.qrScanner}
                    style={{heigth:250,width:250}}/>
                    {props.invalidQR && <Typography variant='subtitle1' style={{color:'red'}}>Invalid QR</Typography>}
                </Grid>
            </Grid>
        </Modal>
        </ThemeProvider>
    )

}


export default withStyles(styles)(QRSacnner)