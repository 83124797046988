import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { ICreatePurchaseOrderPayload, IHeaderInterface, IPurchaseOrderAddFromList, ISupplierListDataType, ISupplierListInterface, SupplierDetailsInterface, supplierFields, ICatalogueItemInterface, IPurchaseOrderCatalogue, SupplierErrorInterface } from "./types";
// Customizable Area End

export const configJSON = require("./config");
export const rows = configJSON.purchaseOrdersRows;
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  createPurchaseOrderData: IPurchaseOrderAddFromList;
  shipmentAddress: string;
  expectedDeliveryDate: Date;
  showDeletePopup: boolean;
  showSuccessPopup: boolean;
  errors: {
    shipmentAddressError: string,
    deliveryDateError: string
  },
  catalogueItemListOpen: boolean,
  catalogueItemList: ICatalogueItemInterface[],
  selectedRowList: number[],
  search: string,
  showSupplierDetails: boolean;
  supplierDetails: SupplierDetailsInterface,
  supplierErrors: SupplierErrorInterface,
  isSupplierPresent: boolean,
  supplierEditData: any,
  editMode: boolean,
  editingPurchaseOrderID: string,
  editingSupplierID: string,
  headerData: IHeaderInterface,
  suppliersListData: ISupplierListInterface[],
  deletedCatalogueItems : ICatalogueItemInterface[],
  purchasePaymentTerms : string,
  isDisabled:boolean

  // Customizable Area End
}
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  userType: any;
  userProfile: any;
  setError: any;
  setUserProfile: any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}

export default class PurchaseOrderAddFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  getItemsApiCallId: string = "";
  confirmPurchaseOrderAPI: string = "";
  getPurchasedOrderDataAPI: string = "";
  createSupplierAPIID: string = "";
  listSupplierAPIID: string = "";

  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      createPurchaseOrderData: configJSON.catalogueListSelectedItems,
      shipmentAddress: "",
      expectedDeliveryDate: new Date(),
      showDeletePopup: false,
      showSuccessPopup: false,
      errors: {
        shipmentAddressError: "",
        deliveryDateError: ""
      },
      catalogueItemListOpen: false,
      catalogueItemList: [],
      selectedRowList: [],
      search: "",
      showSupplierDetails: false,
      supplierDetails: configJSON.supplierObject,
      supplierErrors: configJSON.supplierError,
      isSupplierPresent: false,
      supplierEditData: configJSON.supplierObject,
      editMode: false,
      editingPurchaseOrderID: "",
      editingSupplierID: "",
      headerData: {
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("token"),
      },
      suppliersListData: [],
      deletedCatalogueItems: [],
      purchasePaymentTerms : "",
      isDisabled:false
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)

      );
      if (!responseJson.data && (apiRequestCallId === this.confirmPurchaseOrderAPI || apiRequestCallId === this.createSupplierAPIID)) {
        this.handleErrorResponse();
      }
      else {
      if (apiRequestCallId === this.getItemsApiCallId) {
        this.setState({
          catalogueItemListOpen: true
        })
      }
      else if (apiRequestCallId === this.confirmPurchaseOrderAPI) {
        this.handleSuccessOpen();
        this.setState({
          editingPurchaseOrderID : responseJson.data?.id,
          isDisabled:false
        })
      }
      else if (apiRequestCallId === this.getPurchasedOrderDataAPI) {
        this.setState({
          createPurchaseOrderData: responseJson.data,
          catalogueItemList: responseJson.data?.attributes?.catalogues,
          shipmentAddress: responseJson.data.attributes?.shipment_address,
          expectedDeliveryDate: new Date(responseJson.data.attributes?.delivery_date),
          selectedRowList: responseJson.data?.attributes?.catalogues.map((ele: IPurchaseOrderCatalogue) => ele.catalogue_id),
          isSupplierPresent: responseJson.data.attributes.supplier ? true : false,
          supplierDetails: {
            id: responseJson.data.attributes.supplier?.id,
            ...responseJson.data.attributes.supplier?.attributes
          },
          purchasePaymentTerms : responseJson.data?.attributes?.payment_terms
        })
      }
      else if (apiRequestCallId === this.createSupplierAPIID) {
        const { id, attributes } = responseJson.data

        this.setState({
          isSupplierPresent: true,
          supplierDetails: {
            id, ...attributes
          }
        })
        this.handleSupplierPopupClose()
      }
      else if (apiRequestCallId === this.listSupplierAPIID) {
        this.setState({
          suppliersListData: responseJson.data.map((ele: ISupplierListDataType) => ({
            label: ele.attributes.name,
            value: ele.attributes.name,
            id: ele.id,
            ...ele.attributes
          }))
        })
      }
    }
       }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const url = window.location.href;
    const parts = url.split("/");
    const id: string = parts[parts.length - 1];
    if (url.includes(configJSON.editPurchaseOrderURL)) {
      this.setState({ editMode: true, editingPurchaseOrderID: id }, () => {
        this.handleGetPurchasedOrderData(id)
      })
    }
  }

  handleChangeAddress = (value: string) => {
    this.setState({
      shipmentAddress: value
    })
  }

  handleChangeDate = (value: Date) => {
    this.setState({
      expectedDeliveryDate: value
    })
  }

  handleFieldEditChange = (name: string, id: number, value: number | boolean) => {
    const data = [...this.state.catalogueItemList];
    this.setState({
      catalogueItemList: data.map((ele) => ele.catalogue_id === id ? ({ ...ele, [name]: value }) : ({ ...ele }))
    },()=>{
      //@ts-ignore
    const filterData = this.state.catalogueItemList.filter(item=> {if(item.quantity<1){
      return item
    }})    
     this.setState({ isDisabled: filterData.length? true :false })
    }) 
  }

  handleDelete = () => {
    const data = [...this.state.catalogueItemList];
    const filteredData = data.filter((ele) => !ele.checked);
    const deletedRecords= data.filter((ele)=> ele.checked );
    this.setState({
      catalogueItemList: filteredData,
      deletedCatalogueItems : this.state.editMode ? [ ...this.state.deletedCatalogueItems, ...deletedRecords] : [],
      selectedRowList: filteredData.map((ele: any) => ele.catalogue_id),
    }, () => {
      this.handleDeletePopupClose()
    })
  }

  handleDeletePopupOpen = () => {
    this.setState({
      showDeletePopup: true
    })
  }

  handleDeletePopupClose = () => {
    this.setState({
      showDeletePopup: false
    })
  }

  handleResetForm = () => {
    this.setState({
      // createPurchaseOrderData: "",
      shipmentAddress: "",
      expectedDeliveryDate: new Date(),
      showDeletePopup: false
    })
  }

  handleCancelOrder = () => {
    this.handleResetForm();
    this.handleRedirectToPurchaseOrder()
  }

  handleConfirmOrder = () => {
    const errors = this.handleValidateFields();
    const { shipmentAddressError, deliveryDateError } = errors
    if (!shipmentAddressError && !deliveryDateError) {
      this.handleCreateEditPurchaseOrder()
    }
  }

  handleRedirectToPurchaseOrder = () => {
    this.props.history.push(configJSON.purchaseOrderPage);
  }

  handleRedirectToViewPurchaseOrder = (purchaseID:string) =>{
    this.props.history.push(`${configJSON.viewPurchaseOrder}${purchaseID}`);
  }

  handleErrorResponse() {
    this.props.setError("Something went wrong");
    this.setState({ isDisabled: false });
  }

  handleSuccessOpen = () => {
    this.setState({
      showSuccessPopup: true
    })
  }

  handleSuccessClose = () => {
    this.setState({
      showSuccessPopup: false
    }, () => {
      this.handleRedirectToViewPurchaseOrder(this.state.editingPurchaseOrderID)
    })
  }

  handleValidateFields = () => {
    const errors = {
      shipmentAddressError: "",
      deliveryDateError: ""
    }
    const { shipmentAddress, expectedDeliveryDate } = this.state;
    if (!shipmentAddress) {
      errors.shipmentAddressError = "Shipment address is required"
    }
    if (!expectedDeliveryDate) {
      errors.deliveryDateError = "Expected Delivery Data is required"
    }
    this.setState({
      errors
    });
    return errors
  }

  hadleCatalogueListOpen = () => {
    this.setState({
      catalogueItemListOpen: true
    })
  }

  handleCatalogueListClose = () => {
    this.setState({
      catalogueItemListOpen: false
    })
  }

  handleSaveCatalogueItem = (selectedIds: number[], selectedItems: ICatalogueItemInterface[]) => {
    const currentCatalogueData = [...this.state.catalogueItemList];

    const filterPreviousSelected = currentCatalogueData.filter(item => selectedIds.includes((item.catalogue_id)));
    const filterDeletedCatalogueItem = currentCatalogueData.filter(item => !selectedIds.includes((item.catalogue_id)));
    const filterNewSelected = selectedItems.filter(item => !this.state.selectedRowList.includes(item.catalogue_id));
    const newSelectedData = filterNewSelected.map((ele) => ({
      ...ele,
      catalogue_price: ele.price,
      unit_purchase_price: 0,
      checked: false
    }))

    const filterData = filterNewSelected.map((item)=>item.quantity==0)

    this.setState({
      catalogueItemList: [...filterPreviousSelected, ...newSelectedData],
      deletedCatalogueItems : [...this.state.deletedCatalogueItems, ...filterDeletedCatalogueItem],
      selectedRowList: selectedIds,
      isDisabled:filterData.length?true:false
    }, () => {
      this.handleCatalogueListClose()

    })

  }

  handleSupplierPopupOpen = () => {
    this.handleListSupplier()
    this.setState({
      showSupplierDetails: true,
      supplierEditData:{ ...this.state.supplierDetails, payment_terms : this.state.purchasePaymentTerms}
    })
  }

  handleSupplierPopupClose = () => {
    this.setState({
      showSupplierDetails: false,
      supplierErrors: configJSON.supplierError
    })
  }

  handleSupplierDetailsChange = (fieldName: supplierFields, value: string) => {
    const supplierData = { ...this.state.supplierEditData };
    supplierData[fieldName] = value;
    this.setState({
      supplierEditData: supplierData
    })
  }

  handleSupplierListItemSelect = (selectedData: ISupplierListInterface) => {
    this.setState({
      supplierEditData: {
        id: selectedData.id,
        name: selectedData?.name,
        code: (selectedData.code)?.toString(),
        address: selectedData?.address,
        email: selectedData?.email,
        shipment_address: "",
        payment_terms: ""
      }
    })
  }

  validateSupplierDetails = () => {
    const errors = {
      name: "",
      code: "",
      address: "",
      email: "",
      payment_terms : ""
    }

    const { name, code, email, address, payment_terms } = this.state.supplierEditData
    if (!name) {
      errors.name = 'Name is required';
    }
    if (!code) {
      errors.code = 'Code is required';
    }
    if (!address) {
      errors.address = 'Address is required';
    }
    if (!email) {
      errors.email = 'Email is required';
    }
    if (!payment_terms) {
      errors.payment_terms = 'Payment Terms is required';
    }
    this.setState({
      supplierErrors: errors
    })
    return errors;
  };

  handleConfirmSupplier = () => {
    const errors = this.validateSupplierDetails();
    const { name, code, email, address,payment_terms} = errors
    if (!name && !code && !email && !address && !payment_terms ) {
      this.handleCreateEditSupplier();
    }
  }

  handleCreateEditPurchaseOrder = () => {

    if(!this.state.catalogueItemList.length){
      return this.props.setError("Please add atleast one Item.")
    }
    this.setState({isDisabled:true})

    const catalogueDataList = this.state.catalogueItemList.map((ele) => this.state.editMode ? ({
      catalogue_id: ele.catalogue_id,
      quantity: ele.quantity || 0,
      unit_purchase_price: ele.unit_purchase_price,
      id: ele.id,
      total_purchase_price : Number(ele.quantity || 0)*Number(ele.unit_purchase_price)
    }) :
      ({
        catalogue_id: ele.catalogue_id,
        quantity: ele.quantity || 0,
        unit_purchase_price: ele.unit_purchase_price,
      total_purchase_price : Number(ele.quantity || 0)*Number(ele.unit_purchase_price)
      }
      ))

      const deletedCatalogueDataList = this.state.deletedCatalogueItems.map((ele)=> ({ id: ele.id, _destroy : true }) )

    const payload: ICreatePurchaseOrderPayload = {
      purchase_order: {
        payment_terms :  this.state.purchasePaymentTerms,
        delivery_date: this.state.expectedDeliveryDate,
        shipment_address: this.state.shipmentAddress,
        purchase_order_catalogues_attributes: [...catalogueDataList, ...deletedCatalogueDataList]
      }
    };

    payload.purchase_order.supplier_id = this.state.supplierDetails.id ? this.state.supplierDetails.id : "";

    const APIMethod = this.state.editMode ? configJSON.putApiMethod : configJSON.postApiMethod;

    const APIUrl = this.state.editMode ? `${configJSON.createPurchaseOrderAPIURL}/${this.state.editingPurchaseOrderID}` :
      configJSON.createPurchaseOrderAPIURL;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmPurchaseOrderAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      APIUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      APIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGetPurchasedOrderData = (purchaseID: string) => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPurchasedOrderDataAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPurchaseOrderList}/${purchaseID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCreateEditSupplier = () => {
    
    const payload = {...this.state.supplierEditData }

    this.setState({
      purchasePaymentTerms : this.state.supplierEditData?.payment_terms
    })

    const APIMethod = this.state.isSupplierPresent || this.state.supplierEditData?.id ? configJSON.putApiMethod : configJSON.postApiMethod;

    const APIUrl = this.state.isSupplierPresent || this.state.supplierEditData?.id ? `${configJSON.createSupplierAPIURL}/${this.state.supplierEditData.id}` :
      configJSON.createSupplierAPIURL;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSupplierAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      APIUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      APIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleListSupplier = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listSupplierAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listSupplierAPIURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}