import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { ICatalougeItem, Field } from "./types";

interface Item {
    checked? : boolean,
    item_code: Field,
    displayLocation?:string,
    catalogue_name: string,
    hsn_code : string,
    price : Field,
    quantity : Field,
    catalogue_id:number;
    weight?:Field;
    weight_unit?:Field
    max_quantity?: Field
  }

interface SS {
    id: any;
}

interface S {
    // Customizable Area Start
    listOfSelectedItemIds : number[]
    cataLougeList: ICatalougeItem[],
    search:string
    selectedCatalougeList :Item[],
    page:number,
    totalCount:number
    // Customizable Area End
  }

  export type Props =   {
    // Customizable Area Start
    classes:any;
    open: boolean;
    handleClose: () => void
    handleSave:(selectedIds:number[], selectedRows:Item[])=>void
    selectdRowIdList: number []
    selectedItems:Item[]
    // Customizable Area End
  }
 

  export default class AddItemDilogBoxController extends BlockComponent<
  Props,
  S,
  SS
>{
  debounceTimer: NodeJS.Timeout | null = null
  getCatalougeListApiCallId:string=""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      cataLougeList:[],
      listOfSelectedItemIds:[],
      search:"",
      selectedCatalougeList: [],
      page:1,
      totalCount:0
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
 
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
 
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(responseJson){
            if(responseJson.status === 500){
            //   this.props.setError("Internal Server Error");
              return;
            }
            else if(responseJson.errors){
              console.log("####errors",responseJson.errors);
            }
            else{
             this.handleUpdateCatalougeList(responseJson)
            }
          }
    }
  }

  async componentDidMount(){
    this.getCatalougeList()
    this.handleSetSate()
  }

  handleSetSate = ()=>{
    this.setState({listOfSelectedItemIds:this.props.selectdRowIdList, selectedCatalougeList:this.props.selectedItems})
  }

  handleSave =()=>{
    this.props.handleSave(this.state.listOfSelectedItemIds, this.state.selectedCatalougeList)
  }


  handleUpdateCatalougeList = (response: {message?:string, catalogues?:{ data:ICatalougeItem[],meta:any} })=>{
    if(response.message){
      this.setState({cataLougeList:[]})
    }else if(response.catalogues?.data){
      this.setState({cataLougeList:[... this.state.cataLougeList,...response.catalogues?.data],totalCount:response.catalogues.meta.total_count})
    }
  }

  handleSelectItem = (row:ICatalougeItem)=>{
    if(this.state.listOfSelectedItemIds.includes(row.attributes.id)){
      this.handleRemoveFromList(row.attributes.id)
    }else{
      this.handleAddToList(row)
    }
  }

  handlePagination =()=>{
    this.setState({page:this.state.page+1},()=> this.getCatalougeList())

  }

  handleRemoveFromList = (id:number)=>{
    const selectedIds = this.state.listOfSelectedItemIds.filter(selectedId=> selectedId !== id)
    const selectedItem = this.state.selectedCatalougeList.filter(item => item.catalogue_id != id)
    this.setState({selectedCatalougeList:selectedItem, listOfSelectedItemIds:selectedIds})
  }

  handleAddToList = (row:ICatalougeItem) =>{
    const newItem :Item = {
      checked:false,
      displayLocation:row.attributes.display_location,
      hsn_code:row.attributes.hsn_code,
      catalogue_id:row.attributes.id,
      item_code:row.attributes.item_code,
      price:row.attributes.sale_price,
      catalogue_name:row.attributes.name,
      quantity: row.attributes.available_stock,
      weight:row.attributes.weight,
      weight_unit: row.attributes.weight_unit,
      max_quantity: row.attributes.available_stock,
    }

    this.setState({selectedCatalougeList:[...this.state.selectedCatalougeList, newItem], listOfSelectedItemIds:[...this.state.listOfSelectedItemIds,row.attributes.id]})
  }

  handleSearchDebounce = () =>{
    this.debounceTimer = setTimeout(() => {
        this.getCatalougeList()
    }, 300);
  }

  handleSearchItem = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
   
     this.setState({search:event.target.value,page:1,cataLougeList:[]},this.handleSearchDebounce)
  }

  getCatalougeList = () => {
    let token = window.localStorage.getItem("token");
    let header:Record<string,string|undefined|null> = {
      token,
      "Content-Type":"application/json"
    };
   
    const catalougeApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    catalougeApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    catalougeApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_advanced_search/search/search_product?data[search_params]=${this.state.search}&page=${this.state.page}&per_page=20`
    );
    catalougeApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
   
    this.getCatalougeListApiCallId=  catalougeApiMessage.messageId;

    runEngine.sendMessage(catalougeApiMessage.id, catalougeApiMessage)
  }
}
