// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { MuiThemeProvider } from "@material-ui/core";
import MuiTheme from './MuiTheme';
import { Store } from './AppContext.web';
import './index.css';

ReactDOM.render(
  <Router>
    <Store>
      <MuiThemeProvider theme={MuiTheme}>
        <App />
      </MuiThemeProvider>
    </Store>
    </Router>,
  document.getElementById('root')
);
registerServiceWorker();
