import React from "react";

export const TeamDashboard = () => {
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy" transform="translate(-26.000000, -497.000000)" fill="#000000" fillRule="nonzero">
            <g id="team_dashboard_FILL0_wght200_GRAD0_opsz24" transform="translate(26.000000, 497.000000)">
                <path d="M1.615375,16 C1.16794167,16 0.78685,15.8426333 0.4721,15.5279 C0.157366667,15.21315 0,14.8320583 0,14.384625 L0,1.615375 C0,1.16794167 0.157366667,0.78685 0.4721,0.4721 C0.78685,0.157366667 1.16794167,0 1.615375,0 L14.384625,0 C14.8320583,0 15.21315,0.157366667 15.5279,0.4721 C15.8426333,0.78685 16,1.16794167 16,1.615375 L16,14.384625 C16,14.8320583 15.8426333,15.21315 15.5279,15.5279 C15.21315,15.8426333 14.8320583,16 14.384625,16 L1.615375,16 Z M6.5,15 L6.5,8.5 L1,8.5 L1,14.384625 C1,14.5641083 1.05769167,14.7115417 1.173075,14.826925 C1.28845833,14.9423083 1.43589167,15 1.615375,15 L6.5,15 Z M7.5,15 L14.384625,15 C14.5641083,15 14.7115417,14.9423083 14.826925,14.826925 C14.9423083,14.7115417 15,14.5641083 15,14.384625 L15,8.5 L7.5,8.5 L7.5,15 Z M1,7.5 L15,7.5 L15,1.615375 C15,1.43589167 14.9423083,1.28845833 14.826925,1.173075 C14.7115417,1.05769167 14.5641083,1 14.384625,1 L1.615375,1 C1.43589167,1 1.28845833,1.05769167 1.173075,1.173075 C1.05769167,1.28845833 1,1.43589167 1,1.615375 L1,7.5 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>)
}