import React from "react";
import { 
Box,
Button,
FormControl,
FormLabel,
Grid,
Icon,
TextField,
Typography,
withStyles 
} from "@material-ui/core";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import DeliveryChallanAddEditController, {Props} from "./DeliveryChallanAddEditController.web";
import {withRouter} from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AlertPopup from "../../../components/src/AlertPopup.web";
import SuccessPopup from "../../../components/src/SuccessPopup.web";
import AddItemDialogBox from "../../../components/src/AddItemDialogBox.web";
import OutboundOrderItemTable from "../../../components/src/OutboundOrderItemTable.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import Select from "react-select";

const configJSON = require("./config.js");

const styles : any = {
    formControl: {
        width:"700px",
        marginBottom: "0",
        "*": {
            fontFamily: "gilroy-regular !important"
        },
        "& label": {
            textAlign: "start",
            color: "black",
            fontWeight: "500",
            fontSize: "14px",
        },
        " & input": {
            fontFamily: "gilroy-regular",
            fontSize: "14px !important"
        },
        "& a, a:hover, a:active": {
            textDecoration: "none",
            marginRight: "0px",
            marginLeft: "auto",
            color: "rgba(0, 0, 0, 0.54)",
            "& h6": {
                paddingBottom: "0px !important",
                fontSize: "14px !important"
            }
        },
        "& p": {
            color: "red"
        },
    },
    selectInput:{
        "& .MuiOutlinedInput-input":{
            padding: "10px 32px 10px",
            width:"200px"
        }
    },
    darkStoreInputContainer:{
        display:"flex",
        gap:"10px"
    },
    addBtn:{
        textTransform:"initial",
        border:"1px solid #652d92",
        background:"#fff",
        marginRight:"0px",
        marginLeft:"auto",
        height:"40px",
        "& *":{
            color:"#662D91",
            fontWeight:"bold"
        },
        "& .material-icons":{
            display:"flex",
            alignItems:"center",
            marginRight:"8px"
        }
    },
    tableMargin:{
        marginTop:"1rem"
    },
    datePickerConatiner:{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    deleteItem:{
        fontWeight:500,
        color:"red",
        textDecoration: "underline",
        cursor:"pointer",
        display: "inline",
        fontFamily: "gilroy-medium"
    },
    inputLabel:{
        fontFamily: "gilroy-medium",
        fontSize:"16px !important",
        letterSpacing:"0"
    },
    confirmBtn:{
        background:"rgba(102, 45, 145, 1)",
        height:"56px",
        fontFamily: "gilroy-bold",
        fontSize: "16px",
        width:"162px"
    }
}

class DeliveryChallanAddEdit extends DeliveryChallanAddEditController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { userProfile } = restProps;
        const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile?.attributes?.groups?.data[0].attributes.settings : {};
        const darkstoreList = this.state.darkStoreList.map(store => {
            return { value: store.id, label: store.attributes.legal_name, }
        })
        return (
            <GlobalStoreDashboardLayout classes={classes} {...restProps} headerTitle="Delivery Challan" actionHandler={undefined} headerAction="" subTitle="" hasAccess = {groups.delivery_challan}>
            { !groups?.delivery_challan ? <NoAccessPage description="Sorry, you don't have the permission" /> :
              <div className="main_container">
                <Grid container spacing={3} >
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                        <Box className={classes.darkStoreInputContainer}>
                            <Typography variant="body1" className={classes.inputLabel} style={{lineHeight:"2.5"}}>Dark Stores<span style={{color:'red'}}>*</span></Typography>
                         
                            <Select
               styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "none",
                  backgroundColor: "#F3F3F3",
                  border:0,
                  width:"203px"
                }),
                dropdownIndicator: (styles) => ({
                  ...styles,
                  color: "#101010",
                  border: "none",
                }),
                singleValue: (provided: any) => ({
                  ...provided,
                  height: "100%",
                  color: " #3B3B3B",
                  fontFamily: "gilroy-medium",
                  paddingTop: "3px",
                  fontSize: "14px",
                }),
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
                className={`${classes.select} ${classes.darkStoreSelectOptionDiv}`}
                value={this.state.darkStore}
                options={darkstoreList}
                data-test-id="darkstorselect"
                onChange={this.handleDarkStoreChange}
              />
                        </Box>
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                    <FormControl fullWidth className={classes.formControl}>
                        <FormLabel className={classes.inputLabel}>E-Waybill No.</FormLabel>
                        <Grid container>
                            <Grid item md={8}>
                                <TextField
                                    margin="normal"
                                    data-test-id="Ewaybillno"
                                    placeholder="E-waybill No"
                                    fullWidth
                                    variant="outlined"
                                    onChange={this.handleEWayBillNoChange}
                                    value={this.state.eWayBillNo}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                    <FormControl fullWidth className={classes.formControl}>
                        <FormLabel className={classes.inputLabel}>Shipment Address<span style={{color:"red"}}>*</span></FormLabel>
                        <Grid container>
                            <Grid item md={8}>
                                <TextField
                                    margin="normal"
                                    data-test-id="shippingAdd"
                                    placeholder="Shipment Address"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.shipmentAddress}
                                    onChange={this.handleShipmentAddressChange}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                        <Box className={classes.datePickerConatiner}>
                        <Typography className={classes.inputLabel} variant="inherit">Delivery Date<span style={{color:"red"}}>*</span></Typography>
                            <DatePicker 
                                selected={this.state.deliveryDate}
                                onChange={this.handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                data-test-id="date"
                                minDate={new Date()}
                                customInput={
                                    <TextField
                                    className="width100"
                                    variant="outlined"
                                    value={this.state.deliveryDate}
                                    />
                                }
                                />       
                            </Box>
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                        <Typography className={classes.inputLabel} variant="inherit">Items<span style={{color:"red"}}>*</span></Typography>
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                        <Button className={classes.addBtn} variant="outlined" data-test-id="addItemBtn" onClick={this.handleOpenAddItemPopUp} >
                            <Icon><AddIcon/></Icon> Add Item 
                        </Button>
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                      {
                        /*istanbul ignore next*/
                        <OutboundOrderItemTable handleChange={ this.handleUpdateItem} itemData={this.state.selectedItem} editable ={true}/>}
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12}>
                      {
                    /*istanbul ignore next*/
                    this.state?.selectedItem?.length > 0 &&  <Typography variant="subtitle1" className={classes.deleteItem} onClick={this.handleOpenDeletePopUp} style={{ color: this.state.disableDeleteBtn ? "gray" : "red", pointerEvents:this.state.disableDeleteBtn ? "none" : "auto"}}>Delete Item</Typography>
                    }
                    </Grid>
                    <Grid item xs ={12} sm={12} md ={12} lg={12} style={{display:"flex" ,justifyContent:"end"}}>
                        <Button size="large" className={classes.confirmBtn} style={{ background:"rgba(102, 45, 145, 1)"}} data-test-id="confirmButton" variant="contained" id="confirmButton" color="secondary" disabled={this.state.isDisabled}  onClick ={this.handleConfirm}>Confirm</Button>
                    </Grid>
                </Grid>
               </div>}
               <AlertPopup    
                isOpen={this.state.openDeletePopUp}
                cancelHandler={this.handleOpenDeletePopUp}
                actionHandler={this.handleDelete}
                handleClose={this.handleOpenDeletePopUp}
                action="Delete" 
                description={<Typography variant="h6">Are you sure that you want to <span>Delete</span> this Item</Typography>}/>
                <SuccessPopup description={<Typography variant="h6">{configJSON.outboundOrderSuccessMsg}</Typography>} isOpen={this.state.openSuccessPopUp} handleClose={this.handleOpenSuccessPopUp} handleConfirm = {this.handleRedirectToOrderStatus}/>
                {this.state.openAddItem && <AddItemDialogBox handleClose={this.handleOpenAddItemPopUp}  handleSave={this.handleSaveItem} open={this.state.openAddItem} selectdRowIdList={this.state.listOfSelectedItemIds} selectedItems={this.state.selectedItem} />}
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area End
    }}


export { DeliveryChallanAddEdit };
export default withStyles(styles)(withRouter(DeliveryChallanAddEdit));