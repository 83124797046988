import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { DarkStoreItem } from "./types";

export const configJSON = require("./config");

interface Item {
  checked? : boolean,
  item_code: Field,
  displayLocation?:string,
  catalogue_name: string,
  hsn_code : string,
  price : Field,
  quantity : Field,
  catalogue_id:number,
  weight?:Field;
  weight_unit?:Field,
  max_quantity?: Field
}

type Field = null | string | number | undefined

interface SS {
    id: any;
}

interface S {
  // Customizable Area Start
  selectedDarkStore:{label:string,value:string},
  darkStore: {label:string,value:string},
  eWayBillNo: string,
  shipmentAddress: string,
  deliveryDate:Date,
  selectedItem :Item[],
  listOfSelectedItemIds : number[]
  openDeletePopUp :boolean,
  openSuccessPopUp:boolean,
  darkStoreList:DarkStoreItem[],
  openAddItem:boolean,
  currentOrderId: null | number
  disableDeleteBtn: boolean,
  isDisabled:boolean
  // Customizable Area End
}

export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  userProfile:any;
  setUserProfile:any;
  setError:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}

export default class DeliveryChallanAddEditController extends BlockComponent<
  Props,
  S,
  SS
>{
  getDarkstoreApiCallId:string="";
  createDeliveryChallanApiCallId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      selectedDarkStore:{label:"select dark store",value:"dark store"},
      darkStore:{label:"Select Dark Store",value:""},
      deliveryDate:new Date(),
      eWayBillNo:"",
      openDeletePopUp:false,
      openSuccessPopUp:false,
      openAddItem:false,
      darkStoreList:[],
      listOfSelectedItemIds:[],
      selectedItem:[],
      shipmentAddress:"",
      currentOrderId: null,
      disableDeleteBtn: true,
      isDisabled:false
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }  
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson){
        if (responseJson.status === 500 || (!responseJson?.data && apiRequestCallId === this.createDeliveryChallanApiCallId)) {
          this.props.setError("Internal Server Error");
          this.setState({isDisabled:false})
          return;
        }
        else if(responseJson.errors){
        }
        else{
         this.handleSuccessCallbacks(apiRequestCallId, responseJson);
        } 
      }
    }
    // Customizable Area End
  }

  updateSelectedIds=(selectedDataList:any)=>{
    let selcetedIds:number[]=[];
selectedDataList.forEach((element:any) => {
  selcetedIds.push(element?.catalogue_id);
});
    return selcetedIds
  }

  async componentDidMount(){
    const selectedData:any=this.props.location.state; 
    if(selectedData && selectedData.from === "salesDashboard" ){
      this.setState({
        selectedItem:selectedData.data,
        listOfSelectedItemIds: this.updateSelectedIds(selectedData.data),
        darkStore : selectedData?.data?.length && selectedData.data[0].darkStore
      })
    }
 
    this.getDarkStoreList()
  }
  
  handleSuccessCallbacks = (apiRequestCallId:string, responseJson:any)=>{
    switch(apiRequestCallId){
      case this.getDarkstoreApiCallId:{
        this.handleUpdateDarkStoreList(responseJson)
        break
      }
      case this.createDeliveryChallanApiCallId:{
        this.setState({currentOrderId : responseJson?.data?.attributes?.id,isDisabled:false})
        this.handleOpenSuccessPopUp()
        break
      }

      default:{
        break
      }
    }
  }

  handleUpdateItem = (name:string,id:number,value:string|boolean) =>{
      const data = this.state.selectedItem.map(item=>{
        if(item.catalogue_id === id ){
          return {...item , checked : name === "checked" ? value as boolean : item.checked  , quantity: name === "quantity" ? value as string : item.quantity }
        }
        return item
      })

      const filterData = data.filter(item=> item.checked)
      this.setState({selectedItem: data, disableDeleteBtn: filterData.length > 0 ? false : true})
  }

  handleUpdateDarkStoreList = (response:{data:DarkStoreItem[]})=>{
    const indexOfFirstItem = 0
    const preSelectedDarkStoreData = response.data.find((ele)=> ele.id == this.state.darkStore.value )
    this.setState({darkStoreList:response.data, darkStore: this.state.darkStore.value ? this.state.darkStore : {label:response.data[indexOfFirstItem]?.attributes?.legal_name,value:response.data[indexOfFirstItem].id}, shipmentAddress: preSelectedDarkStoreData ? preSelectedDarkStoreData.attributes.address : response.data[indexOfFirstItem].attributes.address})
  }

  handleDarkStoreChange = (event:any)=>{
    const storeId = event.value as string

    const  getDarkStore = this.state.darkStoreList.filter(store=> store.id === storeId)
    const DEFAULT_INDEX_OF_FIRST_STORE = 0
    this.setState({darkStore:{label:event.label,value:storeId}, shipmentAddress:getDarkStore[DEFAULT_INDEX_OF_FIRST_STORE].attributes.address})
  }
  

  handleRedirectToOrderStatus = ()=>{
    this.props.history.push(`${configJSON.orderStatusRoute}/${this.state.currentOrderId}`)
  }

  handleEWayBillNoChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
      this.setState({eWayBillNo:event.target.value})
  }
  handleShipmentAddressChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
      this.setState({shipmentAddress:event.target.value})
  }

  handleDateChange = (value:Date)=>{
    this.setState({deliveryDate:value})
  }

  handleOpenDeletePopUp = ()=>{
    this.setState({openDeletePopUp:!this.state.openDeletePopUp})
  }

  handleDelete = ()=>{
    const checkedId:number[] = []
    const filterSelectedList = this.state.selectedItem.filter(item=> {
      if(item.checked){
        checkedId.push(item.catalogue_id)
        return false
      }
      return true
    })
    const filterSelectedIds = this.state.listOfSelectedItemIds.filter(id => !checkedId.includes(id))
    this.setState({selectedItem:filterSelectedList, listOfSelectedItemIds:filterSelectedIds, disableDeleteBtn:true})   
    this.handleOpenDeletePopUp()
  }

  handleOpenSuccessPopUp =()=>{
    this.setState({openSuccessPopUp:!this.state.openSuccessPopUp}, ()=>{
      if(!this.state.openSuccessPopUp)
        this.setState({currentOrderId:null})
    })
  }

  handleRedirect = ()=>{
    if(!this.state.openSuccessPopUp){
      this.props.history.push("/delivery_challan")
    }
  }

  handleOpenAddItemPopUp =()=>{
    this.setState({openAddItem:!this.state.openAddItem})
  }

  handleSaveItem = (selectedIds:number[], selectedItems:Item[])=>{
    const filterPreviousSelected = this.state.selectedItem.filter(item => selectedIds.includes(item.catalogue_id))
    const filterNewSelected = selectedItems.filter(item=> !this.state.listOfSelectedItemIds.includes(item.catalogue_id))
    this.setState({selectedItem:[...filterPreviousSelected,...filterNewSelected], listOfSelectedItemIds:selectedIds, openAddItem:false})
  }

  getDarkStoreList = async() => {
    this.getDarkstoreApiCallId =  this.deliveryChallanApiCall({
      contentType:configJSON.contentTypeApplicationJson,
      method: configJSON.getAPIMethod,
      endPoint: configJSON.listDarkstoresEndPoint,
    })
  }

  handleConfirm = ()=>{
    if(!this.state.shipmentAddress){
      this.props.setError("Please add shipment address.")
    }
    else if(this.state.selectedItem.length ){
      this.setState({isDisabled:true})
      let error=false
      const modifiedDeliveryItem = this.state.selectedItem.map(item => {
        if(item.quantity == 0 || item.quantity?.toString().trim() === "" || ( item.max_quantity && item.quantity && item.quantity > item.max_quantity ) || item?.max_quantity === 0){
          error = true
        }
        return {
          "catalogue_id": item.catalogue_id,
          "quantity": item.quantity
        }
      })

      if(error){
        this.props.setError("Item quantity should not be less than 1 and greater than max quantity and not empty")
        this.setState({isDisabled:false})
      }
      else{
        const body = {
          "delivery_challan": {
            "eway_bill_no":this.state.eWayBillNo,
            "store_id": this.state.darkStore.value,
            "delivery_date": this.state.deliveryDate,
            "shipment_address": this.state.shipmentAddress,
            "delivery_challan_catalogues_attributes":modifiedDeliveryItem
          }
        }
  
        this.createDeliveryChallanApiCallId = this.deliveryChallanApiCall({
          contentType:configJSON.contentTypeApplicationJson,
          method: configJSON.postAPIMethod,
          endPoint: configJSON.createDeliveryChallanEndPoint,
          body
        })
      }
      
    }else{
      this.props.setError("Please add atleast one Item.")
    }
  }

  deliveryChallanApiCall = (data: any) => {
    const { contentType, method, endPoint, body, type} = data;
    let token = window.localStorage.getItem("token");
    let header:Record<string,string|undefined|null> = {
      token,
    };
    if(contentType){
      header = {
        ...header,
        "Content-Type":contentType
      }
    }
    const deliveryChallanApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    deliveryChallanApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type === "form-data"? body : JSON.stringify(body) 
    );
    runEngine.sendMessage(deliveryChallanApiMessage.id, deliveryChallanApiMessage)
    return deliveryChallanApiMessage.messageId;
  }
}