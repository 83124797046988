import React from 'react'
import { TextField, Table, TableHead, TableRow, TableCell, TableBody, styled, withStyles } from "@material-ui/core";
import {Field, ICatalougeItemInterface} from "./types"

interface Item {
    checked? : boolean,
    item_code: Field,
    displayLocation?:string,
    catalogue_name: string,
    hsn_code : string,
    price : Field,
    quantity : Field,
    catalogue_id:number,
    weight?:Field;
    weight_unit?:Field
    max_quantity?: Field
  }

interface ItemTableProps {
    itemData:ICatalougeItemInterface[],
    handleChange? : (name:string,id:number,value:string|boolean)=>void,
    editable: boolean,
    classes:any
}

const styles: any = {
    tableHeader:{
        backgroundColor:"rgba(245, 245, 250, 1)",
        "& th":{
            color: "#8181A5",
            fontFamily: "gilroy-bold",
            fontSize: "12px",
            fontStyle: "normal",
        }
    },
    tableBody:{
        "& td":{
            borderRight:"1px solid rgba(224, 224, 224, 1)",
            color: "#8181A5",
            fontFamily: "gilroy-regular",
            fontSize: "14px",
            fontStyle: "normal",
        }
    },
    boldTableData:{
        color: "#1C1D21 !important",
        fontFamily: "gilroy-bold !important",
        fontSize: "16px !important",
        fontStyle: "normal !important",
    },
    radioBtn:{
        accentColor:"#000",
    }
}

const StyledInput = styled(TextField)({
    "& input":{
        textAlign:"center",
        color: "#808080",
        fontFamily: "gilroy-regular",
        fontSize: "14px"
    }
})

class OutboundOrderItemTable extends React.Component<ItemTableProps> {
    constructor(props: any) {
        super(props)
    }

    render() {
        const {itemData, handleChange,editable,classes } = this.props;
        console.log(itemData)
        return  (
       <Table>
        <TableHead className={classes.tableHeader}>
            <TableRow>
                {editable && <TableCell align='center'>Select</TableCell>}
                <TableCell align='center'>Item Code</TableCell>
                <TableCell align='center'>Product Name</TableCell>
                <TableCell align='center'>Display Location</TableCell>
                <TableCell align='center'>HSN Code</TableCell>
                <TableCell align='center'>Price</TableCell>
                <TableCell align='center'>Quantity</TableCell>
            </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
            {itemData.length > 0 ? itemData.map((element:Item)=>{
              return  <TableRow key={element.catalogue_id}>
                   {editable &&  <TableCell align='center' >
                    <input className={classes.radioBtn} type="radio" id={String(element.catalogue_id)} name={String(element.catalogue_id)} checked={element.checked}
                      onClick={()=>handleChange ?  handleChange("checked",element.catalogue_id, !element.checked ): "" }/>
                    </TableCell>}
                    <TableCell align='center'>{element.item_code}</TableCell>
                    <TableCell align='center' className={classes.boldTableData} >{element.catalogue_name}, {element.weight}{element.weight_unit}</TableCell>
                    <TableCell align='center'>{element.displayLocation}</TableCell>
                    <TableCell align='center' >{element.hsn_code}</TableCell>
                    <TableCell align='center' className={classes.boldTableData}>{element.price? `₹${element.price}` : `-`}</TableCell>
                    <TableCell align='center'>
                        {editable? <StyledInput type='number'  variant='outlined' error = {true} 
                        helperText = {element?.max_quantity === 0 ?"Item is not present in inventory" :element?.quantity?.toString().trim() === ""  ? "Quantity should not be emplty":element.quantity == 0 ? "Atleast add 1 item" : (element.quantity && element.max_quantity && element.quantity > element.max_quantity)? `Quantity should be less than ${element.max_quantity}`:""}  
                        value={element.quantity} 
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=> handleChange ?  handleChange("quantity",element.catalogue_id, e.target.value ): ""  } /> : element.quantity}
                    </TableCell>
                </TableRow>
            }) :
            <TableRow>
                <TableCell  colSpan={editable ? 7 : 6} align='center' style={{fontWeight:"bold"}}>No Item Added</TableCell>
            </TableRow>
            }
        </TableBody>
       </Table>
        )
    }
}

export default withStyles(styles)(OutboundOrderItemTable);