import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { checkSubsringPresent, createDataForNotification } from "../../../components/src/CommonFunctions";
// Customizable Area End

export const configJSON = require("./config");
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  openQrModal:boolean
  videoDeviceAvailable:boolean
  invalidQR:boolean
  newNotificationAdded:boolean
  listOfNotification:{id:number,notification:string,date:string}[]
  isClosing: boolean
  // Customizable Area End
}
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  userType: any;
  headerTitle: any;
  headerAction: any;
  subTitle: any;
  actionHandler: any;
  userProfile:any;
  setUserProfile:any;
  setError:any;
  hasAccess:boolean;
  isInventory?: boolean
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  
  // Customizable Area End
}
export default class GlobalStoreLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileCallId: any;
  getNotifiactionCallId:any;
  clearNotificationCallId:any;
  notificationTimer:number | null = null
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      // Customizable Area Start
      anchorEl:null,
      openQrModal:false,
      videoDeviceAvailable:false,
      invalidQR:false,
      newNotificationAdded:false,
      listOfNotification :[],
      isClosing: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount(){
    this.getAllNotofications()
    this.detactDevice()
    this.setIntervalForNotification()
    if(!this.props.userProfile){
      const token =localStorage.getItem("token");
      await this.fetchUserProfile(token);
    }
  }

  async componentWillUnmount() {
      this.setIntervalForNotification()
  }

  globalStoreProfileApiCall = (data:any) => {
    const { contentType, method, endPoint, body, extraHeaders } = data;
    const header = {
      "Content-Type": contentType,
      ...extraHeaders
    };
    
    const globalStoreProfileReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    globalStoreProfileReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    globalStoreProfileReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    globalStoreProfileReqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    globalStoreProfileReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(globalStoreProfileReqMessage.id, globalStoreProfileReqMessage);
    return globalStoreProfileReqMessage.messageId;
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(errorReponse){
        this.props.setError(errorReponse);
      }
      if (!responseJson) {
        return;
      }
    
        if(responseJson.status === 500){
          this.props.setError("Internal Server Error");
          return;
        }
        if (!responseJson.data && this.getUserProfileCallId === apiRequestCallId) {
          this.props.setError("Something went wrong");
          setTimeout(() => {
            window.localStorage.removeItem("token");
            this.props.history.push("/login");
          },1000)
          return;
        }
         if(responseJson.errors  && responseJson.errors.length > 0){
            this.handleExpiredToken(responseJson);
        }
          switch(apiRequestCallId){
            case this.getUserProfileCallId:{
              this.props.setUserProfile(responseJson.data);
              break
            }
            case this.getNotifiactionCallId:{
              this.handleSuccessNotification(responseJson.data)
              break
            }
            case this.clearNotificationCallId:{
              this.setState({listOfNotification:[]})
              this.props.setNewNotification(false)
              this.props.setLenghtOfNotification(0)
              break
            }
            default:
              break
       }
    }
    // Customizable Area End
  }


  handleSuccessNotification = (data:any)=>{
    const notifications = createDataForNotification(data)
    this.setState({listOfNotification:notifications})
    this.props.setNewNotification(this.props.lengthOfNotification !== notifications.length   && notifications.length > 0  ? true:  this.props.newNotification)
    this.props.setLenghtOfNotification(notifications.length)
  }

  handleExpiredToken = ( responseJson:any ) => {
    if(responseJson.errors.length > 0 && responseJson.errors[0].token){
      this.props.setError("Token has expired. Please login.")
      setTimeout(() => {
        window.localStorage.removeItem("token");
        this.props.history.push("/login");
      },1000)
    }
  }

  fetchUserProfile = async (token:any) => {
    this.getUserProfileCallId = this.globalStoreProfileApiCall({
      contentType: configJSON.getUserProfileApiContentType,
      method: configJSON.getUserProfileApiMethodType,
      endPoint: configJSON.getUserProfileApiEndPoint,
      extraHeaders: {
        token
      }
    });
  }

  setIntervalForNotification = ()=>{
    
    if(this.notificationTimer){
      window.clearInterval(this.notificationTimer)
    }
    else{
      this.notificationTimer = window.setInterval(()=>{
        this.getAllNotofications()
      },configJSON.intervalTime)
    }
  }


  getAllNotofications = ()=>{
    const token =localStorage.getItem("token");
    this.getNotifiactionCallId = this.globalStoreProfileApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getNotificationApiMethod,
      extraHeaders: {
        token
      }
    });
  }

  clearAllGlobalStoreNotification = ()=>{
    const token =localStorage.getItem("token");
    this.clearNotificationCallId = this.globalStoreProfileApiCall({
      contentType: configJSON.contentType,
      method: configJSON.deleteMethod,
      endPoint: configJSON.clearNotificationApiEndPoint,
      extraHeaders: {
        token
      }
    });
  }

  handleNavigation = (path: any) => {
    this.props.history.replace(`/${path}`);
  }

  handleLogout = () => {
    localStorage.clear();
    this.props.setUserProfile();
    this.props.setLenghtOfNotification(0)
    this.props.setNewNotification(false)
    this.props.history.push("/login")
  }

  handleOpenNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseNotification = () => {
      this.setState({ anchorEl: null});
      this.props.setNewNotification(false)
  };

  // Customizable Area Start

  handleOpenQrModal = ()=>{
    if(this.state.videoDeviceAvailable){
      this.setState({openQrModal:!this.state.openQrModal,invalidQR:false})
    }else{
      this.props.setError("Camera not found")
    }
  }

  handleCloseDrawer = () => {
    this.setState({ isClosing: !this.state.isClosing })
  }
  
  detactDevice=async()=>{
   let md = navigator.mediaDevices;
   await md.enumerateDevices().then(devices => {
      this.setState({videoDeviceAvailable:devices.some(device => device.kind ==="videoinput")})
    })
  }
  
  // Customizable Area End

  handleScan =(data:string|null)=>{
    if(data){
      if(checkSubsringPresent(data,window.location.origin)){
        const location = data.replace(window.location.origin,"")
        this.setState({invalidQR:false, openQrModal:false})
        this.props.history.push(location)
      }else{
        this.setState({invalidQR:true})
      }
    }
}
  // Customizable Area End
}
