import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React, { createRef } from "react";
import * as Yup from "yup";
import { paginate, getVendorForm } from "../../../components/src/CommonFunctions";
import { RouteComponentProps } from "react-router";
// Customizable Area End

export const configJSON = require("./config");
interface SS {
  id: any;
}

interface S {
  // Customizable Area Start
  currentPage: number;
  currentDataList: any;
  dataList: any;
  rowsCount: number;
  startIndex: number;
  endIndex: number;
  open: boolean;
  anchorEl: any;
  isEdit: boolean;
  noDarkstoreLinked:boolean;
  isDeletePopup: boolean;
  isSuccess: boolean;
  image: any;
  warehouseForm: any;
  error: any;
  isVendorUpdated: boolean;
  isLoading: boolean;
  successMsg: any;
  legal_name: string;
  trade_name: string;
  email: string;
  contact: string;
  store_size: number;
  limit: number;
  gstin: string;
  pan: string;
  authorized_person_name: string;
  authorized_person_designation: string;
  authorized_person_contact: string;
  bank_account_number: number;
  bank_branch: string;
  ifsc: string;
  state: string;
  country: string;
  address: string;
  district: string;
  city: string;
  pincode: number;
  latitude: number;
  longitude: number;
  photo: { url: string | null };
  searchKey:string;
  isOpenSearch: boolean;
  isOpenMap: boolean;
  // Customizable Area End
}
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  userType: any;
  formContainer: any;
  userProfile: any;
  setError: any;
  setSuccess: any;
  setUserProfile: any;
  history: { location: { state: { message: any } } };
  hasAccess: boolean;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}
export default class DarkStoreManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDarkstoreListApiCallId: any;
  updateDarkstoreApiCallId: any;
  deleteDarkstoreApiCallId: any;
  debounceTimeout: any;

  hiddenFileInput = createRef<HTMLInputElement>();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.debounceTimeout = null;
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      // Customizable Area Start
      currentPage: 1,
      currentDataList: [],
      dataList: [],
      rowsCount: 0,
      startIndex: 0,
      endIndex: 0,
      open: false,
      anchorEl: null,
      isEdit: false,
      isDeletePopup: false,
      noDarkstoreLinked: false,
      isSuccess: false,
      image: null,
      warehouseForm: null,
      error: "",
      isVendorUpdated: false,
      isLoading: false,
      successMsg: null,
      legal_name: "",
      trade_name: "",
      email: "",
      contact: "",
      address: "",
      country: "",
      state: "",
      city: "",
      district: "",
      latitude: 0,
      longitude: 0,
      authorized_person_designation: "",
      store_size: 0,
      limit: 0,
      gstin: "",
      authorized_person_contact: "",
      pincode: 0,
      pan: "",
      photo: { url: null },
      bank_branch: "",
      authorized_person_name: "",
      ifsc: "",
      bank_account_number: 0,
      searchKey:"",
      isOpenSearch: false,
      isOpenMap: false,
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (responseJson.status === 500 || !responseJson.data) {
          this.props.setError("Internal Server Error");
          this.setState({ isLoading: false });
          return;
        }
        else if (responseJson.errors) {
          this.setState({ isLoading: false, dataList:[], currentDataList:[] });
        }
        else {
          await this.handleSuccessCallbacks(apiRequestCallId, responseJson);
        }
      }

      if (errorReponse) {
        this.setState({ isLoading: false });
        this.props.setError(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Star

  darkStoreManagementApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    let token = window.localStorage.getItem("token");
    let header = {
      token,
    } as any;
    if (contentType) {
      header = {
        ...header,
        "Content-Type": contentType
      }
    }
    const darkStoreManagementApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type === "form-data" ? body : JSON.stringify(body)
    );
    runEngine.sendMessage(darkStoreManagementApiMessage.id, darkStoreManagementApiMessage);
    return darkStoreManagementApiMessage.messageId;
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.fetchDarkstoreList();
    if(this.props.history.location.state && this.props.history.location.state.message) {
      this.setState({ isVendorUpdated: true, successMsg: this.props.history.location.state?.message });
      this.props.history.replace({ ...this.props.history.location, state: {} });
    }
  }

  handleSuccessCallbacks = async (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getDarkstoreListApiCallId: {
        this.handleDarkstoreListSuccessCallback(responseJson);
        break;
      }

      case this.updateDarkstoreApiCallId: {
        await this.handleDarkstoreUpdateSuccessCallback();
        break;
      }

      case this.deleteDarkstoreApiCallId: {
        await this.fetchDarkstoreList();
        break
      }

      default: {
        break;
      }
    }
  }

  handleDarkstoreUpdateSuccessCallback = async () => {
    this.setState({ isVendorUpdated: true, successMsg: "Vendor Details changed succesfully" });
    await this.fetchDarkstoreList();
  }

  handleDarkstoreListSuccessCallback = (res: any) => {
    const rowsPerPage = configJSON.rowsPerPage;
    if(res.data) {
      const dataList = res.data;
    this.setState({ dataList: dataList, isLoading: false, rowsCount: Math.ceil(dataList.length / rowsPerPage) }, () => this.handlePagination({}, this.state.currentPage));
    }else if(res.message){
      this.setState({noDarkstoreLinked: true});
    }
    
  }

  fetchDarkstoreList = async () => {
    this.setState({noDarkstoreLinked: false});
    this.getDarkstoreListApiCallId = await this.darkStoreManagementApiCall({
      contentType: configJSON.listDarkstoresContentType,
      method: configJSON.listDarkstoresMethodType,
      endPoint: configJSON.listDarkstoresEndPoint,
    });
  }

  handlePagination = (e: any, page: number) => {
    const { dataList } = this.state;
    const { startIndex, endIndex, currentDataList, currentPage } = paginate(page, dataList, configJSON.rowsPerPage);
    this.setState({
      startIndex,
      endIndex,
      currentDataList,
      currentPage,
    });
  }

  searchDarkstores = async (value: any) => {
    this.getDarkstoreListApiCallId = await this.darkStoreManagementApiCall({
      contentType: configJSON.listDarkstoresContentType,
      method: configJSON.listDarkstoresMethodType,
      endPoint: `${configJSON.listDarkstoresEndPoint}?search_query=${value}`,
    });
  }

  handleSearch = (e: any) => {
    const { value } = e.target;
    
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    
    this.debounceTimeout = setTimeout(() => {
      if(value.length === 0){
        this.fetchDarkstoreList();
      }else{
        this.searchDarkstores(value);
      }
    }, 600);
  }

  handlePrevNext = (type: any) => {
    const { currentPage, rowsCount } = this.state;
    if (type === "previous" && currentPage > 1) {
      this.handlePagination({}, currentPage - 1);
    } else if (type === "next" && currentPage < rowsCount + 1) {
      this.handlePagination({}, currentPage + 1);
    }
  }

  handleSubmitUpdateForm = async (values: any) => {   
    let formData = getVendorForm(values,this.state.image,this.state.warehouseForm);   
    await this.updateForm(formData, this.state.warehouseForm);
  }

  updateForm = async (formData: any, warehouseForm: any) => {
    let body = formData;
    this.handleClose();
    this.updateDarkstoreApiCallId = await this.darkStoreManagementApiCall({
      contentType: configJSON.updateDarkstoreContentType,
      method: configJSON.updateDarkstoreMethodType,
      endPoint: `${configJSON.updateDarkstoreEndPoint}/${warehouseForm.id}`,
      body,
      type: "form-data"
    });
  }

  handleNavigateToNewVendorForm = () => {
    this.props.history.push("/dark_store_management/add-new-vendor");
  }

  navigateToWarehouseDetails = (warehouseId: any) => {
    this.props.history.push(`/dark_store_management/${warehouseId}`);
  }

  handleUpdateDropdown = (e: any, warehouse: any) => {
    this.setState({ open: true, anchorEl: e.currentTarget, warehouseForm: warehouse, image:warehouse.attributes?.photo });
  }

  handleClose = () => {
   this.hiddenFileInput.current?.remove();
   this.setState({ open: false, anchorEl: null, isDeletePopup: false, isEdit: false, warehouseForm: null, photo:{url:null}, image:null });
  }

  handleSuccessClose = () => {
    this.setState({ isVendorUpdated: false, successMsg: null })
  }

  deleteDarkstore = async () => {
    const { warehouseForm } = this.state;
    this.deleteDarkstoreApiCallId = await this.darkStoreManagementApiCall({
      contentType: configJSON.deleteDarkstoreContentType,
      method: configJSON.deleteDarkstoreMethodType,
      endPoint: `${configJSON.deleteDarkstoreEndPoint}/${warehouseForm.id}`,
    });
    this.setState({ isLoading: false });
    this.handleClose();
  }

  handleClick = (event: any) => {
    this.hiddenFileInput.current?.click();
  };

  handleImageChange = (event: any) => {
    const reader: any = new FileReader();
    let image = {} as any;
    let photo = {} as any;
    image.file = event.target.files[0];
    reader.onload = () => {
      if (reader.readyState === 2) {
        photo.url = reader.result;
        image = {
          ...image,
          url: reader.result,
        };
        this.setState({ image: image,  photo });
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  openEditHandler = () => {
    if(!!this.state.warehouseForm && !!this.state.warehouseForm.attributes){
      this.setState({ ...this.state.warehouseForm.attributes, isEdit: true, open: false })
    }
  }

  handleDeleteAlert = () => {
    this.setState({ isDeletePopup: true, open: false });
  }

  onChangeHandler = (address:string) => {
    this.setState({searchKey: address})
  }

  handleCloseLocation=() => {
    this.setState({isOpenMap: false, searchKey:""});
  }

  searchFieldHandler = () => {
    this.setState({isOpenSearch: !this.state.isOpenSearch});
  }

  selectDarkstoreLocationHandler = () => {
    this.setState({isOpenSearch: false, isOpenMap: true});
  }

  // Customizable Area End
}