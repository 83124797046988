import {
  Box,
  Grid,
  withStyles,
  TableContainer,
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  FormLabel
} from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
 // Customizable Area Start
import GlobalStoreDashboardLayoutWeb from '../../../components/src/GlobalStoreDashboardLayout.web';
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import PurchaseOrderDetailsFormController, { Props } from "./PurchaseOrderDetailsFormController.web";
import { calculateTotal, formatDate } from "../../../components/src/CommonFunctions";
import PurchaseOrderUpdateStatusPopup from "./PurchaseOrderUpdateStatusPopup";
import Loader from "../../../components/src/Loader.web";
 // Customizable Area End
const styles: any = {
  textFont: {
    color: "black"
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  fontsize: {
    fontSize: "24px",
    fontFamily: "gilroy-bold",
    letterSpacing: "1.196px"
  },
  fontWeight500: {
    fontWeight: "500",
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  colorgrey: {
    color: "grey",
  },
  addressSection: {
    width: "200px",
  },
  datePickerConatiner: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom:"1rem"
  },
  inputLabel: {
    fontSize: "14px",
    fontWeight: 500,
  },
  image: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "200px",
    height: "200px",
  },
  borderNone: {
    borderBottom: "none"
  },
  address: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "12px",
    gap: "12rem"
  },
  tableCellBg: {
    backgroundColor: "grey"
  },
  styleCells: { borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" },
  styleCenter: { textAlign: "center" },
  ourAddressSection: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginRight: "20px",
    fontFamily:" gilroy-bold",
    fontSize: "18px",
  
  },
  supplierSection:{
    fontFamily:" gilroy-bold",
    fontSize: "18px",
  },
  detailsSection:{
    color: "#808080",
  fontFamily: "gilroy-medium",
  fontSize: "16px",
  letterSpacing: "0.797px",
  },
  section2Label:{
    fontFamily: "gilroy-bold",
    fontSize: "16px",
    letterSpacing: "0.797px"
  },
  section2Details:{
    fontFamily: "gilroy-medium",
    fontSize: "14px",
  },
  tableHeader:{
    background: "#F5F5FA",
    "& th":{
      color: "#8181A5",
      fontFamily: "gilroy-bold",
      fontSize: "13px",
    }
  },
  tableBody:{
  
    "& td":{
      fontFamily: "gilroy-medium",
      fontSize: "14px",
      borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center"
    }
  },
  tableDataGrey:{
    color: "#8181A5",
    fontFamily:" gilroy-regular",
    fontSize: "14px"
  }
};

const ordersTableHeadings = [
  "S NO",
  "Quantity",
  "UOM",
  "Item",
  "HSN Code",
  "Description",
  "Unit Price",
  "Line Total",
]

class PurchaseOrderDetailsForm extends PurchaseOrderDetailsFormController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, ...restProps } = this.props;
    const { userProfile } = restProps;
    const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile?.attributes?.groups?.data[0].attributes.settings : {};
    const token =localStorage.getItem("token");
    const userType = localStorage.getItem("userType");
    // Customizable Area Start
     const PoReport =()=>{
      if (this.state.isLoading) {
        return <Loader loading={true} />
      }
      return( 
      <div className="main_container">
        {!this.state.catalogueItemList.length ? (
        <NoAccessPage description="Something went wrong" />
      ) : (
      <Grid container spacing={3} className="detailsFormTest">

        <Grid item
          xs={12}
          sm={12}
          md={12}
          lg={12} style={{ textAlign: "center" }}>
          <span
            className={classes.fontsize}
          >
            Purchase Order
          </span>
          <PurchaseOrderUpdateStatusPopup
            handleOpenStatusMenu={this.handleOpenStatusMenu}
            openStatusMenu={this.state.openStatusMenu}
            openStatusPopup={this.state.openStatusPopup}
            handleCloseStatusMenu={this.handleCloseStatusMenu}
            handleOpenUpdateStatusPopup={this.handleOpenUpdateStatusPopup}
            handleCloseUpdateStatusPopup={this.handleCloseUpdateStatusPopup}
            handleEditOrder={this.handleEditOrder}
            handleCncelledOrder={this.handleCncelledOrder}
            setisCancelled={this.handleCncelledOrder}
            handleEditPurchaseOrder={this.handleEditPurchaseOrder}
            handleChange={this.handleFieldEditChange}
            catalogueData={this.state.catalogueItemList} isCancelled={this.state.isCancelled}
            supplierDetails={this.state.supplierDetails?.id}
             />
        </Grid>


        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.address}
        >
          <Box
            className="purchaseOrderCatalogueTable"
            style={{ width: "70%" }}>
            <Box className={classes.addressSection} >
              <FormLabel className={`${classes.textFont} ${classes.ourAddressSection}`} >
                Our Address
              </FormLabel><br />

              <FormLabel className={classes.detailsSection}>
                {this.state.shipmentAddress}
              </FormLabel>
            </Box>
            {this.state.supplierDetails?.id && 
<Box style={{ marginTop: "25px", display: "flex", flexDirection: "column", gap: "17px", marginBottom: "20px" }}>
              <FormLabel className={`${classes.textFont} ${classes.supplierSection}`} >Supplier</FormLabel>
              <FormLabel className={classes.detailsSection}>{this.state.supplierDetails.name}</FormLabel>
              <FormLabel className={classes.detailsSection}>
                Supplier Code: {this.state.supplierDetails.code}
              </FormLabel>
              <FormLabel className={classes.detailsSection} >
                {this.state.supplierDetails.address}
              </FormLabel>
              <FormLabel className={classes.detailsSection}>{this.state.supplierDetails.email}</FormLabel>
            </Box>}
          </Box>

          <Box className="supplierDetailsDiv" style={{ display: "flex", gap: "30px", marginLeft: "10rem" }}>
            <Grid style={{ display: "flex", flexDirection: "column", gap: "15px", width: "120px" }}>
              <FormLabel className={`${classes.textFont} ${classes.section2Label}`}>PO Number</FormLabel>{" "}
              <FormLabel className={`${classes.textFont} ${classes.section2Label}`}>Date</FormLabel>{" "}
              <FormLabel className={`${classes.textFont} ${classes.section2Label}`}>Ref#</FormLabel>{" "}
            </Grid>
            <Grid style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <FormLabel className={classes.section2Details}>{this.state.createPurchaseOrderData.attributes.po_number}</FormLabel>
              <FormLabel className={classes.section2Details}>{formatDate(this.state.createPurchaseOrderData.attributes.created_at)}</FormLabel>
              <FormLabel ></FormLabel>
            </Grid>
          </Box>

          <Box />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classes.datePickerConatiner}>
            <TableContainer className="purchaseOrderCatalogueTable" component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={`${classes.styleCells}`}>
                      Payment Terms
                    </TableCell>
                    <TableCell className={`${classes.styleCells}`}>
                      Delivery Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  <TableRow>
                    <TableCell className={`${classes.styleCells}`}>
                      {this.state.supplierDetails.payment_terms}
                    </TableCell>
                    <TableCell className={`${classes.styleCells}`}>
                      {formatDate(this.state.expectedDeliveryDate)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormLabel className={`${classes.textFont} ${classes.section2Label}`} >List of orders</FormLabel>
          <TableContainer style={{marginTop:"2rem"}}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {ordersTableHeadings.map((heading) => {
                    return <TableCell className={`${classes.styleCells}`} align="center">{heading}</TableCell>;
                  })}
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody} >
                {this.state.catalogueItemList.map((orderRow, index) => {
                  return (
                    <TableRow key={orderRow.catalogue_id}>
                      <TableCell >{index + 1}</TableCell>
                      <TableCell >{orderRow.quantity}</TableCell>
                      <TableCell >{orderRow.weight_unit}</TableCell>
                      <TableCell >{orderRow.item_code}</TableCell>
                      <TableCell className={`${classes.tableDataGrey} `}>{orderRow.hsn_code}</TableCell>
                      <TableCell >{orderRow.description}</TableCell>
                      <TableCell >₹{orderRow.unit_purchase_price}</TableCell>
                      <TableCell >₹{orderRow.total_purchase_price}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell className={`${classes.textFont} ${classes.fontWeight500} ${classes.borderNone}`}  ><FormLabel className={classes.textFont} style={{fontWeight:600}}>Total</FormLabel></TableCell>
                  <TableCell className={`${classes.textFont} ${classes.fontWeight500} ${classes.styleCenter} ${classes.styleCells}`} style={{ border: "none",backgroundColor: "#F0F0F0" }} ><FormLabel className={classes.textFont} style={{fontWeight:600,background: "#F0F0F0"}}>{calculateTotal(this.state.catalogueItemList, "quantity")}</FormLabel></TableCell>
                  <TableCell colSpan={4} style={styles.borderNone} />
                  <TableCell className={`${classes.textFont} ${classes.fontWeight500} ${classes.styleCenter} ${classes.borderNone} `} ><FormLabel className={classes.textFont} style={{fontWeight:600}}>Sub Total</FormLabel></TableCell>
                  <TableCell className={`${classes.textFont} ${classes.fontWeight500} ${classes.styleCenter}`} style={{...styles.borderNone,backgroundColor: "#F0F0F0"}}><FormLabel className={classes.textFont} style={{background: "#F0F0F0"}}>₹{calculateTotal(this.state.catalogueItemList, "total_purchase_price")}</FormLabel></TableCell>
                </TableRow>
                <TableRow style={{ border: "none" }}>
                  <TableCell colSpan={6} style={styles.borderNone} />
                  <TableCell className={`${classes.textFont} ${classes.fontWeight500} ${classes.styleCenter}`} style={styles.borderNone} ><FormLabel className={classes.textFont} style={{fontWeight:600}}>Sales Tax</FormLabel></TableCell>
                  <TableCell style={styles.borderNone}>{ }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6} style={styles.borderNone} />
                  <TableCell className={`${classes.textFont} ${classes.fontWeight500} ${classes.styleCenter}`} style={styles.borderNone}><FormLabel className={classes.textFont} style={{fontWeight:600}}>Total</FormLabel></TableCell>
                  <TableCell className={`${classes.textFont} ${classes.fontWeight500} ${classes.styleCenter}`} style={{...styles.borderNone, backgroundColor: "#F0F0F0"}}><FormLabel className={classes.textFont} style={{backgroundColor: "#F0F0F0"}}>₹{calculateTotal(this.state.catalogueItemList, "total_purchase_price")}</FormLabel></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>

        <div
          style={{
            display: "flex",
            gap: "100px",
            alignItems: "center",
          }}
        >
          <FormLabel className={`${classes.fontWeight500} ${classes.textFont}`} style={{fontFamily:"gilroy-medium", fontSize:"16px"}}>
            QR Code details
          </FormLabel>
          <img
            src={this.state.qrUrl}
            alt="QR Code details"
            className={classes.image}
          />
        </div>

      </Grid>
      )}
    </div>
      )
    }
    // Customizable Area End
    return (
      userType &&userType ==="globalStoreUser"&& token || groups?.purchase_orders? <GlobalStoreDashboardLayoutWeb classes={undefined} {...restProps} headerTitle="Purchase Orders" actionHandler={this.handleCreateOrder} headerAction="New Purchase Order" subTitle="" hasAccess={groups?.purchase_orders}>
         {!groups?.purchase_orders
          ? <NoAccessPage description="Sorry, you don't have the permission" /> : <PoReport />}
      </GlobalStoreDashboardLayoutWeb>:<PoReport />
    );
  }
}

export { PurchaseOrderDetailsForm }
export default withStyles(styles)(withRouter(PurchaseOrderDetailsForm));
