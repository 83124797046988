import React from "react";
// Customizable Area Start
import { withRouter} from "react-router-dom";
import DarkStoreLandingPageController,{ Props } from "./DarkStoreLandingPageController.web";
import DarkStoreDashboardLayout from "../../../components/src/DarkStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
class DarkStoreLandingPage extends DarkStoreLandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        return (
            <DarkStoreDashboardLayout {...this.props} headerTitle="Dashboard" headerAction="" actionHandler={undefined} subTitle="" hasAccess={true}>
                <NoAccessPage description="Sorry, you don't have the permission" />
            </DarkStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { DarkStoreLandingPage };
export default withRouter(DarkStoreLandingPage);
// Customizable Area End