import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { RouteComponentProps } from "react-router";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import * as Yup from "yup";
// Customizable Area End
export const configJSON = require("./config");
interface SS {
  id: any;
}

export enum userType {
  darkStoreUser = "darkStoreUser",
  globalStoreUser = "globalStoreUser"
}

interface S {
  bulk_order_price: number;
  bulk_order_min: number;
  name:string;
  display_location: string;
  brand_id: string;
  category_id: string;
  sub_category_id: string;
  description: string;
  sale_price: number;
  discount_price: number;
  weight: number;
  weight_unit: { label: string; value: string };
  stock_qty: number;
  alert_quantity: number;
  hsn_code: string;
  is_bulk: boolean;
  categoryDialogBox: boolean;
  error: any;
  categoriesList: any;
  subcategoriesList: any;
  sucessMessageDialogBox: boolean;
  editMode: boolean;
  brandList: any;
  imageList:any[];
  selectedCategory:any;
  selectedSubCategory:any;
  selectedSubsubCategory:any;
  removedImages:any;
  weightUnitList:{ label: string; value: string }[];
  disabledSaveBtn: boolean;
  page:number,
  totalCount:number,
}

export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  userType: any;
  userProfile:any;
  setUserProfile:any;
  setError:any;
  headerTitle:string;
  subTitle:string;
  actionHandler:any;
  headerAction:any;
  hasAccess:boolean;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}

export default class GlobalStoreInventoryAddEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addInventoryApiCallId: any;
  getCategoriesListApiCallId: any;
  getBrandlistApiCallId: any;
  deleteImageApiCallId:any;
  getWeightUnitListApiCallId:any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      weightUnitList:[],
      bulk_order_price: 0,
      bulk_order_min: 0,
      name: "",
      display_location:  "",
      brand_id:  "",
      category_id:  "",
      sub_category_id:  "",
      description:  "",
      sale_price: 0,
      discount_price: 0,
      weight: 0,
      weight_unit: { label: "", value: "" },
      stock_qty: 0,
      alert_quantity: 0,
      hsn_code:  "",
      is_bulk: false,
      selectedCategory:null,
      selectedSubCategory:null,
      selectedSubsubCategory:null,
      error: "",
      categoryDialogBox: false,
      sucessMessageDialogBox: false,
      categoriesList: [],
      subcategoriesList: [],
      editMode: false,
      brandList: [],
      imageList:[],
      removedImages:[],
      disabledSaveBtn: false,
      page:0,
      totalCount:0,     
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.getCategoriesList()
    this.getBranndList()
    this.getWeightUnitList()
  }

  addInventoryApiCall(message: any) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   
    if (!responseJson.errors && responseJson.data) {
      
      const {removedImages} = this.state;
      const editData: any = this.props.location.state;
      if(removedImages.length > 0){
        removedImages.forEach((img: any) => {
          this.deleteImageApiCallId =  this.apiCallHelper({
            contentType: configJSON.generalContentType,
            method: configJSON.deletApiMethod,
            endPoint: `${configJSON.deleteImageApiEndPoint}?id=${editData.id}&attachment_id=${img}`,
            body: null,
          });
        })    
      }
      this.showAlert("Alert", "Item added successfully");
      this.sucessMessageDialogBox();
    } else {
      this.props.setError("Something went wrong")
      this.setState({disabledSaveBtn: false})
      this.showAlert("Alert", "An error occured while adding the item");
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  removeLocalImage = (image:any) => {
    let updatedImageList =[...this.state.imageList];
    let toBeRemovedImageList = [...this.state.removedImages];
    updatedImageList = updatedImageList.filter((img:any) => {
      if(!img.file && (image.id === img.id)){
        toBeRemovedImageList.push(img.id)
      }
      return img?.id !== image?.id
    })
    this.setState({imageList: updatedImageList, removedImages:toBeRemovedImageList})
  }

  getCategoriesListApiCall(message: any) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.errors && responseJson.data) {
      this.setState({
        categoriesList:[...this.state.categoriesList,...responseJson.data],
        totalCount:responseJson.meta.total_count,
      }, () => {
        
        const editData: any = this.props.location.state;
        if (!!editData && !!editData.attributes) {
          this.prefillData(editData.attributes);
          this.setState({ editMode: true })
        }
      })
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  getBrandlistApiCall(message: any) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (!responseJson.errors && responseJson.data) {
      this.setState({
        brandList: responseJson.data,
      })
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }


  getWeightUnitListApiCall(message: any) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (!responseJson.errors && responseJson.data) {
      this.setState({
        weightUnitList: responseJson.data.map((option:any)=>{
          return {value:option.id,label:option.attributes.name}
        }),
      })
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Received", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addInventoryApiCallId != null &&
      this.addInventoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addInventoryApiCall(message)
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoriesListApiCallId != null &&
      this.getCategoriesListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     this.getCategoriesListApiCall(message);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandlistApiCallId != null &&
      this.getBrandlistApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getBrandlistApiCall(message);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getWeightUnitListApiCallId != null &&
      this.getWeightUnitListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getWeightUnitListApiCall(message);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteImageApiCallId != null &&
      this.deleteImageApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({removedImages:[]});
      this.handleSuccessImageDelete(message);
    }
  }


  handleSuccessImageDelete = (message:any) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.message) {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  prefillData(data: any) {
    this.setState(
      {
        display_location: data.display_location,
        description: data.description,
        bulk_order_price: data.bulk_order_price,
        bulk_order_min: data.bulk_order_min,
        name: data.name,
        brand_id: data.brand?.id,
        category_id: data.category?.id,
        sub_category_id: data.sub_category?.id,
        sale_price: data.sale_price,
        discount_price: data.discount_price,
        weight: data.weight,
        weight_unit: {label :data.weight_unit, value : data.weight_unit},
        stock_qty: data.stock_qty,
        alert_quantity: data.alert_quantity,
        hsn_code: data.hsn_code,
        is_bulk: data.is_bulk,
      });

      if(data.images && data.images.length > 0){
        this.setState({
          imageList: [...data.images]
        })
      }

      if(data.category){
        this.prefillCategories(data)
      }

  }

  prefillCategories = (data: any) => {
    let category;
    let subCategory;
    let subSubCategory
    const { categoriesList } = this.state;
    category = categoriesList.find((catg:any)=> catg.id == data.category.id);
    if(category?.attributes?.sub_categories.data?.length > 0){
      if(data.parent_sub_category_id){
        subCategory = category.attributes.sub_categories.data.find((catg:any)=> catg.id == data.parent_sub_category_id);
        if(subCategory?.attributes?.sub_categories?.data?.length > 0){
          subSubCategory = subCategory.attributes.sub_categories.data.find((catg:any)=> catg.id == data.sub_category.id);
        }
      }else{
        subCategory = category.attributes.sub_categories.data.find((catg:any)=> catg.id == data.sub_category.id);
        subSubCategory = category.attributes.sub_categories.data.find((catg:any)=> catg.id == data.sub_category.id);
      }
     
    }
    this.setState({
      selectedCategory: category, selectedSubCategory: subCategory, selectedSubsubCategory: subSubCategory
    })
  }

  getCategoriesList() {  
    this.setState(prevState => ({ page: prevState.page + 1 }),()=>{
      this.getCategoriesListApiCallId = this.apiCallHelper({
        contentType: configJSON.generalContentType,
        method: configJSON.getApiMethod,
        endPoint: `${configJSON.categoriesEndPoint}?page=${this.state.page}&per_page=15`,
        body: null,
      });
    })
    
  }

  handleCategorySelect = (category:any) => {
    this.setState({selectedCategory: category, selectedSubCategory: null, selectedSubsubCategory: null});
  }

  handleSubCategorySelect = (subCategory: any) => {
    this.setState({ selectedSubCategory: subCategory, selectedSubsubCategory: null});
  }

  
  handleSubsubCategorySelect = (subsubCategory: any) => {
    this.setState({ selectedSubsubCategory: subsubCategory});
  }

  apiCallHelper = (data: any) => {
    const { method, endPoint } = data;

    const header = {
      // "Content-Type": contentType,
      token: localStorage.getItem('token'),
    };

    const loginReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    loginReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    loginReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(loginReqMessage.id, loginReqMessage);

    return loginReqMessage.messageId;
  }

  toggleValue = () => {
    this.setState(prevState => ({
      categoryDialogBox: !prevState.categoryDialogBox

    }));
  }

  cancelBtn = () => {
    this.setState(prevState => ({
      categoryDialogBox: !prevState.categoryDialogBox
    }));
  }

  sucessMessageDialogBox = () => {

    this.setState(prevState => ({
      sucessMessageDialogBox: !prevState.sucessMessageDialogBox

    }));
  };
  successMessageDialogBoxOk = () => {
    this.props.history.push('/inventory');
    this.setState(prevState => ({
      sucessMessageDialogBox: !prevState.sucessMessageDialogBox

    }));
  }
  sucessMessageDialogClosedFunc = (data: any) => {
    this.props.history.push('/inventory');
    this.setState(prevState => ({
      sucessMessageDialogBox: !prevState.sucessMessageDialogBox
    }));
  };


  handleFileChange = (e: any) => {
    const reader = new FileReader();
    let image = {} as any;
    image.file = e.target.files[0];
    const {imageList} = this.state;
    if(imageList.length === 8){
      this.props.setError("Max 8 images can be added!")
    }else{
      if(image.file){
        reader.onload = () => {
          if (reader.readyState === 2) {
            image = {
              ...image,
              id:imageList.length,
              url: reader.result,
            };
          }
          
          this.setState({imageList:[...imageList, image]})
        };
        reader.readAsDataURL(image.file);
      }
    }
  }

  SubmitHandler = (values:any) => {
    let { imageList } = this.state;

    let formdata = new FormData();

      if(imageList.length > 0){
        imageList.forEach((image:any) => {
          if(image.file){
            formdata.append('images[]', image.file)
          }
        })
      }
      
      if (values.is_bulk) {
        formdata.append('bulk_order_price', values.bulk_order_price);
        formdata.append('bulk_order_min', values.bulk_order_min);
      }

      if(values.brand_id){
        formdata.append('brand_id', values.brand_id);
      }

      formdata.append('name', values.name);
      formdata.append('display_location', values.display_location);
      formdata.append('category_id', values.category_id);
      formdata.append('sub_category_id', values.sub_category_id);
      formdata.append('description', values.description);
      formdata.append('sale_price', values.sale_price);
      formdata.append('price', values.sale_price);
      formdata.append('discount_price', values.discount_price);
      formdata.append('weight', values.weight);
      formdata.append('weight_unit', values.weight_unit);
      formdata.append('stock_qty', values.stock_qty);
      formdata.append('alert_quantity', values.alert_quantity);
      formdata.append('hsn_code', values.hsn_code);
      formdata.append('is_bulk', values.is_bulk);

      this.setState({disabledSaveBtn: true})

      const editData: any = this.props.location.state;
      const token = localStorage.getItem("token");
      const header = {
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addInventoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      const endPoint = this.state.editMode ? `${configJSON.inventoryAPiEndPoint}/${editData.id}` : configJSON.inventoryAPiEndPoint;
      const requestMethod = this.state.editMode ? configJSON.putAPiMethod : configJSON.postAPiMethod;

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), requestMethod);

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  GlobalstoreInventoryAddEditFormSchema = Yup.object().shape({
    name: Yup.string()
    .nullable()
    .required("Name is required"),
    display_location: Yup.string()
    .nullable()
    .required("Display location is required"),
    category_id: Yup.string()
    .nullable()
    .required("Category Id is required"),
    brand_id: Yup.string()
    .nullable()
    .required("Brand is required"),
    sub_category_id: Yup.string()
    .nullable()
    .required("Subcategory Id is required"),
    discount_price: Yup.number()
    .typeError('Please enter a valid discounted price')
    .positive('Discounted price must be greater than zero')
    .required('Discounted price is required')
    .when('sale_price', (field1Value:any, schema:any) =>
      schema.max(field1Value, 'Discount price must be less or equal to selling price')
    ),
    sale_price: Yup.number()
    .typeError('Please enter a valid selling price')
    .positive('Selling price must be greater than zero')
    .required('Selling price is required'),
    weight_unit: Yup.string()
    .nullable()
    .required("Weight unit is required"),
    stock_qty: Yup.number()
    .typeError('Please enter a valid quantity')
    .min(0, 'Number must be greater than or equal to 0')
    .required('Quantity is required'),
    alert_quantity: Yup.number()
    .typeError('Please enter a valid alert quantity')
    .positive('Alert quantity must be greater than zero')
    .required('Alert quantity is required'),
    hsn_code: Yup.string()
    .nullable()
    .required("HSN code is required"),
    is_bulk: Yup.boolean(),
    bulk_order_price: Yup.number().nullable()
    .when("is_bulk", {
      is: true,
      then: Yup.number()
      .typeError('Please enter a valid bulk order min')
      .positive('Bulk order min must be greater than zero')
      .required('Bulk order min is required')
      }
    )
    .when('discount_price', (field1Value:any, schema:any) =>
    schema.max(field1Value, 'Bulk order price must be less or equal to selling price')
  )
    ,
    bulk_order_min: Yup.number().nullable()
    .when("is_bulk", {
      is: true,
      then: Yup.number()
      .typeError('Please enter a valid bulk order min')
      .positive('Bulk order min must be greater than zero')
      .required('Bulk order min is required')
    })
    ,
  })

  handleErrors = (error:any, touch:any) =>{
    return !!touch ? Boolean(error) : false;
  }

  handleHelperText = (text:any, touch:any) => {
    return !!touch ? text : "";
  }


  getBranndList() {
    this.getBrandlistApiCallId = this.apiCallHelper({
      contentType: configJSON.generalContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.brandEndPoint,
    });
  }

  getWeightUnitList() {
    this.getWeightUnitListApiCallId = this.apiCallHelper({
      contentType: configJSON.generalContentType,
      method: configJSON.getApiMethod,
      endPoint:configJSON.weightUnitEndPoint
    });
  }
}