import React from "react";

export const Logout = () => {
    return (
        <svg width="20.5374908px" height="20px" viewBox="0 0 20.5374908 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-5" transform="translate(-26.022003, -902.021484)">
            <g id="Iconly/Light/Logout" transform="translate(24.000000, 900.000000)">
                <path d="M0,0 L24,0 L24,24 L0,24 Z" id="Iconly/Light/Logout-(Background)"></path>
                <g id="Logout" transform="translate(2.772003, 2.771484)" stroke="#662D91" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <path d="M12.2440004,4.61800003 L12.2440004,3.68499994 C12.2440004,1.64999986 10.5940001,0 8.55900002,0 L3.68400002,0 C1.6500001,0 0,1.64999986 0,3.68499994 L0,14.8149996 C0,16.8499997 1.6500001,18.5 3.68400002,18.5 L8.56900024,18.5 C10.5980003,18.5 12.2440004,16.8550003 12.2440004,14.8260002 L12.2440004,13.8830004" id="Stroke-1"></path>
                    <line x1="19.0374908" y1="9.24987793" x2="6.99649048" y2="9.24987793" id="Stroke-3"></line>
                    <path d="M16.1091919,6.33477783 L19.0371919,9.24977779 L16.1091919,12.1657777" id="Stroke-5"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
    )
}