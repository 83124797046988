import React from "react";
// Customizable Area Start
import { makeStyles, Button, Grid, Typography } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";

const useStyles : any = makeStyles((theme) => ({
    pagination:{
        "& li":{
            margin:"0 10px"
        },
        "& .page, .page:hover":{
            borderRadius:"6px",
            width:"40px",
            height:"35px",
            border:"1px solid #652d92 ",
            color:"#652d92",
            background:"#fff",
        },
        "& .page-selected, .page-selected:hover":{
            color:"#fff !important",
            background:"#652d92 !important",
        },
    },
    pageSelected:{
        color:"#fff",
        background:"#652d92",
    },
    pageContainer:{
        display:"flex",
        justifyContent:"flex-end"
    }
}));

const CustomPagination =(props:any)=>{

    const { dataLength, currentPage, rowsCount, startIndex, endIndex } = props;
    const classes = useStyles();
    return (<>
        {dataLength > 0 ?<Grid container>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Typography>Showing {dataLength > 0 ? startIndex + 1 : 0} to {endIndex} of {dataLength} entries</Typography>
            </Grid>
            <Grid className={classes.pageContainer} item xl={8} lg={8} md={8} sm={12} xs={12}>
                <Pagination
                    className={classes.pagination}
                    page={currentPage}
                    onChange={props.handlePagination}
                    count={rowsCount}
                    renderItem={(item:any) => {
                        if(item.type === "previous"){
                            return <Button 
                            disabled={currentPage <=1} 
                            data-test-id="previousbtn"
                            onClick={() => props.handlePrevNext("previous")} className={classes.pageBtn}>Previous</Button>
                        }
                        if(item.type === "next"){
                            return <Button 
                            disabled={currentPage >= rowsCount} 
                            data-test-id="nextbtn"
                            onClick={() => props.handlePrevNext("next")} className={classes.pageBtn}>Next</Button>
                        }
                        return <PaginationItem classes={{selected:"page-selected", root:"page"}} {...item} />
                    }} 
                />
            </Grid>
        </Grid>: <></>}
        </>
    )
}

export default CustomPagination;
// Customizable Area End
