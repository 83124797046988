
import React from "react";
// Customizable Area Start
import { makeStyles , Typography } from "@material-ui/core";
import { AlertIcon } from "./assets.web";
const useStyles : any = makeStyles({
    empty:{
        display:"flex",
        flexFlow:"column",
        justifyContent:"center",
        alignItems:"center",
        height:"100%",
        width:"100%",
        background:"#fff",
        "& p":{
            fontFamily:"gilroy-bold",
            marginTop:"24px",
            width:"408px",
            display:"inline-flex",
            textAlign:"center",
            justifyContent:"center"
        }
    },
});

interface INoAccessPageProps  {
    description: React.ReactNode,
    className?: string,
    id?: string
}

const NoAccessPage = (props:INoAccessPageProps) => {
    const classes = useStyles();
    return (
        <div className={classes.empty}>
            <AlertIcon/>
        <Typography>{props.description}</Typography>
        </div>
    )
};

export default NoAccessPage;
// Customizable Area End