import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { checkIsFinite, paginate } from "../../../components/src/CommonFunctions";
import XLSX from "xlsx";
import { DashboardTableHeaders,DarkstoreTableResponse,exportDataType,DarkstoreDashboardResponseType } from "./types";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area End

export const configJSON = require("./config");
export const darkStoreTableHeaders = configJSON.DarkStoreTableHeaders;
export const darkstoreDashboardHeadersObject=configJSON.darkstoreDashboardRows;
export const noOfOrders=configJSON.noOfOrders;
export const orderCycle=configJSON.orderCyle;

interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  currentPage: number;
  linkedDarkStoreId:string;
  currentDataList: DarkstoreTableResponse[];
  dataList: DarkstoreTableResponse[];
  rowsCount: number;
  startIndex: number;
  openMenu: null | HTMLElement;
  tableHeadings: DashboardTableHeaders[];
  endIndex: number;
  dateRange: Date[];
  isAllSelected: boolean;
  visibleLabels: string[];
  totalRecords: number;
  darkstoreDashboardTableHeadings: DashboardTableHeaders[];
  filteredDataList:DarkstoreTableResponse[];
  searchQuery: string;
  noOfOrders: number,
  orderCycleTimeData :any[],
  orderCycleTimeCurrentData :any[],
  orderClycleStartIndex:number;
  orderClycleEndIndex:number  
  orderClycleCurrentPage:number
  orderClycleRowsCount:number,
  isModal:boolean
  // Customizable Area End
}
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  groups: { [key: string]: boolean };
  userType: string;
  headerTitle: string;
  headerAction: () => void;
  userProfile: {};
  setUserProfile: () => void;
  setError: any;
  hasAccess: boolean;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
};
export default class DarkStoreDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDarkstoreDashboardApiCallId:string="";
  getLinkedDarkStoreApiId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      visibleLabels: [],
      linkedDarkStoreId:"",
      filteredDataList:[],
      searchQuery: "",
      currentPage: 1,
      isAllSelected: true,
      currentDataList: [],
      dataList: [],
      openMenu: null,
      rowsCount: 0,
      startIndex: 0,
      tableHeadings: [],
      endIndex: 0,
      dateRange: [new Date(),new Date()],
      totalRecords: 0,
      darkstoreDashboardTableHeadings: [],
      noOfOrders :0,
      orderCycleTimeCurrentData :[],
      orderCycleTimeData:[],
      orderClycleStartIndex:0,
      orderClycleEndIndex:0,
      orderClycleCurrentPage:1,
      orderClycleRowsCount:0,
      isModal:false

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
    if (apiRequestCallId === this.getDarkstoreDashboardApiCallId) {
      if(responseJson.data){
      const startDate=moment(this.state.dateRange[0]).format("DD-MM-YYYY");
      const endDate=moment(this.state.dateRange[1]).format("DD-MM-YYYY")

      const finalData =responseJson && responseJson.data.map((ele:DarkstoreDashboardResponseType)=>{
        const sell_through_rate=checkIsFinite(Number(ele.outward_quantity )/Number(ele.inward_quantity));
        const revenue_per_product=checkIsFinite(Number(ele.sales_value) /Number(ele.outward_quantity))
        const no_of_units_sold=checkIsFinite(ele.outward_quantity);
        const no_of_units_received=Number(ele.inward_quantity);
        const  product_sales= startDate !== endDate
        ?"NA": Number(ele.selling_price)-Number(ele.discount_price);
        const selling_price=Number(ele.selling_price);
        const discount_price=Number(ele.discount_price);
        const sales_value=Number(ele.sales_value)
        return {
    ...ele,
    sell_through_rate,
    revenue_per_product,
    product_sales,
    sales_value,
    no_of_units_sold,
    no_of_units_received,
    selling_price,
    discount_price
        }
      });
        const rowsPerPage = configJSON.rowsPerPage;
        this.setState({
          dataList:finalData,  
          filteredDataList:finalData,
          rowsCount: Math.ceil(finalData.length / rowsPerPage),
          orderClycleRowsCount: Math.ceil(responseJson.order_cycles.length / rowsPerPage),
          totalRecords : finalData.length,
          noOfOrders:responseJson.order_count,
          orderCycleTimeData:responseJson.order_cycles
        },()=> {
          this.handleDarkStoreDashboardPagination({}, this.state.currentPage)
          this.handleDarkStoreOrderClyclePagination({},this.state.orderClycleCurrentPage)
          this.state.searchQuery && this.handleFilteredData()
        })
      }else{
        this.props.setError("Internal Server Error")
      }
      }

   if(this.getLinkedDarkStoreApiId===apiRequestCallId){
    this.setState({
      linkedDarkStoreId:responseJson&&responseJson.data.data.id
  },this.getDarkstoreDashboardData)
   }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getLinkedDarkstoreId()
   
    const checkedHeadings = darkStoreTableHeaders.map(
      (ele: { [key: string]: string | boolean }) => ({
        ...ele,
        isChecked: true,
      })
    );

    const newList = [...checkedHeadings, {...orderCycle[0], isChecked : false}, {...noOfOrders[0],isChecked:false}]

    this.setState(
      {
        tableHeadings: newList,
        visibleLabels:newList.map(
          (heading: DashboardTableHeaders) => heading.label !== "Order Cycle Time"  && heading.label !== "No Of Orders"?heading.label: "" 
        ),
        darkstoreDashboardTableHeadings: newList,
      
       
      },
      () => this.handleDarkStoreDashboardPagination({}, this.state.currentPage)
    ); 
  }

   handleOpenColumnMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
  this.setState({
  openMenu: event.currentTarget,
   });
   };

   handleClose = ()=>{
    this.setState({isModal:false})
   }


  handleDarkStoreDashboardPagination = (e: any, page: number) => {
    const {filteredDataList} = this.state;
    const { startIndex, endIndex, currentPage, currentDataList } = paginate(
      page,
      filteredDataList,
      configJSON.rowsPerPage
    );
    this.setState({
      startIndex,
      endIndex,
      currentDataList,
      currentPage,
    });
  };

  handleDarkStoreOrderClyclePagination = (e: any, page: number) => {
    const {orderCycleTimeData} = this.state;
    const { startIndex, endIndex, currentPage, currentDataList } = paginate(
      page,
      orderCycleTimeData,
      configJSON.rowsPerPage
    );
    this.setState({
      orderClycleStartIndex:startIndex,
      orderClycleEndIndex:endIndex,
      orderCycleTimeCurrentData:currentDataList,
      orderClycleCurrentPage:currentPage,
    });
  };

  handleDarkStoreDashboardPrevNext = (type: string) => {
    const { currentPage, rowsCount } = this.state;
    if (type === "previous" && currentPage > 1) {
      this.handleDarkStoreDashboardPagination({}, currentPage - 1);
    } else if (type === "next" && currentPage < rowsCount + 1) {
      this.handleDarkStoreDashboardPagination({}, currentPage + 1);
    }
  };

  handleDarkStoreOrderClyclePrevNext = (type: string) => {
    const { orderClycleCurrentPage, orderClycleRowsCount } = this.state;
    if (type === "previous" && orderClycleCurrentPage > 1) {
      this.handleDarkStoreOrderClyclePagination({}, orderClycleCurrentPage - 1);
    } else if (type === "next" && orderClycleCurrentPage < orderClycleRowsCount + 1) {
      this.handleDarkStoreOrderClyclePagination({}, orderClycleCurrentPage + 1);
    }
  };



 
  handleSelectColumnFilter = (element:DashboardTableHeaders ) => {
    const otherSelected = element.label === "Order Cycle Time" || element.label ==="No Of Orders"  ? true : false

  const updatedColumnList = this.state.tableHeadings.map(
  (heading: DashboardTableHeaders) => {

    switch(true){
      case(heading.label == element.label):{
        return { ...heading, isChecked: !heading.isChecked }
      }
      case(otherSelected):{
       return {...heading,isChecked:false}
      }
      default:{
        return  { ...heading,isChecked: heading.label === "Order Cycle Time" || heading.label ==="No Of Orders" ?false :heading.isChecked  }
      }
    }
      });

    this.setState({ tableHeadings: [...updatedColumnList],},
      () => { this.setState({isAllSelected: this.state.tableHeadings.every((ele) => ele.label === "Order Cycle Time" || ele.label ==="No Of Orders" ? true: ele.isChecked),});
      });};

      handleExportData = () => {

        const exportData:  exportDataType[] = [];
    this.state.filteredDataList.forEach((ele:DarkstoreTableResponse,index:number)=>{
        
      
      let arr:exportDataType =  [];
    for(let key in darkstoreDashboardHeadersObject){
       
      if(this.state.visibleLabels.includes( darkstoreDashboardHeadersObject[key])){
        
        arr.push(ele[key as keyof DarkstoreTableResponse])
        }}
       exportData.push([index+1,
         ...arr]);
      })
      const excelRecords = [
         [          "Sr No",
          ...this.state.visibleLabels.map(
         (heading: string) => heading
            ),
        ], ...exportData];
  
      let xcel: any = {
      SheetNames: ["Dashboard"],
      Sheets: {
         Dashboard: XLSX.utils.aoa_to_sheet(excelRecords),
         },
     Props: {
       title: "Dashboard Report",
        },};

       XLSX.writeFile(xcel, "DashboardReport.xlsx");
     };

  handleCloseColumnMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  handleDateChange = (dateArr: Date[]) => {
    const IndexOfStartDate=0;
    const IndexOfEndDate=1;
    
    const maxEndDate = new Date(dateArr[IndexOfStartDate]);
    maxEndDate.setMonth(maxEndDate.getMonth() + 1);

  if (dateArr[IndexOfEndDate] > maxEndDate) {
    this.setState({isModal:true})
    return;
  }
    this.setState({
      dateRange: dateArr,
    },()=>{
      if(dateArr[IndexOfStartDate] && dateArr[IndexOfEndDate]){

        this.getDarkstoreDashboardData()
      }
    });
  };

 

  handleSelectAllColumns = () => {
    this.setState( {
    isAllSelected: !this.state.isAllSelected,
       },() => {
       const allSelectedColumns = this.state.tableHeadings.map(
      (heading: DashboardTableHeaders) => {
        return {
           ...heading,
          isChecked:heading.label === "Order Cycle Time" || heading.label ==="No Of Orders" ? false : this.state.isAllSelected
        };
           }
         );
     this.setState({ tableHeadings: [...allSelectedColumns],
          });
      });};


  handleSearchItem = (searchQuery: string) => {
    this.setState({
      searchQuery: searchQuery,
    },this.handleFilteredData);
  };
  handleFilteredData = ()=>{
    const rowsPerPage = configJSON.rowsPerPage;
    const filteredData = this.state.dataList.filter((data:DarkstoreTableResponse)=> data.name.toLowerCase().includes(this.state.searchQuery.toLocaleLowerCase()) || data?.item_code?.includes(this.state.searchQuery) )
    this.setState({filteredDataList:filteredData, rowsCount: Math.ceil(filteredData.length / rowsPerPage),
          totalRecords : filteredData.length,},() =>
        this.handleDarkStoreDashboardPagination({}, 1)
    )
  }

  handleDataColumnsSave = () => {
    const visibleHeadersArr: string[] = [];
    this.state.tableHeadings.forEach((heading: DashboardTableHeaders) => {
    if (heading.isChecked) {
       visibleHeadersArr.push(heading.label);
    }
        });
       this.setState({
          darkstoreDashboardTableHeadings: [...this.state.tableHeadings],
          visibleLabels: visibleHeadersArr,
        openMenu: null,
       }
      );
    };
    getDarkstoreDashboardData = () => {

      const header = {
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("token"),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getDarkstoreDashboardApiCallId = requestMessage.messageId;
  const startDate=moment(this.state.dateRange[0]).format("DD-MM-YYYY");
  const endDate=moment(this.state.dateRange[1]).format("DD-MM-YYYY")

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.dasrkstoreDashboardsEndPoint}?store_id=${this.state.linkedDarkStoreId}&start_date=${startDate}&end_date=${endDate}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }; 
     
    getLinkedDarkstoreId = () => {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("token"),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getLinkedDarkStoreApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getLinkedDarkstoreEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }; 
     
  // Customizable Area End
}
