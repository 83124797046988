import React from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import {  LocationIcon, SearchIcon } from "./assets.web";
import { TextField, List, ListItem, ListItemText, IconButton, makeStyles, Typography } from "@material-ui/core";
const useStyles : any = makeStyles({
  locationLogo:{

  },
  locationDescription:{
    display:"flex",
    flexFlow:"column",
    "& h6":{
      fonyFamily:"gilroy-medium !important",
      fontSize:"16px"
    },
    "& caption":{
      fonyFamily:"gilroy-regular !important"
    }
  },
  location:{
    display:"inline-flex"
  }
})

const AddressField = (props:any) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);


  const handleSelect = async (address:string, placeID: string) => {
    const results = await geocodeByAddress(address);
    const { lat, lng } = results[0].geometry.location;
    const longitude = lng();
    const latitude = lat();
    props.selectLocationHandler(latitude, longitude);
  };

  const renderSuggesstions = (suggestions:any, getSuggestionItemProps: any, isLoading: boolean) => {
    if(isLoading){
      return <ListItem><ListItemText primary="Loading..." /></ListItem>
    }else if(error){
      return  <ListItem><ListItemText primary="Can't seem to find given location..." /></ListItem>
    }else if(suggestions.length > 0) {
      return (
        suggestions.map((suggestion: any, index:number) => {
          
          return (
            <ListItem {...getSuggestionItemProps(suggestion)} key={index}>
              <ListItemText primary={
              <div className={classes.location}>
              <div className={classes.locationLogo}>
              <IconButton><LocationIcon/></IconButton>
              </div>
              <div className={classes.locationDescription}>
                <Typography variant="h6">{suggestion.formattedSuggestion.mainText}</Typography>
                <Typography variant="caption">{suggestion.formattedSuggestion.secondaryText}</Typography>
              </div>
              </div>
              }/>
            </ListItem>
          )
        })
      )
    }
  }

    return (
      <PlacesAutocomplete
        value={props.searchKey}
        onChange={(value:string) => {
          props.onChangeHandler(value);
          setError(false);
        }}
        onSelect={handleSelect}
        onError={(status:string, clearSuggestion:() => void) => {
          if(status === "ZERO_RESULTS"){
            clearSuggestion();
            setError(true);
          }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>{
          return  (
            <div 
            >
              <TextField
                InputProps={{
                  startAdornment: <IconButton><SearchIcon/></IconButton>
                }}
              fullWidth
                {...getInputProps({
                  placeholder: "Search Places ...",
                  variant: "outlined",
                  
                })}
              />
              <div className="autocomplete-dropdown-container">
                {
                  <div className="autocomplete-dropdown-list-wrapper">
                    <List>
                      {renderSuggesstions(suggestions, getSuggestionItemProps, loading)}
                    </List>
                  </div>
                }
              </div>
            </div>
          )
        }}
      </PlacesAutocomplete>
    )
};

export default  GoogleApiWrapper({
  apiKey: "AIzaSyB4O1v9XX-9X6_-6gh-DGZ4bN0JL7_QVNU"})(AddressField);