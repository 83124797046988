import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
import LoginPageController,{ Props, userType }  from "./LoginPageController.web";
import { Grid, Button, Typography, TextField, Tab, Tabs, IconButton, FormControl ,FormLabel, Tooltip, withStyles } from "@material-ui/core";
import { eyeOpenLogo, eyeCloseLogo } from "./assets";
import CommonAuthModal from "../../../components/src/CommonAuthModal";
// Customizable Area End
// Customizable Area Start
const styles : any = {
    formControl:{
        marginBottom:"24px",
        "*":{
            fontFamily:"Plus Jakarta Sans !important"
        },
        "& label":{
            textAlign:"start",
            color: "black",
            fontWeight:"500",
            fontSize:"14px",
        },
        " & input":{
            fontFamily:"Plus Jakarta Sans",
            fontSize:"14px !important"
        },
        "& a, a:hover, a:active":{
            textDecoration: "none",
            marginRight:"0px",
            marginLeft: "auto",
            color:"rgba(0, 0, 0, 0.54)",
            "& h6":{
                paddingBottom:"0px !important",
                fontSize:"14px !important"
            }
        },
        "& p":{
            color:"red"
        }
    },
    tabs:{
        marginBottom:"24px",
        "& .MuiTabs-flexContainer":{
            width:"350px"
        }
    },
    tabSelected:{
        background:'#e8bdea !important'
    },
    tabRoot:{
        background:"#f5f5f5",
        borderRadius:"4px",
        fontSize:"16px",
        textTransform: "initial",
        fontFamily:"Plus Jakarta Sans",
    },
    submitBtnContainer:{
        "& button, button:hover, button:active":{
            background: "#652d92",
            height: "55px",
            textTransform: 'initial',
            fontSize: "16px",
            fontWeight:"bold",
            fontFamily:"Plus Jakarta Sans",
        }
    },
    emailPostfix:{
        display:"flex",
        alignItems:"center",
        
        "& h6":{
            padding:"0px !important",
            marginLeft: "16px",
            fontSize:"14px !important"
        }
    },
    passwordEyeIcon:{
        background:"#fff !important",
        "& img":{
            width:"28px"
        }
    },
    redirectLink:{
        "& a, a:hover":{
            display:"flex",
            width:"fit-content",
            margin:"auto",
            cursor:"pointer",
            textDecoration:"none !important",
            color: "black"
        },
        "& span":{
            fontWeight: "bold"
        },
        "& h6":{
            fontSize:"14px !important"
        }
    },
    validatorList:{
        padding:"0px 8px"
    },
    tooltip:{
        border:"1px solid #ccc",
        backgroundColor:"#fff",
        color:"black"
    },
    arrow:{
        "&::before": {
            border:"1px solid #ccc",
            backgroundColor: "#fff",
          },
    },
    valid:{
        color:"green"
    },
    invalid:{
        color:"red"
    }
}
// Customizable Area End


class LoginPage extends LoginPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start 
    renderGlobalStoreLoginFormEmailField = (classes:any) => {
        const { email } = this.state;
        return (
            <Grid container>
                <Grid item md={8}>
                    <TextField 
                        margin="normal"
                        value={email.value} 
                        onChange={this.emailFieldHandler}
                        helperText={email.error || "" }
                        data-test-id="txtInputEmail"
                        placeholder="Enter email"
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={4} className={classes.emailPostfix}>
                    <Typography variant="h6">@docilekart.com</Typography>
                </Grid>
            </Grid>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderDarkStoreLoginFormEmailField = () => {
        const { email } = this.state;
        return (
            <TextField 
                value={email.value}
                data-test-id="txtInputEmail"
                placeholder="Enter email"
                onChange={this.emailFieldHandler}
                helperText={email.error || "" }
                variant="outlined"
                fullWidth
                margin="normal"
        />
        )
    }
    // Customizable Area End
    
    
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { password, passwordVisibility, passwordValidations, signupType } = this.state;
        return (
            <CommonAuthModal backHandler={undefined} title="Welcome to DOCILE" description="Login to your account" haveBackButton={false}>
                <Tabs 
                    variant="fullWidth" 
                    TabIndicatorProps={{style:{display:"none"}}} 
                    className={classes.tabs}
                    value={signupType} 
                    onChange={this.handleChangeSignupType}
                >
                    <Tab value={userType.globalStoreUser} label="Docile Hub" classes={{selected: classes.tabSelected, root: classes.tabRoot}}  />
                    <Tab value={userType.darkStoreUser} label="Dark Stores" classes={{selected: classes.tabSelected, root: classes.tabRoot}}  />
                </Tabs>
            <form>
                <Grid container>
                    <Grid item  sm={12} xs={12} xl={12} lg={12} md={12}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel>Email</FormLabel>
                            {signupType === userType.globalStoreUser ? 
                                this.renderGlobalStoreLoginFormEmailField(classes) :
                                this.renderDarkStoreLoginFormEmailField()}
                        </FormControl>
                </Grid>
                <Grid item  sm={12} xs={12} xl={12} lg={12} md={12}>
                    <FormControl fullWidth className={classes.formControl}>
                        <FormLabel>Password</FormLabel>
                        <Tooltip 
                            classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.arrow
                            }}
                            title={
                                <ul className={classes.validatorList}>
                                    {passwordValidations.map((validator:any, index:any) => {
                                        return <li key={index} className={validator.valid ? classes.valid : classes.invalid}>{validator.description}</li>
                                    })}
                                </ul>
                            } 
                            placement="top-end" 
                            arrow
                            >
                            <TextField
                                value={password.value} 
                                type={passwordVisibility ? "text": "password"}
                                placeholder="Enter password"
                                data-test-id="txtInputPassword"
                                margin="normal"
                                variant="outlined"
                                onChange={this.passwordFieldHandler}
                                InputProps={{
                                    endAdornment: <IconButton data-test-id="eyeIconBtn" onClick={this.handlePasswordVisibility}  className={classes.passwordEyeIcon}><img src={passwordVisibility ? eyeOpenLogo: eyeCloseLogo} /></IconButton>
                                }}
                                helperText={password.error || "" }
                            />
                        </Tooltip>
                        
                        <Link to="/forget-password"><Typography variant="h6">Forgot Password?</Typography></Link>                              
                    </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.submitBtnContainer}>
                    <FormControl fullWidth>
                        <Button data-test-id="loginSubmitBtn" variant="contained" color="primary" onClick={this.loginHandler}>Login</Button>
                    </FormControl>
                </Grid>
            </Grid>
                
            </form>
            <div className={classes.redirectLink}>
                <Link to="/signup"><Typography variant="h6">Don't have an account? <span>Sign up</span></Typography></Link>                              
            </div>
            </CommonAuthModal>
        )
        // Customizable Area End
    }
    
}


// Customizable Area Start
export { LoginPage };
export default withStyles(styles)(withRouter(LoginPage));
// Customizable Area End