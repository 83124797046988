import React from "react";
// Customizable Area Start
import { Radio, Select, IconButton, Table, TableBody, TableHead, TableRow, TableCell, MenuItem, makeStyles, Typography } from "@material-ui/core";
import { EditIcon, KebabIcon } from "./assets.web";

const useStyles: any = makeStyles({
    table:{
        "& td":{
            fontFamily:"gilroy-medium"
        },
        "& th":{
            fontFamily:"gilroy-bold"
        },
        "& thead tr":{
            background:"#f7f7f7",
            color:"#848192", 
        },
        "& tbody tr td":{
            whiteSpace:"nowrap"
        },
        "& button":{
            height:"40px"
        }
    }
});

const TableCommonComponent = (props:any) => {
    const classes = useStyles();
    const { data, rows, cssClasses, handleNavigate } = props;
    return (
        <Table className={`${classes.table} ${cssClasses ? cssClasses: {}}`}>
            <TableHead>
                <TableRow>
                    {rows.map((row:any) => {
                        if(row.key === "select"){
                            return <TableCell key={row.key} align="center">
                                <Select IconComponent={undefined} variant="outlined" value="all-pages">
                                    <MenuItem value="all-pages">Search all in all pages</MenuItem>
                                    <MenuItem value="all">Search all in current page</MenuItem>
                                </Select>
                            </TableCell>
                        }
                        return <TableCell key={row.key} align="center">{row.label}</TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.length? data.map((item:any) => {
                  return  (<TableRow key={item.sNo || item.poNo} onClick={(e)=>handleNavigate?  handleNavigate(e,item.id) : ()=>{}} style={{cursor: handleNavigate? "pointer" : "default"}} id="navigateRow" >
                        {rows.map((row:any) => {
                             if(row.key === "select"){
                                return <TableCell key={row.key} align="center"><Radio/></TableCell>
                             }else if(["status","stockLevel"].includes(row.key)){
                                return  <TableCell className={item[row.key].replace(/ /g,"_")} align="center" key={row.key}><span style={{textTransform:"capitalize"}}>{item[row.key]}</span></TableCell>
                            }else if(row.key === "action"){
                                if(row.type === "edit"){
                                return <TableCell key={row.key} align="center">
                                        <IconButton onClick={ (item.status === "Cancelled" ||  item.status === "Inbounded" ) ? ()=>{}  : (e) => props.handleAction(e,item.id)}><EditIcon/></IconButton>
                                    </TableCell>
                                }else  if(row.type === "update"){
                                    return <TableCell key={row.key} align="center">
                                            <IconButton onClick={props.handleAction}><KebabIcon/></IconButton>
                                        </TableCell>
                                    }
                            }
                            return <TableCell key={row.key} align="center">{item[row.key]}</TableCell>
                        })}
                    </TableRow>)
                }) :<Typography >No Records found</Typography>}
            </TableBody>
        </Table>
    )
}

export default TableCommonComponent;
// Customizable Area End