import React from "react";

export const Link = () => {
    return (
        <svg width="26.802934px" height="25.323852px" viewBox="0 0 26.802934 25.323852" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square" strokeLinejoin="round">
                <g id="Forgot-password-Copy-3" transform="translate(-504.098533, -628.838074)" stroke="#000000" strokeWidth="1.5">
                    <g id="Group-11" transform="translate(497.000000, 616.000000)">
                        <g id="9035947_unlink_sharp_icon" transform="translate(20.500000, 25.500000) rotate(40.000000) translate(-20.500000, -25.500000) translate(9.000000, 20.000000)">
                            <path d="M8,11 L5.03078737,11 C2.25236023,11 0,8.53756612 0,5.5 C0,2.46243388 2.25236023,0 5.03078737,0 L7.93449496,0" id="Path"></path>
                            <path d="M15.065505,0 L17.9692126,0 C20.7476398,0 23,2.46243388 23,5.5 C23,8.53756612 20.7476398,11 17.9692126,11 L15,11" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}