import React from "react";

export const Scan = () => {
    return (
        <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-5" transform="translate(-1348.000000, -34.000000)" fill="#662D91" fillRule="nonzero">
            <g id="noun-scan-5138962" transform="translate(1348.000000, 34.000000)">
                <path d="M1.94108558,15 L5.47059611,15 C5.65978961,15 5.83455892,14.8856151 5.92909668,14.6999874 C6.02363444,14.5143598 6.02363444,14.2856072 5.92909668,14.0999829 C5.83455892,13.9143552 5.65978356,13.7999806 5.47059611,13.7999806 L1.94108558,13.7999806 C1.70722185,13.7995788 1.48303879,13.6940438 1.3176204,13.5066744 C1.15229882,13.3191713 1.05917686,13.0651054 1.05882291,12.8000748 L1.05882291,9.59995911 C1.05882291,9.38567178 0.957904983,9.18760171 0.79411718,9.08045804 C0.630329377,8.97318065 0.428490504,8.97318065 0.264705727,9.08045804 C0.100917924,9.18760171 0,9.38568206 0,9.59995911 L0,12.8000748 C0.000354524161,13.3833478 0.205027442,13.9427577 0.568890422,14.3552523 C0.932853233,14.7676097 1.42647647,14.9995886 1.94109466,15 L1.94108558,15 Z" id="Path"></path>
                <path d="M14.4705803,9 C14.3301927,9 14.1955953,9.06322629 14.0962113,9.17572457 C13.9969483,9.28836 13.9411696,9.440904 13.9411696,9.60001029 L13.9411696,12.8001017 C13.9408151,13.0651474 13.8476969,13.3192217 13.6823725,13.506696 C13.5169302,13.6940606 13.2927565,13.7995989 13.0589083,13.8 L9.52940309,13.8 C9.34020987,13.8 9.16544083,13.9143737 9.07090321,14.1 C8.9763656,14.2856263 8.9763656,14.5143771 9.07090321,14.7 C9.16544083,14.8856263 9.34021592,15 9.52940309,15 L13.0589083,15 C13.5735559,14.9995982 14.0671482,14.7676354 14.4311104,14.3552571 C14.7949517,13.9427657 14.999637,13.3833257 15,12.8000914 L15,9.6 C15,9.44089371 14.9442213,9.28834971 14.8449583,9.17571429 C14.7456953,9.063216 14.6110949,9 14.4705893,9 L14.4705803,9 Z" id="Path"></path>
                <path d="M13.0589144,0 L9.52940389,0 C9.34021039,0 9.16544108,0.114384866 9.07090332,0.300012557 C8.97636556,0.485640248 8.97636556,0.714392838 9.07090332,0.9000171 C9.16544108,1.08564479 9.34021644,1.20001937 9.52940389,1.20001937 L13.0589144,1.20001937 C13.2927781,1.20042117 13.5169612,1.30595617 13.6823796,1.49332559 C13.8477012,1.68082873 13.9408231,1.93489465 13.9411771,2.19992523 L13.9411771,5.40004089 C13.9411771,5.61432822 14.042095,5.81239829 14.2058828,5.91954196 C14.3696706,6.02681935 14.5715095,6.02681935 14.7352943,5.91954196 C14.8990821,5.81239829 15,5.61431794 15,5.40004089 L15,2.19992523 C14.9996455,1.61665224 14.7949726,1.05724229 14.4311096,0.644747739 C14.0671468,0.232390331 13.5735235,0.000411431687 13.0589053,0 L13.0589144,0 Z" id="Path"></path>
                <path d="M0.529419728,6 C0.669807332,6 0.804404697,5.93677371 0.90378869,5.82427543 C1.00305167,5.71164 1.05883038,5.559096 1.05883038,5.39998971 L1.05883038,2.19989829 C1.0591849,1.93485257 1.15230313,1.68077829 1.31762748,1.493304 C1.48306983,1.30593943 1.70724347,1.20040114 1.94109172,1.2 L5.47059691,1.2 C5.65979013,1.2 5.83455917,1.08562629 5.92909679,0.9 C6.0236344,0.714373714 6.0236344,0.485622857 5.92909679,0.3 C5.83455917,0.114373714 5.65978408,0 5.47059691,0 L1.94109172,0 C1.42644406,0.000401794286 0.932851822,0.232364571 0.568889561,0.644742857 C0.205048309,1.05723429 0.000363024447,1.61667429 0,2.19990857 L0,5.4 C0,5.55910629 0.0557787063,5.71165029 0.155041691,5.82428571 C0.254304676,5.936784 0.388905065,6 0.529410652,6 L0.529419728,6 Z" id="Path"></path>
                <path d="M12,7.5 C12,7.36741143 11.9593617,7.24017714 11.8870423,7.14642857 C11.8146348,7.05268 11.716572,7 11.6142908,7 L3.38568004,7 C3.24792674,7 3.12059861,7.09531143 3.05172196,7.25 C2.98275935,7.40468857 2.98275935,7.59531429 3.05172196,7.75 C3.12059861,7.90468857 3.24793335,8 3.38568004,8 L11.6142908,8 C11.716572,8 11.8146348,7.94732 11.8870423,7.85357143 C11.9593617,7.75982286 12,7.63258857 12,7.5 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
    )
}