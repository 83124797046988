import { Box, Button, Dialog, IconButton, DialogActions, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, withStyles, WithStyles, DialogContent, Typography } from "@material-ui/core"
import React from "react"
import { SearchIcon } from "./assets.web"
import CloseIcon from '@material-ui/icons/Close';
import AddItemDilogBoxController from "./AddItemDialogBoxController.web";
import InfiniteScroll from "react-infinite-scroll-component";

const styles: any = {
    dialogHeader: {
        display: "flex",
        justifyContent: "space-between",
        padding: "1.5rem 1rem"
    },
    dialogHeaderTitle: {
        lineHeight:"2.5",
        fontFamily:"gilroy-bold",
        fontSize:"18px",
        letterSpacing:0
    },
    conatiner: {
        // minHeight: "00px"
    },
    tableContainer: {
        marginTop: "1.4rem",
        maxHeight: "45vh",
        overflowY: "scroll",
        padding: "5px",
        '&::-webkit-scrollbar': {
            width: '8px', 
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', 
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888', 
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        }
    },
    borderNone: {
        border: 'none !important',
        cursor: "pointer",
        color: "#353535",
        fontFamily: "gilroy-medium",
        fontSize: "13px",
        fontWeight: "500",
        letterSpacing: "0.548px",
    },
    dialogAction: {
        margin: "1rem 1rem 1rem 0",
    },
    textField:{
        "& input":{
            color:" #85888B",
            fontFamily: "gilroy-regular",
            fontSize: "14px",
            fontWeight: "400"
        }
    },
    confirmBtn:{
        background:"rgba(102, 45, 145, 1)",
        height:"44px",
        fontFamily: "gilroy-medium",
        fontSize: "16px",
        width:"162px"
    }

}

class AddItemDialogBox extends AddItemDilogBoxController{
    constructor(props: any) {
        super(props)
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open} onClose={this.props.handleClose} fullWidth maxWidth="md"   >
                <div className={classes.dialogHeader} >
                    <Typography className={classes.dialogHeaderTitle} >Add Items</Typography>
                    <IconButton onClick={this.props.handleClose}><CloseIcon /></IconButton>
                </div>
                <DialogContent>
                    <Box className={classes.conatiner}>
                        <TextField
                            className={classes.textField}
                            placeholder="Search by Item ID, Item Name"
                            variant="outlined"
                            value={this.state.search}
                            onChange={this.handleSearchItem}
                            InputProps={{
                                endAdornment: <IconButton><SearchIcon /></IconButton>
                            }}
                            fullWidth
                        />
                        <TableContainer component={Paper} className={classes.tableContainer} >
                            <Table >
                                <TableBody >
                                <div style={{ height: '315px', overflow: 'auto' }} id="scrolablediv" >
                              {this.state.cataLougeList.length > 0 ?  <InfiniteScroll
                                                           dataLength={this.state.cataLougeList.length  * 0.7}  
                                                           data-test-id="scollerId"                                                         
                                                            next={()=>this.handlePagination()}
                                                            hasMore={this.state.totalCount > this.state.cataLougeList.length }
                                                             loader={<Typography align="center" variant="h6">Loading...
                                                             </Typography>}
                                                              scrollableTarget="scrolablediv"
                                                         >
                                    {
                                         this.state.cataLougeList.map(data => (
                                            <TableRow className={classes.borderNone} 
                                            onClick={()=>this.handleSelectItem(data)}
                                             style={this.state.listOfSelectedItemIds.includes(data.attributes.id ) ? { backgroundColor: "rgba(243, 243, 243, 1)" } : { backgroundColor: "" }}>
                                                <TableCell className={classes.borderNone}>Item Name : {data.attributes.name}, {data.attributes.weight}{data.attributes.weight_unit}, Item Code : {data.attributes.item_code}</TableCell>
                                            </TableRow>
                                        )) 
                                    }
                                    </InfiniteScroll> : 
                                        <TableRow style={{display:"flex",justifyContent:"center"}}>
                                            <TableCell align="center" style={{fontWeight:"bold",alignItems:"center"}}>No Data Found</TableCell>
                                        </TableRow>}
                                    </div>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button size="large" className={classes.confirmBtn} style={{ background:"rgba(102, 45, 145, 1)"}} data-test-id="" variant="contained" id="confirmButton" color="secondary" onClick={this.handleSave}>Confirm</Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export { AddItemDialogBox }
export default withStyles(styles)(AddItemDialogBox);