import React from "react";
// Customizable Area Start
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { DashboardTableHeaders,DarkstoreTableResponse } from "../../blocks/dashboard/src/types";
// import { EditIcon, KebabIcon } from "./assets.web";

const useStyles: any = makeStyles({
  tableHeadings: {
    color: "#8181A5",
    fontFamily: "gilroy-medium",
    fontSize: "13px",
  },
  tableDataStye: {
    color: " #1C1D21",
    fontFamily: "gilroy-medium",
    fontSize: "14px",
    border: "1px solid #F2F2F2",
    textAlign: "center",
  },
});
interface IProps {
  tableRowsData:DarkstoreTableResponse[] ;
  classes?: any;
  tableHeadings: DashboardTableHeaders[];
  cssClasses?: any;
  visibleLabels: string[];
  currentPage:number;
  perPageCount:number;
  noOfOrders: string | number
  orderCycleData:any[]
}

const DarkStoreDashboardTable = (props: IProps) => {
  const classes = useStyles();
  const { cssClasses, tableHeadings, visibleLabels, tableRowsData,noOfOrders,orderCycleData } = props;

  return (
    <div
      style={{
        overflowX: "scroll",
        width: "100%",
        maxWidth: "100vw",
        boxSizing: "border-box",
      }}
    >
      <Table
        style={{ flex: "1",width:visibleLabels.length < 6? "100%": "250%"}}
        className={` ${cssClasses ? cssClasses : {}}`}
      >
        <TableHead>
          <TableRow>
            {!visibleLabels.includes("No Of Orders")  && !visibleLabels.includes("Order Cycle Time")  && <TableCell
              style={{ border: "1px solid #F2F2F2" }}
              className={`${classes.tableHeadingWidth} ${
                classes.tableHeadings
              }`}
              align="center"
            >
              {"S No"}
            </TableCell>}
            {visibleLabels.includes("Order Cycle Time")  && <TableCell
              style={{ border: "1px solid #F2F2F2" }}
              className={`${classes.tableHeadingWidth} ${
                classes.tableHeadings
              }`}
              align="center"
            >
              Order ID
            </TableCell>}
            {tableHeadings.map((row: DashboardTableHeaders, index: number) => {
              return (
                row.isChecked && (
                  <TableCell
                    style={{ border: "1px solid #F2F2F2" }}
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableHeadings
                    }`}
                    key={row.label}
                    align="center"
                  >
                    {row.label}
                  </TableCell>
                )
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
        {
          visibleLabels.includes("No Of Orders") && (
            <TableRow>
              <TableCell
                className={`${classes.tableHeadingWidth} ${
                  classes.tableDataStye
                }`}
              >
                {noOfOrders}
              </TableCell>
            </TableRow>
                  )
                }
          {!visibleLabels.includes("No Of Orders")  && !visibleLabels.includes("Order Cycle Time") && tableRowsData.map((ele: any, index: number) => {
            return (
              <TableRow key={index}>
               {  <TableCell
                  className={`${classes.tableHeadingWidth} ${
                    classes.tableDataStye
                  }`}
                >
                    {index + 1 + (props.currentPage - 1) * props.perPageCount}
                </TableCell>}
                {visibleLabels.includes("Item Name") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.name}
                  </TableCell>
                )}
                {visibleLabels.includes("Item Code") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.item_code}
                  </TableCell>
                )}
             
                     
           
                     {visibleLabels.includes("No Of Units Sold") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.no_of_units_sold}
                  </TableCell>
                )}
   
        
                {visibleLabels.includes("No of Units Received") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.no_of_units_received}
                  </TableCell>
                )}
                {visibleLabels.includes("Sell-Through Rate") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.sell_through_rate}
                  </TableCell>
                )}
                {visibleLabels.includes("Selling Price") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {Number(ele.selling_price)}
                  </TableCell>
                )}
                  
             
                  {visibleLabels.includes("Discounted Price") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {Number(ele.discount_price)}
                  </TableCell>
                )}
                {visibleLabels.includes("Product Sales") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.product_sales}
                  </TableCell>
                )}


               {visibleLabels.includes("Sales Value") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.sales_value}
                  </TableCell>
                )}
                {visibleLabels.includes("Revenue Per Product") && (
                  <TableCell
                    className={`${classes.tableHeadingWidth} ${
                      classes.tableDataStye
                    }`}
                  >
                    {ele.revenue_per_product}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
          {
 
            visibleLabels.includes("Order Cycle Time") && orderCycleData.map((item:any,index:any)=>(
              <TableRow  key={index}>
                 {
                  (
                    <TableCell
                      className={`${classes.tableHeadingWidth} ${
                        classes.tableDataStye
                      }`}
                    >
                      {item.order_number}
                    </TableCell>
                  )
                }
                {
                  (
                    <TableCell
                      className={`${classes.tableHeadingWidth} ${
                        classes.tableDataStye
                      }`}
                    >
                      {item.hours_to_deliver?`${item.hours_to_deliver}hr `:"" }{ item.minutes_to_deliver? `${item.minutes_to_deliver}min`:''}
                    </TableCell>
                  )
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
};

export default DarkStoreDashboardTable;
// Customizable Area End
