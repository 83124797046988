
import React from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";
import GlobalStoreLandingPageController,{ Props } from "./GlobalStoreLandingPageController.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
class GlobalStoreLandingPage extends GlobalStoreLandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        
        return (
            <GlobalStoreDashboardLayout {...this.props} headerTitle="Dashboard" actionHandler={undefined} headerAction="" subTitle="" hasAccess={true}>
                <NoAccessPage description="Sorry, you don't have the permission" />
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { GlobalStoreLandingPage };
export default withRouter(GlobalStoreLandingPage);
// Customizable Area End