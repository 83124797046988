import React from "react";
// Customizable Area Start
import { Typography, Button, Icon, Box, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from "react-router-dom";
import { IDashboardHeaderProps } from "./types";

const useStyles = makeStyles(() => ({
    header: {
        display: "inline-flex",
        width: "100%",
        background: "#fafafa",
        padding: "12px",
        alignItems:"center",
        height:"80px"
    },
    addBtn:{
        textTransform:"initial",
        border:"1px solid #652d92",
        background:"#fff",
        height:"40px",
        "& *":{
            color:"#662D91",
            fontWeight:"bold"
        },
        "& .material-icons":{
            display:"flex",
            alignItems:"center",
            marginRight:"8px"
        }
    },
    headerContainer:{
        display:"inline-flex",
        "& span":{
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        },
        "& svg":{
            width:"12px",
            height:"12px"
        }
    },
    headerActionContainer:{
        marginRight:"0px",
        marginLeft:"auto",
        display:"flex"
    },
    select:{
        marginRight:"15px",
        "& div":{
            paddingTop:"14px",
            paddingBottom:"14px",
        },
        "& .MuiPaper-root":{
            top:"50px"
        }
    },
    link:{
        textDecoration:"none",
        paddingRight: "16px",
        paddingLeft: "16px",
        color:"black"
    },
    menuItem:{
        paddingRight: "0",
        paddingLeft: "0",
    }
}))

const DashboardHeader = (props:IDashboardHeaderProps) => {
    const classes = useStyles();
    const {headerAction, headerTitle, subTitle, actionHandler, hasAccess, isInventory} = props;
   
    return (
        <div className={classes.header}>
            <div className={classes.headerContainer}>
                <Typography>{headerTitle}</Typography>
                {subTitle
                ? <>  
                    <Icon><ArrowForwardIosIcon/></Icon>
                    <Typography>{subTitle}</Typography>
                  </> 
                : <></>
                }
            </div>
            {hasAccess && headerAction ? <Box className={classes.headerActionContainer}>
               {isInventory && hasAccess &&  <Select
                IconComponent={KeyboardArrowDownIcon}
                variant="outlined"  className={classes.select}
                inputProps={{ "aria-placeholder":"Batch Tools", placeholder:"Batch Tools",}}
                value =" "
                >
                      <MenuItem value=" " disabled>Batch Tool</MenuItem>
                      <MenuItem className={classes.menuItem} value="Mass Upload"><Link className={classes.link} to ="/inventory/mass-upload">Mass Upload</Link></MenuItem>
                      <MenuItem className={classes.menuItem} value="Mass Update"><Link className={classes.link} to ="/inventory/mass-update">Mass Update</Link></MenuItem>
                </Select>}
                <Button className={classes.addBtn} variant="outlined" onClick={actionHandler}>
                    <Icon><AddIcon/></Icon> {headerAction} 
                </Button>
            </Box> : <></>}
        </div>
    )
}

export default DashboardHeader;
// Customizable Area End