import React from "react";
export const Delete = () => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Vendor-Managemtent-wrehouse1-Copy" transform="translate(-1229.000000, -646.000000)">
                    <g id="Group-6" transform="translate(1212.000000, 598.000000)">
                        <g id="Group-4" transform="translate(15.000000, 13.000000)">
                            <g id="fluent:delete-12-regular" transform="translate(0.000000, 33.000000)">
                                <rect id="ViewBox" x="0" y="0" width="24" height="24"></rect>
                                <path d="M10,6 L14,6 C14,4.8954305 13.1045695,4 12,4 C10.8954305,4 10,4.8954305 10,6 Z M8,6 C8,3.790861 9.790861,2 12,2 C14.209139,2 16,3.790861 16,6 L21,6 C21.5522847,6 22,6.44771525 22,7 C22,7.55228475 21.5522847,8 21,8 L20.118,8 L19.232,18.34 C19.0554104,20.4100061 17.3235247,22.0002473 15.246,22 L8.754,22 C6.67647526,22.0002473 4.94458959,20.4100061 4.768,18.34 L3.882,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 L8,6 Z M15,12 C15,11.4477153 14.5522847,11 14,11 C13.4477153,11 13,11.4477153 13,12 L13,16 C13,16.5522847 13.4477153,17 14,17 C14.5522847,17 15,16.5522847 15,16 L15,12 Z M10,11 C10.5522847,11 11,11.4477153 11,12 L11,16 C11,16.5522847 10.5522847,17 10,17 C9.44771525,17 9,16.5522847 9,16 L9,12 C9,11.4477153 9.44771525,11 10,11 Z M6.76,18.17 C6.84832725,19.2053857 7.71485376,20.0006433 8.754,20 L15.246,20 C16.2843785,19.9996044 17.1497379,19.2046206 17.238,18.17 L18.11,8 L5.89,8 L6.762,18.17 L6.76,18.17 Z" id="Shape" fill="#DD403A" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}