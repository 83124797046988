Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.loginAlertMsg = "Please login first";
exports.listOfOrdersEndPoints = "order_management/orders";
exports.listOfOrdersMethod = "GET";
exports.createOrderEndPoint = "order_management/orders";
exports.createOrderItemInOrderEndPoint = "order_management/orders/:id/add_order_items";
exports.createOrderMethod = "POST";
exports.getOrderByIdEndPoint = "order_management/orders/:id";
exports.getOrderByIdMethod = "GET";
exports.deleteOrderItemEndPoint = "order_management/orders/:id/remove_order_items";
exports.deleteOrderItemMethod = "DELETE";
exports.appplyCouponCodeEndpoint = "order_management/orders/:id/apply_coupon";
exports.appplyCouponCodeMethod = "POST";
exports.getAddressListEndPoint = "order_management/addresses";
exports.getAddressListMethod = "GET";
exports.createAddressEntPoint = "order_management/addresses";
exports.createAddressMethod = "POST";
exports.addAddressToAddressEndPoint = "order_management/orders/:id/add_address_to_order";
exports.addAddressToAddressMethod = "PUT";
exports.deleteOrderEndPoint = "order_management/orders/:id";
exports.deleteOrderMethod = "DELETE";
exports.cencelOrderEndPoint = "order_management/orders/:id/cancel_order";
exports.cencelOrderMethod = "PUT";
exports.orderCreatedAlertMsg = "You order is created successfully!";
exports.orderNumberText = "Order Number";
exports.quantityText = "Quantity";
exports.orderedOnText = "Ordered On";
exports.cancelOrderText = "Cancel Order";
exports.deleteOrderText = "Delete Order";
exports.cancelledText = "Cancelled";
exports.descriptionText = "Description: ";
exports.categoryText = "Category: ";
exports.createNewOrderText = "Create New Order"
exports.createNewOrderItemText = "Create New Order Item"
exports.addNewOrderItem = "Add New Order Item";
exports.viewDetailBtn = "View Details";
exports.loadingText = "Loading...";
exports.cancelBtnLabel = "Cancel";
exports.createBtnLabel = "Create";
exports.deleteItemBtnLabel = "Delete this item";
exports.currencySymbole = "$";
exports.subTotalText = "Sub total";
exports.shippingChargeText = "Shipping Charges";
exports.totalTaxText = "Total Tax";
exports.discountText = "Discount";
exports.totalText = "Total";
exports.couponCodePlaceholder = "Please enter coupon code";
exports.applyBtnLabel = "Apply";
exports.addressLabel = "Address:";
exports.selectAddress = "Select Add";
exports.changeAddress = "Change Add";
exports.addressNameLabel = "Name";
exports.addressTypeLabel = "Address Type";
exports.flatNoLabel = "Flat no.";
exports.landmarkLabel = "Landmark";
exports.addressLabel = "Address";
exports.addressLine2Label = "Address Line 2";
exports.cityLabel = "City";
exports.stateLabel = "State";
exports.countryLabel = "Country";
exports.zipcodeLabel = "Zipcode";
exports.createNewAddressText = "Create New Address";
exports.addressTypePlaceholder = "Please enter address type*";
exports.namePlaceholder = "Please enter address name*";
exports.flatNoPlaceholder = "Please enter flat no.*";
exports.zipCodePlaceholder = "Please enter zipcode*";
exports.addressPlaceholder = "Please enter address*";
exports.addressLine2Placeholder = "Please enter address line 2";
exports.landmarkPlaceholder = "Please enter landmark";
exports.cityPlaceholder = "Please enter city";
exports.statePlaceholder = "Please enter state";
exports.countryPlaceholder = "Please enter country";
exports.phoneNoPlaceholder = "Please enter phone no*";
exports.selectThisAddressBtn = "Select this address";
exports.addressNavigationAlert = "Order ID not present";
exports.emptyOrderListMsg = "Order list is empty";
exports.catalogueIdPlaceholder = "Please enter catalogue id";
exports.catalogueVariantIdPlaceholder = "Please enter catalogue variant id";
exports.quantityPlaceholder = "Please enter quantity";
exports.createNewOrderTitle = "Create New Order";
exports.orderManagementLabel = "Order Management";
exports.routeForAddNewChallan = "/delivery_challan/create-challan"
exports.rowsPerPage = 5;
exports.rowsPerPageOrderDetails = 10;
exports.purchaseSuccessMessage = "Purchase Order Placed Successfully";
exports.purchaseEditMessage = "Purchase Order Edited Successfully"
exports.getPurchaseOrderList = "bx_block_order_management/purchase_orders"
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.searchProductAPI = "bx_block_advanced_search/search/search_product"
exports.dateFormat = "dd/MM/yyyy";
exports.createPurchaseOrderAPIURL = "bx_block_order_management/purchase_orders"
exports.createSupplierAPIURL = "account_block/suppliers"
exports.listSupplierAPIURL = "account_block/suppliers"

exports.orderDetailsRows = [
  {key:"orderId", label:"Order Id"},
  {key:"name", label:"Customer Name"},
  {key:"edt", label:"Estimated Delivery Time"},
  {key:"adt", label:"Actual Delivery Time"},
  {key:"date", label:"Order Date/Time"},
  {key:"status", label:"Status"},
];

exports.orderItemsRows = [
  {key:"sNo", label:"S NO"},
  {key:"itemName", label:"Item Name"},
  {key:"quantity", label:"Quantity"},
  {key:"price", label:"Price"},
];

exports.purchaseOrdersRows = [
  {key:"poNo", label:"PO Number"},
  {key:"supplierName", label:"Supplier Name"},
  {key:"creatorEmail", label:"Creator Email"},
  {key:"creationDate", label:"Creation Date"},
  {key:"expectedInboundDate", label:"Expected Inbound Date"},
  {key:"totalValue", label:"Total Value"},
  {key:"status", label:"Status"},
  {key:"action", label:"Action", type:"edit"}
];

exports.deliveryChallanRows = [
  {key:"sNo", label:"S No"},
  {key:"orderNumber", label:"Order ID"},
  {key:"warehouseName", label:"Warehouse Name"},
  {key:"estimatedDate", label:"Estimated Date"},
  {key:"status", label:"Status"}
];

exports.darkstoreOrdersRows = [
  {key:"sNo", label:"S No"},
  {key:"order_number", label:"Order ID"},
  {key:"amount", label:"Amount"},
  {key:"first_name", label:"Buyer Name"},
  {key:"estimated_delivery_time", label:"Estimated Delivery Time"},
  {key:"arrival_time", label:"Actual Delivery Time"},
  {key:"delivery_agent", label:"Delivery Agent"},
  {key:"cash_on_delivery", label:"COD"},
  {key:"status", label:"Status"},
  {key:"cash_collected", label:"Cash Collected"},
];

exports.darkstoreDashboardList = [
  {sNo: 1, itemCode: "DKT-FRU-213",itemName:"Mangos", 
    unitPurchageCost: 12, openingInventory: 999,openingInventoryVal:11988,
     inwards: 20, outwards: 18, closingInventory: 1001, closingInventoryVal: 12012, avgInventory: 12000},
  {sNo: 2, itemCode: "DKT-FRU-213",itemName:"Mangos", 
  unitPurchageCost: 12, openingInventory: 999,openingInventoryVal:11988,
  inwards: 20, outwards: 18, closingInventory: 1001, closingInventoryVal: 12012, avgInventory: 12000},
] 
exports.outboundOrderSuccessMsg = "New Outbound Order Created Succesfully";
exports.listDarkstoresEndPoint = "/account_block/dark_stores?per_page=5000";
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.contentTypeApplicationJson = "application/json";
exports.catalougeListEndPoint = "/bx_block_advanced_search/search/search_product?data[search_params]=";
exports.createDeliveryChallanEndPoint = "/bx_block_order_management/delivery_challans";
exports.createDeliveryChallanEndPoint = "/bx_block_order_management/delivery_challans";
exports.getDeliveryChallanListEndPoint = "/bx_block_order_management/delivery_challans";
exports.getDeliveryChallanOrderDetailsEndPoint = "/bx_block_order_management/delivery_challans";
exports.updateDeliveryChallanOrderStatusEndPoint = "/bx_block_order_management/delivery_challans";
exports.deliveryChallanRowPerPage = 5
exports.orderStatusRoute = "/delivery_challan/order-status"

exports.catalogueListSelectedItems =  {
"id": "",
"type": "purchase_order",
"attributes": {
"id": 0,
"created_at": "",
"po_number": "",
"creator_email": "",
"status": "",
"delivery_date": null,
"updated_at": "",
"catalogues": [],
"supplier": null,
"qr": {
"id": 0,
"url": ""
}
}
}
 

exports.catalogueItemList =  [{
  id: "001",

  itemCode: "001",
  productName: "apple",
  hsnCode: "124578",
  sellingPrice: "100",
  quantity: 10,
  unitPurchasePrice: 100
},
{
  id: "002",

  itemCode: "002",
  productName: "banana",
  hsnCode: "124578",
  sellingPrice: "100",
  quantity: 10,
  unitPurchasePrice: 100
},]

exports.supplierObject = {
  id: "",
  name : "" ,
  code : "",
  address : "",
  email : "",
  shipment_address : "",
  payment_terms : ""
}
exports.supplierError = {
  name : "" ,
  code : "",
  address : "",
  email : "",
  payment_terms : ""
}

exports.purchaseOrderPage = "/purchase-orders"
exports.createPurchaseOrderURL = "/create-purchase-order"
exports.editPurchaseOrderURL = "/edit-purchase-order/"
exports.viewPurchaseOrder = "/view-purchase-order/"

exports.listDarkstoreOrdersEndPoint = "account_block/dark_stores/orders";
exports.listDarkstoreOrdersContentType = "application/json";
exports.listDarkstoreOrdersMethodType = "GET";

exports.getDarkstoreOrderDetailsEndPoint = "bx_block_order_management/orders";
exports.getDarkstoreOrderDetailsContentType = "application/json";
exports.getDarkstoreOrderDetailsMethodType = "GET";

exports.putDarkstoreOrderEndPoint = "bx_block_order_management/orders/update_order_status";
exports.putDarkstoreOrderContentType = "application/json";
exports.putDarkstoreOrderMethodType = "PUT";

exports.patchDarkstoreOrderEndPoint = "bx_block_order_management/orders/update_cash_collected";
exports.patchDarkstoreOrderMethodType = "PATCH";
exports.metaDataInitial = {
  pending: 0,
  assigned: 0,
  delivered: 0,
  on_the_way: 0,
  packed: 0,
  page: 0,
  per_page: 0,
  total_count: 0,
  total_pages: 0
}
// Customizable Area End
