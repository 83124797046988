import React from 'react'
import { TextField } from "@material-ui/core"

interface ITextfieldProps{
value : string | Date | number;
onChange ?: any;
placeholder ?: string
error?:string
inputType?:string
disabled?:boolean
}

const CommonTextfield = (props:ITextfieldProps) =>{
    const {value, onChange, placeholder, error, inputType,disabled} = props;
return<>
 <TextField 
type={inputType}
value={value}
onChange={onChange}
variant="outlined"
placeholder={placeholder}
fullWidth
disabled={disabled?? false}
/>
<div style={{color:"red" , visibility: ((error && !value) ? "visible" : "hidden" )}} >{error}</div>
</>
}

export default CommonTextfield