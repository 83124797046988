import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { checkSubsringPresent, createDataForNotification } from "../../../components/src/CommonFunctions";
// Customizable Area End

export const configJSON = require("./config");
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  openQrModal:boolean
  isVideoDeviceAvailable:boolean
  invalidQRCode:boolean
  newNotificationAddedInDarkStore:boolean
  listOfDarkStoreNotification:{id:number,notification:string,date:string}[]
  isClosing: boolean
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  userType:any;
  headerTitle:any;
  headerAction:any;
  subTitle:any;
  actionHandler:any;
  hasAccess:boolean;
  userProfile:any;
  setUserProfile:any;
  setError:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}
export default class DarkStoreLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileCallId: any;
  getDarkStoreNotifiactionCallId:any;
  clearDarkStoreNotificationCallId:any;
  darkStoreNotificationTimer:number | null = null
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      anchorEl:null,
      invalidQRCode:false,
      isVideoDeviceAvailable:false,
      openQrModal:false,
      listOfDarkStoreNotification:[],
      newNotificationAddedInDarkStore:false,
      isClosing: true
      // Customizable Area End
    }

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(errorReponse){
        this.props.setError(errorReponse);
      }
      if (!responseJson) {
        return;
      }
      
      if (responseJson.status === 500) {
        this.props.setError("Internal Server Error");
        return;
      }
      if (!responseJson.data && this.getUserProfileCallId === apiRequestCallId) {
        this.props.setError("Something went wrong");
        setTimeout(() => {
          window.localStorage.removeItem("token");
          this.props.history.push("/login");
        },1000)
        return;
      }
      if (responseJson.errors && responseJson.errors.length > 0) {
        this.handleDarkstoreExpiredToken(responseJson);
      }
          switch(apiRequestCallId){
            case this.getUserProfileCallId:{
              this.props.setUserProfile(responseJson.data);
              break
            }
            case this.getDarkStoreNotifiactionCallId:{
              this.handleSuccessDarkStoreNotification(responseJson.data)
              break
            }
            case this.clearDarkStoreNotificationCallId:{
              this.setState({listOfDarkStoreNotification:[]})
              this.props.setNewNotification(false)
              this.props.setLenghtOfNotification(0)
              break
            }
            default:
              break
          }
        }
    // Customizable Area End
  }

  // Customizable Area Start

  handleSuccessDarkStoreNotification = (data:any)=>{
    const notifications = createDataForNotification(data)
    this.setState({listOfDarkStoreNotification:notifications})
    this.props.setNewNotification(this.props.lengthOfNotification !== notifications.length  && notifications.length > 0 ? true:  this.props.newNotification)
    this.props.setLenghtOfNotification(notifications.length)
  }


  handleDarkstoreExpiredToken = (responseJson: any) => {
    if(responseJson.errors[0].token){
      this.props.setError("Token has expired. Please login.")
      setTimeout(() => {
        window.localStorage.removeItem("token");
        this.props.history.push("/login");
      },1000)
    }
  }

  async componentDidMount(){
    this.detactVideoDevice()
    this.getAllDarkStoreNotofications()
    this.setIntervalForNotification()
    if(!this.props.userProfile){
      const token =localStorage.getItem("token");
      await this.fetchUserProfile(token);
    }
  }

  async componentWillUnmount() {
    this.setIntervalForNotification()
  }

  setIntervalForNotification = ()=>{
    
    if(this.darkStoreNotificationTimer){
      window.clearInterval(this.darkStoreNotificationTimer)
    }
    else{
      this.darkStoreNotificationTimer = window.setInterval(()=>{
        this.getAllDarkStoreNotofications()
      },configJSON.intervalTime)
    }
  }


  getAllDarkStoreNotofications = ()=>{
    const token =localStorage.getItem("token");
    this.getDarkStoreNotifiactionCallId = this.darkStoreProfileApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getNotificationApiMethod,
      extraHeaders: {
        token
      }
    });
  }

  clearAllDarkStoreNotification = ()=>{
    const token =localStorage.getItem("token");
    this.clearDarkStoreNotificationCallId = this.darkStoreProfileApiCall({
      contentType: configJSON.contentType,
      method: configJSON.deleteMethod,
      endPoint: configJSON.clearNotificationApiEndPoint,
      extraHeaders: {
        token
      }
    });
  }

  darkStoreProfileApiCall = (data:any) => {
    const { contentType, method, endPoint, body, extraHeaders } = data;
    const header = {
      "Content-Type": contentType,
      ...extraHeaders
    };
    const profileReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    profileReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    profileReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    profileReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

      profileReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(profileReqMessage.id, profileReqMessage);
    return profileReqMessage.messageId;
  }

  fetchUserProfile = async (token:any) => {
    this.getUserProfileCallId = this.darkStoreProfileApiCall({
      contentType: configJSON.getUserProfileApiContentType,
      method: configJSON.getUserProfileApiMethodType,
      endPoint: configJSON.getUserProfileApiEndPoint,
      extraHeaders: {
        token
      }
    });
  }

  handleNavigation = (path:any) => {
    this.props.history.push(`/${path}`);
  }

  handleLogout = () => {
    localStorage.clear();
    this.props.setUserProfile();
    this.props.setNewNotification(false)
    this.props.setLenghtOfNotification(0)
    this.props.history.push("/login")
  }


  handleOpenNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({ anchorEl: event.currentTarget },()=>{});
  };

  handleCloseNotification = () => {
      this.setState({ anchorEl: null });
      this.props.setNewNotification(false)
  };

  handleOpenQrModal = ()=>{
    if(this.state.isVideoDeviceAvailable){
      this.setState({openQrModal:!this.state.openQrModal,invalidQRCode:false})
    }else{
      this.props.setError("Camera not found")
    }
  }

  handleCloseDrawer = () => {
    this.setState({ isClosing: !this.state.isClosing })
  }

  detactVideoDevice=async()=>{
   let md = navigator.mediaDevices;
   await md.enumerateDevices().then(devices => {
      this.setState({isVideoDeviceAvailable:devices.some(device => device.kind ==="videoinput")})
    })
  }

  handleScanQR =(data:string|null)=>{
    if(data){
      if(checkSubsringPresent(data,window.location.origin)){
        const location = data.replace(window.location.origin,"")
        this.setState({invalidQRCode:false, openQrModal:false})
        this.props.history.push(location)
      }else{
        this.setState({invalidQRCode:true})
      }
    }
}
  // Customizable Area End
}
