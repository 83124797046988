import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
import NewPasswordController, { Props } from "./NewPasswordController.web";
import {
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  withStyles,
  Tooltip
} from "@material-ui/core";
import { eyeOpenLogo, eyeCloseLogo, resetPasswordLogo } from "./assets";
import CommonAuthModal from "../../../components/src/CommonAuthModal";
// Customizable Area Start

// Customizable Area Start
const styles: any = {
  formControl: {
    marginBottom: "24px",
    "& label": {
      fontSize: "14px",
      fontWeight: "500",
      textAlign: "start",
      fontFamily: "Plus Jakarta Sans !important",
      color: "black"
    },
    "& a, a:hover, a:active": {
      textDecoration: "none",
      marginRight: "0px",
      marginLeft: "auto",
      color: "black"
    },
    "& p": {
      color: "red"
    }
  },
  submitBtnContainer: {
    "& button, button:hover, button:active": {
      background: "#652d92",
      height: "55px",
      textTransform: "initial",
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "Plus Jakarta Sans"
    }
  },
  redirectLink: {
    "& a, a:hover": {
      display: "flex",
      width: "fit-content",
      // margin:"auto",
      cursor: "pointer",
      textDecoration: "none !important",
      color: "black"
    },
    "& span": {
      fontWeight: "bold"
    }
  },
  resetPasswordScreen: {
    padding: "24px 18px",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    "& h6": {
      textAlign: "center",
      marginTop: "24px",
      fontSize: "16px !important"
    },
    "& img": {
      width: "200px"
    },
    "& button, button:hover, button:active": {
      height: "55px",
      fontWeight: "bold",
      fontSize: "16px",
      background: "#652d92",
      fontFamily: "Plus Jakarta Sans",
      textTransform: "initial"
    }
  },
  passwordEyeIcon: {
    background: "#fff !important",
    "& img": {
      width: "28px"
    }
  },
  valid: {
    color: "green"
  },
  invalid: {
    color: "red"
  },
  tooltip: {
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    color: "red"
  },
  validatorList: {
    padding: "0px 8px"
  },
  arrow: {
    "&::before": {
      border: "1px solid #ccc",
      backgroundColor: "#fff"
    }
  },
  popper: {
    display: "none"
  }
};
// Customizable Area End

class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  renderResetPassword = (classes: any) => {
    return (
      <CommonAuthModal
        haveBackButton={false}
        title="Reset Password"
        description=""
        className={classes.resetPasswordScreen}
      >
        <img src={resetPasswordLogo} />
        <Typography variant="h6">
          New password has been created successfully. You can login to your
          account.{" "}
        </Typography>
        <Button
          id="nextBtn"
          variant="contained"
          color="primary"
          onClick={this.handleNavigateToLogin}
        >
          Login
        </Button>
      </CommonAuthModal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderCreateNewPassword = (classes: any) => {
    const {
      password,
      passwordVisibility,
      reEnteredPassword,
      reEnteredPassVisibility,
      passwordValidations
    } = this.state;
    return (
      <CommonAuthModal
        haveBackButton={true}
        backHandler={this.handleNavigateToLogin}
        title="Create Password"
        description="Reset your new password"
      >
        <form>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth className={classes.formControl}>
                <FormLabel>Password</FormLabel>
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow
                  }}
                  title={
                    <ul className={classes.validatorList}>
                      {passwordValidations.map((validator: any) => {
                        return (
                          <li
                            className={
                              validator.valid ? classes.valid : classes.invalid
                            }
                          >
                            {validator.description}
                          </li>
                        );
                      })}
                    </ul>
                  }
                  placement="top-end"
                  arrow
                >
                  <TextField
                    placeholder="Enter password"
                    type={passwordVisibility ? "text" : "password"}
                    margin="normal"
                    data-test-id="txtInputPassword"
                    value={password.value}
                    onChange={this.passwordFieldHandler}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          data-test-id="visibilityBtn"
                          onClick={this.handlePasswordVisibility}
                          className={classes.passwordEyeIcon}
                        >
                          <img
                            src={
                              passwordVisibility ? eyeOpenLogo : eyeCloseLogo
                            }
                          />
                        </IconButton>
                      )
                    }}
                    helperText={password.error || ""}
                  />
                </Tooltip>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FormLabel>Re-enter Password</FormLabel>
                <Tooltip
                  classes={{
                    arrow: classes.arrow,
                    tooltip: classes.tooltip,
                    popper: reEnteredPassword.valid ? classes.popper : ""
                  }}
                  title="Re-entered password doesn't match."
                  placement="top-end"
                  arrow
                >
                  <TextField
                    placeholder="Enter password"
                    margin="normal"
                    variant="outlined"
                    data-test-id="txtInputReEnterPassword"
                    type={reEnteredPassVisibility ? "text" : "password"}
                    value={reEnteredPassword.value}
                    onChange={this.reEnterPasswordFieldHandler}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                         data-test-id="visibilityBtn1"
                          onClick={this.handleReenteredPasswordVisibility}
                          className={classes.passwordEyeIcon}
                        >
                          <img
                            src={
                              reEnteredPassVisibility
                                ? eyeOpenLogo
                                : eyeCloseLogo
                            }
                          />
                        </IconButton>
                      )
                    }}
                    helperText={reEnteredPassword.error || ""}
                  />
                </Tooltip>
              </FormControl>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.submitBtnContainer}
            >
              <FormControl fullWidth>
                <Button
                  data-test-id="nextBtn"
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                >
                  Next
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
        <div className={classes.redirectLink}>
          <Link to="/login">
            <Typography variant="h6">
              Have an account? <span>Sign in</span>
            </Typography>
          </Link>
        </div>
      </CommonAuthModal>
    );
  };
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { step } = this.state;
    switch (step) {
      case 0: {
        return this.renderCreateNewPassword(classes);
      }
      case 1: {
        return this.renderResetPassword(classes);
      }

      default: {
        return this.renderCreateNewPassword(classes);
      }
    }
  }
}

// Customizable Area Start
export { NewPassword };

export default withStyles(styles)(withRouter(NewPassword));
// Customizable Area End
