
import React from "react";
// Customizable Area Start
import { TextField, withStyles, Grid, FormControl, FormLabel, Button, Checkbox, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ListItemText, ListItem, List, Typography, MenuItem } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import GlobalStoreInventoryAddEditController, { Props } from "./GlobalStoreInventoryAddEditController";
import ImageIcon from '@material-ui/icons/Image';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import styles from "./styles";
import { regexNumeric, regexNumberDecimal} from "../../../components/src/CommonFunctions";
import InfiniteScroll from 'react-infinite-scroll-component';


const componentStyles: any = {
    ...styles,
    actions: {
        display: "flex",
        padding: "24px",
        justifyContent: "start"
    },
    list: {
        height: "315px",
        overflowY: "auto"
    },
    actionBtns: {
        display: "inline-flex",
        justifyContent: "flex-end",
        "& button": {
            width: "161px",
            marginLeft: "12px"
        }
    },
    startAdornment: {
        "& p": {
            color: "black !important"
        }
    },
    listGrid: {
        height: "315px",
        overflowY: "auto"
    },
    cancelButton: {
        position: "absolute",
        top: 0,
        right: 0
    },
    imageContainer: {
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid",
        width: "136px",
        height: "110px",
        maxWidth: "106px",
        borderColor: "#dedede",
        borderRadius: '6px',
        display: "flex",
        margin:"auto 12px auto 0px"
    },
    addImgLabel: {
        display: "inline-flex",
        textAlign: "center !important"
    },
    categoriesText: {
        display: "flex",
        justifyContent: "flex-start",
        "& p":
        {
            fontSize: "16px",
            fontWeight: "600",
            display: "flex",
            alignItems: "center"
        },
        "& span": {
            fontSize: "16px",
            color: "gray",
            display: "flex",
            alignItems: "center"
        }
    }
}

class GlobalStoreInventoryAddEdit extends GlobalStoreInventoryAddEditController {

    renderDialogBox() {
        return (
            <Dialog
                open={this.state.sucessMessageDialogBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: '391px',
                        height: '345px',
                        borderRadius: '21px'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'flex-end', padding: "9px", color: "gray" }}>
                    <ClearIcon type="button" id="clearBtn" onClick={this.sucessMessageDialogClosedFunc} ></ClearIcon>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container>

                            <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CheckCircleIcon style={{ fontSize: "83px", color: "green" }}></CheckCircleIcon>
                                    <Typography style={{ fontSize: '17px', textAlign: 'center', padding: '23px 0px 18px', color: 'black', fontWeight: 'bold' }}>
                                        Product Details in Inventory {this.state.editMode ? "Changed" : "Created"} Successfully
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Grid item xs={12}>

                                        <Button fullWidth style={{ height: '58px', background: '#743a97' }} data-test-id="signupSubmitBtn" variant="contained" id="successMsgDialog" onClick={this.successMessageDialogBoxOk}>
                                            <span style={{ color: "white", fontWeight: "bold" }} >  Ok</span>
                                        </Button>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        )
    }
    renderImageList = (classes: any, imageList: any) => imageList.map((img: any) => (
    <div id="test" key={img.id} className={classes.imageContainer}>
        <CancelIcon id="remove-img-btn" className={classes.cancelButton} onClick={() => this.removeLocalImage(img)} />
        <img style={{ width: '90%', height: '90%' }} src={img.url} />
    </div>));

    renderBrandList = (brandList:any) => brandList.map((brn: any) => (<MenuItem key={brn.id} value={brn.id} style={{ textAlign: 'left' }}>
        {brn.attributes.name}
    </MenuItem>
    ))

    handleNumericDecimalOnChange = (event: React.ChangeEvent<HTMLInputElement>, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void) => {
        if(event.target.value === "" || regexNumberDecimal.test(event.target.value)){
            handleChange(event);
        }
    }

    handleNumericOnChange = (event: React.ChangeEvent<HTMLInputElement>, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void) => {
        if(event.target.value === "" || regexNumeric.test(event.target.value)){
            handleChange(event);
        }
    }

    renderCategoryList = (categoriesList:any, values:any, setFieldValue: any) => categoriesList.map((catg: any) => (<ListItem
            key={catg.id}
            selected={values.category_id == catg.id}
            button
            id="categoryButton"
            onClick={() => {
                setFieldValue("category_id", catg.id);
                setFieldValue("sub_category_id","");
                this.handleCategorySelect(catg);
            }}
        >
            <ListItemText primary={catg.attributes.name} />
        </ListItem>
    ))

    renderSubCategoryList = (selectedCategory:any, values:any,setFieldValue:any) => selectedCategory.attributes.sub_categories.data.map((subCategory: any) => (<ListItem
        selected={values.sub_category_id == subCategory.id}
        key={subCategory.id}
        button
        id="categoryButton"
        onClick={() => {
            setFieldValue("sub_category_id", subCategory.id);
            this.handleSubCategorySelect(subCategory);
        }}
    >
        <ListItemText primary={subCategory.attributes.name} />
    </ListItem>
    ))

    renderSubsubCategoryList = (selectedSubCategory:any, values:any, setFieldValue:any) => selectedSubCategory.attributes.sub_categories.data.map((subCategory: any) => ( <ListItem
        selected={values.sub_category_id == subCategory.id}
        key={subCategory.id}
        button
        id="categoryButton"
        onClick={() => {
            setFieldValue("sub_category_id", subCategory.id);
            this.handleSubsubCategorySelect(subCategory);
        }}
    >
        <ListItemText primary={subCategory.attributes.name} />
    </ListItem>
))

    render() {
        // Customizable Area Start
        const { brandList, imageList, categoryDialogBox, categoriesList, selectedCategory, selectedSubCategory
            , selectedSubsubCategory } = this.state;

        const { classes, ...restProps } = this.props;
        const initialValues = {
            bulk_order_price: this.state.bulk_order_price,
            bulk_order_min: this.state.bulk_order_min,
            name: this.state.name,
            display_location: this.state.display_location,
            brand_id: this.state.brand_id,
            category_id: this.state.category_id,
            sub_category_id: this.state.sub_category_id,
            sale_price: this.state.sale_price,
            discount_price: this.state.discount_price,
            weight: this.state.weight,
            weight_unit: this.state.weight_unit.value,
            stock_qty: this.state.stock_qty,
            alert_quantity: this.state.alert_quantity,
            hsn_code: this.state.hsn_code,
            is_bulk: this.state.is_bulk,
            description: this.state.description
        }

        const requireField = {
            color: "red",
        };

        const subCategory = (!!selectedSubsubCategory && selectedSubsubCategory.attributes.name) || (!!selectedSubCategory && selectedSubCategory.attributes.name) || "";

        return (
            <GlobalStoreDashboardLayout {...restProps} headerAction="" headerTitle="Inventory Management" actionHandler={undefined} subTitle="Add New Item" hasAccess={true}>
                <Formik
                    enableReinitialize={true}
                    validateOnBlur={true}
                    initialValues={initialValues}
                    validationSchema={this.GlobalstoreInventoryAddEditFormSchema}
                    onSubmit={(values: any) => this.SubmitHandler(values)}
                >
                    {({ errors,
                        values,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue, }) => {
                        return !!values &&  (<div className="EditForm" >
                            <div className={classes.container}>
                                <Grid container>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Product Image</FormLabel>
                                            <br />
                                            <Grid style={{ display: "flex", marginTop: '10px' }}>
                                                {imageList.length === 0
                                                    ? <div id="test" style={{ display: 'flex', alignItems: "center", justifyContent: "center", border: "solid 1px", width: "136px ", height: "110px", maxWidth: '106px', borderColor: "#dedede", borderRadius: '6px',  margin:"auto 12px auto 0px" }}
                                                    >
                                                        <ImageIcon style={{ width: '78px', maxWidth: '70%', height: '70px', maxHeight: '63%' }}
                                                        >
                                                        </ImageIcon></div>
                                                    : this.renderImageList(classes, imageList)}

                                                {imageList.length < 8 ? <div style={{
                                                    border: "2px dashed ", width: "136px ", height: "110px", borderColor: "#dedede", maxWidth: '106px', borderRadius: '6px'
                                                    , display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                                }}>
                                                    <ImageIcon></ImageIcon>
                                                    <div style={{ marginLeft: "4px" }}>
                                                        <label className={classes.addImgLabel}>
                                                            Add Image
                                                            ({imageList.length}/8)
                                                            <input
                                                                accept="image/*"
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                onChange={this.handleFileChange}
                                                            />
                                                        </label>
                                                    </div>

                                                </div> : <></>}
                                            </Grid>

                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Display Location <span style={requireField}>*</span></FormLabel>
                                            <Grid container>
                                                <Grid item md={8}>
                                                    <TextField
                                                        name="display_location"
                                                        value={values.display_location}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        data-test-id="txtInputEmail"
                                                        placeholder="Display Location"
                                                        fullWidth
                                                        variant="outlined"
                                                        error={this.handleErrors(errors.display_location, touched.display_location)}
                                                        helperText={this.handleHelperText(errors.display_location, touched.display_location)}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Product Name <span style={requireField}>*</span></FormLabel>
                                            <Grid item md={8}>
                                                <TextField
                                                    value={values.name}
                                                    name="name"
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    data-test-id="txtInputEmail"
                                                    placeholder="Product Name"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={this.handleErrors(errors.name, touched.name)}
                                                    helperText={this.handleHelperText(errors.name, touched.name)}
                                                />
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Brand <span style={requireField}>*</span></FormLabel>
                                            <Grid item md={8}>
                                                <TextField
                                                    select
                                                    value={values.brand_id}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    data-test-id="txtInputEmail"
                                                    fullWidth
                                                    name="brand_id"
                                                    variant="outlined"
                                                    error={this.handleErrors(errors.brand_id, touched.brand_id)}
                                                    helperText={this.handleHelperText(errors.brand_id, touched.brand_id)}
                                                >
                                                    {this.renderBrandList(brandList)}
                                                </TextField>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Categories <span style={requireField}>*</span> </FormLabel>
                                            <Grid item md={8}>
                                                <TextField
                                                    name="category_id"
                                                    value={selectedCategory?.attributes?.name }
                                                    margin="normal"
                                                    data-test-id="txtInputEmail"
                                                    placeholder="Categories"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={this.handleErrors(errors.category_id, touched.category_id)}
                                                    helperText={this.handleHelperText(errors.category_id, touched.category_id)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" style={{ display: 'flex', flexDirection: 'column', marginBottom: '53px' }}>
                                                                <IconButton data-test-id="toggleButton" edge="end" type="button" onClick={this.toggleValue} style={{ padding: '0px', margin: '10px' }}>
                                                                    <CreateIcon />
                                                                    {/* <Button variant="outlined" >
                                                                    </Button> */}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </FormControl>
                                    </Grid>

                                    <Dialog
                                        open={categoryDialogBox}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        PaperProps={{
                                            style: {
                                                width: '800px',
                                                height: '500px',
                                                maxWidth: '1000px',
                                                borderRadius: "14px",
                                            }
                                        }}
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Edit Categories
                                        </DialogTitle>

                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <Grid container>
                                                    <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                   <div style={{ height: '315px', overflow: 'auto' }} id="scrolablediv" >
                                                          <InfiniteScroll
                                                           dataLength={categoriesList.length  * 0.7}  
                                                           data-test-id="scollerId"                                                         
                                                            next={()=>this.getCategoriesList()}
                                                            hasMore={this.state.totalCount > categoriesList.length }
                                                             loader={<Typography align="center" variant="h6">Loading...
                                                             </Typography>}
                                                              scrollableTarget="scrolablediv"
                                                         >
                                                        {categoriesList.length > 0 && <List>
                                                            { this.renderCategoryList(categoriesList, values, setFieldValue)}
                                                        </List>}
                                                        </InfiniteScroll>
                                                    </div>
                                                    </Grid>
                                                    {selectedCategory && <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                        <List className={classes.listGrid}>
                                                            {selectedCategory?.attributes?.sub_categories?.data?.length > 0
                                                                && this.renderSubCategoryList(selectedCategory,values, setFieldValue)}
                                                        </List>
                                                    </Grid>}
                                                    {selectedSubCategory && <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                        <List className={classes.listGrid}>
                                                            {selectedSubCategory.attributes.sub_categories?.data?.length > 0
                                                                && this.renderSubsubCategoryList(selectedSubCategory, values, setFieldValue)}
                                                        </List>
                                                    </Grid>}
                                                </Grid>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions className={classes.actions}>
                                            <Grid container>
                                                <Grid xs={7} sm={7} lg={7} md={7} xl={7} item className={classes.categoriesText} >
                                                    <Typography > Currently Selected: &nbsp; </Typography>
                                                    <span>  {selectedCategory?.attributes?.name} &nbsp; </span>
                                                    {(selectedSubCategory || selectedSubsubCategory) && <span> {">"} </span>}
                                                    <span>  {subCategory}  </span>
                                                </Grid>
                                                <Grid item xs={5} sm={5} lg={5} md={5} xl={5} className={classes.actionBtns}>
                                                    <Button onClick={this.cancelBtn} id="cancelButton" style={{ height: '44px' }} variant="contained" color="default">Cancel</Button>
                                                    <Button onClick={this.cancelBtn} id="nextButton" color="secondary" style={{ height: '44px', background: '#4a126b' }} variant="contained">Next</Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Dialog>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Sub Categories <span style={requireField}>*</span> </FormLabel>
                                            <Grid item md={8}>
                                                <TextField
                                                    name="sub_category_id"
                                                    value={subCategory}
                                                    margin="normal"
                                                    data-test-id="txtInputEmail"
                                                    placeholder="Sub Categories"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={this.handleErrors(errors.sub_category_id, touched.sub_category_id)}
                                                    helperText={this.handleHelperText(errors.sub_category_id, touched.sub_category_id)}
                                                />
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Description</FormLabel>
                                            <Grid item md={8}>
                                                <TextField
                                                    multiline
                                                    maxRows={6}                                                    
                                                    minRows={6}
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    data-test-id="txtInputEmail"
                                                    placeholder="Type something..."
                                                    fullWidth
                                                    variant="outlined"
                                                />

                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            {/* Sales Information */}
                            <div className={classes.container}>

                                <Grid container>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>Sales Information </FormLabel>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <Grid container spacing={1}>
                                            <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                <FormControl fullWidth className={classes.formControl}>
                                                    <FormLabel>Selling Price <span style={requireField}>*</span></FormLabel>
                                                    <TextField
                                                        value={values.sale_price}
                                                        name="sale_price"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNumericDecimalOnChange(event, handleChange)}
                                                        margin="normal"
                                                        data-test-id="SellingPrice"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputProps={{ startAdornment: <InputAdornment className={classes.startAdornment} position="start">₹</InputAdornment> }}
                                                        error={this.handleErrors(errors.sale_price, touched.sale_price)}
                                                        helperText={this.handleHelperText(errors.sale_price, touched.sale_price)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                <FormControl fullWidth className={classes.formControl}>
                                                    <FormLabel>Discounted Price  <span style={requireField}>*</span></FormLabel>
                                                    <TextField
                                                        value={values.discount_price}
                                                        name="discount_price"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNumericDecimalOnChange(event, handleChange)}
                                                        margin="normal"
                                                        data-test-id="txtInputEmail"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputProps={{ startAdornment: <InputAdornment className={classes.startAdornment} position="start">₹</InputAdornment> }}
                                                        error={this.handleErrors(errors.discount_price, touched.discount_price)}
                                                        helperText={this.handleHelperText(errors.discount_price, touched.discount_price)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <Grid container spacing={1}>
                                            <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                <FormControl fullWidth className={classes.formControl}>
                                                    <FormLabel>Net Weight</FormLabel>
                                                    <TextField
                                                        value={values.weight}
                                                        name="weight"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNumericDecimalOnChange(event, handleChange)}
                                                        margin="normal"
                                                        data-test-id="txtInputEmail"
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                <FormControl fullWidth className={classes.formControl}>
                                                    <FormLabel>Unit <span style={requireField}>*</span> </FormLabel>
                                                    <TextField
select
value={values.weight_unit}
name="weight_unit"
onChange={handleChange}
margin="normal"
data-test-id="txtInputEmail"
placeholder="grams"
fullWidth
variant="outlined"
error={this.handleErrors(
errors.weight_unit,
touched.weight_unit
)}
helperText={this.handleHelperText(
errors.weight_unit,
touched.weight_unit
)}
>
{this.state.weightUnitList.map((option) => (
<MenuItem key={option.label} value={option.label}>
{option.label}
</MenuItem>
))}
</TextField>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <Grid container spacing={1}>
                                            <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                <FormControl fullWidth className={classes.formControl}>
                                                    <FormLabel>Quantity <span style={requireField}>*</span> </FormLabel>
                                                    <TextField
                                                        value={values.stock_qty}
                                                        name="stock_qty"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNumericOnChange(event, handleChange)}
                                                        margin="normal"
                                                        data-test-id="txtInputEmail"
                                                        fullWidth
                                                        type="number"
                                                        variant="outlined"
                                                        error={this.handleErrors(errors.stock_qty, touched.stock_qty)}
                                                        helperText={this.handleHelperText(errors.stock_qty, touched.stock_qty)}
                                                    />

                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                <FormControl fullWidth className={classes.formControl}>
                                                    <FormLabel>Inventory Alert <span style={requireField}>*</span> </FormLabel>
                                                    <TextField
                                                        name="alert_quantity"
                                                        value={values.alert_quantity}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNumericOnChange(event, handleChange)}
                                                        margin="normal"
                                                        data-test-id="txtInputEmail"
                                                        fullWidth
                                                        variant="outlined"
                                                        error={this.handleErrors(errors.alert_quantity, touched.alert_quantity)}
                                                        helperText={this.handleHelperText(errors.alert_quantity, touched.alert_quantity)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>HSN Code <span style={requireField}>*</span></FormLabel>
                                            <Grid item md={8}>
                                                <TextField
                                                    value={values.hsn_code}
                                                    name="hsn_code"
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    data-test-id="txtInputEmail"
                                                    placeholder="Ex Toor dal,500g"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={this.handleErrors(errors.hsn_code, touched.hsn_code)}
                                                    helperText={this.handleHelperText(errors.hsn_code, touched.hsn_code)}
                                                />
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <Grid container spacing={1}>
                                            {values.is_bulk && (
                                                <>
                                                    <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                        <FormControl fullWidth className={classes.formControl}>
                                                            <FormLabel>Bulk Order Price <span style={requireField}>*</span> </FormLabel>
                                                            <TextField
                                                                value={values.bulk_order_price}
                                                                name="bulk_order_price"
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNumericDecimalOnChange(event, handleChange)}
                                                                margin="normal"
                                                                data-test-id="txtInputEmail"
                                                                fullWidth
                                                                variant="outlined"
                                                                InputProps={{ startAdornment: <InputAdornment className={classes.startAdornment} position="start">₹</InputAdornment> }}
                                                                error={this.handleErrors(errors.bulk_order_price, touched.bulk_order_price)}
                                                                helperText={this.handleHelperText(errors.bulk_order_price, touched.bulk_order_price)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={4} xs={4} xl={4} lg={4} md={4}>
                                                        <FormControl fullWidth className={classes.formControl}>
                                                            <FormLabel>Minimum Purchase Quantity <span style={requireField}>*</span> </FormLabel>
                                                            <TextField
                                                                value={values.bulk_order_min}
                                                                name="bulk_order_min"
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNumericOnChange(event, handleChange)}
                                                                margin="normal"
                                                                data-test-id="txtInputEmail"
                                                                fullWidth
                                                                variant="outlined"
                                                                error={this.handleErrors(errors.bulk_order_min, touched.bulk_order_min)}
                                                                helperText={this.handleHelperText(errors.bulk_order_min, touched.bulk_order_min)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <FormLabel>

                                                <Checkbox
                                                    onClick={() => setFieldValue("is_bulk", !values.is_bulk)} className="bulkOrderCheckbox" data-test-id="bulkCheck" checked={values.is_bulk} color="default" />
                                                Bulk Order </FormLabel>
                                        </FormControl>
                                    </Grid>
                                    <Grid container >
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.submitBtnContainer}>
                                            <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                <Grid item xs={2}>
                                                    <Button fullWidth style={{ height: '44px' }} data-test-id="cancleBtn" variant="contained" color="default" id="cancelButton" onClick={() => this.props.history.push('/inventory')}>Cancel</Button>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button fullWidth style={{ height: '44px', background: '#4a126b' }} data-test-id="signupSubmitBtn" variant="contained" id="saveButton" color="secondary" disabled={this.state.disabledSaveBtn}
                                                        onClick={() => handleSubmit()}
                                                    >Save</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </div>
                        </div>)
                    }}

                </Formik>
                {this.renderDialogBox()}
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { GlobalStoreInventoryAddEdit };
export default withStyles(componentStyles)(withRouter(GlobalStoreInventoryAddEdit));
// Customizable Area End