import React, { PureComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { withState } from './AppContext.web';

export type Props = RouteProps & { userType:any };

interface S {
  isLoggedInUser: any;
  loading: any;
}

class PrivateRoute extends PureComponent<Props, S> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoggedInUser: false,
      loading: true
    };
  }

  componentDidMount = () => {
    let isLoggedInUser = localStorage.getItem('token');
    if (isLoggedInUser) {
      this.setState({ isLoggedInUser: isLoggedInUser, loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  renderPublicRoute = () => {
    const { location, userType } = this.props;
    const { loading, isLoggedInUser } = this.state;

    if(!loading){
      if(!isLoggedInUser){
        return <Redirect to={{ pathname: '/login' }} />;
      }else {
        if (!!location && !!location.pathname && ["/","/login","/singup", "/forget-password", "/create-new-password"].includes(location.pathname)){
          if(userType === "globalStoreUser" ){
            return <Redirect to={{ pathname: "/dark_store_management" }}  />
          }else{
            return <Redirect to={{ pathname: "/orders" }}  />
          }
        }else{
          return  <Route {...this.props} />
        }
      }
    }

    return <></>
  }

  render() {
    return this.renderPublicRoute();
  }
}

export default withState(PrivateRoute);