import React from "react";
// Customizable Area Start
import { Button,Paper, Drawer, AppBar, Toolbar, List, Typography, IconButton, CssBaseline, ClickAwayListener, Hidden} from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MenuIcon from '@material-ui/icons/Menu';
const AppLogoIcon = require("../src/assets/images/Docile.jpeg");
import { makeStyles} from '@material-ui/core/styles';
import {LogoutIcon, NotificationIcon, ScanIcon} from "./assets.web";
import AppNotification from "./AppNotification";
// Customizable Area End

// Customizable Area Start
const drawerWidth = 300;

const useStyles : any = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex :1
    },
    drawerContainer: {
      overflow: 'auto',
      height:"100%",
      "& ul":{
        display:"flex",
        flexFlow:"column",
        height:"100%",
        paddingTop:"40px",
        paddingRight:"24px"
      }
    },
    mainLogo:{
      width:"50%",
      "& img":{
          height:"40px",
          width:"45px",
          margin:"10px auto 10px 100px"
      }
    },
    appBar:{
      background: "#fff",
      height:"60px",
      display:"flex",
      flexFlow:"row",
      boxShadow:"none",
    },
    headerActions:{
        width:"50%",
        display:"flex",
        alignItems:"center",
        justifyContent:"end",
        marginRight:"1rem",
        "& button":{
            height:"36px",
            minWidth:"36px",
            marginLeft:"12px",
            background:"#F7F7FC"
        }
    },
    logoutBtn:{
        margin:"8px 0",
        padding:"8px",
          "& h6":{
              fontFamily:"gilroy-medium",
              fontSize: "1rem",
              textTransform:"initial",
              marginLeft:"12px"
          }
    },
    profileOptions:{
      marginBottom:"0px",
      marginTop:"auto",
      width:"100%",
     
    },
    profile:{
      display:"inline-flex",
      alignItems:"center"
    },
    profileBtn:{
      width:"40px",
      height:"40px",
      border:"1px solid #652d92",
      fontSize:"16px",
      color:"#652d92",
      margin:"0 12px"
    },
    arrowBtn:{
      fontSize:"16px",
      color:"#652d92",
      marginLeft:"10px",
      
      "& svg":{
        width:"32px",
        height:"32px",
      }
    },
    leftarrowBtn:{
      fontSize:"16px",
      color:"#652d92",
      position:"absolute",
      right:"0px",
      top:"0px",
      
      "& svg":{
        width:"32px",
        height:"32px",
      }
    },
    menuBtn:{
      fontSize:"50px",
      color:"#652d92",
      marginTop:"64px",
      
      "& svg":{
        width:"32px",
        height:"32px",
      }
    },
    profileName:{
      "& h6":{
        fontSize:"12px",
        fontFamily:"gilroy-regular"
      },
      "& h5":{
        fontSize:"14px",
        fontFamily:"gilroy-medium"
      }
    },
    listSelected:{
      borderTopRightRadius:"6px",
      borderBottomRightRadius:"6px",
      borderLeft:"4px solid #652d92"
    },
    notification:{
      backgroundColor:"red",
      padding:"4px",
      position:"absolute",
      top:"6px",
      right:"10px",
      borderRadius:"100%"
    }
  }));

interface INotification  {id:number,notification:string,date:string}

interface ICommonDashboardProps {
  anchorEl: HTMLElement | null,
  handleOpenNotification: (event: React.MouseEvent<HTMLButtonElement>) => void,
  handleCloseNotification:  () => void,
  handleOpenQrModal: () => void,
  newNotificationAdded: any,
  notificationList: INotification[],
  email:string | null,
  name: string | null,
  logout: () => void,
  children: React.ReactNode,
  userType: string | null
  clearNotification:()=>void,
  isSidebarClosing:boolean,
  handleCloseAction:()=>void,
}
  
const CommonDashboard = (props: ICommonDashboardProps) => {
  const classes = useStyles();
  const { anchorEl, handleOpenNotification, handleCloseNotification, handleOpenQrModal, newNotificationAdded, notificationList, clearNotification, handleCloseAction, isSidebarClosing } = props

  const handleSidebarCloseAction = () => {
    handleCloseAction()
  }

  const renderMenuItems = () => {
    return (
      <>
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <div onClick={handleSidebarCloseAction}>
              <Hidden lgUp mdUp implementation="css">
                <IconButton className={classes.leftarrowBtn}><KeyboardArrowLeftIcon /></IconButton>
              </Hidden>
              {props.children}
            </div>
            <div className={classes.profileOptions}>
              <Paper className={classes.logoutBtn}>
                <Button onClick={props.logout}>
                  <LogoutIcon />
                  <Typography variant="h6">Log out</Typography>
                </Button>
              </Paper>
              <div className={classes.profile}>
                <IconButton className={classes.profileBtn}>
                  {props.email ? props.email.charAt(0).toUpperCase() : ""}
                </IconButton>
                <div className={classes.profileName}>
                  <Typography variant="h5">
                    {props.name}
                  </Typography>
                  <Typography variant="h6">
                    {props.email}
                  </Typography>
                </div>
                <IconButton className={classes.arrowBtn}><KeyboardArrowRightIcon /></IconButton>
              </div>
            </div>
          </List>
        </div>
      </>
    )
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.mainLogo}>
          <img src={AppLogoIcon} />
        </div>
        <div className={classes.headerActions}>
          <Button onClick={handleOpenQrModal}> <ScanIcon /> </Button>
          <Button onClick={handleOpenNotification} aria-describedby="app-notification" aria-controls="app-notification" style={{ position: "relative" }} ><NotificationIcon />
            {newNotificationAdded && <div className={classes.notification}></div>}
          </Button>
          <AppNotification clearNotification={clearNotification} anchorEl={anchorEl} handleClick={handleOpenNotification} handleClose={handleCloseNotification} notificationList={notificationList} />
        </div>
      </AppBar>
      <div>
        <IconButton className={classes.menuBtn} onClick={handleSidebarCloseAction}><MenuIcon /></IconButton>
      </div>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {renderMenuItems()}
        </Drawer>
      </Hidden>
      <Hidden lgUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{
            display: isSidebarClosing ? 'none' : 'contents'
          }}
        >
          {renderMenuItems()}
        </Drawer>
      </Hidden>
    </>
  );
}
export default CommonDashboard;
// Customizable Area End