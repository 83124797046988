import React from "react";
export const Close = () => {
    return (
        <svg width="10.509626px" height="10.509626px" viewBox="0 0 10.509626 10.509626" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Vendor-Managemtent-wrehouse1-Copy-61" transform="translate(-873.000000, -373.000000)" fill="#A6A6A6" fillRule="nonzero">
                <g id="Group-7" transform="translate(533.000000, 349.000000)">
                    <g id="Group" transform="translate(340.000000, 24.000000)">
                        <path d="M6.66931299,5.25531299 L10.204313,1.71931299 C10.4642696,1.46823852 10.5685256,1.0964351 10.4770101,0.746805669 C10.3854947,0.397176238 10.1124497,0.124131277 9.76282032,0.0326158368 C9.41319089,-0.0588996039 9.04138746,0.0453564089 8.79031299,0.305312993 L5.25431299,3.84031299 L1.71931299,0.305312993 C1.46823852,0.0453564089 1.0964351,-0.0588996039 0.746805669,0.0326158368 C0.397176238,0.124131277 0.124131277,0.397176238 0.0326158368,0.746805669 C-0.0588996039,1.0964351 0.0453564089,1.46823852 0.305312993,1.71931299 L3.84031299,5.25431299 L0.305312993,8.79031299 C0.0453564089,9.04138746 -0.0588996039,9.41319089 0.0326158368,9.76282032 C0.124131277,10.1124497 0.397176238,10.3854947 0.746805669,10.4770101 C1.0964351,10.5685256 1.46823852,10.4642696 1.71931299,10.204313 L5.25431299,6.66931299 L8.79031299,10.204313 C9.04138746,10.4642696 9.41319089,10.5685256 9.76282032,10.4770101 C10.1124497,10.3854947 10.3854947,10.1124497 10.4770101,9.76282032 C10.5685256,9.41319089 10.4642696,9.04138746 10.204313,8.79031299 L6.66931299,5.25431299 L6.66931299,5.25531299 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}