import React from "react";

export const Kebab = () => {
    return (
        <svg width="18.75px" height="4.25px" viewBox="0 0 18.75 4.25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-2" transform="translate(-992.875000, -731.875000)">
            <g id="icon/kebab-copy" transform="translate(990.000000, 722.000000)">
                <g id="charm:menu-kebab" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) ">
                    <rect id="ViewBox" x="0" y="0" width="24" height="24"></rect>
                    <g id="Group" strokeLinecap="round" strokeLinejoin="round" transform="translate(11.000000, 4.000000)" fill="#01040D" stroke="#01040D" strokeWidth="2.25">
                        <circle id="Oval" cx="1" cy="1" r="1"></circle>
                        <circle id="Oval" cx="1" cy="8.25" r="1"></circle>
                        <circle id="Oval" cx="1" cy="15.5" r="1"></circle>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>)
}