import {
  Button,
  Dialog,
  IconButton,
  DialogActions,
  withStyles,
  DialogContent,
  DialogTitle,
  Menu,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TableContainer,
  Table,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ICatalogueItemInterface } from "./types";

interface IProps {
  openStatusMenu: null | HTMLElement;

  catalogueData: any[];
  handleChange: (id: number, value: number | string) => void;
  handleEditPurchaseOrder: (isQuantityEdit: boolean) => void;
  setisCancelled: (cancelled: boolean) => void;
  handleOpenStatusMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseStatusMenu: () => void;
  handleOpenUpdateStatusPopup: () => void;
  handleCloseUpdateStatusPopup: () => void;
  handleEditOrder: () => void;
  handleCncelledOrder: () => void;
  openStatusPopup: boolean;
  isCancelled : boolean
  supplierDetails? :  string
}

interface IState {
  openStatusMenu: null | HTMLElement;
  openStatusPopup: boolean;
}

const styles: any = {
  styleCells: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
  },

  dialogHeader: {
    float: "right",
  },
  requiredFields: {
    color: "red",
  },
  textFont: {
    color: "black",
  },
  margin: {
    margin: "10px 0px",
  },
  borderNone: {
    borderBottom: "none",
  },
  styleCenter: { textAlign: "center" },
  // popUpWidth:{width:"60px"}
};

class PurchaseOrderUpdateStatusPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
  // Customizable Area Start
    const statusDisabled = this.props.catalogueData.reduce(
      (acc, curr) =>
        acc + (curr.verified_quantity ? Number(curr.verified_quantity) : 0),
      0
    );
    const getHeight = -(window.innerHeight * 0.2 / 15)+"%";
    return (
      <>
      <div> 
        <Button
          disabled={statusDisabled || !this.props.supplierDetails  || this.props.isCancelled}
          aria-controls="simple-menu"
          style={{ float: "right" }}
          onClick={this.props.handleOpenStatusMenu}
        >
          Update Status
        </Button>
        <Menu
          className="purchaseOrderUpdateStatusPopUp"
          id="simple-menu"
          keepMounted
          open={Boolean(this.props.openStatusMenu)}
          onClose={this.props.handleCloseStatusMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          style={{top: getHeight}}
        >
          <MenuItem onClick={this.props.handleOpenUpdateStatusPopup}>
            Inbounded
          </MenuItem>
          <MenuItem onClick={this.props.handleCncelledOrder}>
            Cancelled
          </MenuItem>
        </Menu>

        </div>
        <Dialog
          style={{ padding: "10px 10px" }}
          open={this.props.openStatusPopup}
          onClose={this.props.handleCloseUpdateStatusPopup}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Verify Quantity</span>
              <span>
                <IconButton onClick={this.props.handleCloseUpdateStatusPopup}>
                  <CloseIcon />
                </IconButton>
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item Id</TableCell>
                    <TableCell>Item name</TableCell>{" "}
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.props.catalogueData.map((catalogue: ICatalogueItemInterface) => {
                    return (
                      <TableRow key={catalogue.catalogue_id}>
                        <TableCell style={styles.styleCells}>
                          {catalogue.item_code}
                        </TableCell>
                        <TableCell style={styles.styleCells}>
                          {catalogue.catalogue_name}
                        </TableCell>
                        <TableCell style={styles.styleCells}>
                            <TextField
                            style={{ width: "80px" }}
                            data-test-id="quantityFieldID"
                            variant="outlined"
                            defaultValue={
                              catalogue.verified_quantity || catalogue.quantity
                            }
                            inputProps={{min:0,type:"number"}}
                            onChange={(e: any) =>
                              this.props.handleChange(
                                catalogue.catalogue_id,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              style={{
                margin: "auto",
                height: "44px",
                width: "250px",
                background: "#4a126b",
              }}
              data-test-id=""
              variant="contained"
              id="confirmButton"
              color="secondary"
              onClick={this.props.handleEditOrder}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
     // Customizable Area End
  }
}
export { PurchaseOrderUpdateStatusPopup };
export default withStyles(styles)(PurchaseOrderUpdateStatusPopup);
