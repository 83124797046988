
import React from "react";
// Customizable Area Start
import { TextField, Typography, withStyles, FormLabel, Table, TableCell, TableRow, TableBody, TableHead } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import DarkStoreInventoryController,{  rows } from "./DarkStoreInventoryController.web";
import { SearchIcon } from "../../../../components/src/assets.web";
import CustomPagination from "../../../../components/src/Pagination.web";
import DarkStoreDashboardLayout from "../../../../components/src/DarkStoreDashboardLayout.web";
import CommonStatuscard from "../../../../components/src/CommonStatuscard.web";
import {stockStatusLabel, NoPermissionLabel} from "../../../../components/src/CommonFunctions";
import NoAccessPage from "../../../../components/src/NoAccessPage.web";
const inventoryImg = require("../../../../components/src/assets/images/product.jpeg");

const styles : any = {
    productDetails:{
        display:"inline-flex",
        alignItems:"center",
        width:"100%",
        "& img":{
            height:"50px",
            width:"80px",
            paddingRight:"8px"
        }
    },
    image:{
        width:"80px",
        height:"70px",
        display:"flex",
        alignItems:"center"
    },
    table: {
        "& td": {
            fontFamily: "gilroy-medium"
        },
        "& td .detail":{
            margin:"0px !important",
            whiteSpace: "break-spaces"
        },
        "& th": {
            fontFamily: "gilroy-bold",
            width:"350px"
        },
        "& thead tr": {
            background: "#f7f7f7",
            color: "#848192",
        },
    }
}

class DarkStoreInventory extends DarkStoreInventoryController {

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { dataList, hasAccess, total_pages, currentPage, startIndex, endIndex, metaData } = this.state;
        return (
            <DarkStoreDashboardLayout {...restProps} headerAction="New Item" headerTitle="Inventory Management" actionHandler={undefined} subTitle="" hasAccess={true}>
                {hasAccess ? <div className="main_container">
                    <TextField 
                    className="text_field"
                    placeholder="Search"
                    variant="outlined"
                    onChange={this.handleSearch}
                    InputProps={{
                        endAdornment: <SearchIcon/>
                    }}
                     />
                    <CommonStatuscard handleStockStatus={this.handleStockFilter} metaData={metaData} stockStatus={this.state.stockStatus} />
                    <div className="details_container">
                        <Typography variant="h6" className="title">All Products</Typography>
                        <Table className={`${classes.table} stock_table`}>
                            <TableHead>
                                <TableRow>
                                    {rows.map((row: any) => <TableCell key={row.key} align="center">{row.label}</TableCell>)}
                                </TableRow>

                            </TableHead>
                            <TableBody>
                               {dataList.length > 0 
                               ? dataList.map((order: any, index: number) => {
                                return (
                                    <TableRow key={order.id} data-test-id={`row${index}`} className="orderRow" onClick={(e: React.MouseEvent<HTMLTableRowElement>) => this.navigateToInwardsInventoryDetails(e,order.id)}>
                                        <TableCell align="center">{startIndex + index}</TableCell>
                                        <TableCell align="center" className={classes.productDetails}>
                                            <div className={classes.image}>
                                            <img src={order.attributes.images?.length > 0 && order.attributes.images[0].url || inventoryImg} />
                                            </div>
                                            <div>
                                            <FormLabel><span className="detail">{order.attributes.name} , </span>
                                            <span className="detail">{order.attributes.weight} {order.attributes.weight_unit?.charAt(0)?.toLowerCase()}</span></FormLabel>  
                                            
                                            </div>
                                            
                                        </TableCell>
                                        <TableCell align="center">{order.attributes.item_code}</TableCell>
                                        <TableCell align="center">{order.attributes.stock_qty} Pcs</TableCell>
                                        <TableCell align="center" className={stockStatusLabel(order.attributes.status).replace(/ /g,"_")}>
                                            <span>
                                            {stockStatusLabel(order.attributes.status)}
                                            </span>
                                        </TableCell>
                                     </TableRow>
                                 )
                            }) : <Typography className="no_records_found">No Records found</Typography>}
                            </TableBody>
                        </Table>
                    </div>
                    <CustomPagination
                        dataLength={metaData?.total_count}
                        currentPage={currentPage}
                        rowsCount={total_pages}
                        startIndex={startIndex - 1}
                        endIndex={endIndex}
                        handlePagination={this.handleDarkstoreInventoryPagination}
                        handlePrevNext={this.handleDarkstoreInventoryPrevNext}
                    />
                </div>: <NoAccessPage className="no-access" description={NoPermissionLabel} />}
            </DarkStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { DarkStoreInventory };
export default withStyles(styles)(withRouter(DarkStoreInventory));
// Customizable Area End
