import React from "react";

export const OutboundActive = () => {
    return (
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-5" transform="translate(-24.000000, -292.000000)" fill="#662D91" fill-rule="nonzero">
            <g id="outbound_FILL1_wght200_GRAD0_opsz24" transform="translate(24.000000, 292.000000)">
                <path d="M11.5,7.188475 L11.5,9.675 C11.5,9.80448333 11.5479833,9.916025 11.64395,10.009625 C11.7399,10.1032083 11.8588083,10.15 12.000675,10.15 C12.1425417,10.15 12.261225,10.1020833 12.356725,10.00625 C12.4522417,9.91041667 12.5,9.79166667 12.5,9.65 L12.5,6.3077 C12.5,6.07885 12.4226,5.88701667 12.2678,5.7322 C12.1129833,5.5774 11.92115,5.5 11.6923,5.5 L8.325,5.5 C8.19551667,5.5 8.083975,5.54798333 7.990375,5.64395 C7.89679167,5.7399 7.85,5.85880833 7.85,6.000675 C7.85,6.14254167 7.89791667,6.261225 7.99375,6.356725 C8.08958333,6.45224167 8.20833333,6.5 8.35,6.5 L10.786525,6.5 L5.540375,11.74615 C5.433975,11.8525667 5.383975,11.9695583 5.390375,12.097125 C5.39679167,12.2246917 5.44871667,12.3371917 5.54615,12.434625 C5.65256667,12.541025 5.77051667,12.594225 5.9,12.594225 C6.02948333,12.594225 6.14743333,12.541025 6.25385,12.434625 L11.5,7.188475 Z M9.00335,18 C7.7588,18 6.58871667,17.7638417 5.4931,17.291525 C4.39748333,16.8191917 3.44444167,16.178175 2.633975,15.368475 C1.823525,14.558775 1.18191667,13.6066333 0.70915,12.51205 C0.236383333,11.41745 0,10.2478833 0,9.00335 C0,7.7588 0.236158333,6.58871667 0.708475,5.4931 C1.18080833,4.39748333 1.821825,3.44444167 2.631525,2.633975 C3.441225,1.823525 4.39336667,1.18191667 5.48795,0.70915 C6.58255,0.236383333 7.75211667,0 8.99665,0 C10.2412,0 11.4112833,0.236158333 12.5069,0.708475 C13.6025167,1.18080833 14.5555583,1.821825 15.366025,2.631525 C16.176475,3.441225 16.8180833,4.39336667 17.29085,5.48795 C17.7636167,6.58255 18,7.75211667 18,8.99665 C18,10.2412 17.7638417,11.4112833 17.291525,12.5069 C16.8191917,13.6025167 16.178175,14.5555583 15.368475,15.366025 C14.558775,16.176475 13.6066333,16.8180833 12.51205,17.29085 C11.41745,17.7636167 10.2478833,18 9.00335,18 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>)
}