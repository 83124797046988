import React from "react";
import InventoryMassUpdateController, {Props} from "./InventoryMassUpdateController";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import { withStyles,Typography, Box,Tabs,Tab,styled, Grid, Button, createTheme, ThemeProvider, TableContainer, TextField, TableHead, TableRow, Table, TableCell, TableBody} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import CustomFileUpload from "../../../components/src/CustomFileUpload";
import AlertPopup from "../../../components/src/AlertPopup.web";
import TableCommonComponent from "../../../components/src/TableCommonCompoent.web";
import CustomPagination from "../../../components/src/Pagination.web";
import { dateTimeFormat } from "../../../components/src/CommonFunctions";


const configJSON = require("./config.js");


const CustomTabs = styled(Tabs)({
    "& .Mui-selected":{
        color:"#662D91",
        fontWeight:700,
    },
    "& .MuiTabs-indicator":{
        backgroundColor:"#652d92",
    },
    "& .PrivateTabIndicator-root-93":{
        height:"2.3px"
    },
    "border-bottom": "1px solid #e0e0e0"
})


const styles : any = {
 container:{
    marginTop:"2rem",
 },
 downloadTemplateContainer:{
    marginTop:"1rem",
    "& button, button:hover, button:active":{
        width:"170px",
        color:"#fff",
        background: "#652d92",
        height: "56px",
        textTransform: 'initial',
        fontSize: "16px",
        fontWeight:"700",
        marginBottom:"0",
        marginTop:"0",
        fontFamily: "Plus Jakarta Sans",
    },
 },
bold:{
    fontWeight:"600"
},
subTitle:{
    color:"#5d5757"
},
updateSection:{
    marginTop:"1.5rem"
},
tableContainer:{
    marginTop:"1.3rem"
},
button:{
    background:"rgba(102, 45, 145, 1) !important",
    height:"40px !important",
    fontFamily: "gilroy-bold !important",
    fontSize: "16px !important",
    width:"120px !important",
    color:"white !important",
    fontWeight:"500 !important",
  },
  btn_reset:{
    height:"40px !important",
    fontFamily: "gilroy-bold !important",
    fontSize: "16px !important",
    width:"120px !important",
    color:"#757575 !important",
    fontWeight:"500 !important",
    background:"none !important",
    marginLeft: "1rem"
  },
  download:{
    fontFamily: "gilroy-medium !important",
    fontSize: "14px !important",
    color:"#32B51F !important",
    background:"none !important",
    border: "1px solid #32B51F !important",
    borderRadius:"4px"
  },
  paginationContainer:{
    marginTop:"10px"
  },
 table:{
    "& thead tr":{
        border:"1px solid rgba(224, 224, 224, 1)",
    },
    "& tbody tr":{
        border:"1px solid rgba(224, 224, 224, 1)",
        "&  td.Failed, td.Successful ":{
            padding:"0px",
        },
        "& span, td.Failed span, td.Successful span":{
            padding:"6px 16px",
            borderRadius:"6px",
            minWidth:"110px",
            display:"inline-flex",
            justifyContent:"center"
        },
        "& td.Failed span":{
            background:"#fed4d5",
            color:"#c96058"
        },
        "& td.Successful span":{
            background:"#e4fcd8",
            color:"#438147"
        }
    }
 },
 lableContainer:{
    display:"flex",
    gap:"15px"
 },
 gilroyBold:{
    fontWeight:500,
    fontFamily: "gilroy-bold !important"
 },
 gilroMedium:{
    fontWeight:500,
    fontFamily: "gilroy-medium"
 },
 gilroyRegular:{
    fontWeight:500,
    fontFamily: "gilroy-regular"
 },
 textField:{
    "& input":{
        padding:"10px",
        color:"#E58654",
        fontWeight:"600"
    },
    "& fieldset":{
        borderColor:"rgba(0, 0, 0, 0.23) !important"
    }
 },
 tableHeader:{
    backgroundColor:"rgba(245, 245, 250, 1)",
    "& tr":{
        border:"1px solid rgba(224, 224, 224, 1)",
    },
    "& th":{
        color: "#8181A5",
        fontFamily: "gilroy-bold",
        fontSize: "13px",
        fontStyle: "normal",
    }
},
tableBody:{
    "& tr":{
        border:"1px solid rgba(224, 224, 224, 1)",
    },
    "& td":{
        color: "black",
        fontFamily: "gilroy-medium",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight:500
    }
},
}

const theme = createTheme({
    palette: {
        secondary: {
            light: '#662D91',
            main: '#662D91',
        } 
    },
    typography: {
      fontFamily: "Plus Jakarta Sans",
      fontWeightLight: "normal",
      fontWeightRegular: "normal",
      h4:{
        fontSize:"20px"
      },
      h5:{
        fontSize:"18px",
        fontWeight:700
      },
      h6:{
        fontSize:"16px",
        fontWeight:600
      },
      subtitle1:{
        fontSize:"14px",
        fontWeight:600
      },
      subtitle2:{
        fontSize:"12px",
        fontWeight:400
      }
    },
  });

class InventoryMassUpdate extends InventoryMassUpdateController{
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes, ...restProps } = this.props;
        const { userProfile } = restProps;
        const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile?.attributes?.groups?.data[0].attributes.settings : {};

        return (
            <GlobalStoreDashboardLayout classes={classes} {...restProps} 
             headerAction="New Item" actionHandler={this.handleNavigateToAddNewInventoryFromMassUpdate} subTitle="" headerTitle="Inventory Management" hasAccess={groups?.inventory} 
            isInventory={groups?.inventory}>
                 { !groups?.inventory  ? <NoAccessPage description="Sorry, you don't have the permission" /> :<ThemeProvider theme={theme}>
                 <div className="main_container">
                 <Typography variant="h5" className="title">Mass Update</Typography>
                 <Box className={classes.container}>
                 <CustomTabs value={this.state.selectedTab} onChange={this.handleChangeMassUpdateTab} >
                    <Tab label="Download" />
                    <Tab label="Upload File" />
                 </CustomTabs>
                 {
                    this.state.selectedTab === configJSON.INDEX_OF_MASS_UPDATE_GENERATE  &&  <Box>
                        <Grid container className={classes.downloadTemplateContainer} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6"  >
                                Generate Templates
                                </Typography>
                                <Typography variant="subtitle2" className={classes.subTitle}>
                                    Use the filters to generate a template and mass-update the listings.
                                </Typography>
                            </Grid>                            
                            <Grid item xs={12}>
                                <div className={classes.lableContainer}>
                                    <Typography variant="subtitle1" className={classes.gilroyBold} style={{lineHeight:"2.5"}}>Selected Items</Typography>
                                    <TextField 
                                        className={classes.textField}  
                                        variant="outlined"
                                        disabled
                                        value={this.state.totalItemCodes? `${this.state.processedItemCodes}/${this.state.totalItemCodes}`:""}
                                    />
                                </div>
                            </Grid>                            
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.subTitle}>
                                    Please upload a CSV containing the selected item codes
                                </Typography>    
                                <CustomFileUpload handleDrop={this.handleDropSelectedItemCodesFile} handleFileChange={this.handleSelectSelectedItemCodesFile} selectedFile={this.state.itemCodeFile} loading={this.state.uploadLoading}/>                            
                            </Grid>                            
                            <Grid item xs={12}>
                                <Button variant="contained" style={{background:"rgba(102, 45, 145, 1)"}} className={classes.button} onClick={this.generateProductFile}>
                                    Generate
                                </Button>        
                                <Button variant="outlined"  className={classes.btn_reset} onClick={this.handleResetForm}>
                                    Reset
                                </Button>       
                            </Grid>                            
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.gilroyBold}>Records</Typography>
                            </Grid>                            
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead className={classes.tableHeader} >
                                        <TableRow>
                                            {configJSON.massUpdateProductFileHeader.map((headerName:string)=>(
                                                <TableCell align="center" key={headerName}>{headerName}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        <TableRow>
                                        {
                                            !this.state.downloadableFileDetails ?(
                                                <TableCell colSpan={4} align="center" className={classes.gilroyBold} style={{fontSize:"1rem"}} >No Data</TableCell>
                                                ):(
                                                <>
                                                    <TableCell  align="center" >{this.state.downloadableFileDetails.created_at && dateTimeFormat(this.state.downloadableFileDetails.created_at)}</TableCell>
                                                    <TableCell  align="center" >{this.state.downloadableFileDetails.name}</TableCell>
                                                    <TableCell  align="center" >{this.state.processedItemCodes}</TableCell>
                                                    <TableCell  align="center" >
                                                       {this.state.downloadableFileDetails?.url &&  <a href={this.state.downloadableFileDetails.url}>
                                                            <Button className={classes.download} variant="outlined">Download</Button>
                                                        </a>}
                                                    </TableCell>
                                                </>
                                                )
                                        }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>                            
                        </Grid>
                    </Box> 
                 }  
                 
                {this.state.selectedTab === configJSON.INDEX_OF_MASS_UPDATE_FILE &&  <Box className={classes.updateSection} >
                    <CustomFileUpload handleDrop={this.handleDrop} handleFileChange={this.handleFileChange} selectedFile={this.state.selectedFiles}  loading={true} />
                    <Typography variant="subtitle2" className={classes.subTitle}>
                        Please Upload the Comleted files to finish the mass Update process.
                    </Typography>
                    <Typography variant="h6" style={{fontWeight:700, marginTop:"1rem"}}>Records</Typography>        
                    <TableContainer className={classes.tableContainer} >
                            <TableCommonComponent rows={configJSON.massUploadRows} data={this.state.dataList} cssClasses={classes.table}  />
                        </TableContainer>
                        <Box className={classes.paginationContainer}>
                            <CustomPagination
                                rowsCount={this.state.rowsCount}
                                dataLength={this.state.totalCount}
                                currentPage={this.state.currentPage}
                                endIndex={this.state.endIndex}
                                startIndex={this.state.startIndex}
                                handlePrevNext={this.handleRecordPrevNext}
                                handlePagination={this.handleRecordPagination}
                            />
                        </Box>
                </Box>}
                </Box>
                </div>
                <AlertPopup  
                    isOpen={this.state.openFileNotSupportedPopUp}
                    handleClose={this.handleClosePopUp}
                    hideButtons = {true}
                    action="Close" 
                    description={<Typography variant="h5">Sorry, the file format is not supported, please check your file.</Typography>}/>
                </ThemeProvider>}
                </GlobalStoreDashboardLayout>
        )
    }
}

export {InventoryMassUpdate}
export default withStyles(styles)(withRouter(InventoryMassUpdate));