import React from 'react'
import { withRouter } from "react-router-dom";
import { TextField, withStyles, Grid, FormLabel, Icon, Typography, Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PurchaseOrderAddFormController, { Props } from './PurchaseOrderAddFormController.web';
import GlobalStoreDashboardLayoutWeb from '../../../components/src/GlobalStoreDashboardLayout.web';
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import PurchaseOrderItemTable from './PurchaseOrderItemTable';
import AlertPopup from '../../../components/src/AlertPopup.web';
import SuccessPopup from '../../../components/src/SuccessPopup.web';
import AllocateSupplierDialogBox from './PurchaseOrderAllocateSupplierPopup.web';
import CommonTextfield from '../../../components/src/CommonTextfield';
import { IEvent } from './types';
import AddItemDialogBox from '../../../components/src/AddItemDialogBox.web';


const configJSON = require("./config.js");

const styles: any = {
    textFont: {
        color: "black",
        fontFamily: "gilroy-medium",
        fontSize: "16px",
    },
    margin: {
        margin: "10px 0px"
    },
    addBtn: {
        textTransform: "initial",
        border: "1px solid #652d92",
        background: "#fff",
        marginRight: "0px",
        marginLeft: "auto",
        height: "40px",
        "& *": {
            color: "#662D91",
            fontWeight: "bold"
        },
        "& .material-icons": {
            display: "flex",
            alignItems: "center",
            marginRight: "8px"
        }
    },
    successBtn: {
        "& button, button:hover, button:active": {
            color: "#fff",
            background: "#652d92",
            fontSize:"16px",
            fontFamily: "gilroy-bold",
            height: "56px",
            width:"170px",
            "& *": {
                color: "#fff",
            }
        },
    },
    cancelBtn: {
        marginRight:"10px",
        "& button, button:hover, button:active": {
            color: "#000",
            fontSize:"16px",
            fontFamily: "gilroy-bold",
            height: "56px",
            width:"170px"
        }
    },
    deleteActive: {
        color: 'red',
        textDecoration: "underline",
        fontFamily: "gilroy-medium",
        fontSize: "16px",
    },
    deleteInactive: {
        color: "#949494",
        fontFamily: "gilroy-medium",
        fontSize: "16px",
        texteDcoration: "underline",
    },
    requiredFields: {
        color: "red"
    },
    btnContainer:{
        display:"flex",
        justifyContent:"end"
    }
}

class PurchaseOrderAddForm extends PurchaseOrderAddFormController {
    constructor(props: Props) {
        super(props)
    }

    render() {
         // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const groups = restProps.userProfile?.attributes?.groups?.data?.length > 0 ?
            restProps.userProfile?.attributes?.groups?.data[0].attributes.settings : {};

        const selectedCount = this.state.catalogueItemList.reduce((acc, curr) => acc + (curr.checked ? 1 : 0), 0)

        return <GlobalStoreDashboardLayoutWeb headerAction="" classes={classes}
            {...restProps} headerTitle="Purchase Orders" actionHandler={undefined} subTitle="" hasAccess={groups.purchase_orders} >
            {!groups?.purchase_orders
                ? <NoAccessPage description="Sorry, you don't have the permission" />
                : <div className="main_container">

                    <Grid container spacing={0} className={classes.margin} >
                        <Grid xs={12} md={8} item>
                            <Grid xs={12} md={12} item className={classes.margin}>
                                {this.state.editMode && <FormLabel ><span className={classes.textFont}>PO Number </span> {this.state.createPurchaseOrderData.attributes.po_number}  </FormLabel>
                                }  </Grid>
                            {this.state.isSupplierPresent && <>
                                <Grid container spacing={0} >
                                    <Grid xs={12} md={12} item className={classes.margin}>
                                        <FormLabel className={classes.textFont} >Supplier Details</FormLabel>
                                    </Grid>
                                    <Grid xs={12} md={8} item className={classes.margin} >
                                        <FormLabel >{this.state.supplierDetails.name} </FormLabel>
                                    </Grid>
                                    <Grid xs={12} md={8} item className={classes.margin} >
                                        <FormLabel >Supplier Code : {this.state.supplierDetails.code} </FormLabel>
                                    </Grid>
                                    <Grid xs={12} md={8} item className={classes.margin} >
                                        <FormLabel >{this.state.supplierDetails.address} </FormLabel>
                                    </Grid>
                                    <Grid xs={12} md={8} item className={classes.margin} >
                                        <FormLabel >{this.state.supplierDetails.email} </FormLabel>
                                    </Grid>
                                </Grid>
                                <br />
                            </>
                            }
                            <Grid xs={12} md={12} item className={classes.margin}>
                                <FormLabel className={classes.textFont} >Shipment Address <span className={classes.requiredFields}>*</span></FormLabel>
                            </Grid>
                            <Grid xs={12} md={12} item className={classes.margin}>
                                <CommonTextfield
                                    error={this.state.errors.shipmentAddressError}
                                    value={this.state.shipmentAddress}
                                    placeholder="Enter Shipment Address"
                                    data-test-id="changeAddress"
                                    onChange={(e: IEvent) => this.handleChangeAddress(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={4} item style={{ display: "flex" }} >
                            <Button className={classes.addBtn} variant="outlined" onClick={this.handleSupplierPopupOpen} >
                                {this.state.isSupplierPresent ? "Edit supplier" : <><Icon><AddIcon /></Icon> Allot To Supplier</>}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} >
                        <Grid xs={12} md={12} item className={classes.margin}>
                            <FormLabel className={classes.textFont}>Items <span className={classes.requiredFields}>*</span></FormLabel>
                        </Grid>
                        <Grid xs={12} md={12} item className={classes.margin}>
                            <Button className={classes.addBtn} variant="outlined" onClick={this.hadleCatalogueListOpen} >
                                <Icon><AddIcon /></Icon> Add Item
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                    <div className={classes.margin}>
                        <PurchaseOrderItemTable itemData={this.state.catalogueItemList} handleChange={this.handleFieldEditChange} />
                    </div>
                    <div className={classes.margin} >
                        <Button className={selectedCount === 0 ? classes.deleteInactive : classes.deleteActive} onClick={this.handleDeletePopupOpen}
                            disabled={selectedCount === 0}  >{this.state.catalogueItemList?.length ? "Delete Item" : null}</Button>
                    </div>
                    <br />
                    <br />
                    <Grid container spacing={0}  >
                        <Grid xs={12} md={12} item className={classes.margin}>

                            <FormLabel className={classes.textFont} >Expected Delivery Date <span className={classes.requiredFields}>*</span></FormLabel>
                        </Grid>

                        <Grid xs={12} md={12} item className={classes.margin}>
                            <DatePicker selected={this.state.expectedDeliveryDate} data-test-id="DatePicker" onChange={this.handleChangeDate} dateFormat={configJSON.dateFormat} minDate={new Date()}
                                customInput={
                                    <TextField
                                        error={Boolean(this.state.errors.deliveryDateError)}
                                        className="width100"
                                        variant="outlined"
                                        value={this.state.expectedDeliveryDate}
                                        helperText={this.state.errors.deliveryDateError}
                                    />
                                }
                            />
                        </Grid>

                    </Grid>

                    <div  className={classes.btnContainer} >
                        <div   className={classes.cancelBtn} >
                            <Button variant='contained' onClick={this.handleCancelOrder} >Cancel</Button>
                        </div>
                        <div   className={classes.successBtn}>
                            <Button variant='contained' onClick={this.handleConfirmOrder} disabled={this.state.isDisabled} >Confirm</Button>
                        </div>

                    </div>
                    <AllocateSupplierDialogBox
                        open={this.state.showSupplierDetails}
                        handleClose={this.handleSupplierPopupClose}
                        supplierDetails={this.state.supplierEditData}
                        handleChange={this.handleSupplierDetailsChange}
                        errors={this.state.supplierErrors}
                        handleSave={this.handleConfirmSupplier}
                        suppliersListData={this.state.suppliersListData}
                        handleListItemSelect={this.handleSupplierListItemSelect}
                        shipmentAddress={this.state.shipmentAddress}
                    />
                    {this.state.catalogueItemListOpen && <AddItemDialogBox
                        open={this.state.catalogueItemListOpen}
                        selectdRowIdList={this.state.selectedRowList}
                        selectedItems={this.state.catalogueItemList}
                        handleClose={this.handleCatalogueListClose}
                        handleSave={this.handleSaveCatalogueItem}
                    />
                    }
                    <AlertPopup
                        isOpen={this.state.showDeletePopup}
                        handleClose={this.handleDeletePopupClose}
                        cancelHandler={this.handleDeletePopupClose}
                        actionHandler={this.handleDelete}
                        action="Delete"
                        description={<Typography variant="h6">Are you sure that you want to <span>Delete</span> this item ?</Typography>} />

                    <SuccessPopup
                        description={<Typography variant="h6">{this.state.editMode ? configJSON.purchaseEditMessage : configJSON.purchaseSuccessMessage}</Typography>}
                        isOpen={this.state.showSuccessPopup}
                        handleClose={this.handleSuccessClose}
                    />
                </div>}
        </GlobalStoreDashboardLayoutWeb>
        // Customizable Area End
    }
}

export { PurchaseOrderAddForm };
export default withStyles(styles)(withRouter(PurchaseOrderAddForm));	
