import React from "react";
// Customizable Area Start
import { TextField, IconButton, withStyles, Typography ,  Select, MenuItem } from "@material-ui/core";
import {withRouter} from "react-router-dom";
import DeliveryChallanController,{ Props, rows } from "./DeliveryChallanController.web";
import CustomPagination from "../../../components/src/Pagination.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import { SearchIcon } from "../../../components/src/assets.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TableCommonComponent from "../../../components/src/TableCommonCompoent.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
export const configJSON = require("./config");

const styles : any = {
table:{
    "& tbody tr":{
        "& td.Delivering, td.Pending, td.Ready, td.Completed ":{
            padding:"0px",
        },
        "& td.Delivering span, td.Pending span, td.Ready span, td.Completed span":{
            padding:"6px 16px",
            borderRadius:"6px",
            minWidth:"110px",
            display:"inline-flex",
            justifyContent:"center"
        },
        "& td.Delivering span":{
            background:"#fffae2",
            color:"#cfcd6a"
        },
        "& td.Pending span":{
            background:"#fed4d5",
            color:"#c96058"
        },
        "& td.Ready span":{
            background:"#ffe5d4",
            color: "#b08055"
        },
        "& td.Completed span":{
            background:"#e4fcd8",
            color:"#438147"
        }
    }
},
actionBar:{
    display:"inline-flex",
    width:"100%",
    marginBottom:"12px"
},
select:{
    marginLeft:"24px",
},
filterBar:{
    marginRight:"0px",
    marginLeft:"auto",
    "& .MuiOutlinedInput-root":{
        height:"40px",
    },
    "& .MuiOutlinedInput-input":{
        padding:"10.5px 30px"
    }
}
}

class DeliveryChallan extends DeliveryChallanController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { dataList, currentPage, rowsCount, startIndex, endIndex } = this.state;
        const { userProfile } = restProps;
        const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile?.attributes?.groups?.data[0].attributes.settings : {};
        return (
            <GlobalStoreDashboardLayout {...restProps} headerTitle="Delivery Challan" actionHandler={this.handleNavigateToAddNewChallan} headerAction="New Order" subTitle="" hasAccess = {groups.delivery_challan}> 
                {!groups?.delivery_challan
                ?<NoAccessPage description="Sorry, you don't have the permission" />
                :<div className="main_container">
                    <TextField 
                        className="text_field"
                        placeholder="Search by Order ID, Warehouse Name"
                        variant="outlined"
                        InputProps={{
                            endAdornment: <IconButton><SearchIcon/></IconButton>
                        }}
                        data-test-id="searchField"
                        value={this.state.search}
                        onChange={this.handleSearchItem}
                     />
                    <div className="details_container">
                    <div className={classes.actionBar}>
                            <Typography variant="h6" className="title">List of orders</Typography>
                            <div className={classes.filterBar}>
                                <Select 
                                IconComponent={KeyboardArrowDownIcon}
                                onChange={this.filterByStatus}
                                data-test-id="selectStatus"
                                variant="outlined" value={this.state.filterStatus} className={classes.select}>
                                    <MenuItem value=" ">All Data</MenuItem>
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="delivering">Delivering</MenuItem>
                                    <MenuItem value="completed">Completed</MenuItem>
                                </Select>
                                <Select 
                                onChange={this.filterByYear}
                                data-test-id="selectYear"
                                IconComponent={KeyboardArrowDownIcon}
                                variant="outlined" value={this.state.filterYear} className={classes.select}>
                                    {this.state.listOfYears.map(year=>(
                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                    </div>
                    <TableCommonComponent rows={rows} data={dataList} cssClasses={classes.table} handleNavigate= {this.handleNavigateToOrderDetails}/>
                    </div>
                    <CustomPagination
                        dataLength={this.state.totalCount}
                        currentPage={currentPage}
                        rowsCount={rowsCount}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        handlePagination={this.handleDeliveryChallanPagination}
                        handlePrevNext={this.handleDeliveryChallanPrevNext}
                    />
                </div>}
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { DeliveryChallan };
export default withStyles(styles)(withRouter(DeliveryChallan));
// Customizable Area End
