import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { StockStatus } from "../types";
// Customizable Area End

export const configJSON = require("../config");
export const rows = configJSON.inventoryListRows;
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  currentPage:number;
  dataList:any;
  rowsCount:number;
  startIndex:number;
  endIndex:number;
  metaData:any;
  total_pages:number;
  searchKey: string;
  darkStoreId:number | null;
  stockStatus:StockStatus;
  hasAccess: boolean;
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  setError:any;
  userProfile:any;
  setUserProfile:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number,
  history:any
  // Customizable Area End
}
export default class DarkStoreInventoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDarkstoreInventoryListApiCallId:string = "";
  getLinkedDarkstoreInfoApiCallId:string = "";
  debounceTimeout: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      currentPage:1,
      dataList:[],
      rowsCount:0,
      startIndex:0,
      endIndex:0,
      metaData: {},
      total_pages:0,
      darkStoreId:null,
      stockStatus:"",
      searchKey:"",
      hasAccess: false
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      let darkstoreinventoryApicall = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(errorReponse){
        this.handleErrorCallbacks(darkstoreinventoryApicall, errorReponse);
      }

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson){
        if(responseJson.errors){
          console.log("errors", responseJson.errors);
        }else if(responseJson.status === 500){
          console.log("error", responseJson);
        }else{
          this.handleSuccessCallbacks(darkstoreinventoryApicall, responseJson);
        }
      }

      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  darkStoreInventoryApiCall = (data:any) => {
    const { method, endPoint, contentType } = data;

    const header = {
       "Content-Type": contentType,
      token: localStorage.getItem('token'),
    };
    
    const darkStoreInventoryReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    darkStoreInventoryReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    darkStoreInventoryReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    
    darkStoreInventoryReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    
    runEngine.sendMessage(darkStoreInventoryReqMessage.id, darkStoreInventoryReqMessage);
    
    return darkStoreInventoryReqMessage.messageId;
  }

  handleSearch = (e: any) => {
    const { value } = e.target;
    this.setState({searchKey: value});
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    
    this.debounceTimeout = setTimeout(() => {
      this.setState({total_pages:0}, () => {
        const {darkStoreId, stockStatus} = this.state;
        this.fetchInventoryList(darkStoreId,1,stockStatus);
      });
      
    }, 600);
  }

  handleFetchInventoryListSuccessCallback = (responseJson:any) => {
    if( responseJson.data && responseJson.data.length > 0 && responseJson.meta){
      const meta = responseJson.meta;
      const data = responseJson.data;
      const page = meta.page;
      
      const rowsPerPage = configJSON.rowsPerPage;
        let pages = Math.ceil(meta.total_count/configJSON.rowsPerPage);
        this.setState({total_pages:pages})
      
      let updatedEndIndex = rowsPerPage*page;
      let endIndex = rowsPerPage*(page -1) + data.length;
      if(updatedEndIndex > endIndex){
        updatedEndIndex = endIndex;
      }

      this.setState({
        dataList: data, metaData: meta, currentPage:meta.page, 
        startIndex: rowsPerPage*page - rowsPerPage + 1, 
        endIndex: updatedEndIndex
      });
    }else if(responseJson.meta){
      this.setState({
        dataList: [], metaData: responseJson.meta,
        startIndex:0,
        endIndex:0,
        currentPage:  responseJson.meta
      })
    }
  }

  handleGetLinkedDarkstoreSuccessCallback = (responseJson: any) => {
    if(responseJson.data.data != null){
      const {currentPage, stockStatus} = this.state;
      this.setState({darkStoreId: responseJson.data.data.id});
      this.fetchInventoryList(responseJson.data.data.id, currentPage, stockStatus)
      this.setState({hasAccess: true})
    }else{
      this.setState({hasAccess: false})
    }
  }

  handleSuccessCallbacks = (apiReqCallId:string, responseJson:any) => {
  
    if(apiReqCallId === this.getDarkstoreInventoryListApiCallId) {
      this.handleFetchInventoryListSuccessCallback(responseJson);
    }else if(this.getLinkedDarkstoreInfoApiCallId === apiReqCallId){
      this.handleGetLinkedDarkstoreSuccessCallback(responseJson)
    }
  }

  handleErrorCallbacks = (apiReqCallId:string, errorJson:any) => {

  }

  async componentDidMount(){
    const { history } = this.props;
    if (history.location.state && history.location.state.invetryCurrentPage) {
      this.setState({currentPage:history.location.state.invetryCurrentPage,stockStatus: history.location.state.stockStatus},()=>{
        this.getLinkedDarkstore();    
      })
     } else{
      this.getLinkedDarkstore();
     }    
    
  }

  getLinkedDarkstore() {
    this.getLinkedDarkstoreInfoApiCallId = this.darkStoreInventoryApiCall({
      contentType:configJSON.validationApiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.getLinkedDarkstoreEndPoint}`,
    })
  }

  fetchInventoryList = (darkstoreId: number | null, page: number, status: StockStatus) => {
    this.getDarkstoreInventoryListApiCallId = this.darkStoreInventoryApiCall({
      method:configJSON.getAPIMethod,
      endPoint:`${configJSON.getInventoryList}?page=${page}&per_page=${configJSON.rowsPerPage}&dark_store_id=${darkstoreId}&status=${status}&search_query=${this.state.searchKey}`,
      contentType : configJSON.generalContentType
    })
  }

  handleStockFilter = (status: StockStatus) => {
    const {darkStoreId} = this.state;
    this.setState({stockStatus:status});
    this.fetchInventoryList(darkStoreId, 1,status);
  }

  handleDarkstoreInventoryPagination = (e: any ,page: number) => {
    const {darkStoreId, stockStatus} = this.state;
    this.fetchInventoryList(darkStoreId, page,stockStatus);
  }

  handleDarkstoreInventoryPrevNext = (type: string) => {
    const { currentPage, metaData } = this.state;
      if(type === "previous" && currentPage > 0){
      this.handleDarkstoreInventoryPagination({}, currentPage - 1);
    }else if(type === "next" && currentPage <= metaData.total_pages){
      this.handleDarkstoreInventoryPagination({}, currentPage + 1);
    }
  }

  navigateToInwardsInventoryDetails = (e: React.MouseEvent<HTMLTableRowElement>,id:string) => {
    this.props.history.replace({
      ...this.props.history.location,
      state: {
        ...this.props.history.location.state,
        invetryCurrentPage: this.state.currentPage,
        stockStatus:this.state.stockStatus     
        
      }
    });
    this.props.history.push(`/inventory/${id}`);
  }

  // Customizable Area End
}
