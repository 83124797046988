import React from "react";
// Customizable Area Start
import {
  TextField,
  IconButton,
  Button,
  MenuItem,
  Menu,
  InputAdornment,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DashboardTableHeaders } from "../../blocks/dashboard/src/types";
import { SearchIcon } from "./assets.web";
import DatePicker from "react-datepicker";

// export const configJSON = require("./config");


interface IProps {
  classes?: any;
  configJSON:any;
  handleExportData:()=>void;
  handleOpenColumnMenu:(event: React.MouseEvent<HTMLButtonElement>)=>void;
  openMenu: null | HTMLElement;
  handleCloseColumnMenu:()=>void;
  isAllSelected:boolean;
  tableHeadings:DashboardTableHeaders[];
  handleSelectColumnFilter:(ele:any)=>void;
  handleDataColumnsSave:()=>void;
  dateRange:any[];
  handleDateChange:(ele:any)=>void;
  handleSelectAllColumns:()=>void;
  searchQuery:string,
  handlesearchItem:(searchQuery:string)=>void,
  visibleLabels:string[]
}


const DashboardHeaderFilters = (props: IProps) => {

    const {classes,configJSON,tableHeadings,searchQuery,handlesearchItem,handleSelectColumnFilter,handleExportData,handleOpenColumnMenu,openMenu,handleCloseColumnMenu,isAllSelected,handleSelectAllColumns,handleDataColumnsSave,dateRange,handleDateChange,visibleLabels}= props;
    return (
      <>
        <div>
          <TextField
            className="text_field"
            placeholder={configJSON.searchPlaceholderText}
            value={searchQuery}
            onChange={(e)=>handlesearchItem(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            style={{ marginBottom: "30px" }}
          />
          <div style={{ float: "right", display: "flex", gap: "20px" }}>
           {!visibleLabels.includes("No Of Orders")  && !visibleLabels.includes("Order Cycle Time") && <Button
              className={classes.exportBtn}
              data-test-id="exportBtn"
              onClick={handleExportData}
            >
              <CloudUploadIcon style={{ marginRight: "10px" }} /> Export
            </Button>}
            <Button
              data-test-id="columnListBtn"
              className={classes.dataSelection}
              style={{
                border: "1px solid #E7E7E7",
                backgroundColor: "#E7E7E7",
              }}
              onClick={(e) => handleOpenColumnMenu(e)}
            >
              Data Selection
              <KeyboardArrowDownIcon style={{ marginLeft: "6px" }} />
            </Button>

            <Menu
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              id="simple-menu"
              keepMounted
              open={Boolean(openMenu)}
              anchorEl={openMenu}
              onClose={handleCloseColumnMenu}
            >
              <div
                style={{
                  maxHeight: 250,
                  overflowY: "auto",
                }}
              >
                <div style={{ display: "flex", gap: "5px" }}>
                  <input
                    className={classes.inputCheckBox}
                    type="checkbox"
                    data-test-id="allcolumnId"
                    checked={isAllSelected}
                    onChange={handleSelectAllColumns}
                  />
                  <MenuItem style={{ fontSize: "13px" }}>Select All</MenuItem>
                </div>
                {tableHeadings
                  .filter((element: DashboardTableHeaders) => !element.mandatory)
                  .map((ele: DashboardTableHeaders, index: number) => {
                    return (
                      <div style={{ display: "flex", gap: "5px" }} key={index}>
                        <input
                          className={classes.inputCheckBox}
                          type="checkbox"
                          data-test-id="columnfilterId"
                          checked={ele.isChecked}
                          onChange={() => handleSelectColumnFilter(ele)}
                        />
                        <MenuItem style={{ fontSize: "13px" }}>
                          {ele.label}
                        </MenuItem>
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  padding: "16px",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "white",
                  margin:"auto",
                  width:"90%"
                }}
              >
                <Button
                data-test-id="savecolumn"
                style={{width:"100%"}}
                  onClick={handleDataColumnsSave}
                  className={classes.columnSaveBtn}
                >
                  Save
                </Button>
              </div>
            </Menu>

            <div style={{ backgroundColor: "#E7E7E7" }}>
              <DatePicker
                data-test-id="DateRangeSelectorBtn"
                className={`${classes.date_picker}`}
                selectsRange={true}
                placeholderText={configJSON.datePlaceholderText}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(update) => {
                  handleDateChange(update);
                }}
                dateFormat={configJSON.dateFormat}
                maxDate={new Date()}
                customInput={
                  <TextField
                    className="width100"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon style={{ color: "#662D91" }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <KeyboardArrowDownIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  
}
export default DashboardHeaderFilters;
