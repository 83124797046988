import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
// Customizable Area End

export const configJSON = require("./config");
export type Props = RouteComponentProps & {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  userType: any;
  setError:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  step: number;
  email:field;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
type field = {
  value: string;
  error: string;
}

// Customizable Area End
export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postForgotPasswordApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      // Customizable Area Start
      step:0,
      email:{
        value:"",
        error:""
      }
      // Customizable Area End
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(errorReponse){
        this.props.setError(errorReponse);
        console.log("@@@@ reset-password errorReponse",errorReponse);
      }

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson){
        console.log("@@@@ reset-password response",responseJson);
        if(responseJson.errors && responseJson.errors.email){
          this.setState({email:{...this.state.email ,error:responseJson.errors.email}});
        }
        if(responseJson.success){
          this.setState({step:this.state.step + 1});
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  forgotPasswordApiCall = (data:any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
      
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleNext = () => {
    const { email } = this.validateFields();
    this.setState({email});
    if(email.error.length === 0) {
      const formData = new FormData();
      formData.append("data[email]", email.value) 

      this.postForgotPasswordApiCallId = this.forgotPasswordApiCall({
        method: configJSON.forgotPasswordApiMethodType,
        endPoint: configJSON.forgotPasswordApiEndPoint,
        body: formData,
      });
    }

  }

  emailFieldHandler = (e:any) => {
    this.setState({email: {
      value:e.target.value,
      error:""
    }});
  }

  backToPrevStep = () => {
    if(this.state.step === 0) {
      this.props.history.goBack();
    }else{
      this.setState({step: this.state.step - 1});
    }
  }

  validateFields = () => {
    let { email } = this.state;
    if(email.value.trim() === ""){
      email.error = "Please enter valid email";
    }else {
      let validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
      if(!(validEmailRegex.test(email.value.trim()))){
        email.error = "Please enter valid email";
      }
    }
    return {
      email
    }
  }

  // Customizable Area End
}
