import React from "react";
// Customizable Area Start
import { Box, TextField, Typography, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SalesForceDashboardController, {
  Props,
} from "./SalesForceDashboardController.web";
import { SearchIcon } from "../../../components/src/assets.web";
import CustomPagination from "../../../components/src/Pagination.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import SalesForceDashboardItemstable from "../../../components/src/SalesForceDashboardItemsTable.web";
import Select from "react-select";
export const configJSON = require("./config");

export const rowsPerPage = configJSON.salesForceDashboradRows;

const styles: any = {
  styleCells: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
  },
  searchField: {
    width: "460px",
    height: "48px",
    borderRadius: "4px",
    background: "#F7F7F7",
  },
  fontSize12: {
    fontSize: "12px",
  },
  fontSize14: {
    fontSize: "14px",
  },
  fontSize16: {
    fontSize: "16px",
  },
  fontGilroyBold: {
    fontFamily: "gilroy-bold",
  },
  fontGilroyMedium: {
    fontFamily: "gilroy-medium",
  },
  fontGilroyRegular: {
    fontFamily: "gilroy-regular",
  },
  darkStoreSelectOptionDiv: {
    width: "203px",
    height: "39px",
    borderRadius: "4px",
    background: " #F3F3F3",
  },
  menuDiv: {
    display: "flex",
    gap: "30px",
    alignItems: "center",
    marginTop: "30px",
  },
  select: {
    marginLeft: "24px",
  },
};

class SalesForceDashboard extends SalesForceDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes, ...restProps } = this.props;
    const {
      currentPage,
      rowsCount,
      currentPageRecords,
      startIndex,
      endIndex,
      totalRecords,
      stockData,
      isAllDataSelected,
    } = this.state;
    const { userProfile } = restProps;
    const groups =
      userProfile?.attributes?.groups?.data?.length > 0
        ? userProfile?.attributes?.groups?.data[0].attributes.settings
        : {};

    return (
      <GlobalStoreDashboardLayout
        {...restProps}
        headerAction="Create Outbound Order"
        actionHandler={this.handleRedirectToCreateChallanOrder}
        headerTitle="Sales Dashboard"
        subTitle=""
        hasAccess={groups?.salesforce}
      >
        {groups && !groups.salesforce ? (
          <NoAccessPage description="Sorry, you don't have the permission" />
        ) : (
          <div className="main_container">
            <TextField
              fullWidth
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
              placeholder="Search"
              variant="outlined"
              value={this.state.searchQuery}
              onChange={this.handleSearchItem}
              className={`text_field ${classes.searchField}`}
            />
            <Box className={classes.menuDiv}>
              <Typography
                className={`${classes.fontGilroyBold} ${classes.fontSize16} `}
              >
                Dark Store
              </Typography>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "none",
                    border: 0,
                    backgroundColor: "#F3F3F3",
                  }),
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: "#101010",
                    border: "none",
                  }),
                  singleValue: (provided: any) => ({
                    ...provided,
                    height: "100%",
                    color: " #3B3B3B",
                    fontFamily: "gilroy-medium",
                    paddingTop: "3px",
                    fontSize: "14px",
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                className={`${classes.select} ${classes.darkStoreSelectOptionDiv}`}
                value={this.state.selectedDarkStore}
                options={this.state.darkStoreData}
                onChange={(e: any) => this.handleSelectDarkStore(e)}
                data-test-id="darkstoreOption"
              />
            </Box>
            <div className="details_container">
              <SalesForceDashboardItemstable
                handleStockStatus={this.handleStockStatus}
                stockStatus={this.state.stockStatus}
                isAllDataSelected={isAllDataSelected}
                stockData={stockData}
                handleSelectRecords={this.handleSelectRecords}
                handleUnSelectAllRecords={this.handleUnSelectAllRecords}
                data={currentPageRecords}
                handleCloseMenu={this.handleCloseMenu}
                handleOpenMenu={this.handleOpenMenu}
                openStatusMenu={this.state.openStatusMenu}
                handleSelectAllinCurrentPage={this.handleSelectAllinCurrentPage}
                handleSelectAllinAllPage={this.handleSelectAllinAllPage}
                selectedItems={this.state.selectedItem}
                currentPage={this.state.currentPage}
                rowsPerPage={rowsPerPage}
                perPageCount={configJSON.rowsPerPage}
                handleClickEdit={this.handleClickEdit}
                handleCloseEditingRow={this.handleCloseEditingRow}
                handleUpdate={this.handleUpdate}
                editingRowId={this.state.editingRowId}
                editStockQuantity = {this.state.editStockQuantity}
                onEditStockQuqntity ={this.onEditStockQuqntity}
              />
            </div>
            {currentPageRecords.length > 0 ? (
            <CustomPagination
              dataLength={totalRecords}
              currentPage={currentPage}
              rowsCount={rowsCount}
              startIndex={startIndex}
              endIndex={endIndex}
              handlePagination={this.handleSalesForceDashboardPagination}
              handlePrevNext={this.handleSalesForceDashboardPrevNext}
            />):( <Typography className="no_records_found">No Records found</Typography>)}
          </div>
        )}
      </GlobalStoreDashboardLayout>
    );
  }
}

// Customizable Area Start
export { SalesForceDashboard };
export default withStyles(styles)(withRouter(SalesForceDashboard));
// Customizable Area End
