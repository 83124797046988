import React from "react";
// Customizable Area Start
import { Typography } from "@material-ui/core";
import { AllStockIcon, LowStockIcon, OutOfStockIcon  } from "./assets.web";
import { StockStatus } from "../../blocks/inventorymanagement2/src/types";
import {ICatalougesMeta} from "./types";
interface IProps {
    handleStockStatus: (stockStatus: StockStatus) => void;
    metaData: ICatalougesMeta | null;
    stockStatus: StockStatus;
}

const CommonStatuscard = (props:IProps) => {
    return (
        <>
        <div className="details_container">
            <div className="product_status_list">
                <div className={`statusCard all ${props.stockStatus === ""? "enabled":""}`} onClick={() => props.handleStockStatus("")}>
                    <div className="status_icon" >
                            <AllStockIcon/>
                    </div>
                    <div className="status_detail">
                        <Typography variant="h6">All Products</Typography>
                        <Typography variant="h5">{props?.metaData?.total_catalogues_count || props?.metaData?.total_count}</Typography>
                    </div>
                </div>
                <div className={`statusCard low-stock ${props.stockStatus === "low_stock"?"enabled":""}`} onClick={() => props.handleStockStatus("low_stock")}>
                    <div className="status_icon">
                            <LowStockIcon/>
                    </div>
                    <div className="status_detail" >
                        <Typography variant="h6">Low Stock Products</Typography>
                        <Typography variant="h5">{props?.metaData?.low_stock}</Typography>
                    </div>
                </div>
                <div className={`statusCard out-of-stock ${props.stockStatus === "out_of_stock" ? "enabled":""}`} onClick={() => props.handleStockStatus("out_of_stock")}>
                    <div className="status_icon">
                        <OutOfStockIcon/>
                    </div>
                    <div className="status_detail">
                        <Typography variant="h6">Out of Stock Products</Typography>
                        <Typography variant="h5">{props?.metaData?.out_of_stock}</Typography>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default CommonStatuscard;
// Customizable Area End