import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core";
import {withRouter} from "react-router-dom";

import InwardsInventoryOrderDetailsController,{ Props } from "./InwardsInventoryOrderDetailsController.web";
import DarkStoreDashboardLayout from "../../../components/src/DarkStoreDashboardLayout.web";
import OutboundOrderDetailWeb from "../../../components/src/OutboundOrderDetail.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import Loader from "../../../components/src/Loader.web";
const styles : any = {

}

class InwardsInventoryOrderDetails extends InwardsInventoryOrderDetailsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;

        return (
            <DarkStoreDashboardLayout {...restProps} headerTitle="Inwards Inventory" actionHandler={undefined} headerAction="New Order" subTitle="" hasAccess={true}>
                <div className="main_container">
                    {this.state.orderDetails ? <OutboundOrderDetailWeb
                        handleClose={this.handleClose}
                        anchorEl={this.state.anchorEl}
                        handleClick={this.handleClick}
                        itemList={[]}
                        orderDetails={this.state.orderDetails}
                        globalStore={false}
                        handleUpdateStatus={this.handleUpdateStatus}
                    /> : this.state.isLoading ? <Loader loading={true}/> : <NoAccessPage description="Something went wrong" />}
                </div>
            </DarkStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { InwardsInventoryOrderDetails };
export default withStyles(styles)(withRouter(InwardsInventoryOrderDetails));
// Customizable Area End
