import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { makeStyles, Dialog, DialogContent, Typography, IconButton, Button } from "@material-ui/core";
import { IAddressProps } from "./types";
import { CloseIcon } from './assets.web';

const useStyles : any = makeStyles({
  container:{
    height:"700px",
    display:"flex",
    justifyContent:"center",
  },
  confirmedAddressContainer:{
    background:"#fff !important",
    width:"100%",
    zIndex:1,
    position:"absolute",
    bottom:0,
    padding:"20px",
    "& p":{
      marginBottom:"8px"
    },
    "& button, button:hover, button:active":{
      background: "#652d92",
      color:"#fff",
      height: "55px",
      textTransform: 'initial',
      fontSize: "16px",
      fontWeight:"bold",
      fontFamily:"gilroy-bold",
    }
  },
  paper:{
    marginTop:"100px",
    maxHeight:"580px",
    maxWidth:"618px",
    width:"714px !important",
    overflow:"clip"
},
header:{
    display:"inline-flex",
    paddingTop:"18px",
    marginBottom:"20px",
    "& button":{
        marginLeft:"auto",
        marginRight:"8px"
    },
    "& p":{
        marginLeft:"24px",
        marginTop:"8px"
    }
},
content:{
    padding:"0px",
    overflow:"hidden",
    "& form":{
        marginBottom:"0px !important"
    }
},
map:{
  width: '93%',
}
});

interface IGoogleMapProps {
  lat: number | undefined,
  long: number | undefined,
  selectLocationHandler: (lat:number, long:number) => void,
  autoFillFields: (addressComponents: IAddressProps[] | undefined, currentAddress:string) => void,
  isOpen: boolean,
  handleClose: () => void,
  google: any
}

const GoogleMapContainer = (props:IGoogleMapProps) => {
  const classes = useStyles();
  const [currentAddress, setCurrentAddress] = React.useState("");
  const [addressComponents, setAddressComponents] = React.useState();

    React.useEffect(() => {
      if(props.lat && props.long){
        getAddressFromLatLng(props.lat, props.long);
      }
    },[props.lat, props.long])
  
    const mapStyles = {
        width: '100%',
        height: '544px'
      };

      const handleMarkerClick = (_:any, marker:any, e: any) => {
        const { latLng } = e;
        const lat = latLng.lat();
        const lng = latLng.lng();
        props.selectLocationHandler(lat, lng);
      };

      const getAddressFromLatLng = async (lat: number, lng : number) => {
        const geocoder = new props.google.maps.Geocoder();
        try {
          const response = await geocoder.geocode({ location: { lat, lng } });
          if (response?.results?.length > 0) {
            setAddressComponents(response.results[0].address_components.slice(-5));
            setCurrentAddress(response.results[0].formatted_address);
          }
        } catch (error) {
          console.error('Error fetching address:', error);
        }
      };


      const confirmHandler = () => {
        props.autoFillFields(addressComponents, currentAddress);
      }

      return (
        <Dialog classes={{paper:classes.paper}} open={props.isOpen} onClose={props.handleClose}>
          <div className={classes.header}>
                <Typography>Your location</Typography>
                <IconButton onClick={props.handleClose}><CloseIcon/></IconButton>
          </div>
          <DialogContent className={classes.content}>
            <div className={classes.container}>
              {props.lat && props.long &&<Map
              google={google}
              zoom={14}
              style={mapStyles}
              center={{lat: props.lat, lng: props.long }}
              initialCenter={{
                lat: 37.7749,
                lng: -122.4194
              }}
            >
              <Marker
                onClick={handleMarkerClick}
                zIndex={99999}
                draggable={true}
                position={{ lat: props.lat, lng: props.long }}
              />
              </Map>}
              <div className={classes.confirmedAddressContainer}>
                <Typography>{currentAddress}</Typography>
                <Button onClick={confirmHandler} fullWidth variant="outlined">Confirm & Continue</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyB4O1v9XX-9X6_-6gh-DGZ4bN0JL7_QVNU'
  })(GoogleMapContainer);