import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area End


export const configJSON = require("./config");
export const rows = configJSON.darkstoreOrdersRows;
interface SS {
  id: any;
}

interface IResponseData{
  data:{ data:any[] },
  meta:IMetaData,
  message?: string
}

interface IMetaData{
    pending: number,
    assigned: number,
    delivered: number,
    cancelled:number,
    on_the_way: number,
    packed: number,
    page: number,
    per_page: number,
    total_count: number,
    total_pages: number
}
interface S {
  // Customizable Area Start
  currentPage:number;
  dataList:any;
  total_pages:number;
  startIndex:number;
  endIndex:number;
  metaData:IMetaData;
  noAccess:boolean;
  searchKey:string;
  isOpen:boolean;
  selectedRow:any;
  isLoading:boolean;
  card_status:string
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  setError:any;
  userProfile:any;
  setUserProfile:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number,
  history:any
  // Customizable Area End
}
export default class DarkStoreOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getItemsApiCallId = "";
  setCashCollectedApiCallId = "";
  timer :  any;
  debounceTimeout: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      currentPage:1,
      dataList:[],
      total_pages:0,
      startIndex:0,
      endIndex:0,
      metaData:{
        pending: 0,
        assigned: 0,
        cancelled:0,
        delivered: 0,
        on_the_way: 0,
        packed: 0,
        page: 0,
        per_page: 0,
        total_count: 0,
        total_pages: 0
      },
      noAccess: false,
      searchKey:"",
      isOpen: false,
      selectedRow:null,
      isLoading: false,
      card_status:""
      // Customizable Area End
    }
    this.debounceTimeout = null;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({isLoading: false});

      if(errorReponse){
        this.props.setError(errorReponse);
      }

      if(responseJson){
        if(responseJson.status === 500){
          this.props.setError("Internal server error");
        }else if(responseJson.errors){
          console.log("####errors",responseJson.errors);
        }else{
          this.handleSuccessCallback(responseJson, apiRequestCallId)
        }
      }
      
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount(){
    const { history } = this.props;
    if (history.location.state && history.location.state.orderCurrentPage) {
     this.setState({currentPage:history.location.state.orderCurrentPage,card_status:history.location.state.card_status},async()=>{
      await this.fetchDarkstoreOrders(this.state.currentPage);
     })
    }else{
      await this.fetchDarkstoreOrders(1);
    }

    this.timer =  setInterval(() => {
      const fetch = async() => {
        await this.fetchDarkstoreOrders(this.state.currentPage);
      }
      fetch();
    },10000)
  }

  handleCashCollect = (order:any) => {
    this.setState({isOpen:true, selectedRow: order});
  }

  handleCashCollected = async() => {
    const { selectedRow, dataList } = this.state;
    const findIndex = dataList.findIndex((order:any) => order.id === selectedRow.id);
    dataList[findIndex] = {
      ...dataList[findIndex],
      attributes: {
        ...dataList[findIndex].attributes,
        cash_collected: true
      }
    }
    const body = {
      order_id : selectedRow.id,
      cash_collected : true
    }
    this.handleClose();
    this.setCashCollectedApiCallId = await this.darkstoreOrdersApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.patchDarkstoreOrderMethodType,
      endPoint: configJSON.patchDarkstoreOrderEndPoint,
      body
    });
    this.setState({isLoading: true, dataList: dataList});
  }

  handleClose = () => {
    this.setState({isOpen:false, selectedRow: null});
  }

  handleSuccessCallback = (responseData:IResponseData, apiRequestCallId: string) => {
    if(apiRequestCallId === this.getItemsApiCallId){
     if(responseData.message){
      if(responseData.message === "No orders found"){
        this.setState({dataList:[]})
      }else{
        this.setState({noAccess: true});
      }
      }else if(responseData.data.data.length > 0 ){
        const meta = responseData.meta;
        const data = responseData.data.data;
        const page = meta.page;
        
        const rowsPerPage = configJSON.rowsPerPage;
        let pages = Math.ceil(meta.total_count/configJSON.rowsPerPage);
        this.setPagination(pages);
        
        let updatedEndIndex = rowsPerPage*page;
        let endIndex = rowsPerPage*(page -1) + data.length;
        if(updatedEndIndex > endIndex){
          updatedEndIndex = endIndex;
        }
  
        this.setState({
          dataList: data, metaData: meta, currentPage:meta.page, 
          startIndex: rowsPerPage*page - rowsPerPage + 1, 
          endIndex: updatedEndIndex
        });
      }
    }
    
    if(apiRequestCallId === this.setCashCollectedApiCallId){
      this.fetchDarkstoreOrders(this.state.currentPage);
      if(responseData.message){
        this.props.setError("Something went wrong!")
      }
    }
    
  }

  async componentWillUnmount(): Promise<void> {
    clearInterval(this.timer); 
  }

  setPagination = (total_pages:number) => {
    this.setState({total_pages})
  }

  fetchDarkstoreOrders = async (page:number) => {
    this.setState({isLoading: true});
    this.getItemsApiCallId = await this.darkstoreOrdersApiCall({
      contentType: configJSON.listDarkstoreOrdersContentType,
      method: configJSON.listDarkstoreOrdersMethodType,
      endPoint: `${configJSON.listDarkstoreOrdersEndPoint}?page=${page}&per_page=${configJSON.rowsPerPage}&search_query=${this.state.searchKey}&&status=${this.state.card_status}`,
    });
  }

  getStatus = (row:any) => {
    const value = row?.attributes?.status;
    let status = "";
    if(value === "placed"){
      status = "Pending";
    }else if(value === "confirmed"){
      if(row?.attributes?.deliver_by){
        status = "Assigned";
      }else{
        status = "Packed";
      }
    }else if(value === "in_transit"){
      status = "On the way";
    }else if(value === "delivered"){
      status = "Delivered";
    }else if(value === "cancelled"){
      status = "Cancelled";
    }
    return status;
  }

  darkstoreOrdersApiCall = async(data: any) => {
    const { contentType, method, endPoint, body} = data;
    let token = await localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };
    const darkStoreManagementApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) 
    );
    runEngine.sendMessage(darkStoreManagementApiMessage.id, darkStoreManagementApiMessage);
    return darkStoreManagementApiMessage.messageId;
  };

  handleDarkstoreOrdersPagination = (e: any ,page: number) => {
    this.fetchDarkstoreOrders(page);
  }

   handleSearch = (e: any) => {
    const { value } = e.target;
    this.setState({searchKey: value});
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    
    this.debounceTimeout = setTimeout(() => {
      this.setState({total_pages:0});
      this.fetchDarkstoreOrders(1);
    }, 600);
  }

  handleDarkstoreOrdersPrevNext = (type: any) => {
    const { currentPage, total_pages } = this.state;
    if(type === "previous" && currentPage > 1){
      this.handleDarkstoreOrdersPagination({}, currentPage - 1);
    }else if(type === "next" && currentPage < total_pages + 1){
      this.handleDarkstoreOrdersPagination({}, currentPage + 1);
    }
  }

  navigateToOrderDetails = (orderId:any,e:React.MouseEvent<HTMLTableRowElement>) => {
    if(!this.state.isOpen){
      this.props.history.replace({
        ...this.props.history.location,
        state: {
          ...this.props.history.location.state,
          orderCurrentPage: this.state.currentPage,
          card_status:this.state.card_status
          
        }
      });
      this.props.history.push(`/orders/${orderId}`);
    }
    
  }

  handleOrderStatus = (status:string)=>{
    if(status ===this.state.card_status){
      this.setState({card_status:"",currentPage:1},()=>{
        this.fetchDarkstoreOrders(this.state.currentPage)
      }) 
    }else{
    this.setState({card_status:status,currentPage:1},()=>{
      this.fetchDarkstoreOrders(this.state.currentPage)
    })
   }
  }
  // Customizable Area End
}
