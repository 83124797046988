import React, { ChangeEvent } from "react";
// Customizable Area Start
import { makeStyles, Grid, FormControl, FormLabel, TextField, Typography, IconButton, Dialog, DialogContent } from "@material-ui/core";
import ImageIcon from '@material-ui/icons/Image';
import { EditIcon, CloseIcon } from "./assets.web";
import AddressField from "./AddressField.web";
import GoogleMapContainer from "./GoogleMapContainer.web";
import { IAddressProps, IVendorManagementFormValues, IVendorManagementFormTouched, IVendorManagementFormError } from "./types";
import { regexNumeric} from "../../components/src/CommonFunctions";

const useStyles: any = makeStyles({
    paper:{
        marginTop:"100px",
        maxHeight:"580px",
        maxWidth:"900px",
        width:"714px !important"
    },
    header:{
        display:"inline-flex",
        paddingTop:"18px",
        marginBottom:"20px",
        "& button":{
            marginLeft:"auto",
            marginRight:"8px"
        },
        "& p":{
            marginLeft:"24px",
            marginTop:"8px",
            fontFamily:"gilroy-bold"
        }
    },
    content:{
        padding:"8px 24px 0px",
        "& form":{
            marginBottom:"0px !important"
        }
    },
    formControl: {
        marginBottom: "12px",
        padding: "0 8px 0",
        "& label": {
            textAlign: "start",
            color: "black",
            fontWeight: "500",
            fontSize: "14px",
        },
        " & input": {
            fontFamily: "gilroy-regular",
            fontSize: "14px !important"
        },
        "& a, a:hover, a:active": {
            textDecoration: "none",
            marginRight: "0px",
            marginLeft: "auto",
            color: "rgba(0, 0, 0, 0.54)",
            "& h6": {
                paddingBottom: "0px !important",
                fontSize: "14px !important"
            }
        },
        "& p": {
            color: "red"
        }
    },
    gridItem: {
        display: "flex",
        flexFlow: "column"
    },
    imgInput: {
        "& input": {
            display: "none"
        },
        "& fieldset": {
            height: "130px",
            borderStyle: "dashed"
        },
        "& ,MuiFormControl-root-*": {
            marginTop: "0px !important"
        }
    },

    fileInputContainer: {
        height: "150px",
        position: "relative",
        border: "2px dashed #e7e7e7",
        borderRadius: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
        cursor: "pointer",
        width: "160px",
        "& img": {
            borderRadius: "12px",
            height: "150px",
            width: "160px",
        }
    },
    imageInputGrid: {
        marginBottom: "24px",
        "& p": {
            marginBottom: "0px !important",
            fontFamily: "gilroy-medium !important",
            marginLeft:"40px !important"
            //textAlign:"center"
        },
        "& button, button:hover, button:active": {
            position: "absolute",
            bottom: -10,
            right: -18,
            background: "#652d92",
            "& path": {
                fill: "#fff"
            }
        }
    },
});

interface IVendorManagementFormProps {
    values: IVendorManagementFormValues | null,
    touched: IVendorManagementFormTouched | null,
    errors: IVendorManagementFormError | null,
    handleChange: (e:ChangeEvent<any>) => void,
    handleLocationFields: (lat:number, lng:number) => void,
    autoFillAddressHandler: (addressComponents:IAddressProps[] | undefined,currentAddress:string) => void,
    image: {url?:string},
    selectLocationHandler: (lat:number, lng:number) => void,
    isOpenMap: boolean,
    handleImageChange: (event:any) => void,
    hiddenFileInput: React.RefObject<HTMLInputElement>,
    handleClick: any,
    searchFieldHandler: () => void,
    isOpenSearch: boolean,
    isEdit: boolean,
    containerStyles: string,
    handleClose: () => void,
    searchKey: string,
    onChangeHandler: (address: string) => void,
    children?: React.ReactNode,
    validationSchema?:any
}

const renderImageContainer = (props: IVendorManagementFormProps, classes:any) => {

    const  {values, hiddenFileInput,handleClick,handleImageChange, isEdit, image } = props;
    return (<Grid item xs={12} sm={12} md={2} className={`${classes.gridItem} ${classes.imageInputGrid}`}>
    <div className={classes.fileInputContainer} onClick={handleClick}>
        <input
            accept="image/*"
            style={{ display: "none" }}
            ref={hiddenFileInput}
            type="file"
            onChange={handleImageChange}
        />
        {isEdit ? <IconButton><EditIcon /></IconButton> : <></>}
        {(!!image && image.url !== null)? <img src={image.url} /> : (!!values && !!values.photo && values.photo.url !== null)? <img src={values.photo.url} /> : <ImageIcon />}
    </div>
    {isEdit ? <></> : <Typography>Add Picture</Typography>}
</Grid>)
}

const VendorManagementFormCommon = (props: IVendorManagementFormProps) => {
    const classes = useStyles();
    const { isEdit,
        values,
        touched,
        errors,
        handleChange,
        handleLocationFields,
        containerStyles,handleClose,autoFillAddressHandler, selectLocationHandler,isOpenMap, searchFieldHandler, isOpenSearch  } = props;


       const handleNumericOnChange = (event: React.ChangeEvent<HTMLInputElement>, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void) => {
            if(event.target.value === "" || regexNumeric.test(event.target.value)){
                handleChange(event);
            }
        }

        return (
        <>
            <div className={containerStyles}>
                <Grid container>
                    {renderImageContainer(props,classes)}
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                    <Grid item xs={12} sm={12} md={isEdit ? 9 : 7} className={classes.gridItem}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Legal Name</FormLabel>
                            <TextField
                                name="legal_name"
                                value={values?.legal_name}
                                error={!!errors?.legal_name && touched?.legal_name}
                                placeholder="Enter Vendor Full Name"
                                onChange={handleChange}
                                helperText={touched?.legal_name ?
                                    errors?.legal_name: ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel>Trade Name</FormLabel>
                            <TextField
                                name="trade_name"
                                value={values?.trade_name}
                                placeholder="Enter Vendor Trade Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Email</FormLabel>
                            <TextField
                                name="email"
                                error={!!errors?.email && touched?.email}
                                value={values?.email}
                                type="email"
                                placeholder="Enter Vendor Email"
                                onChange={handleChange}
                                helperText={touched?.email ? errors?.email : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Contact</FormLabel>
                            <TextField
                                value={values?.contact}
                                error={touched?.contact && !!errors?.contact}
                                placeholder="Enter Vendor Contact"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNumericOnChange(event, handleChange)}
                                helperText={touched?.contact ? errors?.contact : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="contact"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Address</FormLabel>
                            <TextField
                                value={values?.address}
                                error={touched?.address && !!errors?.address}
                                placeholder="Enter Vendor Address"
                                onClick={searchFieldHandler}
                                helperText={touched?.address ? errors?.address : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="address"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                value={values?.country}
                                placeholder="Country"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="country"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                value={values?.state}
                                placeholder="State"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="state"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                value={values?.city}
                                placeholder="City"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="city"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                value={values?.district}
                            
                                placeholder="District"
                                onChange={handleChange}

                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="district"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                value={values?.pincode}
                                placeholder="Pin"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNumericOnChange(event, handleChange)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="pincode"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Store Size in Sft</FormLabel>
                            <TextField
                                value={values?.store_size}
                                error={touched?.store_size && !!errors?.store_size}
                                placeholder="Store size"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNumericOnChange(event, handleChange)}
                                helperText={touched?.store_size ? errors?.store_size : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="store_size"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Dark Store Limit &#91;km&#93;</FormLabel>
                            <TextField
                                value={values?.limit}
                                error={touched?.limit && !!errors?.limit}
                                placeholder="Dark store Limit"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNumericOnChange(event, handleChange)}
                                helperText={touched?.limit ? errors?.limit : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="limit"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel >GSTIN</FormLabel>
                            <TextField
                                value={values?.gstin}
                                placeholder="Vendor's GST Number"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="gstin"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >PAN</FormLabel>
                            <TextField
                                value={values?.pan}
                                error={touched?.pan && !!errors?.pan}
                                placeholder="Vendor's PAN Number"
                                onChange={handleChange}
                                helperText={touched?.pan ? errors?.pan : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="pan"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <div className={containerStyles}>
                <Typography>Authorized Person Details </Typography>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Authorized Person Name</FormLabel>
                            <TextField
                                value={values?.authorized_person_name}
                                error={!!errors?.authorized_person_name && touched?.authorized_person_name}
                                placeholder="Enter Name"
                                onChange={handleChange}
                                helperText={touched?.authorized_person_name ? errors?.authorized_person_name : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="authorized_person_name"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Designation</FormLabel>
                            <TextField
                                value={values?.authorized_person_designation}
                                error={!!errors?.authorized_person_designation && touched?.authorized_person_designation}
                                placeholder="Enter Designation"
                                onChange={handleChange}
                                helperText={touched?.authorized_person_designation ? errors?.authorized_person_designation : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="authorized_person_designation"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Contact Number of Authorized Person</FormLabel>
                            <TextField
                                value={values?.authorized_person_contact}
                                error={!!errors?.authorized_person_contact && touched?.authorized_person_contact}
                                placeholder="Contact Number"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNumericOnChange(event, handleChange)}
                                helperText={touched?.authorized_person_contact ? errors?.authorized_person_contact : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="authorized_person_contact"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <div className={containerStyles}>
                <Typography>Bank Details </Typography>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 12 : 10} >
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >Bank Account Number</FormLabel>
                            <TextField
                                value={values?.bank_account_number}
                                error={!!errors?.bank_account_number && touched?.bank_account_number}
                                placeholder="Bank Account Number"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNumericOnChange(event, handleChange)}
                                helperText={touched?.bank_account_number ? errors?.bank_account_number : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="bank_account_number"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required >IFSC Code</FormLabel>
                            <TextField
                                value={values?.ifsc}
                                error={!!errors?.ifsc && touched?.ifsc}
                                placeholder="Enter IFSC Code"
                                onChange={handleChange}
                                helperText={touched?.ifsc ? errors?.ifsc : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="ifsc"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={isEdit ? 6 : 5} lg={isEdit ? 6 : 5} xl={isEdit ? 6 : 5}>
                        <FormControl fullWidth className={classes.formControl}>
                            <FormLabel required>Bank Branch</FormLabel>
                            <TextField
                                value={values?.bank_branch}
                                error={!!errors?.bank_branch && touched?.bank_branch}
                                placeholder="Enter Bank Branch"
                                onChange={handleChange}
                                helperText={touched?.bank_branch ? errors?.bank_branch : ""}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="bank_branch"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {props.children}
            </div>
            {isOpenSearch && <Dialog open classes={{paper:classes.paper}}>
                <div className={classes.header}>
                    <Typography>Your location</Typography>
                    <IconButton onClick={searchFieldHandler}><CloseIcon/></IconButton>
                </div>
                <DialogContent className={classes.content}>
                    <AddressField selectLocationHandler={(lat:number, lng:number) => {
                        selectLocationHandler(lat, lng);
                        handleLocationFields(lat,lng)
                    }} searchKey={props.searchKey} onChangeHandler={props.onChangeHandler} />
                </DialogContent>
            </Dialog>}
            <GoogleMapContainer 
            autoFillFields={(addressComponents:IAddressProps[] | undefined,currentAddress:string) => {
                autoFillAddressHandler(addressComponents, currentAddress);
            }}
            selectLocationHandler={(lat:number, lng:number) => {
                handleLocationFields(lat,lng)
               }} lat={values?.latitude} long={values?.longitude} isOpen={isOpenMap} handleClose={handleClose}/>
        </>
    )
}


// Customizable Area Start
export default VendorManagementFormCommon;
// Customizable Area End