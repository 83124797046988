import React from "react";
// Customizable Area Start
import { Grid, Typography, IconButton, makeStyles } from "@material-ui/core";
const mainLogo = require("./assets/images/Docile.jpeg");
const leftArrowLogo = require("./assets/images/FullArrowLeft.jpeg");
// Customizable Area End

// Customizable Area Start
const useStyles : any = makeStyles({
    authContainer:{
        width:"80%",
        margin:"60px auto"
    },
    gridContainer:{
        height:"678px"
    },
    logo:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        "& img" : {
            height:"340px",
            // margin:"auto",
            marginLeft:"25px"
        }
    },
    form:{
        padding:"24px 18px", 
    },
    landing:{
        padding:"64px 18px 24px 18px", 
    },
    formGrid:{
        display:"flex",
        flexFlow:"column",
        justifyContent:"center",
        padding:"16px 12px",
        "& h6, h4":{
            textAlign: "start", 
        },
        "& h4":{
            fontWeight: "bold",
            fontSize: "36px",
            paddingBottom: "18px",
            fontFamily:"Plus Jakarta Sans"
        },
        "& h6":{
            fontSize: "18px",
            fontWeight: "400",
            paddingBottom: "24px",
            fontFamily:"Plus Jakarta Sans"
        },
        "& button.backBtn, button.backBtn:hover, button.backBtn:active":{
            width:"fit-content",
            background:"#fff",
            "& img":{
                width:"20px"
            }
        },
    }
})
// Customizable Area End
interface ICustomAuthModelProps {
    description: string,
    haveBackButton: boolean,
    title: string,
    children: React.ReactNode,
    backHandler?: any,
    className?: string,
}

const CommonAuthModal = (props:ICustomAuthModelProps) => {
    const classes = useStyles();
    return (
        <div className={classes.authContainer}>
        <Grid container className={classes.gridContainer}>
            <Grid className={classes.logo} item xl={5} lg={5} md={5} sm={12} xs={12}>
            <img src={mainLogo} alt="landingPage"/>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}></Grid>
            <Grid className={classes.formGrid} item xl={5} lg={5} md={5} sm={12} xs={12}>
                   {props.haveBackButton ? <IconButton data-test-id="backBtnNext" className="backBtn" onClick={props.backHandler}><img src={leftArrowLogo} alt="landingPage"/></IconButton>:<></>}
                    <div className={props.className ? props.className : props.haveBackButton ? classes.form: classes.landing}>
                        <Typography variant="h4">{props.title}</Typography>
                        <Typography variant="h6">{props.description}</Typography>
                        {props.children}
                    </div>
            </Grid>
        </Grid>
        
    </div>
    )
}

// Customizable Area Start
export default CommonAuthModal;
// Customizable Area End
