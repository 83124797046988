import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface myProps {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  // Customizable Area Start
  root: {
    position: "relative",
    display:"flex",
    justifyContent:"center",
    alignContent:"center",
    flexDirection:"column",
    zIndex: 1,
    height:"100%",
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  // Customizable Area End
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div />
  );
}
