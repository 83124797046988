import React from "react";
// Customizable Area Start
import { TextField, IconButton, withStyles, Grid, Typography, Popover, FormControl, FormLabel, Avatar } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import WarehouseDetailsController,{ Props } from "./WarehouseDetailsController.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import { KebabIcon, SearchIcon, LinkIcon } from "../../../components/src/assets.web";
import CustomPopup from "../../../components/src/CustomPopup.web";
import AlertPopup from "../../../components/src/AlertPopup.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import Loader from "../../../components/src/Loader.web";
const warehouseImg = require("../../../components/src/assets/images/warehouse.jpeg");

const styles : any = {
    container:{
        margin:"24px",
        //padding:"24px",
        background:"#fff",
        borderRadius:"10px",
        maxWidth:"1192px"
    },
    textField:{
        margin:"24px",
        "& .MuiOutlinedInput-root":{
            height:"40px",
        },
        '& fieldset':{
            border:"none",
        },
        background:"#F7F7F7",
        width:"410px",
        borderRadius:"4px"
    },
    detailsContainer:{
        paddingLeft:"12px",
        // "& .title":{
        //     marginBottom:"24px"
        // },
        "& h6":{
            fontSize:"16px",
            fontFamily:"gilroy-bold",
        }
    },
    accountList:{
        display:"inline-flex",
        flexWrap:"wrap",
        margin:"0 12px"
        //width:"max-content"
    },
    warehouseGrid:{
        maxWidth:"max-content",
        marginRight:"24px",
        marginBottom:"24px"
    },
    warehouseContainer:{
        //border:"1px solid #E6E7EC",
        borderRadius:"6px",
        margin:"auto 22px 22px 0px",
        display:"inline-flex",
        width:"607px",
        background:"#F7F7F7"
    },
    linkedAccounts:{
        // margin:"auto 22px 22px 0px",
        // display:"inline-flex",
    },
    linkedAccountList:{
        marginTop:"12px",
        display:"inline-flex",
        flexWrap: "wrap"
    },
    warehouseImg:{
       // margin:"16px",
        padding:"16px",
        width:"max-content",
        maxWidth:"max-content",
        height:"min-content",
        "& img":{
            width:"145px",
            height:"132px",
            borderRadius:"6px"
        }
    },
    userProfileImg:{
        padding:"16px",
        width:"max-content",
        maxWidth:"max-content",
        height:"min-content",
        "& img":{
            width:"45px",
            height:"40px",
            borderRadius:"6px"
        }
    },
    warehouseAccDetails:{
        margin:"16px auto 16px 0",
        padding:"12px 0",
        "& h6":{
            lineHeight:"1em !important",
            marginBottom:"8px",
        },
    },
    accountDetails:{
        display:"flex",
        flexFlow:"column",
        justifyContent:"center",
        "& h6":{
            fontFamily:"gilroy-medium"
        },
        "& p":{
            fontFamily:"gilroy-regular",
            margin:"0px !important"
        }
    },
    accountContainer:{
        display:"inline-flex",
        border:"1px solid #E6E7EC",
        borderRadius:"12px",
        width:"335px"
    },
    detail:{
        display:"inline-flex",
        width:"100%",
        paddingBottom:"5.5px",
        "& p":{
            margin:"0px !important",
            fontSize:"12px",
            width:"50%",
            fontFamily:"gilroy-light"
        },
        "& span":{
            marginRight:"12px",
            width:"50%",
            fontFamily:"gilroy-medium"
        }
    },
    linkBtn:{
        display:"flex",
        "& button":{
            height:"42px",
            marginRigjt:"0px",
            marginLeft:"auto"
        }
    },
    pagination:{
        margin:"0 24px",
        paddingBottom:"18px"
    },
    empty:{
        display:"flex",
        flexFlow:"column",
        justifyContent:"center",
        alignItems:"center",
        height:"100%",
        width:"100%",
        background:"#fff",
        "& p":{
            fontFamily:"gilroy-bold",
            marginTop:"24px"
        }
    },
    warehouseDetails:{
        padding:"0 12px",
        "& h6":{
            marginBottom:"24px",
            fontSize:"16px",
            fontFamily:"gilroy-bold",
        }
    },
    editPopper:{
        display:"inline-flex",
        padding:"10px 8px",
        width:"166px",
        height:"50px",
        alignItems:"center",
        cursor:"point",
        "& h6":{
            fontFamily:"gilroy-medium",
            fontSize:"14px",
        },
        "& svg":{
            marginRight:"8px"
        }
    },
    paper:{
        boxShadow:"0 0 8px 0 rgba(0, 0, 0, 0.05) !important",
        background:"#fff",
        borderRadius:"8px !important"
    },
    popoverPaper:{
        width:"714px !important"
    },
    formControl:{
        marginBottom:"24px",
        "*":{
            fontFamily:"gilroy-regular !important"
        },
        "& label":{
            textAlign:"start",
            color: "black",
            fontWeight:"500",
            fontSize:"14px",
        },
        " & input":{
            fontFamily:"Plus Jakarta Sans",
            fontSize:"14px !important"
        },
        "& a, a:hover, a:active":{
            textDecoration: "none",
            marginRight:"0px",
            marginLeft: "auto",
            color:"rgba(0, 0, 0, 0.54)",
            "& h6":{
                paddingBottom:"0px !important",
                fontSize:"14px !important"
            }
        },
        "& p":{
            color:"red"
        }
    },
}

class WarehouseDetails extends WarehouseDetailsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { warehouse, open, anchorEl, linkNewAccountForm, isLinkPopup, email, remark,isLoading } = this.state;         
        return (
            <GlobalStoreDashboardLayout {...restProps} headerTitle="Dark Store Management" actionHandler={this.handleLinkNewVendor} headerAction="Link New Account" subTitle={warehouse?.attributes?.legal_name} hasAccess={true}>
              {warehouse ?  <div className={classes.container}>
                <TextField 
                    className={classes.textField}
                    placeholder="Search Linked Accounts"
                    variant="outlined"
                    InputProps={{
                        endAdornment: <IconButton><SearchIcon/></IconButton>
                    }}
                    value={this.state.searchAccount}
                    onChange={this.searchLinkAccount}
                 />
                <div className={classes.detailsContainer}>
                    <div className={classes.warehouseDetails}>
                        <Typography  variant="h6">Warehouse Details</Typography>
                        <div className={classes.warehouseContainer}>
                        <Grid item xl={4} lg={4} md={4} xs={12} sm={12}  className={classes.warehouseImg}>
                            <img src={warehouse?.attributes?.photo?.url || warehouseImg} alt="warehouse" />
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} xs={12} sm={12} className={classes.warehouseAccDetails}>
                            <Typography variant="h6">{warehouse.attributes.legal_name}</Typography>
                            <div className={classes.detail}>
                                <span>Phone Number</span> <p>{warehouse.attributes.contact}</p>
                            </div>
                            <div className={classes.detail}>
                                <span>Address</span>  <p>{warehouse.attributes.address} </p>
                            </div>
                        </Grid>
                        </div>
                    </div>
                    <Grid container className={classes.accountList}>
                        {this.state.filteredList && this.state.filteredList.length > 0 
                        ? <div className={classes.linkedAccounts}>
                        <Typography  variant="h6">Linked Account</Typography>
                        <div className={classes.linkedAccountList}>
                        {this.state.filteredList.map((account: any) => {
                            const email = account.attributes.email;
                            return (
                                <Grid  key={account.attributes.unique_auth_id} item xl={4} lg={6} md={6} xs={12} sm={12} className={classes.warehouseGrid}>
                                    <Grid container key={account.attributes.unique_auth_id} className={classes.accountContainer}>
                                        <Grid item xl={3} lg={3} md={3} xs={12} sm={12}  className={classes.userProfileImg}>
                                            <Avatar>
                                                {email.charAt(0).toUpperCase()}
                                            </Avatar>
                                        </Grid>
                                        <Grid item xl={7} lg={7} md={7} xs={12} sm={12} className={classes.accountDetails}> 
                                            <Typography variant="h6">{email ? email.substring(0, email.indexOf("@")): ""}</Typography>
                                            <p>{email}</p>
                                        </Grid>
                                        <Grid item xl={2} lg={2} md={2} xs={2} sm={2} className={classes.linkBtn}>
                                            <IconButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleLinkAccount(e, account)}>
                                                <KebabIcon/>
                                            </IconButton>
                                            <Popover 
                                                anchorEl={anchorEl} 
                                                open={open} 
                                                PaperProps={{className: classes.paper}}
                                                anchorOrigin={{horizontal:"left", vertical:"bottom"}}
                                                onClose={this.handleClose}
                                            > 
                                                <div className={classes.editPopper} data-test-id = "unlink-account" onClick={this.handleUnlinkAccuount}>
                                                    <LinkIcon/>
                                                    <Typography variant="h6">Unlink Account</Typography>
                                                </div>
                                            </Popover>
                                        </Grid>
                                    </Grid>
                                </Grid>
                              )
                        })}
                        </div>
                       
                        </div>
                    :<></>}
                    </Grid>  
                </div>
                <CustomPopup isOpen={linkNewAccountForm} title="Link New Account" action="Confirm" paperClass={classes.popoverPaper} handleClose={this.handleClose} actionHandler={this.linkNewAccountSubmitHandler}>
                    <form>
                        <Grid container>
                            <Grid item  sm={12} xs={12} xl={12} lg={12} md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <FormLabel>Remark</FormLabel>
                                    <TextField
                                        value={remark.value} 
                                        placeholder="Enter company name here"
                                        className="txtInputRemark"
                                        margin="normal"
                                        variant="outlined"
                                        name="remark"
                                        helperText={remark.error || ""}
                                        onChange={this.remarkFieldHandler}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item  sm={12} xs={12} xl={12} lg={12} md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <FormLabel>Email</FormLabel>
                                    <TextField
                                        value={email.value} 
                                        placeholder="Enter email here"
                                        className="txtInputEmail"
                                        margin="normal"
                                        variant="outlined"
                                        name="email"
                                        helperText={email.error || ""}
                                        onChange={this.emailFieldHandler}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </CustomPopup>
                <AlertPopup 
                    isOpen={isLinkPopup}
                    cancelHandler={this.handleClose}
                    actionHandler={this.unlinkHandler}
                    handleClose={this.handleClose}
                    action="Unlink" 
                    description={<Typography variant="h6">Are You Sure that you want to <span>Unlink</span> this account ?</Typography>}/>
                </div>
                : isLoading ? <Loader loading={isLoading}/> : <NoAccessPage description="Something went wrong"/>}
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { WarehouseDetails };
export default withStyles(styles)(withRouter(WarehouseDetails));
// Customizable Area End