import React from "react";

export const CloseRoundLine = () => {
    return (
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-18" transform="translate(-1144.000000, -664.000000)" fill="#000000" fillRule="nonzero">
            <g id="close-round-line-icon" transform="translate(1144.000000, 664.000000)">
                <path d="M8.99992676,0 C11.4851604,0 13.7351421,1.00737305 15.3638886,2.63613281 C16.9926352,4.26474609 18,6.51489258 18,9 C18,11.4854004 16.9926352,13.7352539 15.3638886,15.3638672 C13.7351421,16.992627 11.4851604,18 8.99992676,18 C6.51469308,18 4.26471139,16.992627 2.63611136,15.3638672 C1.00736485,13.7352539 0,11.4852539 0,8.99985352 C0,6.51474609 1.00736485,4.26459961 2.63611136,2.63598633 C4.26471139,1.00737305 6.51469308,0 8.99992676,0 L8.99992676,0 Z M11.7420919,5.47397461 C11.9326666,5.28325195 12.2418926,5.28325195 12.4324672,5.47397461 C12.6230418,5.66455078 12.6230418,5.97363281 12.4324672,6.16435547 L9.59640628,9.00058594 L12.4324672,11.8368164 C12.6230418,12.0273926 12.6230418,12.3364746 12.4324672,12.5271973 C12.2418926,12.7177734 11.9326666,12.7177734 11.7420919,12.5271973 L8.90588455,9.6909668 L6.06967717,12.5273438 C5.87910255,12.7179199 5.56987655,12.7179199 5.37930193,12.5273438 C5.18872731,12.3366211 5.18872731,12.0275391 5.37930193,11.8369629 L8.2156558,9.00073242 L5.37930193,6.16435547 C5.18872731,5.97363281 5.18872731,5.66455078 5.37930193,5.47397461 C5.56987655,5.28325195 5.87910255,5.28325195 6.06967717,5.47397461 L8.90603104,8.31020508 L11.7420919,5.47397461 L11.7420919,5.47397461 Z M8.99992676,0.976464844 C6.78422213,0.976464844 4.77842791,1.87441406 3.3264866,3.32636719 C1.87454529,4.77832031 0.976456897,6.78427734 0.976456897,8.99985352 C0.976456897,11.2155762 1.87454529,13.2215332 3.32634012,14.6734863 C4.77828143,16.125293 6.78407565,17.0233887 8.99978028,17.0233887 C11.2154849,17.0233887 13.2214256,16.125293 14.6733669,14.6734863 C16.1253082,13.2215332 17.0233966,11.2155762 17.0233966,8.99985352 C17.0233966,6.78427734 16.1254547,4.77832031 14.6735134,3.32636719 C13.2215721,1.87441406 11.2156314,0.976464844 8.99992676,0.976464844 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
    )
}