import React, { Component } from "react";
import {
  Button,
  FormLabel,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { AllStockIcon, LowStockIcon, OutOfStockIcon } from "./assets.web";
import { Item, StockStatus } from "../../blocks/salesteamdashboard12/src/types";
import { stockStatusLabel } from "./CommonFunctions";
const inventoryImg = require("./assets/images/product.jpeg");
const editImage = require("./assets/images/Edit.png");
const cnacelImage = require("./assets/images/Close.png");
const saveImage = require("./assets/images/CheckMark.png");
interface IProps {
  classes: any;
  rowsPerPage: number;
  stockStatus: StockStatus;
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openStatusMenu: null | HTMLElement;
  handleCloseMenu: () => void;
  handleStockStatus: (stockStatus: StockStatus) => void;
  handleSelectAllinAllPage: () => void;
  handleSelectAllinCurrentPage: () => void;
  data: Item[];
  handleSelectRecords: (data: Item) => void;
  handleUnSelectAllRecords:  (event: React.MouseEvent<HTMLButtonElement>) => void
  selectedItems: Record<number | string, any>;
  isAllDataSelected: boolean;
  stockData: {
    allProducts: number,
    lowStock: number,
    outOfStock: number,
  }
  currentPage: number,
  perPageCount: number
  handleClickEdit : (id:string, quantity:number) =>void
  handleCloseEditingRow:()=>void
  handleUpdate:()=>void
  editingRowId:string | null
  editStockQuantity : number
  onEditStockQuqntity : (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>void
}

const styles: any = {
  styleCells: {
    textAlign: "left",
    width: "auto"
  },
  searchField: {
    width: "50%",
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  darkStoreSelectOptionDiv: {
    display: "flex",
    width: "30%",
    gap: "30px",
    alignItems: "center",
    marginTop: "50px",
  },
  fontFamily: {
    fontFamily: "Gilroy",
  },
  TableColumnWidth: {
    width: "10rem"
  },
  cursorPointor: {
    cursor: "pointer"
  },
  fontWeight500: {
    fontFamily: "gilroy-medium",
  },
  fontSize12: {
    fontSize: "12px",
  },
  fontSize14: {
    fontSize: "14px",
  },
  select: {
    marginLeft: "24px",
  },
  green: {
    backgroundColor: "#E5FCDA",
    color: "rgb(123, 163, 59)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  orange: {
    backgroundColor: "#FEE5D4",
    color: "rgb(173, 132, 47)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  red: {
    backgroundColor: "#FED4D4",
    color: "rgb(184, 67, 55)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  tableHeadings: {
    color: "#8181A5",
    fontFamily: "gilroy-medium",
    fontSize: "13px",
  },
  tableRowStyles: {
    color: "#1C1D21",
    fontFamily: "gilroy-medium",
    fontSize: "14px",

  },
  radioBtnSize: {
    height: "20px",
    width: "20px"
  },
  selectRadioBtn: {
    height: " 20px",
    width: "20px",
    accentColor: "black",
  },
  flex:{
    display : "flex"
  },
  input:{
    outline:'none',
    width:"60px",
    padding:"5px",
    marginRight: '5px'
  },
  flexEnd:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"end"
  },
  img:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center"
  }
};
const tableHeadings = [
  "Select All",
  "S No",
  "Item Details",
  "Item Code",
  "Quantity",
  "Stock Level",
];

class SalesForceDashboardItemstable extends Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { classes, data, stockData, stockStatus, perPageCount } = this.props;
    return (<>
      <div className="details_container">
        <div className="product_status_list">
          <div className={`statusCard all ${classes.cursorPointor}`} style={{ backgroundColor: "#E0F7E4", border: stockStatus === "" ? "1px solid #00C120" : "none" }} onClick={() => this.props.handleStockStatus("")}>
            <div className="status_icon">
              <AllStockIcon />
            </div>
            <div className="status_detail">
              <Typography variant="h6">All Products</Typography>
              <Typography variant="h5">{stockData.allProducts}</Typography>
            </div>
          </div>
          <div className={`statusCard low-stock ${classes.cursorPointor}`} style={{ backgroundColor: "#F7EFE0", border: stockStatus === "low_stock" ? "1px solid #ED9B00" : "none" }} onClick={() => this.props.handleStockStatus("low_stock")}>
            <div className="status_icon">
              <LowStockIcon />
            </div>
            <div className="status_detail">
              <Typography variant="h6">Low Stock Products</Typography>
              <Typography variant="h5">{stockData.lowStock}</Typography>
            </div>
          </div>
          <div className={`statusCard out-of-stock ${classes.cursorPointor}`} style={{ backgroundColor: "#F7E0E0", border: stockStatus === "out_of_stock" ? "1px solid #D10000" : "none" }} onClick={() => this.props.handleStockStatus("out_of_stock")}>
            <div className="status_icon">
              <OutOfStockIcon />
            </div>
            <div className="status_detail">
              <Typography variant="h6">Out of Stock Products</Typography>
              <Typography variant="h5">{stockData.outOfStock}</Typography>
            </div>
          </div>
        </div>
      </div>
      <Typography variant="h6" className={`${classes.fontSize16} ${classes.fontWeight700}`} >{this.props.stockStatus === "out_of_stock"  ? "Out Of Stock Items" : this.props.stockStatus === "low_stock" ? "Low Stock Items" : "All Items"}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeadings.map((row: string) => {
              if (row === "Select All") {
                return (
                  <TableCell
                    className={`${classes.tableHeadings} ${classes.TableColumnWidth}`}
                    key={row}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>

                      <label className="radio-label">
                        <input
                          style={{ width: "20px", height: "20px", accentColor: "black",cursor:"pointer" }}
                          type="radio"
                          name={"selectRadio"}
                          checked={this.props.isAllDataSelected && Object.keys(this.props.selectedItems).length >0 }
                          disabled={data.length < 1 ? true : false}
                          onClick={(e:any) => this.props.handleUnSelectAllRecords(e)}
                        />  <span className="custom-radio"></span>
                      </label>

                      <Button
                        disabled={data.length < 1 ? true : false}
                        aria-controls="simple-menu"
                        style={{ float: "left" }}
                        className={classes.tableHeadings}
                        onClick={this.props.handleOpenMenu}
                      >
                        Select All
                      </Button>
                      <Menu
                        style={{ marginLeft: "35px" }}
                        className={`${classes.tableHeadings} purchaseOrderUpdateStatusPopUp`}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ horizontal: "center", vertical: "top" }}
                        id="simple-menu"
                        anchorEl={this.props.openStatusMenu}
                        keepMounted
                        open={Boolean(this.props.openStatusMenu)}
                        onClose={this.props.handleCloseMenu}
                      >
                        <MenuItem
                          className={classes.tableHeadings}
                          onClick={this.props.handleSelectAllinAllPage}
                          >
                          Select all in all pages
                        </MenuItem>
                        <MenuItem
                          className={classes.tableHeadings}
                          onClick={this.props.handleSelectAllinCurrentPage}
                        >
                          Select all in current page
                        </MenuItem>
                      </Menu>
                    </span>

                  </TableCell>
                );
              }
              return row === "Item Details" ? (
                <TableCell
                  style={{ width: "20rem", fontFamily : 'gilroy-medium', fontSize: "13px" }}
                  key={row}
                  align="left"
                >
                  {row}
                </TableCell>
              ) : (<TableCell
                className={`${classes.tableHeadings} ${classes.TableColumnWidth}`}
                key={row}
                align="left"
              >
                {row}
              </TableCell>)
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((ele: any, index: number) => {
            return (
              <TableRow className={`${classes.tableRowStyles}`} key={ele.id}>
                <TableCell className={classes.styleCells}>
                  <label className="radio-label">
                    <input
                      type="radio"
                      id={ele.id}
                      name={ele.id}
                      className={classes.selectRadioBtn}
                      checked={this.props.selectedItems[ele.id]? true : false}
                      onClick={() => this.props.handleSelectRecords(ele)}
                    />  <span className="custom-radio"></span>
                  </label>

                </TableCell>
                <TableCell className={classes.styleCells}>
                  <FormLabel className={`${classes.tableRowStyles}`}>
                    {index + 1 + (this.props.currentPage - 1) * perPageCount}
                  </FormLabel>
                </TableCell>{" "}
                <TableCell
                  className={classes.styleCells}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={
                      ele.attributes?.images ? ele.attributes?.images[0].url : inventoryImg
                    }
                    width={"50px"}
                    height={"50px"}
                    alt={"ProductImage"}
                  />
                  <FormLabel style={{ marginLeft: "7px" }} className={`${classes.tableRowStyles}`}>
                    {ele.attributes?.name},
                  </FormLabel>
                  <FormLabel style={{ marginLeft: "5px" }} className={`${classes.tableRowStyles}`}>
                    {ele.attributes?.weight}{ele.attributes?.weight_unit?.charAt(0).toLowerCase()}
                  </FormLabel>
                </TableCell>{" "}
                <TableCell className={classes.styleCells}>
                  <FormLabel className={`${classes.tableRowStyles}`}>
                    {ele.attributes?.item_code}
                  </FormLabel>
                </TableCell>{" "}
                <TableCell className={classes.styleCells}>
                  <FormLabel className={`${classes.tableRowStyles} ${classes.flex}`}>
                    {this.props.editingRowId === ele.id ?(<input className={classes.input} type="number" min="1" value={this.props.editStockQuantity} onChange={this.props.onEditStockQuqntity}/>):<span className={classes.flexEnd} style={{lineHeight:"inherit"}}> {ele.attributes?.stock_qty}</span>} 
                    <span className={classes.flexEnd} style ={{lineHeight:this.props.editingRowId === ele.id  ? 2: "inherit", marginLeft:"5px"}}>pcs</span>
                   {this.props.editingRowId === ele.id ? <><span  className={classes.img}><img src={cnacelImage} onClick={this.props.handleCloseEditingRow} style={{cursor:"pointer",marginLeft:"5px",height:"18px",width:"18px"}} /></span><span  className={classes.img}><img onClick={this.props.handleUpdate} src={saveImage} style={{cursor:"pointer",marginLeft:"5px",height:"18px",width:"18px"}} /></span></>: <span>{<img src={editImage} style={{cursor:"pointer",marginLeft:"5px",height:"18px",width:"18px"}} onClick ={()=>this.props.handleClickEdit(ele.id,ele.attributes.stock_qty)}/>}</span>}
                  </FormLabel>
                </TableCell>{" "}
                <TableCell className={classes.styleCells}>
                  <FormLabel
                    className={
                      ele.attributes?.status === "full_stock"
                        ? classes.green
                        : ele.attributes?.status === "out_of_stock"
                          ? classes.red
                          : classes.orange
                    }
                  >
                    {stockStatusLabel(ele.attributes?.status)}
                  </FormLabel>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
    );
  }
}
export { SalesForceDashboardItemstable };
export default withStyles(styles)(SalesForceDashboardItemstable);
