import React from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withState } from './AppContext.web';

const useStyles : any = makeStyles({
    snackbarRoot:{
        height:"auto !important",
    },
    alert:{
        fontFamily:"Plus Jakarta Sans !important",
        fontSize:"16px",
        fontWeight:"bold"
    }
})


const Notification = (props:any) => {

    const handleClose = () => {
        props.setError();
        props.setSuccess();
    }

    const renderNotification = () => {
        const {error, success} = props;
        const classes = useStyles()
        if(!!error){
            return (
                <Snackbar className={classes.snackbarRoot}  open 
                autoHideDuration={3000}  
                anchorOrigin={{vertical:"bottom", horizontal:"right" }} onClose={handleClose}>
                    <Alert className={classes.alert} severity='error'>{error}</Alert>
            </Snackbar>
            )
        }
       
        if(!!success){
            return (
                <Snackbar open className={classes.snackbarRoot}
                autoHideDuration={3000}
                anchorOrigin={{vertical:"bottom", horizontal:"right"}}  onClose={handleClose}>
            <Alert className={classes.alert} severity='success'>{success}</Alert>
            </Snackbar>)
        }
        return <></>
    }

    return renderNotification();
}

export default withState(Notification);