import React from "react";
import { withStyles,Typography, Box,WithStyles, CircularProgress} from "@material-ui/core";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Button from '@material-ui/core/Button';
const uploadIcon = require("./assets/images/uploadIcon.png");

type nullableFile = File | null

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
    handleDrop :(event: React.DragEvent<HTMLDivElement>)=>void
    selectedFile: nullableFile
    handleFileChange:(event: React.ChangeEvent<HTMLInputElement>) =>void
    handleRemove?:()=>void
    loading:boolean
}


const styles : any = {
 
    inputFileContainer:{
        cursor: "pointer",
        height: "100%",
        width: "-webkit-fill-available",
        position: "relative"
    },
    inputFileContainerLabel:{
        cursor: "pointer",
        height: "100%",
        width: "-webkit-fill-available",
        position: "relative",
        display:"block",
        backgroundColor:"#f2f2f2",
        "& >span":{
            position:"absolute",
            top:"50%",
            left:"50%",
            transform:"translate(-50%,-50%)",
            textAlign:"center"
        },
        "& >span>span, >span>span:hover, >span>span:active":{
            width:"200px",
            color:"#fff",
            background: "#652d92",
            height: "55px",
            textTransform: 'initial',
            fontSize: "16px",
            fontWeight:"bold",
            fontFamily:"gilroy-medium",
            marginBottom:"24px",
            margin:"auto 16px"
        },
    },

    customUpload: {
        marginTop:"10px",
        marginBottom:"1rem",
        width: '100%',
        height: '220px',
        border: '2px dashed #ccc',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background:"#f2f2f2",
        '&:hover': {
          borderColor: '#aaa',
        },
      },
      text: {
        fontSize: '12px',
        marginBottom: '10px',
      },
      icon: {
        fontSize: '24px',
      },
      fileInput: {
        display: 'none',
      },
      button:{
        background:"rgba(102, 45, 145, 1)",
        height:"40px !important",
        fontFamily: "gilroy-bold",
        fontSize: "16px",
        width:"120px",
        color:"white",
        fontWeight:"500"
      }
}



class CustomFileUpload extends React.Component<Props> {
    constructor(props: any) {
        super(props)
    }
    
    render() {
        const { classes, handleDrop,selectedFile,handleFileChange} = this.props
       
        return(
            <Box
            className={classes.customUpload}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            >
            {this.props.selectedFile  ? (
               <>
                {
                    this.props.loading?( 
                    <div style={{display:"flex",flexDirection:"column", backgroundColor:"#f2f2f2",alignItems:"center"}}>
                        <div>Processing...</div>
                        <CircularProgress />                
                    </div>
                ):(
                    <div style={{display:"flex",fontWeight:600,fontSize:"1rem", flexDirection:"column", backgroundColor:"#f2f2f2",alignItems:"center"}}>
                        <div>{this.props.selectedFile.name}</div>               
                    </div>
                )
                }
               </>
            ) : (
                <div className={classes.inputFileContainer}>
                <label htmlFor="fileInput" className={classes.inputFileContainerLabel}>
                    <span>
                        <img src={uploadIcon} width={36} height={40} style={{marginBottom:"10px"}}/>
                        <Typography className={classes.text}>Select file or drop your excel file here</Typography>
                        <Button variant="contained" component='span' className={classes.button} style={{background:"rgba(102, 45, 145, 1)"}}>
                            Select File
                        </Button>
                    </span>
                </label>
                <input
                    id="fileInput"
                    type="file"
                    multiple ={ false}
                    onChange={handleFileChange}
                    className={classes.fileInput}
                    accept=".csv"
                />
                </div>
            )}
            </Box>

        )
}}



export { CustomFileUpload };
export default withStyles(styles)(withRouter(CustomFileUpload));