Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.getUserProfileApiEndPoint = "/account_block/accounts/logged_user";
exports.getUserProfileApiContentType = "application/json";
exports.getUserProfileApiMethodType = "GET";
exports.getNotificationApiMethod = "/bx_block_notifications/notifications"
exports.clearNotificationApiEndPoint = "/bx_block_notifications/notifications/clear_all"
exports.deleteMethod = "DELETE"
exports.getMethod = "GET"
exports.intervalTime="30000"
exports.contentType = "application/json";
exports.btnExampleTitle = "CLICK ME";
exports.rowsPerPage = 5;
// Customizable Area End