import React from "react";
export const Search = () => {
    return (
        <svg width="14.0000005px" height="13.9999997px" viewBox="0 0 14.0000005 13.9999997" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Vendor-Managemtent-wrehouse1-Copy-18" transform="translate(-756.000000, -241.000000)" fill="#5C4FA1" fillRule="nonzero">
                    <g id="Group-3" transform="translate(334.000000, 224.000000)">
                        <path d="M433.422709,27.672078 L433.366314,27.5863104 C433.261453,27.4819146 433.118075,27.423105 432.968417,27.423105 C432.81876,27.423105 432.675382,27.4819146 432.570521,27.5863104 C430.525638,29.4623338 427.374703,29.5643073 425.207404,27.8246022 C423.040104,26.0848972 422.528968,23.0433566 424.012979,20.7171185 C425.496991,18.3908803 428.51565,17.5017929 431.066995,18.6394955 C433.618339,19.7771981 434.910667,22.5886534 434.086912,25.2093154 C434.027588,25.3986386 434.076079,25.6045235 434.214117,25.7494152 C434.352155,25.8943069 434.558769,25.9561929 434.756132,25.9117614 C434.953494,25.8673299 435.111621,25.7233309 435.170945,25.5340077 C436.155676,22.4240504 434.66842,19.0772877 431.671282,17.6587404 C428.674144,16.2401931 425.066587,17.1755768 423.182338,19.8597918 C421.298089,22.5440067 421.702625,26.171502 424.134252,28.3956507 C426.565878,30.6197994 430.294847,30.7731203 432.908889,28.7564293 L435.039357,30.8393621 C435.259391,31.0535456 435.615116,31.0535456 435.83515,30.8393621 C436.054951,30.6221431 436.054951,30.2724177 435.83515,30.0551987 L435.828883,30.0245678 L433.422709,27.672078 Z" id="Search"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}