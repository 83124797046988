import React from "react";
import InventoryMassUploadController, {Props} from "./InventoryMassUploadController.web";
import { withStyles,Typography, Box,Tabs,Tab,styled, Grid, Button, createTheme, ThemeProvider, TableContainer} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import CustomUploadFile from "../../../components/src/CustomFileUpload";
import AlertPopup from "../../../components/src/AlertPopup.web";
import TableCommonComponent from "../../../components/src/TableCommonCompoent.web";
import CustomPagination from "../../../components/src/Pagination.web";
import {catalogueFile} from "./assets"

const configJSON = require("./config.js");

const styles : any = {
 container:{
    marginTop:"2rem",
 },
 downloadTemplateContainer:{
    marginTop:"1rem",
    "& button, button:hover, button:active":{
        width:"170px",
        color:"#fff",
        background: "#652d92",
        height: "56px",
        textTransform: 'initial',
        fontSize: "16px",
        fontWeight:"700",
        marginBottom:"24px",
        marginTop:"1.5rem",
        fontFamily: "Plus Jakarta Sans",
    },
 },
bold:{
    fontWeight:"600"
},
subTitle:{
    color:"#5d5757"
},
uploadSection:{
    marginTop:"10px"
},
tableContainer:{
    marginTop:"1.3rem"
},
button:{
    background:"rgba(102, 45, 145, 1) !important",
    height:"40px !important",
    fontFamily: "gilroy-bold !important",
    fontSize: "16px !important",
    width:"120px !important",
    color:"white !important",
    fontWeight:"500 !important",
  },
  paginationContainer:{
    marginTop:"10px"
  },
 table:{
    "& thead tr":{
        border:"1px solid rgba(224, 224, 224, 1)",
    },
    "& tbody tr":{
        border:"1px solid rgba(224, 224, 224, 1)",
        "&  td.Failed, td.Successful ":{
            padding:"0px",
        },
        "& span, td.Failed span, td.Successful span":{
            padding:"6px 16px",
            borderRadius:"6px",
            minWidth:"110px",
            display:"inline-flex",
            justifyContent:"center"
        },
        "& td.Failed span":{
            background:"#fed4d5",
            color:"#c96058"
        },
        "& td.Successful span":{
            background:"#e4fcd8",
            color:"#438147"
        }
    }
 }
}

const CustomTabs = styled(Tabs)({
    "& .Mui-selected":{
        color:"#662D91",
        fontWeight:700,
    },
    "& .MuiTabs-indicator":{
        backgroundColor:"#652d92",
    },
    "& .PrivateTabIndicator-root-93":{
        height:"2.3px"
    },
    "border-bottom": "1px solid #e0e0e0"
})

const theme = createTheme({
    palette: {
        secondary: {
            light: '#662D91',
            main: '#662D91',
        } 
    },
    typography: {
      fontFamily: "Plus Jakarta Sans",
      fontWeightLight: "normal",
      fontWeightRegular: "normal",
      h4:{
        fontSize:"20px"
      },
      h5:{
        fontSize:"18px",
        fontWeight:700
      },
      h6:{
        fontSize:"16px",
        fontWeight:600
      },
      subtitle2:{
        fontSize:"12px",
        fontWeight:400
      }
    },
  });
  


class InventoryMassUpload extends InventoryMassUploadController{
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes, ...restProps } = this.props;
        const { userProfile } = restProps;
        const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile?.attributes?.groups?.data[0].attributes.settings : {};
    
        return(
            <GlobalStoreDashboardLayout classes={classes} {...restProps}  headerAction="New Item" actionHandler={this.handleNavigateToAddNewInventoryFromMassUpload} subTitle="" headerTitle="Inventory Management" hasAccess={groups?.inventory} isInventory={groups?.inventory}>
                 { !groups?.inventory  ? <NoAccessPage description="Sorry, you don't have the permission" /> :<ThemeProvider theme={theme}>
                 <div className="main_container">
                 <Typography variant="h5" className="title">Mass Upload</Typography>
                 <Box className={classes.container}>
                 <CustomTabs value={this.state.selectedTab} onChange={this.handleChangeTab} >
                    <Tab label="Download Template" />
                    <Tab label="Upload File" />
                 </CustomTabs>
                 {
                    this.state.selectedTab === configJSON.INDEX_OF_MASS_UPLOAD_TEMPLATE  &&  <Box>
                        <Grid container className={classes.downloadTemplateContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h6"  >
                                    Basic Template
                                </Typography>
                            </Grid>                            
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.subTitle}>
                                    The template requires fields to list your product
                                </Typography>
                            </Grid>                            
                            <Grid item xs={12}>
                            <a href = {`${configJSON.BE_URL}/bx_block_catalogue/catalogues/download_template`} > 
                               <Button variant="contained" style={{background:"rgba(102, 45, 145, 1)"}} className={classes.button}>
                                    Download
                               </Button>
                            </a>
                            </Grid>                            
                        </Grid>
                    </Box> 
                 }  
                  {this.state.selectedTab === configJSON.INDEX_OF_MASS_UPLOAD_FILE &&  <Box className={classes.uploadSection} >
                        <Typography variant="subtitle2" className={classes.subTitle}>
                            Upload the Complete template and you can check the newly created products in the Inventory
                        </Typography>
                        <CustomUploadFile handleDrop={this.handleDrop} handleFileChange={this.handleFileChange} selectedFile={this.state.selectedFiles} loading={true} />
                        <Typography variant="h6" style={{fontWeight:700}}>Records</Typography>    
                        <TableContainer className={classes.tableContainer} >
                            <TableCommonComponent rows={configJSON.massUploadRows} data={this.state.dataList} cssClasses={classes.table}  />
                        </TableContainer>
                        <Box className={classes.paginationContainer}>
                            <CustomPagination
                                dataLength={this.state.totalCount}
                                currentPage={this.state.currentPage}
                                rowsCount={this.state.rowsCount}
                                startIndex={this.state.startIndex}
                                endIndex={this.state.endIndex}
                                handlePagination={this.handleRecordPagination}
                                handlePrevNext={this.handleRecordPrevNext}
                            />
                        </Box>
                    </Box>}
                 </Box>
                    </div>
                    <AlertPopup  
                        isOpen={this.state.openFileNotSupportedPopUp}
                        handleClose={this.handleClosePopUp}
                        hideButtons = {true}
                        action="Close" 
                        description={<Typography variant="h5">Sorry, the file format is not supported, please check your file.</Typography>}/>
                 </ThemeProvider>
                 }
            </GlobalStoreDashboardLayout>
        )
    }
}

export { InventoryMassUpload };
export default withStyles(styles)(withRouter(InventoryMassUpload));