import React from "react";

export const TeamDashboardActive = () => {
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy-18" transform="translate(-26.000000, -497.000000)" fill="#662D91" fill-rule="nonzero">
            <g id="team_dashboard_FILL1_wght200_GRAD0_opsz24" transform="translate(26.000000, 497.000000)">
                <path d="M1.615375,16 C1.17114167,16 0.790858333,15.841825 0.474525,15.525475 C0.158175,15.2091417 0,14.8288583 0,14.384625 L0,8.25 L6.5,8.25 L6.5,16 L1.615375,16 Z M7.5,16 L7.5,8.25 L16,8.25 L16,14.384625 C16,14.8288583 15.841825,15.2091417 15.525475,15.525475 C15.2091417,15.841825 14.8288583,16 14.384625,16 L7.5,16 Z M0,7.25 L0,1.615375 C0,1.17114167 0.158175,0.790858333 0.474525,0.474525 C0.790858333,0.158175 1.17114167,0 1.615375,0 L14.384625,0 C14.8288583,0 15.2091417,0.158175 15.525475,0.474525 C15.841825,0.790858333 16,1.17114167 16,1.615375 L16,7.25 L0,7.25 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>)
}