import React from "react";
// Customizable Area Start
import CommonDashboard from "./CommonDashboard.web";
import DarkStoreDashboardController from "../../blocks/landingpage/src/DarkStoreLandingPageController.web";
import {  Toolbar, ListItem, ListItemIcon, ListItemText, withStyles } from "@material-ui/core";
import DashboardHeader from "./DashboardHeader.web";
import { GridViewIcon, GridViewActiveIcon, ListIcon, ListActiveIcon, OutboundIcon, OutboundActiveIcon, InventoryIcon, InventoryActiveIcon } from "./assets.web";
import QRScannerWeb from "./QRScanner.web";

const styles : any = {
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        paddingTop: "1rem",
        background:"#f6f5fa",
        minHeight:"900px",
        overflowX:"auto"
      },
      toolbar:{
        minHeight:"36px"
      },
      selectedListItem:{
        borderTopRightRadius:"6px",        
        borderLeft:"4px solid #652d92 !important",
        background:"linear-gradient(0deg, #F7F7FC, #F7F7FC), #F5F5FA",
        borderBottomRightRadius:"6px",

      },
      rootListItem:{
        borderLeft:"4px solid #fff",
      }
}

class DarkStoreDashboardLayout extends DarkStoreDashboardController{

    customListItem = (props:any) => {
        const { classes } = this.props;
        return (<ListItem {...props} onClick={() => this.handleNavigation(props.pathname)} classes={{selected:classes.selectedListItem, root:classes.rootListItem}}>
            {props.children}
      </ListItem>)
    }
    
    render() {
        const { classes, headerAction, headerTitle, actionHandler, userProfile} = this.props;
        return (
            <div style={{display: "flex"}}>
                <CommonDashboard clearNotification={this.clearAllDarkStoreNotification} name={userProfile?.attributes?.first_name} email={userProfile?.attributes?.email} logout={this.handleLogout} userType={this.props.userType} anchorEl ={this.state.anchorEl} handleOpenNotification = {this.handleOpenNotification} handleCloseNotification ={this.handleCloseNotification} handleOpenQrModal = {this.handleOpenQrModal} newNotificationAdded={this.props.newNotification} notificationList = {this.state.listOfDarkStoreNotification}
                isSidebarClosing={this.state.isClosing} handleCloseAction={this.handleCloseDrawer}>
                    <this.customListItem button key="Orders" pathname="orders" selected={ window.location.pathname.includes("/orders")}>
                    <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/orders") ? <ListActiveIcon/> :<ListIcon/>}</ListItemIcon>
                    <ListItemText primary="Orders" onClick={this.handleCloseDrawer} />
                    </this.customListItem>
                    <this.customListItem button key="Inwards Inventory" pathname="inwards-inventory" selected={ window.location.pathname.includes("/inwards-inventory") }>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/inwards-inventory") ? <OutboundActiveIcon/> :<OutboundIcon/>}</ListItemIcon>
                        <ListItemText primary="Inwards Inventory" onClick={this.handleCloseDrawer} />
                    </this.customListItem>
                    <this.customListItem button key="Inventory" pathname="inventory" selected={ window.location.pathname.includes("/inventory") }>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/inventory") ? <InventoryActiveIcon/> : <InventoryIcon/>}</ListItemIcon>
                        <ListItemText primary="Inventory" onClick={this.handleCloseDrawer} />
                    </this.customListItem>
                    <this.customListItem button key="Dashboard" pathname="dashboard"  selected={ window.location.pathname.includes("/dashboard") }>
                        <ListItemIcon onClick={this.handleCloseDrawer}>{window.location.pathname.includes("/dashboard") ? <GridViewActiveIcon/> :<GridViewIcon/>}</ListItemIcon>
                        <ListItemText primary="Dashboard" onClick={this.handleCloseDrawer} />
                    </this.customListItem>
                </CommonDashboard>
                <main className={classes.content}>
                <Toolbar className={classes.toolbar} />
                <DashboardHeader subTitle="" actionHandler={actionHandler} headerAction={headerAction} headerTitle={headerTitle} />
                <div style={{position:"relative", height:"100%"}}>
                    {this.props.children}
                </div>
                </main>
                {this.state.openQrModal && this.state.isVideoDeviceAvailable && <QRScannerWeb open={this.state.openQrModal} handleClose={this.handleOpenQrModal}  handleScan={this.handleScanQR} invalidQR={this.state.invalidQRCode} setError={this.props.setError}/>}
            </div>
            )
    }
}


export default withStyles(styles)(DarkStoreDashboardLayout);
// Customizable Area End