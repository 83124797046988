import React from "react";

export const Checkmark = () => {
    return (
        <svg width="63px" height="63px" viewBox="0 0 63 63" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Forgot-password-Copy-8" transform="translate(-689.000000, -472.000000)" fillRule="nonzero">
                    <g id="icons8-checkmark" transform="translate(689.000000, 472.000000)">
                        <path d="M63,31.5 C63,48.895875 48.895875,63 31.5,63 C14.104125,63 0,48.895875 0,31.5 C0,14.104125 14.104125,0 31.5,0 C48.895875,0 63,14.104125 63,31.5 Z" id="Path" fill="#C8E6C9"></path>
                        <path d="M45.6779689,20.8623432 L28.9692563,37.4666374 C28.1903512,38.2406748 26.9330425,38.2419442 26.152576,37.4694811 L21.3243872,32.6908034 C20.1559813,31.5343791 18.2738837,31.5357248 17.1071328,32.6938188 C15.9534004,33.8389909 15.9464609,35.7026211 17.091633,36.8563535 C17.0976008,36.8623659 17.1035945,36.8683526 17.109614,36.8743132 L26.1585476,45.8348077 C26.9389554,46.6075882 28.1965172,46.6064777 28.9755589,45.8323201 L49.8937036,25.0453191 C51.0488125,23.897452 51.0546832,22.0305211 49.9068161,20.8754122 C49.9019353,20.8705007 49.8970373,20.8656063 49.8921221,20.8607293 C48.7255823,19.7032542 46.8436218,19.7039749 45.6779689,20.8623432 Z" id="Path" fill="#4CAF50"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}