import { Button, Dialog, IconButton, DialogActions, withStyles, Grid, DialogContent, DialogTitle, FormLabel } from "@material-ui/core"
import React from "react"
import CloseIcon from '@material-ui/icons/Close';
import { ISupplierListInterface, SupplierDetailsInterface, SupplierErrorInterface, supplierFields } from "./types";
import CommonTextfield from "../../../components/src/CommonTextfield";
import CreatableSelectComponent from "../../../components/src/CreatableSelect";

interface Props {
    open: boolean;
    handleClose: () => void;
    classes?: any;
    handleChange: (fieldName: supplierFields, value: string) => void;
    supplierDetails: SupplierDetailsInterface
    errors: SupplierErrorInterface
    handleSave: () => void;
    suppliersListData: ISupplierListInterface[];
    handleListItemSelect: (element: ISupplierListInterface) => void;
    shipmentAddress:string;
}

const styles: any = {
    dialogTitle:{
        fontFamily: "gilroy-bold",
        fontSize: "18px",
    },
    dialogHeader: {
        float: "right"
    },
    requiredFields: {
        color: "red"
    },
    textFont: {
        color: "black",
        fontFamily: "gilroy-bold",
        fontSize: "16px",
        fontStyle:" normal",
    },
    margin: {
        margin: "10px 0px"
    },
    dialogAction:{
        marginBottom:"1rem",
        "& button, button:hover, button:active": {
            color: "#fff",
            background: "#652d92",
            fontSize:"16px",
            fontFamily: "gilroy-bold",
            height: "56px",
            width:"170px",
            "& *": {
                color: "#fff",
              }
        },
    }

}

class AllocateSupplierDialogBox extends React.Component<Props>{
    constructor(props: any) {
        super(props)

    }

    render() {
        const { classes, errors, suppliersListData } = this.props;

        return (

            <Dialog className="purchaseOrderSupplierPopUp" open={this.props.open} onClose={this.props.handleClose} fullWidth maxWidth="sm"   >
                <DialogTitle className={classes.dialogTitle}>Allocate to Supplier
                    <span className={classes.dialogHeader} >
                        <IconButton onClick={this.props.handleClose}><CloseIcon /></IconButton>
                    </span>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid xs={12} md={12} item className={classes.margin} >
                            <FormLabel className={classes.textFont} >Supplier Legal Name <span className={classes.requiredFields}>*</span></FormLabel>
                        </Grid>
                        <Grid xs={12} md={12} item className={classes.margin} >
                            <CreatableSelectComponent
                                error={errors.name}
                                value={{ label: this.props.supplierDetails.name, value: String(this.props.supplierDetails.id )}}
                                handleListItemSelect={this.props.handleListItemSelect}
                                handleNewSelect={this.props.handleChange}
                                supplierOptions={suppliersListData}
                            />
                        </Grid>

                        <Grid xs={12} md={12} item className={classes.margin} >
                            <FormLabel className={classes.textFont} >Supplier Code <span className={classes.requiredFields}>*</span></FormLabel>
                        </Grid>
                        <Grid xs={12} md={12} item className={classes.margin} >
                            <CommonTextfield
                                data-test-id="SupplierFieldID"
                                error={errors.code}
                                value={this.props.supplierDetails.code }
                                onChange={(e: any) => this.props.handleChange("code", e.target.value)}
                                placeholder="Enter Supplier Code"
                                inputType="number"
                            />
                        </Grid>

                        <Grid xs={12} md={12} item className={classes.margin} >
                            <FormLabel className={classes.textFont} >Supplier Address <span className={classes.requiredFields}>*</span></FormLabel>
                        </Grid>
                        <Grid xs={12} md={12} item className={classes.margin} >
                            <CommonTextfield
                              data-test-id="addressFieldID"
                                error={errors.address}
                                value={this.props.supplierDetails.address}
                                onChange={(e: any) => this.props.handleChange("address", e.target.value)}
                                placeholder="Enter Supplier Address"
                            />
                        </Grid>

                        <Grid xs={12} md={12} item className={classes.margin} >
                            <FormLabel className={classes.textFont} >Supplier Email <span className={classes.requiredFields}>*</span></FormLabel>
                        </Grid>
                        <Grid xs={12} md={12} item className={classes.margin} >
                            <CommonTextfield
                              data-test-id="emailFieldID"
                                error={errors.email}
                                value={this.props.supplierDetails.email}
                                onChange={(e: any) => this.props.handleChange("email", e.target.value)}
                                placeholder="Enter Supplier Email"
                            />
                        </Grid>

                        <Grid xs={12} md={12} item className={classes.margin} >
                            <FormLabel className={classes.textFont} >Shipment Address</FormLabel>
                        </Grid>
                        <Grid xs={12} md={12} item className={classes.margin} >
                            <CommonTextfield
                                value={this.props.shipmentAddress}
                                placeholder="Enter Shipment Address"
                                disabled = {true}
                            />
                        </Grid>

                        <Grid xs={12} md={12} item className={classes.margin} >
                            <FormLabel className={classes.textFont} >Payment Terms <span className={classes.requiredFields}>*</span></FormLabel>
                        </Grid>
                        <Grid xs={12} md={12} item className={classes.margin} >
                            <CommonTextfield
                                data-test-id="PaymentFieldID"
                                 value={this.props.supplierDetails.payment_terms}
                                error={errors.payment_terms}
                                 onChange={(e: any) => this.props.handleChange("payment_terms", e.target.value)}
                                placeholder="Enter Payment Terms"

                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button size="large" style={{ height: '44px', background: '#4a126b' }} data-test-id="" variant="contained" id="confirmButton" color="secondary"
                        onClick={this.props.handleSave}
                    >Confirm</Button>
                </DialogActions>
            </Dialog>

        )
    }
}


export { AllocateSupplierDialogBox }
export default withStyles(styles)(AllocateSupplierDialogBox)