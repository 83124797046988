import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { validatePassword,vaidatorList } from "../../../components/src/Utils.web";
import { RouteComponentProps } from "react-router";
// Customizable Area End

export const configJSON = require("./config");
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  setSuccess:any;
  setError:any;
  // Customizable Area End
}

// Customizable Area Start
type field = {
  value: string;
  error: string;
  valid: boolean;
}

// Customizable Area End
interface S {
  // Customizable Area Start
  password:field;
  reEnteredPassword:field;
  passwordVisibility:boolean;
  reEnteredPassVisibility:boolean;
  passwordValidations: any;
  step: number;
  error: any; 
  token: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreatePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postCreateNewPasswordApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      password:{
        value:"",
        error:"",
        valid:false
      },
      reEnteredPassword:{
        value:"",
        error:"",
        valid:false
      },
      passwordVisibility:false,
      reEnteredPassVisibility:false,
      passwordValidations:vaidatorList,
      step:0,
      error: "",
      token:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    this.setState({token: query.get("token")});
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("@@@@ new password response",responseJson);
      if(responseJson){
        if(responseJson.status){
          this.setState({step:1});
        }
        if(responseJson.errors){
          this.props.setError(responseJson.errors[0].token);
        }
      }

      if(errorReponse){
        this.props.setError(errorReponse);
        console.log("@@@@ new password errorReponse",errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  newPasswordApiCall = (data:any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  passwordFieldHandler = (e:any) => {
    let { isValidPassword, passwordValidations } = validatePassword(e.target.value, this.state.passwordValidations);
    this.setState({
      passwordValidations,
      password: {
      ...this.state.password,
      valid: isValidPassword,
      value: e.target.value,
      error: "" ,
      }
    })
  }

  isReEnteredPasswordMatch = (reenteredPassword: any) => {
    const { password } = this.state;
    return {
      password: reenteredPassword,
      isValid :password.value.trim() === reenteredPassword
    };
  }

  reEnterPasswordFieldHandler = (e:any) => {
    const { reEnteredPassword } = this.state;
    const { password, isValid } = this.isReEnteredPasswordMatch(e.target.value);
    this.setState({reEnteredPassword: {
      ...reEnteredPassword,
      value:password,
      error:"",
      valid:isValid
    }});
  }

  handlePasswordVisibility = () => {
    this.setState({passwordVisibility: !this.state.passwordVisibility});
  }

  handleReenteredPasswordVisibility = () => {
    this.setState({reEnteredPassVisibility: !this.state.reEnteredPassVisibility});
  }

  handleNext = () => {
    const { reEnteredPassword,password } = this.validateFields();
    const { token } = this.state;
    
    if(!reEnteredPassword.error && !password.error){
      let apiBody = {
            new_password: reEnteredPassword.value,
            token:token
      };

      this.postCreateNewPasswordApiCallId = this.newPasswordApiCall({
        contentType: configJSON.newPasswordApiContentType,
        method: configJSON.newPasswordApiMethodType,
        endPoint: configJSON.newPasswordApiEndPoint,
        body: apiBody,
      });
    }
  }

  handleNavigateToLogin = () => {
    this.props.history.push("/login");
  }

  validateFields = () => {
    let { password, reEnteredPassword } = this.state;
    
    if(password.value.trim() === "" || !password.valid){
      password.error = configJSON.invalidPasswordErrorText;
    }

    if(reEnteredPassword.value.trim() === "" || !reEnteredPassword.valid){
      reEnteredPassword.error = configJSON.invalidReEnteredPasswordErrorText;
    }
    this.setState({ password, reEnteredPassword });
    return { password, reEnteredPassword }
  }

  backHandler = () => {
    this.props.history.goBack()
  }
  // Customizable Area End
}
