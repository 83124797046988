import React from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";
import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";
import {
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  withStyles
} from "@material-ui/core";
import { resetPasswordLogo } from "./assets";
import CommonAuthModal from "../../../components/src/CommonAuthModal";
// Customizable Area Start

// Customizable Area Start
const styles: any = {
  formControl: {
    "& *": {
      fontFamily: "Plus Jakarta Sans !important"
    },
    marginBottom: "24px",
    "& label": {
      textAlign: "start",
      fontSize: "14px",
      color: "black",
      fontWeight: "500"
    },
    "& a, a:hover, a:active": {
      textDecoration: "none",
      marginRight: "0px",
      marginLeft: "auto",
      color: "black"
    },
    "& p": {
      color: "red"
    }
  },
  submitBtnContainer: {
    "& button, button:hover, button:active": {
      background: "#652d92",
      height: "55px",
      textTransform: "initial",
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "Plus Jakarta Sans"
    }
  },
  resetPasswordScreen: {
    padding: "24px 18px",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    "& img": {
      width: "200px"
    },
    "& h6": {
      textAlign: "center",
      fontSize: "16px !important",
      marginTop: "24px"
    }
  }
};
// Customizable Area End

class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  renderEmailForm = (classes: any) => {
    return (
      <CommonAuthModal
        haveBackButton={true}
        backHandler={this.backToPrevStep}
        title="Forget Password"
        description="We will send link to your email. Please reset your password."
      >
        <form>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth className={classes.formControl}>
                <FormLabel>Email</FormLabel>
                <TextField
                  data-test-id="txtInputEmail"
                  placeholder="Enter email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={this.state.email.value}
                  onChange={this.emailFieldHandler}
                  helperText={this.state.email.error || ""}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.submitBtnContainer}
            >
              <FormControl fullWidth>
                <Button
                  data-test-id="nextBtn"
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                >
                  Next
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </CommonAuthModal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderResetPassword = (classes: any) => {
    return (
      <CommonAuthModal
        haveBackButton={true}
        backHandler={this.backToPrevStep}
        title="Reset Password"
        description=""
        className={classes.resetPasswordScreen}
      >
        <img src={resetPasswordLogo} />
        <Typography variant="h6">
          A link has been sent to your email address, which you can use to
          change your password{" "}
        </Typography>
      </CommonAuthModal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderMainContainer = () => {
    const { classes } = this.props;
    const { step } = this.state;
    switch (step) {
      case 0: {
        return this.renderEmailForm(classes);
      }

      case 1: {
        return this.renderResetPassword(classes);
      }

      default: {
        return this.renderEmailForm(classes);
      }
    }
  };
  // Customizable Area End

  render() {
    return <>{this.renderMainContainer()}</>;
  }
}

export { ForgotPassword };

export default withStyles(styles)(withRouter(ForgotPassword));
