import React from "react";
// Customizable Area Start
import { Popover, Button, Typography, makeStyles } from "@material-ui/core";
import { EditIcon, DeleteIcon } from "./assets.web";

const useStyles : any = makeStyles(() => ({
    editPopper:{
        width:"140px",
        display:"flex",
        height:"100px",
        flexFlow:"column",
        "& button":{
            height:"50px",
            '& span':{
                justifyContent:"space-evenly"
            },
            padding:"8px 10px !important"
        },
        "& p":{
            width:"50px",
            textAlign:"left"
        }
    },
    paper:{
        boxShadow:"0 0 8px 0 rgba(0, 0, 0, 0.05) !important",
        background:"#fff",
        borderRadius:"8px !important"
    }
}))

 const UpdateModal = (props:any) => {
    const { open, anchorEl, editHandler, deleteHandler } = props;
    const classes = useStyles();
    return (
        <Popover 
             onClose={props.handleClose}
            open={open} 
            anchorEl={anchorEl} 
            PaperProps={{className: classes.paper}}
            anchorOrigin={{horizontal:"left", vertical:"bottom"}}
        >
            <div className={classes.editPopper} >  
                <Button onClick={editHandler}>
                    <EditIcon/>
                    <Typography variant="body1">Edit</Typography>
                </Button>
                <Button onClick={deleteHandler}>
                    <DeleteIcon/>
                    <Typography variant="body1" color="error">Delete</Typography>
                </Button>
            </div>
        </Popover>
    )
}

export default UpdateModal;
// Customizable Area End