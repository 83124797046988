import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { createRef } from "react";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";
import { getVendorForm } from "../../../components/src/CommonFunctions";
// Customizable Area End
export const configJSON = require("./config");
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  image:any;
  error:string;
  legal_name: string;
  trade_name: string;
  email: string;
  contact: string;
  store_size: number;
  limit: number;
  gstin: string;
  pan: string;
  authorized_person_name: string;
  authorized_person_designation: string;
  authorized_person_contact: string;
  bank_account_number: number;
  bank_branch: string;
  ifsc: string;
  state: string;
  country: string;
  address: string;
  district: string;
  city: string;
  pincode: string;
  latitude: number;
  longitude: number;
  photo: {url: string | null};
  isOpenSearch: boolean;
  isOpenMap:boolean;
  searchKey: string;
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  userType:any;
  isEdit:boolean;
  containerStyles: any;
  userProfile:any;
  setError:any;
  setSuccess:any;
  setUserProfile:any;
  hasAccess:boolean;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}

export default class VendorManagementFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createDarkstoreApiCallId: any;
  hiddenFileInput = createRef<HTMLInputElement>();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      // Customizable Area Start
      image:null,
      error:"",
      legal_name:"",
      trade_name:"",
      email:"",
      contact:"",
      address:"",
      country:"",
      state:"",
      city:"",
      district:"",
      latitude: 0,
      longitude: 0,
      pincode:"",
      store_size:0,
      limit:0,
      gstin:"",
      pan:"",
      photo: { url:null },
      authorized_person_name:"",
      authorized_person_designation:"",
      authorized_person_contact:"",
      bank_branch:"",
      bank_account_number:0,
      ifsc:"",
      isOpenSearch: false,
      searchKey: "",
      isOpenMap: false
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(errorReponse){
      
        this.props.setError(errorReponse);
      }

      if(responseJson){
          if(responseJson.status === 500 || !responseJson.data){
            this.props.setError("Internal Server Error");
            return;
          }
          else if(responseJson.errors){
            console.log("####errors",responseJson.errors);
          }
          else{
           this.handleSuccessCallbacks(apiRequestCallId, responseJson);
          } 
      }
    }
    // Customizable Area End
  }

  vendormanagementFormApiCall = async(data: any) => {
    const { method, endPoint, body} = data;
    let token = await localStorage.getItem("token");
    const header = {
      token,
    };
    const darkStoreManagementApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    darkStoreManagementApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(darkStoreManagementApiMessage.id, darkStoreManagementApiMessage);
    return darkStoreManagementApiMessage.messageId;
  }

  // Customizable Area Start

  handleSuccessCallbacks = (apiRequestCallId: any,responseJson: any) => {
    if(apiRequestCallId === this.createDarkstoreApiCallId){
      this.handleDarkstoreCreateSuccessCallback(responseJson);
    }
  }

  handleDarkstoreCreateSuccessCallback = (res:any) => {
    this.props.history.push({pathname:"/dark_store_management", state:{message:"New Vendor has been added Succesfully!"}});
  };

  handleClick = (event:any) => {
    this.hiddenFileInput.current?.click();
  };
  handleChange = (event:any) => {
    const reader: any = new FileReader();
    let image = {} as any;
    let photo = {} as any;
    image.file = event.target.files[0];
    reader.onload = () => {
      if (reader.readyState === 2) {
        photo.url = reader.result;
        image = {
          ...image,
          url: reader.result,
        };
        this.setState({ image :image, photo});
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  handleSubmitUpdateForm = async(values: any) => {
      let formData = getVendorForm(values, this.state.image);  
    
     await this.updateForm(formData);
  }
  
  updateForm = async(formData:any) => {
      let body = formData;
      this.createDarkstoreApiCallId = await this.vendormanagementFormApiCall({
        contentType: configJSON.createDarkstoreContentType,
        method: configJSON.createDarkstoreMethodType,
        endPoint: configJSON.createDarkstoreEndPoint,
        body,
      });
  }

  onChangeHandler = (address:string) => {
    this.setState({searchKey: address})
  }

  searchFieldHandler = () => {
    this.setState({isOpenSearch: !this.state.isOpenSearch});
  }

  handleClose=() => {
    this.setState({isOpenMap: false, searchKey:""});
  }

  selectLocationHandler = () => {
    this.setState({isOpenSearch: false});
     this.setState({isOpenMap: true})
  }
  
  // Customizable Area End
}
