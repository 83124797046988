export const validatePassword = (password:any, validatorList:any) => {
    let passwordValidations  = validatorList;
    let isValidPassword = true;
    passwordValidations = passwordValidations.map((validator:any) => {
        const isvalid = validator.regex.test(password);
        isValidPassword = isvalid && isValidPassword;
        return {
        ...validator,
        valid: isvalid,
      }
    });
    return {
        passwordValidations,
        isValidPassword
    }
}
export const vaidatorList = [
    { valid: false,
      regex: /(?=.{8,})/,
      description:"Password should be minimum 8 characters long"
    },
    { valid: false,
      regex: /(?=.*[A-Z])/,
      description:"Password should have atleast one capital letter"
    },
    { valid: false,
      regex: /(?=.*[a-z])/,
      description:"Password should have atleast one lowercase letter"
    },
    { valid: false,
      regex: /(?=.*\d)/,
      description:"Password should have atleast one number"
    },
]