import React from "react";
// Customizable Area Start
import { Formik } from "formik";
import { withStyles, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import VendorManagementFormController from "./VendorManagementFormController.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import VendorManagementFormCommon from "../../../components/src/VendorManagementFormCommon.web";
import {IAddressProps} from "../../../components/src/types";
import { VendorManagementForm1Schema } from "../../../components/src/CommonFunctions";

const styles: any = {
    container: {
        margin: "24px",
        padding: "24px",
        background: "#fff",
        borderRadius: "10px",
        "& p": {
            fontFamily: "gilroy-bold !important",
            marginBottom: "24px",
            marginLeft: "8px"
        }
    },
    btnContainer: {
        width: "100%",
        display: "flex",
        padding: "24px 18px",
        "& button, button:hover, button:active": {
            color: "#fff",
            marginRight: "0px",
            marginLeft: "auto",
            background: "#652d92",
            height: "50px",
            width: "200px",
            fontWeight: "bold"
        }
    },
}

class VendorManagementForm extends VendorManagementFormController {
    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { image } = this.state;
        
        const initialValues = {
            legal_name: this.state.legal_name,
            trade_name: this.state.trade_name,
            email: this.state.email,
            contact: this.state.contact,
            address: this.state.address,
            country: this.state.country,
            state: this.state.state,
            city: this.state.city,
            district: this.state.district,
            latitude: 0,
            longitude: 0,
            pincode: Number(this.state.pincode),
            store_size: this.state.store_size,
            limit: this.state.limit,
            gstin: this.state.gstin,
            pan: this.state.pan,
            photo: { url: null },
            authorized_person_name: this.state.authorized_person_name,
            authorized_person_designation: this.state.authorized_person_designation,
            authorized_person_contact: this.state.authorized_person_contact,
            bank_branch: this.state.bank_branch,
            bank_account_number: this.state.bank_account_number,
            ifsc: this.state.ifsc,
        }
        
        return (
            <GlobalStoreDashboardLayout {...restProps} headerTitle="Dark Store Management" actionHandler={undefined} headerAction="" subTitle="Add New Vendor" hasAccess={true}>
                <Formik 
                    enableReinitialize={true}
                    validateOnBlur={true}
                    initialValues={initialValues}
                    validationSchema={VendorManagementForm1Schema}
                    onSubmit={(values:any) => this.handleSubmitUpdateForm(values)}
                >
                {({ errors,
                values,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue, }) => {
               return <form onSubmit={handleSubmit}>
               <VendorManagementFormCommon
                        image={image}
                        values={values}
                        touched={touched}
                        handleChange={handleChange}
                        errors={errors}
                        isEdit={false}
                        containerStyles={classes.container}
                        hiddenFileInput={this.hiddenFileInput}
                        handleImageChange={this.handleChange}
                        handleClick={this.handleClick}
                        validationSchema={VendorManagementForm1Schema}
                        isOpenSearch={this.state.isOpenSearch}
                        searchKey={this.state.searchKey}
                        onChangeHandler={this.onChangeHandler}
                        searchFieldHandler={this.searchFieldHandler}
                        selectLocationHandler={this.selectLocationHandler}
                        autoFillAddressHandler={(addressComponents:IAddressProps[] | undefined,address:string) => {
                            setFieldValue("address", address);
                            if(addressComponents){
                                setFieldValue("pincode", Number(addressComponents[4].long_name));
                                setFieldValue("country", addressComponents[3].long_name);
                                setFieldValue("state", addressComponents[2].long_name);
                                setFieldValue("district", addressComponents[1].long_name);
                                setFieldValue("city", addressComponents[0].long_name);
                            }
                            this.handleClose()
                        }}
                        handleLocationFields={(lat:number, lng:number) => {
                            setFieldValue("latitude",lat)
                            setFieldValue("longitude", lng)
                        }}
                        isOpenMap={this.state.isOpenMap}
                        handleClose={this.handleClose}
                >
                    <div className={classes.btnContainer}>
                        <Button data-test-id="submitBtn" variant="contained" type="submit"> Confirm</Button>
                    </div>
                </VendorManagementFormCommon>
             
                </form>
                   }}
                </Formik>
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { VendorManagementForm };
export default withStyles(styles)(withRouter(VendorManagementForm));
// Customizable Area End