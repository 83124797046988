import React from "react";
// Customizable Area Start
import { makeStyles ,Dialog, IconButton,DialogContent, DialogActions, Button} from "@material-ui/core";
import { CloseIcon, CheckmarkIcon } from "./assets.web";

const useStyles : any = makeStyles({
    paper:{
        marginTop:"100px",
        maxHeight:"950px",
    },
    header:{
        display:"inline-flex",
        paddingTop:"18px",
        marginBottom:"6px",
        "& button":{
            marginLeft:"auto",
            marginRight:"8px"
        }
    },
    content:{
        padding:"0px 24px 0px",
        display:"flex",
        flexFlow:"column",
        alignItems:"center",
        "& svg":{
            height:"63px",
            width:"63px"
        },
        "& h6":{
            fontFamily:"gilroy-bold",
            fontSize:"1rem",
            margin:"20px auto",
            width:"234px",
            textAlign:"center",
            "& span":{
                color:"#ff6373"
            }
        }
    },
    actions:{
        justifyContent:"start",
        "& button, button:hover, button:active":{
            width:"335px",
            color:"#fff",
            background: "#652d92",
            height: "55px",
            textTransform: 'initial',
            fontSize: "16px",
            fontWeight:"bold",
            fontFamily:"gilroy-medium",
            marginBottom:"24px",
            margin:"auto 16px"
        },
    }
});

interface ISuccessPopupProps {
    description: React.ReactNode,
    isOpen: boolean,
    paperClass?: object,
    handleConfirm?: () => void,
    handleClose: () => void,
}

const SuccessPopup = (props:ISuccessPopupProps) => {
    const { description, isOpen, paperClass, handleClose, handleConfirm } = props;
    const classes = useStyles();
    return (
        <Dialog open={isOpen} classes={{paper:`${classes.paper} ${paperClass}`}}>
            <div className={classes.header}>
                <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                </div>
            <DialogContent className={classes.content}>
                <CheckmarkIcon/>
                {description}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button variant="contained" onClick={()=>handleConfirm ? handleConfirm() : handleClose()}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SuccessPopup;

// Customizable Area End