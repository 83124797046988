import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { InwardsInventoryItem } from "./types";
// Customizable Area End

export const configJSON = require("./config");
export const rows = configJSON.inwardsInventoryRows;
interface S {
  // Customizable Area Start
  orderDetails :null | InwardsInventoryItem;
  anchorEl: null | HTMLElement;
  isLoading:boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export type Props = RouteComponentProps &  {
  id: string;
  navigation: any;
  // Customizable Area Start
  userProfile: any;
  match:{ params: { orderId:any } };
  classes:any;
  userType:any;
  setUserProfile:any;
  setError:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}
export default class InwardsInventoryOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInwardsInventoryOrderDetailsApiCallId:string="";
  putInwardsInventoryOrderDetailsApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      // Customizable Area Start
      orderDetails : null,
      anchorEl: null,
      isLoading:true
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let inwardsInventoryOrderDetailsApiCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (responseJson.status === 500) {
              this.setState({isLoading:false})
              this.props.setError("Internal Server Error");
              this.handleClose();
              return;
          }
          else if (responseJson.errors) {
              this.setState({isLoading:false})
              console.log("####errors", responseJson.errors);
              this.props.setError("Something went wrong");
          }
          else {
           this.handleSuccessCallbacks(inwardsInventoryOrderDetailsApiCallId, responseJson)
          }
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
     await this.getInventoryDetails()
     this.setState({isLoading:true})
  }

  handleSuccessCallbacks = (apiCallId: string, responseJson:any) => {
    if(apiCallId === this.getInwardsInventoryOrderDetailsApiCallId){
      this.setState({orderDetails:responseJson?.data})
    }else if(apiCallId === this.putInwardsInventoryOrderDetailsApiCallId){
      this.props.history.push("/inwards-inventory");
    }
  }

  inwardsInventoryOrderDetailsApiCall = (data: any) => {
    const { contentType, method, endPoint, body } = data;
   
    let token = window.localStorage.getItem("token");
    let header:Record<string,string|undefined|null> = {
      token,
    };
    if(contentType){
      header = {
        ...header,
        "Content-Type":contentType
      }
    }
    const inwardsInventoryOrderDetailsApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    inwardsInventoryOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    inwardsInventoryOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    inwardsInventoryOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    inwardsInventoryOrderDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) 
    );
    runEngine.sendMessage(inwardsInventoryOrderDetailsApiMessage.id, inwardsInventoryOrderDetailsApiMessage)
    return inwardsInventoryOrderDetailsApiMessage.messageId;
  }

  getInventoryDetails = async() => {
    const orderId = this.props.match.params.orderId;
    this.getInwardsInventoryOrderDetailsApiCallId = this.inwardsInventoryOrderDetailsApiCall({
      contentType:configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getDeliveryChallanOrderDetailsEndPoint}/${orderId}`,
      })
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
      this.setState({ anchorEl: null });
  };

  handleUpdateStatus = (orderId: string| number,status:string) => {
    if(status === "completed"){
      const body = {
        status
      }
      this.putInwardsInventoryOrderDetailsApiCallId = this.inwardsInventoryOrderDetailsApiCall({
        contentType:configJSON.validationApiContentType,
        method: configJSON.putDeliveryChallanOrderDetails,
        endPoint: `${configJSON.getDeliveryChallanOrderDetailsEndPoint}/${orderId}`,
        body
      })
    }
  }

  // Customizable Area End
}
