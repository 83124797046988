Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "warehousemanagement2";
exports.labelBodyText = "warehousemanagement2 Body";

exports.invalidEmailErrorText = "Please enter valid email";
exports.emptyRemarkErrorText = "Please enter valid remark";

exports.listDarkstoresEndPoint = "/account_block/dark_stores";
exports.listDarkstoresContentType = "application/json";
exports.listDarkstoresMethodType = "GET";

exports.updateDarkstoreEndPoint = "/account_block/dark_stores";
exports.updateDarkstoreContentType = "";
exports.updateDarkstoreMethodType = "PUT";

exports.deleteDarkstoreEndPoint = "/account_block/dark_stores";
exports.deleteDarkstoreContentType = "application/json";
exports.deleteDarkstoreMethodType = "DELETE";

exports.getDarkstoreEndPoint = "/account_block/dark_stores";
exports.getDarkstoreContentType = "application/json";
exports.getDarkstoreMethodType = "GET";

exports.createNewLinkAccountEndPoint = "/account_block/dark_stores";
exports.createNewLinkAccountContentType = "";
exports.createNewLinkAccountMethodType = "POST";

exports.createNewLinkAccountEndPoint = "/account_block/dark_stores";
exports.createNewLinkAccountContentType = "";
exports.createNewLinkAccountMethodType = "POST";

exports.unLinkAccountEndPoint = "/account_block/dark_stores";
exports.unLinkAccountContentType = "application/json";
exports.unLinkAccountMethodType = "DELETE";

exports.btnExampleTitle = "CLICK ME";
exports.rowsPerPage = 15;
// Customizable Area End