import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

import { IMassUploadRecord, Nullable } from "./types";
import { createMassUploadRecordData, handlePaginationWithFilter } from "../../../components/src/CommonFunctions";

export const configJSON = require("./config");

interface SS {
    id: any;
}

interface S {
    // Customizable Area Start
    selectedFiles: Nullable<File>
    selectedTab: number
    openFileNotSupportedPopUp: boolean
    loadingOnFileUpload: boolean
    currentPage:number;
    dataList:any;
    rowsCount:number;
    startIndex:number;
    endIndex:number;
    totalCount:number;
    // Customizable Area End
}

export type Props = RouteComponentProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    userType: any;
    userProfile: any;
    setUserProfile: any;
    setError: any;
    setSuccess:any;
    newNotification:boolean
    setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
    setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
    lengthOfNotification:number
    // Customizable Area End
}

export default class InventoryMassUploadController extends BlockComponent<
    Props,
    S,
    SS
>{
  uploadCatalogueApiCallId:string=""
  getMassUploadRecordsApiCallId:string=""
  constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
      ];

      this.state = {
        // Customizable Area Start
          selectedFiles:null,
          selectedTab:0,
          openFileNotSupportedPopUp:false,
          loadingOnFileUpload:false,
          currentPage:1,
          dataList:[],
          endIndex:0,
          rowsCount:0,
          startIndex:0,
          totalCount:0,
        // Customizable Area End
      }
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let inventoryMassUploadApiCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
          );

          const inventoryMassUploadResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          if (inventoryMassUploadResponseJson) {
              if (inventoryMassUploadResponseJson.status === 500) {
                  this.props.setError("Internal Server Error");
                  this.removeFile()
                  return;
              }
              else if (inventoryMassUploadResponseJson.errors) {
                  this.removeFile()
                  this.props.setError(inventoryMassUploadResponseJson.errors)
                  console.log("####errors", inventoryMassUploadResponseJson.errors);
              }
              else if (!inventoryMassUploadResponseJson?.data) {
                  this.removeFile()
                  this.props.setError(inventoryMassUploadResponseJson.message)
              }
              else {
                  this.handleSuccessCallbacks(inventoryMassUploadApiCallId,inventoryMassUploadResponseJson)
              }
          }
      }
  }


  handleRecordPrevNext = (type: string) => {
    const { currentPage, rowsCount } = this.state;
    if(type === "previous" && currentPage > 1){
      this.setState({currentPage: currentPage-1},this.getRecords)
    }else if(type === "next" && currentPage < rowsCount + 1){
      this.setState({currentPage: currentPage+1},this.getRecords)
    }
  }

  handleRecordPagination = (e: any ,page: number) => {
    this.setState({currentPage: page},this.getRecords)
  }

  handleSuccessCallbacks = (apiRequestCallId:string, responseJson:any)=>{
    switch(apiRequestCallId){
      case this.uploadCatalogueApiCallId:{
        if(responseJson.data.data.attributes.error_file){
          this.handleDownloadFile("error.csv", responseJson.data.data.attributes.error_file.url)
        }else{
          this.props.setSuccess(responseJson.message)
        }

        this.state.currentPage === 0 ? this.setState({currentPage:1},this.getRecords) : this.getRecords()
        
        this.removeFile()
        break
      }
      case this.getMassUploadRecordsApiCallId:{
        this.handleRecordData(responseJson)
        break
      }
      default:{
        break
      }
    }      
  }

  handleRecordData = (responseJson:any)=>{
    if(responseJson.message){
      this.setState({dataList:[],startIndex:0,endIndex:0,currentPage:0,totalCount:0, rowsCount:0})
     }else{
      const { startIndex, endIndex, currentPage} = handlePaginationWithFilter({},responseJson.meta.page,responseJson.data,configJSON.rowsPerPage,responseJson.meta.total_count);
      const formatedData = responseJson.data.map((item:IMassUploadRecord,index:number)=>{
        return createMassUploadRecordData(item.attributes.created_at,item.attributes.file_name,item.attributes.total_count, item.attributes.uploaded_count,item.attributes.status)
      })
      this.setState({dataList:formatedData,startIndex,endIndex,currentPage,totalCount:responseJson.meta.total_count, rowsCount: Math.ceil(responseJson.meta.total_count/configJSON.rowsPerPage)})
     }
  }  

  removeFile = ()=>{
    this.setState({selectedFiles:null})
  }

  handleDownloadFile = (fileName:string,url:string) =>{
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getRecords = () =>{
     this.getMassUploadRecordsApiCallId = this.inventoryMassUploadApiCall({
      method: configJSON.getAPIMethod,
      endPoint: `${configJSON.getMassUploadRecordsApi}?page=${this.state.currentPage}&per_page=${configJSON.rowsPerPage}`
      })
  }


  handleNavigateToAddNewInventoryFromMassUpload = () => {
    this.props.history.push(configJSON.pathForNewInventory);
  }

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({selectedTab:newValue},()=>{
      if(this.state.selectedTab === 1){
        this.setState({currentPage:1},this.getRecords)      }
    });
  };
  
  handleClosePopUp = () => {
    this.setState({openFileNotSupportedPopUp:false})
  }

  handleDrop = (e:React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files
    const fileFormats = "text/csv"
    if(fileFormats === files[0].type){
      this.setState({selectedFiles:files[0]})
      const formdata = new FormData();
      formdata.append("file",files[0])
      this.uploadCatalogueApiCallId = this.inventoryMassUploadApiCall({
        type:"form-data",
        body:formdata,
        endPoint:configJSON.uploadProductCsvFileApi,
        method:configJSON.postAPIMethod,
      })
    }else{
      this.setState({openFileNotSupportedPopUp:true})
    }
  };

    handleFileChange = (e:any) => {
    const files = e.target.files as File[];
    const fileFormats = "text/csv"
    if(fileFormats === files[0].type){
      this.setState({selectedFiles:files[0]})
      const formdata = new FormData();
      formdata.append("file",files[0])
      this.uploadCatalogueApiCallId = this.inventoryMassUploadApiCall({
        type:"form-data",
        body:formdata,
        endPoint:configJSON.uploadProductCsvFileApi,
        method:configJSON.postAPIMethod
      })
    }else{
      this.setState({openFileNotSupportedPopUp:true})
    }
  };

  inventoryMassUploadApiCall = (data: any) => {
      const { contentType, method, endPoint, body, type} = data;
      let token = window.localStorage.getItem("token");
      let header:Record<string,string|undefined|null> = {
        token,
      };
      if(contentType){
        header = {
          ...header,
          "Content-Type":contentType
        }
      }
      const inventoryMassUploadApiCallApiMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      inventoryMassUploadApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      inventoryMassUploadApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      inventoryMassUploadApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      inventoryMassUploadApiCallApiMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "form-data"? body : JSON.stringify(body) 
      );
      runEngine.sendMessage(inventoryMassUploadApiCallApiMessage.id, inventoryMassUploadApiCallApiMessage)
      return inventoryMassUploadApiCallApiMessage.messageId;
    }
}
