import React from "react";

export const Warehouse = () => {
    return (
        <svg width="18px" height="15.81155px" viewBox="0 0 18 15.81155" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vendor-Managemtent-wrehouse1-Copy" transform="translate(-24.000000, -157.000000)" fill="#000000" fillRule="nonzero">
            <g id="warehouse_FILL0_wght200_GRAD0_opsz24-(1)" transform="translate(24.000000, 157.000000)">
                <path d="M9,0.96155 L1.384625,4.0077 C1.269225,4.05898333 1.176275,4.13590833 1.105775,4.238475 C1.03525833,4.34104167 1,4.456425 1,4.584625 L1,14.196175 C1,14.3756583 1.05769167,14.5230917 1.173075,14.638475 C1.28845833,14.7538583 1.43589167,14.81155 1.615375,14.81155 L4,14.81155 L4,7.81155 C4,7.5436 4.099675,7.30995 4.299025,7.1106 C4.49839167,6.91123333 4.73205,6.81155 5,6.81155 L13,6.81155 C13.26795,6.81155 13.5016083,6.91123333 13.700975,7.1106 C13.900325,7.30995 14,7.5436 14,7.81155 L14,14.81155 L16.384625,14.81155 C16.5641083,14.81155 16.7115417,14.7538583 16.826925,14.638475 C16.9423083,14.5230917 17,14.3756583 17,14.196175 L17,4.584625 C17,4.456425 16.9647417,4.34104167 16.894225,4.238475 C16.823725,4.13590833 16.730775,4.05898333 16.615375,4.0077 L9,0.96155 Z M5,15.81155 L1.615375,15.81155 C1.16794167,15.81155 0.78685,15.6541833 0.4721,15.33945 C0.157366667,15.0247 0,14.6436083 0,14.196175 L0,4.584625 C0,4.244875 0.0932666667,3.94038333 0.2798,3.67115 C0.466333333,3.40193333 0.712808333,3.20514167 1.019225,3.080775 L8.40385,0.130775 C8.59871667,0.0435916667 8.79743333,0 9,0 C9.20256667,0 9.40128333,0.0435916667 9.59615,0.130775 L16.980775,3.080775 C17.2871917,3.20514167 17.5336667,3.40193333 17.7202,3.67115 C17.9067333,3.94038333 18,4.244875 18,4.584625 L18,14.196175 C18,14.6436083 17.8426333,15.0247 17.5279,15.33945 C17.21315,15.6541833 16.8320583,15.81155 16.384625,15.81155 L13,15.81155 L13,7.81155 L5,7.81155 L5,15.81155 Z M6.5577,15.81155 L6.5577,14.2731 L8.09615,14.2731 L8.09615,15.81155 L6.5577,15.81155 Z M8.230775,12.81155 L8.230775,11.2731 L9.769225,11.2731 L9.769225,12.81155 L8.230775,12.81155 Z M9.90385,15.81155 L9.90385,14.2731 L11.4423,14.2731 L11.4423,15.81155 L9.90385,15.81155 Z M13,6.84905 L5,6.84905 L13,6.84905 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>)
}