Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethod = "GET";
exports.inventoryAPiEndPoint = "bx_block_catalogue/catalogues";
exports.postAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.deletApiMethod = "DELETE";

exports.multipartContentType = "multipart/form-data";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "inventorymanagement2";
exports.labelBodyText = "inventorymanagement2 Body";
exports.getLinkedDarkstoreEndPoint = "/account_block/dark_stores/get_linked_store";
exports.categoriesEndPoint = "bx_block_categories/categories";
exports.subcategoriesEndPoint = "bx_block_categories/sub_categories?category_id=";
exports.brandEndPoint = "/bx_block_catalogue/brands";
exports.weightUnitEndPoint="bx_block_catalogue/units";

exports.searchItemEndPoint = "/bx_block_catalogue/catalogues/search_catalogue";
exports.searchItemApiMethod = "GET";
exports.deleteImageApiEndPoint = "bx_block_catalogue/catalogues/delete_image"
exports.generalContentType = "application/json";

exports.btnExampleTitle = "CLICK ME";
exports.rowsPerPage = 5;
exports.productsPerPage = 15;

exports.pathForNewInventory="/add-inventory"

exports.darkstoreRows = [
  {key:"sNo", label:"S No"},
  {key:"itemDetail", label:"Item Details"},
  {key:"itemCode", label:"Item Code"},
  {key:"quantity", label:"Quantity"},
  {key:"stockLevel", label:"Stock Level"}
];
exports.inventoryListRows = [
  {key:"sNo", label:"S No"},
  {key:"itemDetail", label:"Item Details"},
  {key:"itemCode", label:"Item Code"},
  {key:"quantity", label:"Quantity"},
  {key:"stockLevel", label:"Stock Level"}
];
exports.INDEX_OF_MASS_UPLOAD_TEMPLATE = 0
exports.INDEX_OF_MASS_UPLOAD_FILE = 1
exports.INDEX_OF_MASS_UPDATE_GENERATE = 0
exports.INDEX_OF_MASS_UPDATE_FILE = 1
exports.massUploadRows = [
  {key:"date", label:"Date"},
  {key:"file", label:"File Name"},
  {key:"product", label:"Products"},
  {key:"status", label:"Status"}
];
exports.massUpdateProductFileHeader = ["Date","File Name", "Processed","Action"]
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.getDownloadTemplateDataApi = "/bx_block_catalogue/catalogues/download_template"
exports.uploadProductCsvFileApi = "/bx_block_catalogue/catalogues/import"
exports.getMassUploadRecordsApi = "/bx_block_catalogue/catalogues/import_logs"
exports.uploadItemsCodesApi ="/bx_block_catalogue/catalogues/check_item_codes"
exports.exportProductsApi = "/bx_block_catalogue/catalogues/export"
exports.DEV_URL = "https://vmart-319951-ruby.b319951.dev.eastus.az.svc.builder.cafe/"
exports.STAGE_URL = "https://vmart-319951-ruby.b319951.stage.eastus.az.svc.builder.ai/"
exports.BE_URL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT"

exports.getDarkstoreIdApi = "/account_block/dark_stores/get_linked_store"
exports.getInventoryList = "/bx_block_catalogue/dark_store_catalogues"
exports.getInventoryDetails="/bx_block_catalogue/catalogues"
// Customizable Area End