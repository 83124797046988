import { Box, Grid, Typography, WithStyles, withStyles, Popover, createTheme, ThemeProvider, Button, SvgIcon, Menu } from "@material-ui/core";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { foramtedDateForNotification } from "./CommonFunctions";

interface INotification  {id:number,notification:string,date:string}

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
    anchorEl: null | HTMLElement;
    handleClose: () => void;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    notificationList:INotification[]
    clearNotification : ()=>void
}

const theme = createTheme({
    palette: {
        secondary: {
            light: '#662D91',
            main: '#662D91',
        } 
    },
    typography: {
      fontFamily: "Plus Jakarta Sans",
      fontWeightLight: "normal",
      fontWeightRegular: "normal",
      h4:{
        fontSize:"20px"
      },
      h5:{
        fontSize:"18px",
        fontWeight:700
      },
      h6:{
        fontSize:"16px",
        fontWeight:600
      },
      subtitle1:{
        fontSize:"14px",
        fontWeight:500
      },
      subtitle2:{
        fontSize:"12px",
        fontWeight:400
      }
    },
  });
  

const styles:any = {
    popover:{
        "& > div":{
            bottom:"inherit"
        }
    },
    gilroyBold:{
        fontWeight:500,
        fontFamily: "gilroy-bold !important"
     },
     gilroyMedium:{
        fontWeight:500,
        fontFamily: "gilroy-medium"
     },
     gilroyRegular:{
        fontWeight:500,
        fontFamily: "gilroy-regular"
     },
     notification:{
        display:"flex",
        gap:"3px"
     },
     notification_svg_container:{
        paddingTop:"6px",
        display:"flex",
        marginRight:"5px",
        paddingRight:"5px"
     },
     notification_content:{
        paddingRight:"10px",
        paddingLeft:"10px"
     },
     spacing:{
        marginBottom:"10px"
     },
     clearNotification:{
        display:"flex",
        justifyContent:"end",
        marginRight:"10px",
        "& span":{
            cursor:"pointer",
            fontSize:"16px"
        }
     }
}

class AppNotification extends React.Component<Props>{

    constructor(props: any) {
        super(props)
    }

    render(){
        const {classes, anchorEl, handleClick, handleClose ,notificationList,clearNotification} = this.props

        return(
            <>
            <ThemeProvider theme={theme}>
                <Popover
                id="app-notification"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                className={classes.popover}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                >
                    <Grid container spacing={2} style={{maxWidth:"550px", padding:"25px", minWidth:"500px"}}>
                        <Grid item xs={12}>
                            <Box style={{display:"flex" , justifyContent:"space-between"}}>
                                <Typography variant="h6" className={classes.gilroyBold} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                    Notification
                                </Typography>
                                <Button onClick={handleClose}>
                                    <CloseIcon />
                                </Button>
                            </Box>
                        </Grid>
                       {notificationList.length > 0 && <Grid item xs={12}>
                            <Box className={`${classes.clearNotification} ${classes.gilroyMedium}`}>
                                <span onClick={clearNotification}>Clear All</span>
                            </Box>
                        </Grid>}
                        {notificationList.length > 0 && notificationList.map((notification:INotification)=>(
                            <Grid xs ={12} key ={notification.id}>
                            <Box className={classes.notification} >
                                <Box className={classes.notification_svg_container}>
                                    <SvgIcon style={{height:"10px" , width:"10px", color:"rgba(102, 45, 145, 0.40)"}}>
                                        <FiberManualRecordRoundedIcon/>
                                    </SvgIcon>
                                </Box>
                                <Box style={{}}>
                                    <Typography variant="subtitle1" className={classes.gilroyMedium}>{notification.notification}</Typography>
                                    <Typography variant="subtitle2" className={classes.gilroyRegular} style={{color:"#9F9F9F"}}>{foramtedDateForNotification(notification.date)}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        ))}
                        {notificationList.length===0&& ( <Grid xs ={12} style={{display:"flex",justifyContent:"center"}}>
                            <Typography variant="h6" className={classes.gilroyMedium} style={{color:"#9F9F9F"}} >
                            No Notification
                            </Typography>

                        </Grid>)}
                    </Grid>
                </Popover>
            </ThemeProvider>
            </>
        )
    }
}

export {AppNotification}
export default withStyles(styles)(withRouter(AppNotification))