import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { paginate,checkIsFinite, roundNumber } from "../../../components/src/CommonFunctions";
import XLSX from "xlsx";
import { DashboardTableHeaders, ResponseType ,exportDataType, IGlobaldashboardTableData} from "./types";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";



// Customizable Area End

export const configJSON = require("./config");
export const tableHeaders = configJSON.GlobalStoreTableHeaders;
export const globalDashboardHeadersObject=configJSON.globalDashboardRows;


interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  currentPage: number;
  dataList:IGlobaldashboardTableData[]
  filteredDataList:IGlobaldashboardTableData[],
  rowsCount: number;
  startIndex: number;
  openMenu: null | HTMLElement;
  tableHeadings: DashboardTableHeaders[];
  endIndex: number;
  dateRange:Date[];
  isAllSelected: boolean;
  visibleLabels: string[];
  totalRecords : number;
  globalstoreDashboardTableHeadings:DashboardTableHeaders[],
  searchQuery:string,
  isModal:boolean

  // Customizable Area End
}
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  groups: { [key: string]: boolean };
  classes: any;
  userType : string;
  headerTitle: string;
  headerAction: ()=> void;
  userProfile: {};
  setUserProfile: ()=> void;
  setError: ()=> void;
  hasAccess: boolean;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
};
export default class GlobalStoreDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGlobalstoreDashboardApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      visibleLabels: [],
      searchQuery:"",
      currentPage: 1,
      isAllSelected: true,
      filteredDataList:[],
      dataList: [],
      openMenu: null,
      rowsCount: 0,
      startIndex: 0,
      tableHeadings: [],
      endIndex: 0,
      dateRange: [new Date(), new Date()],
      totalRecords : 0,
      globalstoreDashboardTableHeadings:[],
      isModal:false

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getGlobalstoreDashboardApiCallId) {
        const finalData = responseJson && responseJson.data.map((ele: ResponseType) => {
          const average_inventory= roundNumber((ele.opening_count + ele.closing_count) / 2);
          const closing_inventory_value = roundNumber(Number(ele.closing_count) * Number(ele.unit_purchase_price));
          const opening_inventory_value =roundNumber( Number(ele.opening_count) * Number(ele.unit_purchase_price));
          const inwards_value =roundNumber( Number(ele.inward_quantity) * Number(ele.unit_purchase_price));
          const outwards_value =roundNumber( Number(ele.outward_quantity) * Number(ele.unit_purchase_price));
          const costs_of_goods_sold =roundNumber( (opening_inventory_value + Number(inwards_value)) - closing_inventory_value);
          const inventory_turnover_ratio=roundNumber( checkIsFinite((costs_of_goods_sold*100)/average_inventory ));
          const days_on_hands= roundNumber(checkIsFinite((Number(average_inventory) * 365) / costs_of_goods_sold ));
          const selling_price=roundNumber(Number(ele.sale_price));
          const sales=roundNumber(Number(ele.outward_quantity)* selling_price);
          const stocks_to_sales_ratio= roundNumber(checkIsFinite((closing_inventory_value / sales )));
          const margin_per_unit=roundNumber(selling_price - Number(ele.unit_purchase_price));
          const gross_margin=roundNumber(margin_per_unit* Number(ele.outward_quantity));
          const gross_margin_by_product= roundNumber(checkIsFinite((Number(gross_margin)/ sales) ))



          // Days On Hand
          return {
            ...ele,
             average_inventory,           opening_inventory_value,
            inwards_value,
            outwards_value,
            closing_inventory_value,
            costs_of_goods_sold,
            inventory_turnover_ratio,
            days_on_hands,
            selling_price,
            sales,
            stocks_to_sales_ratio,
            margin_per_unit,
            gross_margin,
            gross_margin_by_product

          }
        })

        const meta = responseJson.meta;
        const data = responseJson.data;
        const page = meta.page;
        
        const rowsPerPage = configJSON.rowsPerPage;        
        
        let updatedEndIndex = rowsPerPage*page;
        let endIndex = rowsPerPage*(page -1) + data.length;
        if(updatedEndIndex > endIndex){
          updatedEndIndex = endIndex;
        }

        this.setState({
          dataList:finalData,  
          filteredDataList:finalData,
          rowsCount: Math.ceil(meta.total_count / rowsPerPage),
          totalRecords : meta.total_count,
          startIndex: rowsPerPage*page - rowsPerPage, 
          endIndex: updatedEndIndex
        })

      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getGlobalstoreDashboardData()
    const checkedHeadings = tableHeaders.map((ele:{[key : string]: string | boolean})=> ({ ...ele, isChecked : true }) )
    this.setState(
      {
        tableHeadings : checkedHeadings,
        visibleLabels: checkedHeadings.map((heading:DashboardTableHeaders) => heading.label),
        globalstoreDashboardTableHeadings:checkedHeadings,
      },
      () =>
        this.handleGlobalStoreDashboardPagination({}, this.state.currentPage)
    );
  }

  
  handleGlobalStoreDashboardPagination = (e: any, page: number) => {
    this.setState({currentPage:page},()=>this.getGlobalstoreDashboardData())
  };

  handleGlobalStoreDashboardPrevNext = (type: string) => {
    const { currentPage, rowsCount } = this.state;
    if (type === "previous" && currentPage > 1) {      
      this.handleGlobalStoreDashboardPagination({}, currentPage - 1);
    } else if (type === "next" && currentPage < rowsCount + 1) {
      this.handleGlobalStoreDashboardPagination({}, currentPage + 1);
    }
  };

  
  handleOpenColumnMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      openMenu: event.currentTarget,
    });
  };

  handleSelectColumnFilter = (element:DashboardTableHeaders) => {
    const updatedColumnList = this.state.tableHeadings.map(
      (heading: DashboardTableHeaders) => {
        return heading.label == element.label
          ? { ...heading, isChecked: !heading.isChecked }
          : { ...heading };
      }
    );
    this.setState(
      {
        tableHeadings: [...updatedColumnList],
      },
      () => {      
        this.setState({
          isAllSelected: this.state.tableHeadings.every((ele)=> ele.isChecked )
        });
      }
    );
  };

  handleCloseColumnMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  handleDateChanger = (dateArr: Date[]) => {
    const StartDate=0;
    const EndDate=1;

    const maxEndDateRange = new Date(dateArr[StartDate]);
    maxEndDateRange.setMonth(maxEndDateRange.getMonth() + 1);

  if (dateArr[EndDate] > maxEndDateRange) {
    this.setState({isModal:true})
    return;
  }
        this.setState({
      dateRange: dateArr,
    },()=>{
      if(dateArr[StartDate] && dateArr[EndDate]){

        this.getGlobalstoreDashboardData();
      }
    })

  }

  handleClose = ()=>{
    this.setState({isModal:false})
   }

  handleExportData = () => {
    const exportData: exportDataType[] = [];
    this.state.filteredDataList.forEach((ele: IGlobaldashboardTableData, index: number) => {
      let arr: exportDataType = [];
      for (let key in globalDashboardHeadersObject) {
        if (this.state.visibleLabels.includes(globalDashboardHeadersObject[key])) {  
          arr.push( ele[key as keyof IGlobaldashboardTableData] || 0);
        }
      }
      exportData.push([index + 1, ...arr]);
    })
    const excelRecords = [
      [
        "Sr No",
        ...this.state.visibleLabels.map(
          (heading: string) => heading
        ),
      ],
      ...exportData
    ];

    let xcel: any = {
      SheetNames: ["Dashboard"],
      Sheets: {
        Dashboard: XLSX.utils.aoa_to_sheet(excelRecords),
      },
      Props: {
        title: "Dashboard Report",
      },
    };
    XLSX.writeFile(xcel, "DashboardReport.xlsx");
  };

  handleSelectAllColumns = () => {
    this.setState(
      {
        isAllSelected: !this.state.isAllSelected,
      },
      () => {
        const allSelectedColumns = this.state.tableHeadings.map(
          (heading: DashboardTableHeaders) => {
            return {
              ...heading,
              isChecked: heading.mandatory
                ? heading.isChecked
                : this.state.isAllSelected,
            };
          }
        );
        this.setState({
          tableHeadings: [...allSelectedColumns],
        });
      }
    );
  };

  handleDataColumnsSave = () => {
    const visibleHeadersArr: string[] = [];
    this.state.tableHeadings.forEach((heading: DashboardTableHeaders) => {
      if (heading.isChecked) {
        visibleHeadersArr.push(heading.label);
      }
    });
    this.setState({
      globalstoreDashboardTableHeadings: [...this.state.tableHeadings],
      visibleLabels: visibleHeadersArr,
      openMenu: null,
    });
  };

  handleSearchItem=(searchQuery:string)=>{
    this.setState({
      searchQuery:searchQuery,
      currentPage:1
    },this.getGlobalstoreDashboardData)
  }

 
    
  getGlobalstoreDashboardData = () => {
        const header = {
          "Content-Type": configJSON.apiContentType,
          token: localStorage.getItem("token"),
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getGlobalstoreDashboardApiCallId = requestMessage.messageId;
    const startDate=moment(this.state.dateRange[0]).format("DD-MM-YYYY");
    const endDate=moment(this.state.dateRange[1]).format("DD-MM-YYYY")

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.globalStoreDashboardEndPoint}?start_date=${startDate}&end_date=${endDate}&page=${this.state.currentPage}&per_page=10&search=${this.state.searchQuery}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };  
  // Customizable Area End
}
