import React from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";
import { Typography, withStyles } from "@material-ui/core";
import DarkStoreDashboardController, {
  Props,
} from "./DarkStoreDashboardController.web";
import DarkStoreDashboardLayout from "../../../components/src/DarkStoreDashboardLayout.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import CustomPagination from "../../../components/src/Pagination.web";
import DarkStoreDashboardTable from "../../../components/src/DarkStoreDashboardTable.web";
import { NoPermissionLabel } from "../../../components/src/CommonFunctions";
import DashboardHeaderFilters from "../../../components/src/DashboardHeaderFilters";
import AlertPopup from "../../../components/src/AlertPopup.web";

export const configJSON = require("./config");

const styles: any = {
  table: {
    maxWidth: "1172px",
  },
  inputCheckBox: {
    accentColor: "#662D91",
    width: "16px",
    height: "16px",
  },
  exportBtn: {
    fontFamily: "gilroy-medium",
    width: "124px",
    height: "40px",
    backgroundColor: "#662D91",
    color: "white",
    "&:hover": {
      backgroundColor: "#662D91",
    },
  },
  date_picker: {
    borderRadius: "14px",
    fontFamily: "gilroy-medium !imortant",
    backgroundColor: "#E7E7E7",
    color: "red",
    "& .react-datepicker__day--in-range": {
      display: " block",
      border: "10px solid red",
      width: "250px",
      marginRight: "13px",
      marginLeft: "3px",
      borderRadius: "1.3rem",
      backgroundColor: "red",
      color: "black",
    },
  },
   
  columnSaveBtn: {
    width: "206px",
    height: "40px",
    backgroundColor: "#662D91",
    color: "white",
    margin: "auto",
    marginTop: "20px",
    position: "sticky",
    "&:hover": {
      backgroundColor: "#662D91",
    },
  },
  dataSelection: {
    width: "159px",
    height: "40px",
    backgroundColor: "#f9f9f9",

    "& .MuiButton-label": {
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
      fontFamily: "gilroy-medium",
    },
  },
 
};

class DarkStoreDashboard extends DarkStoreDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes, groups, ...restProps } = this.props;
    const {
      currentPage,
      endIndex,
      startIndex,
      rowsCount,
      tableHeadings,
      totalRecords,
      openMenu,
      isAllSelected,
      dateRange,
      currentDataList,
      darkstoreDashboardTableHeadings,
      visibleLabels,
      searchQuery,
    } = this.state;

    return (
      <DarkStoreDashboardLayout
        {...restProps}
        headerTitle="Dashboard"
        headerAction=""
        actionHandler={undefined}
        subTitle=""
        hasAccess={groups?.dashboard}
      >
        {groups && !groups.dashboard ? (
          <NoAccessPage className="no-access" description={NoPermissionLabel} />
        ) : (
          <div className="main_container">
            <DashboardHeaderFilters
              searchQuery={searchQuery}
              handlesearchItem={this.handleSearchItem}
              configJSON={configJSON}
              classes={classes}
              handleSelectAllColumns={this.handleSelectAllColumns}
              handleExportData={this.handleExportData}
              handleOpenColumnMenu={this.handleOpenColumnMenu}
              openMenu={openMenu}
              handleCloseColumnMenu={this.handleCloseColumnMenu}
              isAllSelected={isAllSelected}
              tableHeadings={tableHeadings}
              handleSelectColumnFilter={this.handleSelectColumnFilter}
              handleDataColumnsSave={this.handleDataColumnsSave}
              dateRange={dateRange}
              handleDateChange={this.handleDateChange}
              visibleLabels={visibleLabels}
            />
            <DarkStoreDashboardTable
               currentPage={currentPage}
               perPageCount={configJSON.rowsPerPage}
              tableRowsData={currentDataList}
              visibleLabels={visibleLabels}
              tableHeadings={darkstoreDashboardTableHeadings}
              noOfOrders = {this.state.noOfOrders}
              orderCycleData={this.state.orderCycleTimeCurrentData}
            />
            {!groups && <Typography className="no_records_found">No Records found</Typography>}

            <div className={classes.pagination}>
             {!visibleLabels.includes("No Of Orders") &&  !visibleLabels.includes("Order Cycle Time") &&  <CustomPagination
                dataLength={totalRecords}
                handlePrevNext={this.handleDarkStoreDashboardPrevNext}
                currentPage={currentPage}
                startIndex={startIndex}
                rowsCount={rowsCount}
                handlePagination={this.handleDarkStoreDashboardPagination}
                endIndex={endIndex}
              />}

              {visibleLabels.includes("Order Cycle Time") &&  <CustomPagination
                dataLength={this.state.orderCycleTimeData.length}
                handlePrevNext={this.handleDarkStoreOrderClyclePrevNext}
                currentPage={this.state.orderClycleCurrentPage}
                startIndex={this.state.orderClycleStartIndex}
                rowsCount={this.state.orderClycleRowsCount}
                handlePagination={this.handleDarkStoreOrderClyclePagination}
                endIndex={this.state.orderClycleEndIndex}
              />}
            </div>

            {this.state.isModal&& <AlertPopup description={<Typography variant="h6">The Date interval exceeds 1 month. Please select a valid range (within 1 month)</Typography>} 
                handleClose={this.handleClose}
                action="OK" 
                isOpen={this.state.isModal} 
                cancelHandler={this.handleClose}
                actionHandler={this.handleClose}         
            />}
          </div>
        )}
      </DarkStoreDashboardLayout>
    );
    // Customizable Area Start
  }
}

// Customizable Area Start
export { DarkStoreDashboard };
export default withRouter(withStyles(styles)(DarkStoreDashboard));
// Customizable Area End
