import React from "react";
import {
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  withStyles,
  Typography,
} from "@material-ui/core";
import { ItemTableProps } from "./types";

const styles : any = {
   
   tableHeader:{
    backgroundColor:"rgba(245, 245, 250, 1)",
    "& th":{
      color: "#8181A5",
      fontFamily: "gilroy-bold",
      fontSize: "12px",
      textAlign : "center"
    }
   },
   tableBody:{
    "& td":{
      borderRight:"1px solid rgba(224, 224, 224, .40)", 
      textAlign:"center",
      color: "#1C1D21",
      fontFamily:" gilroy-medium",
      fontSize:" 14px",
    }
   },
   tableCell:{
    color: "#8181A5 !important",
    fontFamily: "gilroy-regular !important",
    fontSize: "14px !important",
   },
   radioBtn:{
    accentColor:"#000",
}
}

const StyledInput = styled(TextField)({
  "& input":{
      textAlign:"center",
      color: "#808080",
      fontFamily: "gilroy-regular",
      fontSize: "14px",
  }
})

class PurchaseOrderItemTable extends React.Component<ItemTableProps> {
  constructor(props: ItemTableProps) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { itemData, handleChange,classes } = this.props;
    return (
      <Table className="catalogueItemTable" >
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell  >Select</TableCell>
            <TableCell  >Item Code</TableCell>
            <TableCell  >Product Name</TableCell>
            <TableCell  >HSN Code</TableCell>
            <TableCell  >Selling Price</TableCell>
            <TableCell  >Quantity</TableCell>
            <TableCell  >Unit Purchase Price</TableCell>
            <TableCell  >Total Purchase Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {itemData.map((element: any) => {
            return (
              <TableRow key={element.catalogue_id}>
                <TableCell  >
                  <input
                    type="radio"
                    className={classes.radioBtn}
                    id={element.catalogue_id}
                    name={element.catalogue_id}
                    checked={element.checked}
                    onClick={() =>
                      handleChange(
                        "checked",
                        element.catalogue_id,
                        !element.checked
                      )
                    }
                  />
                </TableCell>
                <TableCell >{element.item_code}</TableCell>
                <TableCell >{element.catalogue_name}</TableCell>
                <TableCell className={classes.tableCell} >{element.hsn_code}</TableCell>
                <TableCell >{
                  `₹${element.price || element.catalogue_price || 0}`
                  }</TableCell>
                <TableCell >
                  <StyledInput
                  data-test-id="quantityFieldID"
                    type="number"
                    error = {true} 
                    variant="outlined"
                    value={Math. trunc(element.quantity)}
                    helperText = {element?.quantity === 0 ?"Atleast add 1 item" :element?.quantity?.toString().trim() === ""  ? "Quantity should not be emplty":""}  
                    onChange={(e: any) =>
                      handleChange("quantity", element.catalogue_id, e.target.value)
                    }
                    style={{ width : '100px' }}
                  />
                </TableCell>
                <TableCell >
                  <StyledInput
                  data-test-id="unitPurchasePriceFieldID"
                    type="number"
                    variant="outlined"
                    value={Math. trunc(element.unit_purchase_price)}
                    onChange={(e: any) =>
                      handleChange(
                        "unit_purchase_price",
                        element.catalogue_id,
                        e.target.value
                      )
                    }
                    InputProps={{
                      startAdornment:<Typography style={{color:"#8181A5 "}}>₹</Typography>
                  }}
                  style={{ width : '100px' }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} >
                  {`₹${element.quantity * element.unit_purchase_price}`}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
    // Customizable Area End
  }
}

export {PurchaseOrderItemTable}
export default withStyles(styles) (PurchaseOrderItemTable);