import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import {
  handlePaginationWithFilter,
  createPurchaseOrdersData,
  formatStatus,
} from "../../../components/src/CommonFunctions";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import {  IPurchaseOrderTableView } from "./types";
// Customizable Area End

export const configJSON = require("./config");
export const rows = configJSON.purchaseOrdersRows;
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  searchQuery:string;
  fliterStatus:string;
  currentPage: number;
  currentDataList: any;
  dataList: any;
  rowsCount: number;
  startIndex: number;
  endIndex: number;
  totalRecords: number;


  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  userProfile:any;
  setError:any;
  setUserProfile:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number;
  history:any
  // Customizable Area End
}
export default class PurchaseOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getItemsApiCallId: string = "";
  searchDebounceTimer: NodeJS.Timeout | null = null

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      fliterStatus:" ",
      searchQuery:"",
      currentPage: 1,
      currentDataList: [],
      dataList: [],
      rowsCount: 0,
      startIndex: 0,
      endIndex: 0,
      totalRecords: 0

      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getItemsApiCallId) {
        const formattedData = responseJson.data ? responseJson.data.map((ele: any) => {
          return createPurchaseOrdersData(
            ele.id,
            ele.attributes.po_number,
            ele.attributes.supplier? ele.attributes.supplier.attributes.name : "",
            ele.attributes.creator_email,
            ele.attributes.created_at,
            ele.attributes.delivery_date,
            ele.attributes.total_purchase_price,
            formatStatus(ele.attributes.status)
          );
        }) : [];
        this.setState({
          dataList:formattedData,
          rowsCount:Math.ceil(responseJson?.meta?.total_count/configJSON.rowsPerPage),
          totalRecords: responseJson?.meta?.total_count,
        },()=>{
          this.handlePurchaseOrdersPagination({},this.state.currentPage)
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const token = localStorage.getItem("token");
    const { history } = this.props;
    if (history.location.state && history.location.state.purchaseOrdercurrentPage) {
     this.setState({currentPage:history.location.state.purchaseOrdercurrentPage,fliterStatus:history.location.state.fliterStatus},()=>this.getPurchaseOrderList(token))
    }
    else{
      this.getPurchaseOrderList(token);
    }       
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentPage !== this.state.currentPage) {
            const token = localStorage.getItem("token");
      this.getPurchaseOrderList(token)
    }
  }

  handlePurchaseOrdersPagination = (e: any , page: number) => {
    const { dataList, totalRecords } = this.state;
    const { startIndex, endIndex, currentDataList, currentPage } = handlePaginationWithFilter(
      {},
      page,
      dataList,
      configJSON.rowsPerPage,
      totalRecords
    );
    this.setState({
      startIndex,
      endIndex,
      currentDataList, 
      currentPage,
    });
  }
    
  handlePurchaseOrdersPrevNext = (type: any) => {
    const { currentPage, rowsCount } = this.state;
    if (type === "previous" && currentPage > 1) {
      this.handlePurchaseOrdersPagination({},currentPage - 1);
    } else if (type === "next" && currentPage < rowsCount + 1){
      this.handlePurchaseOrdersPagination({},currentPage + 1);
    }
  }

  handleNavigateToAddPurchaseOrder = () => {
    this.props.history.push(configJSON.createPurchaseOrderURL);
  };

  handleNavigateToEditPurchaseOrder = (e:any,orderID:string) =>{
    e.stopPropagation()
    this.props.history.replace({
      ...this.props.history.location,
      state: {
        ...this.props.history.location.state,
        purchaseOrdercurrentPage: this.state.currentPage,
        fliterStatus:this.state.fliterStatus
      }
    });
      this.props.history.push({
        pathname: configJSON.editPurchaseOrderURL+orderID
      });     
  }

  handleViewPurchaseOrder = (e:any,orderID:string) =>{
    e.stopPropagation()
    this.props.history.replace({
      ...this.props.history.location,
      state: {
        ...this.props.history.location.state,
        purchaseOrdercurrentPage: this.state.currentPage,
        fliterStatus:this.state.fliterStatus
      }
    });
    this.props.history.push({
      pathname: configJSON.viewPurchaseOrder+orderID});  }

  getPurchaseOrderList = (token: string | null) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPurchaseOrderList}?page=${this.state.currentPage}&per_page=${configJSON.rowsPerPage}&search_query=${this.state.searchQuery}&status=${this.state.fliterStatus}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(() => {
    const token = localStorage.getItem("token");

     
     this.setState({currentPage:1},()=>{
      this.getPurchaseOrderList(token)
     })
    }, 300);
  }

  handleSearchItem = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
   
      this.setState({searchQuery:event.target.value},this.handleSearchDebounce)
  }



  filterByStatus =(event:React.ChangeEvent<{ value: unknown }>)=>{
    const token = localStorage.getItem("token");
    const status = event.target.value as string
    this.setState({currentPage:1, fliterStatus:status,searchQuery:""},()=>{
      this.getPurchaseOrderList(token)
     })
  }
  // Customizable Area End
}

